import { useEffect, useState } from 'react';
import ApiDatabase from 'server';
import {
  Building,
  Calendar,
  ExternalLink,
  LinkIcon,
  ReferenceIcon,
  SearchLoop,
  Signer,
  TrashIcon,
} from '../../../assets/icons';
import EmptyState from '../../../components/EmptyState/EmptyState';
import useSlideOver from '../../../components/SlideOver/useSlideOver';
import BadgeSquare from '../../../components/badge/badgeSquare';
import Button from '../../../components/button';
import CopyToClipboard from '../../../components/copyToClipboard';
import useModal from '../../../components/modal/useModal';
import PageForm from '../../../components/pageForm/PageForm';
import Pagination from '../../../components/pagination/pagination';
import StyledInput from '../../../components/styledInput/StyledInput';
import TempWorkerMission from '../../../components/tempWorkerMission/tempWorkerMission';
import ThreeStateCheckbox from '../../../components/threeStateCheckbox/threeStateCheckbox';
import { dateWordShort } from '../../../helper/helper';
import UseContext from '../../../hooks/useContext';
import usefetchdata from '../../../hooks/usefetchdata';
import AdminMissionDetails from './adminMissionDetails';

const MissionPeriod = () => {
  const { objID, setObjID, searchMissionResult, setSearchMissionResult } =
    UseContext();
  const { toggleSlideOver } = useSlideOver();
  const [searchMissionResultWaiting, setSearchMissionResultWaiting] =
    useState(false);
  const [isMissionSearchAssigned, setIsMissionSearchAssigned] = useState(null);
  const [isMissionSearchSigned, setIsMissionSearchSigned] = useState(null);
  const [isMissionSearchSignatureSend, setIsMissionSearchSignatureSend] =
    useState(null);
  const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(false);
  const [isInfoMissionLoadData, setIsInfoMissionLoadData] = useState(false);
  const [editMission, setEditMission] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limitElement, setLimitElement] = useState(10);
  const [countPerPage, setCountPerPage] = useState(10);
  const [countTotal, setCountTotal] = useState(0);
  const [hoveredCompanyItems, setHoveredCompanyItems] = useState({});

  const [search, setSearch] = useState('');

  const [typeContract, setTypeContract] = useState([
    { type: 'sendToSign', name: 'Envoyé pour signature', checked: false },
    {
      type: 'toBeSentToSign',
      name: 'À envoyer pour signature',
      checked: false,
    },
    { type: 'signByCompany', name: 'Signé par l’entreprise', checked: false },
    {
      type: 'signByTempWorker',
      name: 'Signé par l’intérimaire',
      checked: false,
    },
    { type: 'notCreated', name: 'Contrat non généré', checked: false },
  ]);

  const [departments, setDepartments] = useState([
    { name: 'Martinique', checked: false },
    { name: 'Guadeloupe', checked: false },
  ]);

  const [missionStatus, setMissionStatus] = useState([
    { type: 'all', name: 'Tous les status', checked: true },
    { type: 'done', name: 'Terminé', checked: false },
    { type: 'inProgress', name: 'En cours', checked: false },
    { type: 'soon', name: 'À venir', checked: false },
  ]);

  const { toggle } = useModal();
  const { userData } = usefetchdata();
  const [role, setRole] = useState(500);
  const token = localStorage.getItem('xsrfToken');

  useEffect(() => {
    if (userData?.role) {
      setRole(userData.role);
    }
  }, [userData]);

  const getDepartmentMissions = () => {
    ApiDatabase.getDepartmentMissions(
      { token },
      (data) => {
        if (data.departments.length > 0) {
          const departments = data.departments.map((department) => ({
            name: department,
            checked: false,
          }));
          setDepartments(departments);
        }
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
        }
      }
    );
  };

  const manageMissionSearch = () => {
    setSearchMissionResultWaiting(true);

    setIsInfoMissionLoadData(false);
    setIsInfoMissionWaiting(false);
    setEditMission(false);

    ApiDatabase.searchMission(
      {
        token,
        search,
        isMissionSearchAssigned,
        typeContract: typeContract
          .filter((item) => item.checked)
          .map((item) => item.type),
        departments: departments
          .filter((item) => item.checked)
          .map((item) => item.name),
        missionStatus: missionStatus
          .filter((item) => item.checked)
          .map((item) => item.type),
        limit: limitElement,
        page: currentPage,
      },
      (data) => {
        setSearchMissionResult(data.missions);

        setCountTotal(data.count);
        setCountPerPage(data.countPerPage);
        setTotalPages(data.countPages);

        if (data.missions.length === 1) {
          setEditMission(data.missions[0].id_mission_real);
        }

        setSearchMissionResultWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          setSearchMissionResultWaiting(false);
          setSearchMissionResult([]);
          console.error(err);
        }
      }
    );
  };

  useEffect(() => {
    manageMissionSearch();
  }, [
    isMissionSearchAssigned,
    typeContract,
    departments,
    missionStatus,
    currentPage,
  ]);

  let timerManageMissionSearch = '';
  useEffect(() => {
    timerManageMissionSearch = setTimeout(async () => {
      manageMissionSearch();
    }, 400);
    return () => clearTimeout(timerManageMissionSearch);
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    isMissionSearchAssigned,
    typeContract,
    departments,
    missionStatus,
    search,
  ]);

  useEffect(() => {
    getDepartmentMissions();
  }, []);

  useEffect(() => {
    if (isMissionSearchAssigned === false) {
      setIsMissionSearchSigned(false);
      setIsMissionSearchSignatureSend(false);
    }
  }, [isMissionSearchAssigned]);

  useEffect(() => {
    if (isMissionSearchSigned === true) {
      setIsMissionSearchAssigned(true);
      setIsMissionSearchSignatureSend(true);
    }
  }, [isMissionSearchSigned]);

  useEffect(() => {
    if (isMissionSearchSignatureSend === true) {
      setIsMissionSearchAssigned(true);
    } else if (isMissionSearchSignatureSend === false) {
      setIsMissionSearchSigned(false);
    }
  }, [isMissionSearchSignatureSend]);

  const openCompanyInfo = (id) => {
    setObjID(id);
    toggleSlideOver('get_company');
  };

  useEffect(() => {
    if (role) {
      if (role === 525) {
        setIsMissionSearchAssigned(true);
        setIsMissionSearchSignatureSend(false);
      }
    }
  }, [role]);

  const shouldShowTrashButton = (mission) => {
    if (mission.status === 'cancel') return 'delete';

    const now = new Date();
    const startDate = new Date(mission.duration.datestart);
    const endDate = new Date(mission.duration.dateend);
    const hasAssignedUser = mission.user
      ? typeof mission.user === 'object' && true
      : false;

    let missionStatus;
    if (now < startDate) {
      missionStatus = 'soon';
    } else if (now > endDate) {
      missionStatus = 'past';
    } else {
      missionStatus = 'in_progress';
    }

    if (missionStatus === 'soon' && hasAssignedUser) {
      return 'cancel';
    }

    if (
      (missionStatus === 'in_progress' ||
        missionStatus === 'soon' ||
        missionStatus === 'past') &&
      !hasAssignedUser
    ) {
      return 'delete';
    }

    return false;
  };

  const handleDeleteMission = (idMission) => {
    setObjID(idMission);
    toggle('mission_delete');
  };

  const handleCancelMission = (idMission) => {
    setObjID(idMission);
    toggle('mission_cancel');
  };

  const updateTypeContract = (updatedTypeContract) => {
    const updatedTypeContracts = [...typeContract];

    for (let i = 0; i < updatedTypeContracts.length; i++) {
      for (let j = 0; j < updatedTypeContract.length; j++) {
        if (updatedTypeContracts[i].type === updatedTypeContract[j].value) {
          updatedTypeContracts[i].checked = updatedTypeContract[j].checked;
        }
      }
    }

    for (let i = 0; i < updatedTypeContracts.length; i++) {
      if (updatedTypeContracts[i].checked) {
        setIsMissionSearchAssigned(true);
        break;
      }
    }

    setTypeContract(updatedTypeContracts);
  };

  const updateDepartment = (updatedDepartment) => {
    const updatedDepartments = [...departments];

    for (let i = 0; i < updatedDepartments.length; i++) {
      for (let j = 0; j < updatedDepartment.length; j++) {
        if (updatedDepartments[i].name === updatedDepartment[j].value) {
          updatedDepartments[i].checked = updatedDepartment[j].checked;
        }
      }
    }

    setDepartments(updatedDepartments);
  };

  const updateMissionStatus = (updatedMissionStatus) => {
    const updatedMissionStatuses = [...missionStatus];

    for (let i = 0; i < updatedMissionStatuses.length; i++) {
      for (let j = 0; j < updatedMissionStatus.length; j++) {
        if (updatedMissionStatuses[i].type === updatedMissionStatus[j].value) {
          updatedMissionStatuses[i].checked = updatedMissionStatus[j].checked;
        }
      }
    }

    setMissionStatus(updatedMissionStatuses);
  };

  const openTempWorkerInfo = (id) => {
    setObjID(id);
    toggleSlideOver('get_temp_worker');
  };

  useEffect(() => {
    if (searchMissionResult.length > 0 && !editMission) {
      for (let i = 0; i < searchMissionResult.length; i++) {
        ApiDatabase.getMissionDetailsById(
          { token, idMission: searchMissionResult[i].id_mission_real },
          (data) => {},
          (err) => {}
        );
        if (!searchMissionResult[i].user) {
          ApiDatabase.getTempWorkerPostulateMission(
            { token, idMission: searchMissionResult[i].id_mission_real },
            (data) => {},
            (err) => {}
          );
        }
      }
    }
  }, [searchMissionResult]);

  return (
    <PageForm>
      <div className={'p-3 flex flex-col w-full h-full gap-3'}>
        <div className={'flex justify-between items-center text-sm'}>
          <div className={'flex items-center gap-2'}>
            <div className={'border rounded py-2 px-4'}>
              <label className={'flex items-center gap-2 cursor-pointer'}>
                <ThreeStateCheckbox
                  checked={isMissionSearchAssigned}
                  onChange={setIsMissionSearchAssigned}
                />
                <div className={''}>Mission attribuée</div>
              </label>
            </div>
            <div className={`max-w-[155px]`}>
              <StyledInput
                type={'selectMultipleCheckbox'}
                value={'Contrat'}
                list={typeContract.map((contract) => {
                  return {
                    value: contract.type,
                    label: contract.name,
                    checked: contract.checked,
                  };
                })}
                onChange={(updatedTypeContract) =>
                  updateTypeContract(updatedTypeContract)
                }
              />
            </div>
            <div className={`max-w-[175px]`}>
              <StyledInput
                type={'selectMultipleCheckbox'}
                value={'Département'}
                list={departments.map((department) => {
                  return {
                    value: department.name,
                    label: department.name,
                    checked: department.checked,
                  };
                })}
                onChange={(updatedDepartment) =>
                  updateDepartment(updatedDepartment)
                }
              />
            </div>
            <div className={`max-w-[180px]`}>
              <StyledInput
                type={'selectMultipleRadio'}
                value={
                  missionStatus.filter((status) => status.checked === true)
                    .length > 0
                    ? missionStatus
                        .filter((status) => status.checked === true)
                        .map((status) => status.name)
                        .join(', ')
                    : 'Status'
                }
                list={missionStatus.map((status) => {
                  return {
                    value: status.type,
                    label: status.name,
                    checked: status.checked,
                  };
                })}
                onChange={(updatedMissionStatus) =>
                  updateMissionStatus(updatedMissionStatus)
                }
              />
            </div>
          </div>
          <div className={'flex items-center'}>
            <div className={'w-[245px]'}>
              <StyledInput
                type={'text'}
                placeholder={'Rechercher...'}
                imgFront={<SearchLoop wh={16} color={'#6B7280'} />}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={'flex flex-1 w-full h-full gap-3 overflow-y-auto'}>
          {searchMissionResultWaiting ? (
            <>
              <div
                className={
                  'flex-1 h-full w-[555px] max-w-[555px] animate-pulse'
                }
              >
                <div className={'flex flex-col gap-4 max-h-full w-full'}>
                  <div className={'flex flex-col gap-3 overflow-y-auto'}>
                    {[1, 2, 3, 4, 5].map((i, index) => (
                      <div
                        key={index}
                        className={`flex flex-col gap-1.5 border border-gray-100 rounded-lg p-4 cursor-pointer hover:bg-gray-50`}
                      >
                        <div
                          className={'flex gap-2 justify-between items-center'}
                        >
                          <div
                            className={'flex items-center gap-1 flex-1 min-w-0'}
                          >
                            <div className={'truncate'}>
                              <div
                                className={'h-4 bg-gray-200 rounded w-56'}
                              ></div>
                            </div>
                            <div>
                              <div
                                className={'h-4 bg-gray-200 rounded w-8'}
                              ></div>
                            </div>
                          </div>
                          <div className={'flex gap-1 items-center text-sm'}>
                            <div className={'px-1.5'}>
                              <div
                                className={'h-6 bg-gray-200 rounded w-6'}
                              ></div>
                            </div>
                            <Button size={'SMI'} color={'DEFAULT'}>
                              <div
                                className={'h-6 bg-gray-200 rounded w-6'}
                              ></div>
                            </Button>
                            <Button size={'SMI'} color={'DEFAULT'}>
                              <div
                                className={'h-6 bg-gray-200 rounded w-6'}
                              ></div>
                            </Button>
                            <Button size={'SMI'} color={'DEFAULT'}>
                              <div
                                className={'h-6 bg-gray-200 rounded w-6'}
                              ></div>
                            </Button>
                          </div>
                        </div>
                        <div
                          className={
                            'text-sm flex items-center justify-between gap-4'
                          }
                        >
                          <div
                            className={
                              'flex flex-col gap-0.5 text-gray-700 w-1/2'
                            }
                          >
                            <div className={'flex gap-1 items-center'}>
                              <div className={''}>
                                <Calendar wh={18} color={'#374151'} />
                              </div>
                              <div
                                className={'h-4 bg-gray-200 rounded w-56'}
                              ></div>
                            </div>
                            <div className={'flex gap-1 items-center'}>
                              <div className={''}>
                                <Building wh={18} color={'#374151'} />
                              </div>
                              <div className={'flex gap-1'}>
                                <div
                                  className={'h-4 bg-gray-200 rounded w-56'}
                                ></div>
                              </div>
                            </div>
                            <div className={'flex gap-1 items-center'}>
                              <div className={''}>
                                <Signer wh={18} color={'#374151'} />
                              </div>
                              <div
                                className={'h-4 bg-gray-200 rounded w-56'}
                              ></div>
                            </div>
                            <div className={'flex gap-1 items-center'}>
                              <div className={''}>
                                <ReferenceIcon wh={18} color={'#374151'} />
                              </div>
                              <div
                                className={'h-4 bg-gray-200 rounded w-56'}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {searchMissionResult.length > 0 ? (
                <>
                  <div className={'flex-1 h-full w-[555px]'}>
                    <div className={'flex flex-col gap-4 max-h-full w-full'}>
                      <div className={'flex flex-col gap-3 overflow-y-auto'}>
                        {searchMissionResult.map((mission, index) => {
                          return (
                            <div
                              key={index}
                              className={`flex flex-col gap-1.5 ${
                                editMission === mission.id_mission_real
                                  ? 'border border-yellow-500'
                                  : 'border border-gray-100'
                              } rounded-lg p-4 cursor-pointer hover:bg-gray-50`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEditMission(mission.id_mission_real);
                              }}
                            >
                              <div
                                className={
                                  'flex gap-2 justify-between items-center'
                                }
                              >
                                <div
                                  className={
                                    'flex items-center gap-1 flex-1 min-w-0'
                                  }
                                >
                                  <div className={'truncate'}>
                                    {mission.title}
                                  </div>
                                  <div>n°{mission.id_mission}</div>
                                </div>
                                <div
                                  className={'flex gap-1 items-center text-sm'}
                                >
                                  {mission.status !== 'cancel' &&
                                    mission.is_urgent && (
                                      <BadgeSquare type={11} />
                                    )}
                                  <div className={'border rounded px-1.5'}>
                                    {mission.status === 'cancel'
                                      ? 'Annulé'
                                      : mission.users
                                      ? mission.users.filter(
                                          (user) => user.refused === false
                                        ).length
                                      : 0}
                                  </div>
                                  {shouldShowTrashButton(mission) ===
                                    'delete' && (
                                    <Button
                                      size={'SMI'}
                                      color={'DEFAULT'}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDeleteMission(
                                          mission.id_mission_real
                                        );
                                      }}
                                    >
                                      <TrashIcon wh={20} color={'#374151'} />
                                    </Button>
                                  )}
                                  {shouldShowTrashButton(mission) ===
                                    'cancel' && (
                                    <Button
                                      size={'SMI'}
                                      color={'DEFAULT'}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleCancelMission(
                                          mission.id_mission_real
                                        );
                                      }}
                                    >
                                      <TrashIcon wh={20} color={'#374151'} />
                                    </Button>
                                  )}
                                  <CopyToClipboard
                                    text={`${process.env.REACT_APP_INTERIM}ficheMissionSearch/${mission.id_mission_real}`}
                                  >
                                    <Button size={'SMI'} color={'DEFAULT'}>
                                      <LinkIcon wh={20} color={'#374151'} />
                                    </Button>
                                  </CopyToClipboard>
                                </div>
                              </div>
                              <div
                                className={
                                  'text-sm flex items-center justify-between gap-4'
                                }
                              >
                                <div
                                  className={
                                    'flex flex-col gap-0.5 text-gray-700 w-1/2'
                                  }
                                >
                                  <div className={'flex gap-1 items-center'}>
                                    <div className={''}>
                                      <Calendar wh={18} color={'#374151'} />
                                    </div>
                                    {dateWordShort(mission.duration.datestart) +
                                      ' > ' +
                                      dateWordShort(mission.duration.dateend)}
                                  </div>
                                  <div className={'flex gap-1 items-center'}>
                                    <div className={''}>
                                      <Building wh={18} color={'#374151'} />
                                    </div>
                                    <div
                                      className={'flex gap-1'}
                                      onMouseEnter={() =>
                                        setHoveredCompanyItems((prev) => ({
                                          ...prev,
                                          [index +
                                          mission.company?.id_company]: true,
                                        }))
                                      }
                                      onMouseLeave={() =>
                                        setHoveredCompanyItems((prev) => ({
                                          ...prev,
                                          [index +
                                          mission.company.id_company]: false,
                                        }))
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openCompanyInfo(
                                          mission.company?.id_company
                                        );
                                      }}
                                    >
                                      {mission.company?.reason_social ===
                                      mission.company?.name_commercial
                                        ? mission.company?.reason_social
                                        : mission.company?.reason_social +
                                          ' => ' +
                                          mission.company?.name_commercial}
                                      {mission.company?.id_bestt
                                        ? ' n°' + mission.company?.id_bestt
                                        : ''}
                                      {hoveredCompanyItems[
                                        index + mission.company?.id_company
                                      ] && (
                                        <div>
                                          <ExternalLink
                                            wh={18}
                                            color={'#235FE6'}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {mission.user && (
                                    <div className={'flex gap-1 items-center'}>
                                      <div className={''}>
                                        <Signer wh={18} color={'#374151'} />
                                      </div>
                                      {mission.contract_tempworker[0]?.id_bestt
                                        ? `Contrat n°${mission.contract_tempworker[0]?.id_bestt}`
                                        : 'Auncun contrat BeSTT'}
                                    </div>
                                  )}
                                  {mission.reference && (
                                    <div className={'flex gap-1 items-center'}>
                                      <div className={''}>
                                        <ReferenceIcon
                                          wh={18}
                                          color={'#374151'}
                                        />
                                      </div>
                                      Référence n°{mission.reference}
                                    </div>
                                  )}
                                </div>
                                {mission.user && (
                                  <div
                                    className={
                                      'w-1/2 rounded-lg border border-gray-100 px-4 py-2.5 text-gray-500 hover:bg-gray-100'
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      openTempWorkerInfo(mission.user.user_id);
                                    }}
                                  >
                                    <TempWorkerMission user={mission.user} />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        countPerPage={countPerPage}
                        countTotal={countTotal}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </div>
                  <div className={'flex-1 overflow-hidden'}>
                    {editMission ? (
                      <AdminMissionDetails
                        missionId={editMission}
                        searchMissionResult={searchMissionResult}
                        setSearchMissionResult={setSearchMissionResult}
                      />
                    ) : (
                      <EmptyState type={'select_mission'} />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <EmptyState type={'mission_search'} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </PageForm>
  );
};

export default MissionPeriod;

import React from 'react';
import {Import} from "assets/icons";

const Importfile = ({name,change,titleFile}) => {
    return (
        <>
            <form id='uploadForm'>
                <label
                    className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                    <div
                        className='ft-sm text-gray-700 w-1/4 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                        <Import color={'#374151'} wh={14}/>
                        {name}
                    </div>

                    <div
                        className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                        {titleFile ? titleFile : 'Aucun fichier'}
                    </div>

                    <input type="file" id='file'
                           accept='image/jpeg,image/jpg,application/pdf'
                           className='hidden'
                           onChange={(e) => change(e)}/>
                </label>

                <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
            </form>
        </>
    );
};

export default Importfile;
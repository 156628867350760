import React, {useEffect, useRef} from 'react';
import useSlideOver from "../useSlideOver";
import useContext from "../../../hooks/useContext";

const SlideOverStructure = ({isShowing, children}) => {

    return (
        <>
            {isShowing ?
                <>
                    <div className="w-full h-full fixed inset-0 z-30">
                        <div className="w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900 opacity-50"></div>
                        <div className="w-[800px] bg-white h-full absolute right-0 duration-300 ease-out transition-all z-30 flex flex-col animate-slideR">
                            {children}
                        </div>
                    </div>
                </> : null}
        </>
    );
};

export default SlideOverStructure;
import React, {
    useEffect,
    useState,
} from "react";
import Divider from "components/divider/divider";
import SelectPhone
    from "components/formcomponents/SelectPhone";
import usefetchdata from "hooks/usefetchdata";
import AddressGeoApi from "components/AddressGeoApi/AddressGeoApi";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";
import CardProfile from "../../../../components/card/cardProfile/cardProfile";

const Compte = () => {
    const {refreshLoadWorker} = UseContext()
    const {userData, tempWorkerData} = usefetchdata();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    useEffect(() => {
        if (userData?.birthday?.length > 0) {
            setBirthDay(userData.birthday)
        }
        if (userData?.firstname?.length > 0) {
            setFirstName(userData.firstname)
        }
        if (userData?.lastname?.length > 0) {
            setLastName(userData.lastname)
        }
        if (userData?.email?.length > 0) {
            setEmail(userData.email)
        }
        if (userData?.phone?.length > 0) {
            setPhone(userData.phone)
        }
        if (tempWorkerData?.addresses?.length > 0) {
            ApiDatabase.getAddress({address: tempWorkerData.addresses},
                (data) => {
                    tempWorkerData.addresses = data;
                    setAddress(data);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.error(err)
                    }
                })
        }
    }, [userData, tempWorkerData]);

    useEffect(() => {
        if (tempWorkerData?.addresses !== address && Object.keys(address).length > 0) {
            //Insert address in database
            ApiDatabase.postUpdateAddress(
                {
                    token: localStorage.getItem('xsrfToken'),
                    address: address
                },
                (data) => {
                    toast.success('L\'adresse a été modifié.')
                    refreshLoadWorker()
                }
            )
        }
    }, [address]);

    useEffect(() => {
        if (phone !== userData?.phone) {
            //Insert phone in database
            ApiDatabase.postUpdatePhone(
                {
                    token: localStorage.getItem('xsrfToken'),
                    phone: phone
                },
                (data) => {
                    toast.success('Le numéro de téléphone a été modifié.')
                    userData.phone = phone;
                    refreshLoadWorker()
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                }
            )
        }
    }, [phone]);

    return (
        <div className='col-span-12 flex justify-center items-center mb-32 tab:mb-0'>
            <div className='md:w-[800px] w-full mx-auto'>
                <CardProfile>
                    <h2 className='flex justify-start ft-xl'>Informations personnelles</h2>
                    <div className='mt-4'>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="name"
                                       className='ft-sm text-gray-500'>Nom</label>
                                <input type="text"
                                       value={lastName}
                                       name='lastname'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled
                                />
                            </div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="prenom"
                                       className='ft-sm text-gray-500'>Prenom</label>
                                <input type="text"
                                       value={firstName}
                                       name='firstname'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled/>
                            </div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="birthday"
                                       className='ft-sm text-gray-500'>
                                    Date de naissance</label>
                                <input type="date"
                                       value={birthDay}
                                       name='birthday'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled/>
                            </div>
                        </div>
                    </div>
                    <Divider w={95}/>
                    <h2 className='flex justify-start ft-xl'>Coordonnées</h2>
                    <div className='mt-4'>
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="email"
                                       className='ft-sm text-gray-500'>E-mail</label>
                                <input type="text"
                                       value={email}
                                       name='email'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled/>
                            </div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="phone"
                                       className='ft-sm text-gray-500'>Numéro</label>
                                <div className="relative">
                                    <SelectPhone
                                        phoneValue={phone}
                                        setPhoneValue={setPhone}
                                    />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <AddressGeoApi
                                    label={'Adresse'}
                                    addressGeoValue={setAddress}
                                    addressValue={address}
                                    page={'profileAddress'}
                                />
                            </div>
                        </div>
                    </div>
                </CardProfile>
            </div>
        </div>
    );
};

export default Compte;
import {CheckIcon, Close, EyeIcon} from "assets/icons";
import BadgeSquare from "components/badge/badgeSquare";
import UseContext from "../../hooks/useContext";
import useModal from "../modal/useModal";
import {useEffect} from "react";
import usefetchdata from "../../hooks/usefetchdata";
import {dateWordShort} from "../../helper/helper";

const AdminCollapseFile = ({ title, dateAdd, link, status, type, _id}) => {
    const {setAdminLink, setAdminTempWorkerId, setAdminType, openModal, setOpenModal}=UseContext();
    const {userData} = usefetchdata()
    const {toggle} = useModal();
    const openDocument = (e) => {
        e.preventDefault();
        setAdminLink(link);
        setAdminType(type);
        setAdminTempWorkerId(_id);
        toggle('ad_file_view');
    }

    useEffect(() => {
        if(openModal){
            toggle(openModal)
            setOpenModal(false)
        }
    }, [openModal]);

    const acceptDocument = (e) => {
        e.preventDefault();
        setAdminLink(link);
        if(type === 'selfie' || type === 'proof_of_address' || type === 'accommodation_of_address'){
            toggle('ad_file_accept_simple');
        }
        else{
            if(type === 'identity') {
                toggle('ad_file_accept_identity');
            }
            if(type === 'visa') {
                toggle('ad_file_accept_visa');
            }
            if(type === 'card_vitality') {
                toggle('ad_file_accept_card_vitality');
            }
        }
        setAdminTempWorkerId(_id);
        setAdminType(type);
    }

    return (
        <div className={'p-3 flex justify-between items-center'}>
            <button className={'flex items-center gap-3'} onClick={openDocument}>
                <div className={''}><EyeIcon wh={20}/></div>
                <div className={'underline'}>{title}</div>
                <div className={'text-gray-500 text-sm'}>- {dateWordShort(new Date(dateAdd))}</div>
            </button>
            <div className={'flex items-center'}>
                <div className={''}>
                    <BadgeSquare type={status}/>
                </div>
                <div className={'flex ml-8 items-center'}>
                    <button className={''} onClick={acceptDocument}>
                        <CheckIcon w={24} h={12} color={'#111827'}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AdminCollapseFile;
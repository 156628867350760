import UseContext from "../../../../../hooks/useContext";
import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import React, {useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import ApiDatabase from "server";
import {toast} from "react-toastify";

const ModalMissionDetailSubmit = () => {
  const {toggle} = useModal();
  const {listChangesMissionDetail, isChangesNeedsNewContractMissionDetail, objID, setObjID, setModalDataReturn} = UseContext();
  const [isLoading, setIsLoading] = useState(false)

  const token = localStorage.getItem('xsrfToken');

  const handleSubmit = () => {
    setIsLoading(true)
    ApiDatabase.postMissionDetail({token, idMission: objID, generateContract: isChangesNeedsNewContractMissionDetail, changes: listChangesMissionDetail}, (data) => {
      toast.success(data.message || data.error || data.success)
      if (data.idContract) {
        setObjID(data.idContract)
      } else {
        setObjID('')
      }
      setModalDataReturn('mission updated')
      toggle()
    }, (err) => {
      toast.error(err.response.data.message || err.response.data.error)
      setModalDataReturn('mission updated')
      toggle()
      console.log('err')
    })
  }

  const refChangesMissionDetail = {
    qualification: "Qualification",
    period: "Période",
    schedule: "Planning",
    salary: "Rémunération",
    address: "Adresse",
    reason: "Motif",
    licences: "Permis",
    languages: "Langue",
    tools: "Matériel",
    reference: "Référence",
    other: "Informations pour le candidat",
    other_interim: "Informations pour l'agence",
  }

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Confirmer la modification
      </Modalheader>
      <ModalBody>
        <div className={'text-sm text-gray-500 flex flex-col gap-1'}>
          <div>Une modification a été effectuée sur les champs :</div>
          <div>
            {listChangesMissionDetail.map((change, index) => (
              <div key={index}>
                - {refChangesMissionDetail[change.field]}
              </div>
            ))}
          </div>
          <div>
            {isChangesNeedsNewContractMissionDetail && "Un nouveau contrat sera généré et remplacera l'actuel."}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'DISABLED'}>Annuler</Button>
            <Button size={'LG'} color={'DISABLED'}>Modification de la mission ...</Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
          </>
        )}
      </ModalFooter>
    </>
  )
}

export default ModalMissionDetailSubmit;
import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";


import Unauthorized from "pages/unAuthorized";
import RequireAuth from "components/requireAuth";
import Dashboard from "pages/pages/Dashboard";
import Documents from "pages/pages/Documents";
import Contract
    from "pages/content/documents/contracts/contract";
import Sign from "pages/content/documents/contracts/sign";
import Nosign
    from "pages/content/documents/contracts/nosign";
import Payslip
    from "pages/content/documents/payslip/payslip";
import {Help} from "assets/icons";
import Profil from "pages/pages/Profil";
import Compte from "pages/pages/profil/content/compte";
import Savoirfaire
    from "pages/pages/profil/content/savoirfaire";
import Cv from "pages/content/savoirfaire/cv";
import Experience
    from "pages/content/savoirfaire/experience";
import Langues from "pages/content/savoirfaire/langues";
import Tools from "pages/content/savoirfaire/tools";
import Justificatif
    from "pages/pages/profil/content/justificatif";
import Identity
    from "pages/content/justificatifs/identity";
import Formations
    from "pages/content/justificatifs/formations";
import Licences
    from "pages/content/justificatifs/licences";
import Disponibilite
    from "pages/pages/profil/content/disponibilite";
import Myhours from "pages/content/disponibilites/myhours";
import Availabilities
    from "pages/content/disponibilites/availabilities";
import Parametre
    from "pages/pages/profil/content/parametre";
import Mission from "pages/pages/Mission";
import Progress from "pages/content/mission/progress";
import Completed from "pages/content/mission/completed";
import Comingsoon from "pages/content/mission/comingsoon";
import SearchMission from "pages/pages/SearchMission";
import Missionfiche
    from "pages/pages/mission/missionfiche";
import LayoutTw from 'components/layout'
import Layout from "pages/pages/layout";
import IdentityValidation from "../pages/pages/IdentityValidation";
import TimeSheetsBeSTT from "../pages/pages/timeSheetsBeSTT";
import TimeSheet from "../pages/timeSheet/timeSheet";
import CheckedContent from "../pages/timeSheet/content/checked.content";
import ToBeCheckedContent from "../pages/timeSheet/content/toBeChecked.content";
import LitigationContent from "../pages/timeSheet/content/litigation.content";
import FicheHour from "../pages/content/documents/hours/ficheHour";
import HrFlow from "../pages/pages/HrFlow/HrFlow";
import Company from "../pages/pages/Company";
import MissionPeriod from "../pages/pages/mission/missionPeriod";
import HrFlowGeneral from "../pages/pages/HrFlow/HrFlowGeneral";
import HrFlowJobs from "../pages/pages/HrFlow/HrFlowJobs";
import Vivier from 'pages/pages/vivier/vivier.js';
import FicheVivier from 'pages/pages/vivier/fiche.vivier.js';
import ListAllVivier from 'pages/pages/vivier/list.vivier.js';
import PdfView from "pages/pages/pdfView";
import VivierRatingTempWorker from "../pages/pages/vivier/vivier-rating-temp-worker";
import Qualification from "../pages/pages/qualification/qualification";
import ToBeCompletedContent from "../pages/timeSheet/content/toBeCompleted.content";

const TempWorkerRoutes = () => {
    const ROLES = {
        'admin': '500',
        'steeve': '525',
        'alternant': '515',
    }

    return (
        <>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route path={'admin'} element={<LayoutTw/>}/>
                    <Route element={<RequireAuth allowedRoles={[ROLES.admin, ROLES.steeve, ROLES.alternant]}/>}>
                        <Route path='dashboard' element={<Dashboard/>}/>
                        <Route path='identity_validation' element={<IdentityValidation/>}/>
                        <Route path={'timeSheet'} element={<TimeSheet/>}>
                            <Route path={'checked'} element={<CheckedContent/>}/>
                            <Route path={'toBeChecked'} element={<ToBeCheckedContent/>}/>
                            <Route path={'litigation'} element={<LitigationContent/>}/>
                            <Route path={'toBeCompleted'} element={<ToBeCompletedContent/>}/>
                            <Route path={'ficheHour/:id'} element={<FicheHour/>}/>
                        </Route>
                        <Route path='hrflow' element={<HrFlow/>}>
                            <Route path='general' element={<HrFlowGeneral/>}></Route>
                            <Route path='jobs' element={<HrFlowJobs/>}></Route>
                        </Route>
                        <Route path='vivier' element={<Vivier/>}/>
                        <Route path='qualification' element={<Qualification/>}/>
                        <Route path={'vivier-rating-temp-worker/rating-untreated'} element={<VivierRatingTempWorker/>}/>
                        <Route path={'vivier-rating-temp-worker/rating-treated'} element={<VivierRatingTempWorker/>}/>
                        <Route path='ficheVivier/:id' element={<FicheVivier/>}/>
                        <Route path='listAllVivier' element={<ListAllVivier/>}/>
                        <Route path='company' element={<Company/>}></Route>
                        <Route path='missions' element={<MissionPeriod/>}></Route>
                        <Route path='documents' element={<Documents/>}>
                            <Route path='contracts' element={<Contract/>}>
                                <Route path={'sign'} element={<Sign/>}/>
                                <Route path={'nosign'} element={<Nosign/>}/>
                            </Route>
                            <Route path='payslip' element={<Payslip/>}/>
                        </Route>
                        <Route path='help' element={<Help/>}/>
                        <Route path='profil' element={<Profil/>}>
                            <Route path='compte' element={<Compte/>}/>
                            <Route path='savoir_faire'
                                   element={<Savoirfaire/>}>
                                <Route path='cv' element={<Cv/>}/>
                                <Route path='experiences'
                                       element={<Experience/>}/>
                                <Route path='language'
                                       element={<Langues/>}/>
                                <Route path='tools'
                                       element={<Tools/>}/>
                            </Route>
                            <Route path='justificatifs'
                                   element={<Justificatif/>}>
                                <Route path='identity'
                                       element={<Identity/>}/>
                                <Route path='degree'
                                       element={<Formations/>}/>
                                <Route path='licence'
                                       element={<Licences/>}/>
                            </Route>
                            <Route path='disponibilites'
                                   element={<Disponibilite/>}>
                                <Route path='myhours'
                                       element={<Myhours/>}/>
                                <Route path='availabilities'
                                       element={<Availabilities/>}/>
                            </Route>
                            <Route path='parametres'
                                   element={<Parametre/>}/>
                        </Route>
                        <Route path='mission' element={<Mission/>}>
                            <Route path={'in_progress'}
                                   element={<Progress/>}/>
                            <Route path={'completed'}
                                   element={<Completed/>}/>
                            <Route path={'coming_soon'}
                                   element={<Comingsoon/>}/>
                        </Route>
                        <Route path='searchMission'
                               element={<SearchMission/>}/>
                        <Route path='ficheMission/:id'
                               element={<Missionfiche/>}/>
                    </Route>
                    {/*<Route path={'*'} element={<Unauthorized/>}/>*/}
                    <Route path='pdfView' element={<PdfView/>}/>
                    <Route path={""} element={<Navigate to="/dashboard"/>} />
                    <Route path={"*"} element={<Navigate to="/dashboard"/>} />
                    <Route path={'/timeSheetsBeSTT'} element={<TimeSheetsBeSTT/>}/>
                </Route>
            </Routes>
        </>
    )
        ;
};

export default TempWorkerRoutes;
import React, {useContext} from 'react';
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import PageForm from "components/pageForm/PageForm";
import SubNavP from "components/subnav/profil/desktop/subnavprofil";
import Subnavmobile from "components/subnav/profil/mobile/subnavmobile";
import MediaQuery from "react-responsive";
import {ChevronRight} from "assets/icons";
import UseContext from "hooks/useContext";


const Profil = () => {
    const {language} = UseContext()
    const route =useLocation()
    const {pathname}=route
    const navigate = useNavigate()
    const linkMobile = [
        {
            path: 'compte',
            pTrad: 'profil.account'
        }, {
            path: 'savoir_faire',
            pTrad: 'profil.knowledge'
        },
        {
            path:'justificatifs',
            pTrad: 'profil.proof'
        },
        {
            path:'disponibilites',
            pTrad: 'profil.availabilities'
        },
        {
            path:'parametres',
            pTrad:'profil.params'
        }
    ]
    return (
        <PageForm>
            <MediaQuery minWidth={769}>
                <div className="col-span-12 bg-white sticky z-10 top-16 ">
                    <SubNavP/>
                </div>
                <div className="col-span-12 mt-8 mb-28">
                    <Outlet/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <div className="col-span-12 bg-white sticky z-10 top-16 ">
                    <Subnavmobile/>
                </div>
                {pathname ==='/profil'?

                <div className="col-span-12 mt-8 mb-28">
                    {linkMobile.map((item) =>
                        (<div onClick={e=>
                        navigate(`./${item.path}`)
                        } >
                            <div
                                className='flex items-center justify-between h-14 px-2 my-2'>
                                <p className='ft-sm font-bold text-gray-900'>
                                {language.getValue(item.pTrad)}
                                </p>
                            <ChevronRight color={'#111827'} wh={15}/>
                            </div>
                        </div>)
                    )}
                </div>
                :<Outlet/>}
            </MediaQuery>

        </PageForm>
    );
};

export default Profil;


import React,{useEffect}  from 'react';
import {
    useLocation,
    Outlet,
    useParams,
    useNavigate
} from "react-router-dom";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import {JobbizLogo, Loading2} from "../../assets/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Index = () => {
    const {height, width} = useWindowDimensions();
    const {updateUser}=UseContext()
    const location = useLocation();
    const navigate=useNavigate()
    const tokenURL= location.search.split('=')[1]

    useEffect(()=> {
        if(tokenURL){
            ApiDatabase.postRoleUser({token:tokenURL},
                (data)=>{
                    updateUser(data.token_refresh,data.role)
                    navigate('../dashboard')
                    // window.location.reload()
                },
                (err)=>{
                    window.location.href = process.env.REACT_APP_ADMIN_LOGIN
            })
        } else {
            const test= localStorage.getItem('rUser')
            const tt = localStorage.getItem('xsrfToken')
            if (test && tt) {
                navigate('../dashboard')
            } else {
                window.location.href = process.env.REACT_APP_ADMIN_LOGIN
            }
        }
    },[tokenURL])

    return (
        <>
            <div className={`flex flex-col ${width <= 460 ? 'gap-5' : 'gap-10'} justify-center items-center h-screen`}>
                <JobbizLogo wh={(width <= 460 ? 166 : 300)}/>
                <div className={'animate-spin'}>
                    <Loading2 wh={(width <= 460 ? 40 : 60)} color={'#FF9600'}/>
                </div>
            </div>
        </>
    );
};

export default Index;
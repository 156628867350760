import React,{useState} from 'react';
import Requirements
    from "components/modal/dataModal/data/modaljustify/identity/components/requirements";
import Importfile from "components/formcomponents/importfile";
import Visaimage
    from "components/modal/dataModal/data/modaljustify/identity/components/images/visaimage";

const Autorisationtravail = ({setVisa,visa}) => {
    const [titleFileR, setTitleFileR] = useState('')
    const [titleFileV, setTitleFileV] = useState('')

    // name,change,titleFile
    const handlerSingleFileR = e => {
        setVisa(prevState => {
            return ({
                ...prevState,
                fileR: e.target.files[0]
            })
        })
        setTitleFileR(e.target.files[0].name)
    }
    const handlerSingleFileV = e => {
        setVisa(prevState => {
            return ({
                ...prevState,
                fileV: e.target.files[0]
            })
        })
        setTitleFileV(e.target.files[0].name)
    }
    const handlerSingleFileP = e => {
        setVisa(prevState => {
            return ({
                ...prevState,
                fileR: e.target.files[0]
            })
        })
        setTitleFileR(e.target.files[0].name)
    }

    const handleChange = (e) => {
        e.preventDefault()
        const type = e.type.target
        const name = e.target.name

        const value = type === 'checkbox'
            ? e.target.checked
            : e.target.value

        setVisa(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    return (
        <div className={'h-[600px] overflow-y-auto px-2'}>
            <Requirements/>
            <div className={'flex flex-col gap-3'}>
                <form className={'flex flex-col gap-3'}>
                    <div>
                        <label
                            className={'ft-sm font-medium text-gray-500'}>Type
                            de document</label>
                        <select name="type" id=""
                                className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                onChange={handleChange}
                                value={visa.type}
                        >
                            <option value=""></option>
                            <option value="Visa">Visa
                            </option>
                            <option value="RTS">Récépissé de titre de séjour
                            </option>
                        </select>
                    </div>
                </form>
                <Visaimage visa={visa.type}/>
                {visa.type === 'Visa' ?
                    <>
                        {/*CNI*/}
                        <Importfile name={'Recto'}
                                    titleFile={titleFileR}
                                    change={handlerSingleFileR}/>
                        <Importfile name={'Verso'}
                                    titleFile={titleFileV}
                                    change={handlerSingleFileV}/>
                    </>
                    :
                    //Passeport
                    <Importfile name={'Importer'} titleFile={titleFileR}
                                change={handlerSingleFileP}/>

                }

            </div>
        </div>
    );
};

export default Autorisationtravail;
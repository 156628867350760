import UseModal from "components/modal/useModal";
import UseContext from "../../../../../../hooks/useContext";
import React, { useEffect, useState } from "react";
import ModalFooter from "../../../../components/footer";
import Button from "../../../../../button";
import ModalBody from "../../../../components/body";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
    CardIdentity,
    CardVitale,
    CrossIcon,
    DocumentIcon,
    Localisation,
    SelfieIdentity
} from "../../../../../../assets/icons";
import ApiDatabase from "../../../../../../server";
import { dateToEpoch } from "../../../../../../helper/helper";
import Usefetchdata from "../../../../../../hooks/usefetchdata";

const AdminModalFileView = () => {
    const { toggle } = UseModal();
    const { adminLink, adminType, adminTempWorkerId } = UseContext();
    const { userData } = Usefetchdata();
    const [tempWorkerInfos, setTempWorkerInfos] = useState({
        tempWorker: {},
        user: {},
    });
    const token = localStorage.getItem('xsrfToken');
    const [docs, setDocs] = useState([]);
    const [viewerStyle, setViewerStyle] = useState({ maxHeight: 700, pdfVerticalScrollByDefault: true });
    const links = Object.values(adminLink);
    const [isFileExist, setIsFileExist] = useState(false);
    const [isWaitingFile, setIsWaitingFile] = useState(false);

    useEffect(() => {
        if (userData._id) {
            manageFiles();
        }
    }, [adminLink, userData]);

    const manageFiles = async () => {
        if (adminLink) {
            setIsWaitingFile(true);
            for (const [key, value] of Object.entries(adminLink)) {
                if (value) {
                    const response = await checkFileExistence(value);
                    setIsFileExist(response);
                    if (response) {
                        const buffer = await fetch(value).then(res => res.arrayBuffer());
                        const blob = new Blob([buffer], { type: response.type });
                        const fileURL = URL.createObjectURL(blob);
                        setDocs(docs => [...docs, { uri: fileURL, fileType: response.type }]);
                    }
                }
            }
            setIsWaitingFile(false);
        }
    };

    const checkFileExistence = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf') || contentType.includes('image')) {
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: contentType });

                    return blob.size !== 0 ? { type: contentType } : false;
                }
                return false;
            }
            return false;
        } catch (error) {
            console.error("Error checking file existence:", error);
            return false;
        }
    };

    const type = {
        'identity': {
            text: 'Pièce d\'identité',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'selfie': {
            text: 'Selfie d\'identité',
            icon: <SelfieIdentity wh={24} color={'#6B7280'} />,
        },
        'visa': {
            text: 'Visa',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'card_vitality': {
            text: 'Carte Vitalité',
            icon: <CardVitale wh={24} color={'#6B7280'} />,
        },
        'proof_of_address': {
            text: 'Justificatif de domicile',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
        'accommodation_of_address': {
            text: 'Attestation de logement',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
        'cv': {
            text: 'CV',
            icon: <DocumentIcon wh={24} color={'#6B7280'} />,
        }
    };

    useEffect(() => {
        if (userData._id) {
            if (token && adminTempWorkerId) {
                ApiDatabase.getTempWorkerById({ token, adminTempWorkerId },
                    (data) => {
                        setTempWorkerInfos(prevData => ({
                            ...prevData,
                            tempWorker: data,
                        }));
                        const id_user = data.id_user;
                        ApiDatabase.getUserById({ token, id_user },
                            (data) => {
                                const birthDay = dateToEpoch(data.birthday);
                                setTempWorkerInfos(prevData => ({
                                    ...prevData,
                                    user: {
                                        ...data,
                                        birthday: (('0' + birthDay.getDate()).toString().slice(-2)) + '/' + (('0' + (parseInt(birthDay.getMonth()) + 1)).toString().slice(-2)) + '/' + birthDay.getFullYear(),
                                    },
                                }));
                            },
                            (err) => {
                                if (err.name !== "CanceledError") {
                                    console.log(err);
                                }
                            });
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            console.log(err);
                        }
                    });
            }
        }
    }, [adminTempWorkerId, userData]);

    return (
        <>
            <ModalBody>
                <div className={'flex gap-3'}>
                    <div className={adminTempWorkerId ? 'w-2/3' : 'w-full'}>
                        {isWaitingFile ? (
                            <>
                                Chargement du document...
                            </>
                        ) : (
                            <>
                                {
                                    isFileExist ? (
                                        docs.length > 0 && (
                                            <DocViewer documents={docs} style={viewerStyle} pluginRenderers={DocViewerRenderers} />
                                        )
                                    ) : (
                                        <div className={'flex justify-center items-center h-full py-3 px-1 border rounded'}>
                                            <div className={'text-gray-500'}>
                                                Une erreur est survenue lors du chargement du document. Le fichier est peut-être corrompu ou inexistant.
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    {
                        adminTempWorkerId && (
                            <div className={'w-1/3 flex flex-col gap-3'}>
                                <div className={'w-full'}>
                                    <div className={'flex justify-between gap-5'}>
                                        <div className={'w-full'}>
                                            <div className={'p-3 border rounded flex gap-3'}>
                                                <div className={'my-auto'}>
                                                    {type[adminType].icon}
                                                </div>
                                                <div className={'my-auto ft-sm'}>
                                                    <div className={''}>
                                                        {adminType === 'identity' && (tempWorkerInfos.tempWorker.identity?.type === 'Passeport' ? 'Passeport' : 'Carte d\'identité')}
                                                        {adminType === 'visa' && (tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'Visa' : 'Titre de séjour')}
                                                        {(adminType !== 'identity' && adminType !== 'visa') && type[adminType].text}
                                                    </div>
                                                    <div className={'text-gray-500'}>
                                                        {
                                                            docs.length === 2 ? 'Recto & verso' : (docs.length > 2 ? 'Plusieurs documents' : 'Document unique')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={''}>
                                            <div className={'cursor-pointer'} onClick={toggle}>
                                                <CrossIcon wh={20} color={'#374151'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'PRIMARY'} onClick={toggle}>Fermer</Button>
            </ModalFooter>
        </>
    );
}

export default AdminModalFileView;
import React from 'react'
import PageForm from 'components/pageForm/PageForm.js'
import HeaderVivier from './components/header/header.vivier.js'
import BodyVivier from './components/content/body.vivier.js'



function Vivier() {
  return (
    <>
    <PageForm>
        <div className={'col-span-12 md:p-8 h-full space-y-4'}>
          <HeaderVivier/>
          <BodyVivier/>
        </div>
    </PageForm>
</>
  )
}

export default Vivier
import React from 'react';
import PageForm from "components/pageForm/PageForm";
import MediaQuery from "react-responsive";
import SubNavFiles from "components/subnav/documents/desktop/subnavfiles";
import {Outlet} from "react-router-dom";

const Documents = () => {
    return (
        <PageForm>
            <MediaQuery minWidth={770}>
                <div
                    className=" flex items-center justify-between col-span-12 bg-white sticky z-10 top-16 ">
                    <SubNavFiles/>
                </div>
                <div className="col-span-12 mt-8 mb-28">
                    <Outlet/>
                </div>
            </MediaQuery>

        </PageForm>
    );
};

export default Documents;
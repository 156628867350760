import {
  BanIcon,
  ChevronDown,
  Loading2,
  Localisation,
  Plus,
  SearchLoop,
  SortIcon,
} from 'assets/icons/index.js';
import axios from 'axios';
import Button from 'components/button/index.js';
import useModal from 'components/modal/useModal.js';
import Pagination from 'components/pagination/pagination.js';
import UseContext from 'hooks/useContext.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import { useEffect, useRef, useState } from 'react';
import useAbortController from '../../../../../hooks/useAbortController.js';
import TableVivier from './components/table.vivier.js';

function BodyVivier() {
  // vivierListUSer
  // #region **** UseState ****
  const { toggle } = useModal();
  const { height, width } = useWindowDimensions();
  const { setInfoVivier, usersVivier, setUsersVivier, setObjID } = UseContext();
  const [search, setSearch] = useState('');
  const [optionSearch, setOptionSearch] = useState('');
  const [selectMQ, setSelectMQ] = useState(false);
  const [selectGP, setSelectGP] = useState(false);
  const [selectProfil, setSelectProfil] = useState(false);
  const [selectApp, setSelectApp] = useState(false);
  const [searchCandidatWaiting, setSearchCandidatWaiting] = useState(true);
  const [sortName, setSortName] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [countPerPage, setCountPerPage] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [data, setData] = useState();
  const [result, setResult] = useState();
  const [listAddVivier, setAddVivier] = useState([]);
  const [osChecked, setOsChecked] = useState({
    web: true,
    app: true,
  });
  const [departmentChecked, setDepartmentChecked] = useState({
    martinique: true,
    guadeloupe: true,
    other: true,
  });
  const [sortChecked, setSortChecked] = useState({
    a_z: false,
    recent: false,
  });

  const [dropdownMultipleActionsOpen, setDropdownMultipleActionsOpen] =
    useState(false);
  const [dropdownSortOpen, setDropdownSortOpen] = useState(false);
  const [dropdownDepartmentsOpen, setDropdownDepartmentsOpen] = useState(false);
  const [dropdownOsOpen, setDropdownOsOpen] = useState(false);
  const dropDownMultipleActionsRef = useRef();
  const dropDownSortRef = useRef();
  const dropDownDepartmentsRef = useRef();
  const dropDownOsRef = useRef();

  const getAbortController = useAbortController();

  const handleSelectMartinique = (e) => {
    e.preventDefault();
    if (!selectMQ) {
      setSelectMQ(true);
      setSelectGP(false);
    } else {
      setSelectMQ(false);
      setSelectGP(false);
    }
  };

  const handleSelectGuadeloupe = (e) => {
    e.preventDefault();
    if (!selectGP) {
      setSelectMQ(false);
      setSelectGP(true);
    } else {
      setSelectMQ(false);
      setSelectGP(false);
    }
  };

  const handleSelectProfil = (e) => {
    e.preventDefault();
    setSelectProfil((prevState) => !prevState);
  };

  const handleSelectApp = (e) => {
    e.preventDefault();
    setSelectApp((prevState) => !prevState);
  };

  const handleAddVivier = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('vivier_add');
  };

  const manageCandidatSearch = async () => {
    setSearchCandidatWaiting(true);
    const controller = getAbortController();

    try {
      const filterOptions = {
        ...osChecked,
        ...departmentChecked,
        ...sortChecked,
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}vivier/vivierListUser`,
        {
          signal: controller.signal,
          params: {
            page: currentPage,
            limit: limitElement,
            search: search,
            optionSearch: optionSearch,
            profil: selectProfil,
            filterOptions: JSON.stringify(filterOptions),
          },
        }
      );

      if (selectProfil) setCurrentPage(1);
      setTotalPages(data.countPages);
      setCountPerPage(limitElement);
      setCountTotal(data.count);
      setData(data.count);
      setResult(data.users);
      setSearchCandidatWaiting(false);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error('An error occurred:', error);
        setSearchCandidatWaiting(false);
      }
    }
  };

  let timerViverSearch = '';
  useEffect(() => {
    timerViverSearch = setTimeout(async () => {
      await manageCandidatSearch();
    }, 400);
    return () => clearTimeout(timerViverSearch);
  }, [
    search,
    optionSearch,
    selectGP,
    selectMQ,
    sortName,
    currentPage,
    selectProfil,
    selectApp,
    osChecked,
    departmentChecked,
    sortChecked,
  ]);

  useEffect(() => {
    const refs = {
      dropDownMultipleActions: dropDownMultipleActionsRef,
      dropDownSort: dropDownSortRef,
      dropDownDepartments: dropDownDepartmentsRef,
      dropDownOs: dropDownOsRef,
    };

    const states = {
      dropDownMultipleActions: setDropdownMultipleActionsOpen,
      dropDownSort: setDropdownSortOpen,
      dropDownDepartments: setDropdownDepartmentsOpen,
      dropDownOs: setDropdownOsOpen,
    };

    const handleClickOutside = (e) => {
      Object.entries(refs).forEach(([refName, ref]) => {
        if (!ref.current.contains(e.target)) {
          states[refName](false);
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    dropDownMultipleActionsRef,
    dropDownSortRef,
    dropDownDepartmentsRef,
    dropDownOsRef,
  ]);

  const handleMultipleActions = () => {
    setDropdownMultipleActionsOpen((prev) => !prev);
  };

  const handleSort = () => {
    setDropdownSortOpen((prev) => !prev);
  };

  const handleDepartments = () => {
    setDropdownDepartmentsOpen((prev) => !prev);
  };

  const handleOs = () => {
    setDropdownOsOpen((prev) => !prev);
  };

  const excludeAgency = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('exclude_agency');
  };

  const changeLocalisation = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('localisation_preferences');
  };

  useEffect(() => {
    if (usersVivier.length > 0) {
      for (let i = 0; i < usersVivier.length; i++) {
        for (let j = 0; j < result.length; j++) {
          if (usersVivier[i].id_user.toString() === result[j]._id.toString()) {
            let isInVivier = false;
            if (usersVivier[i].preferences_department?.department) {
              if (
                departmentChecked.martinique &&
                usersVivier[i].preferences_department?.department ===
                  'Martinique'
              ) {
                isInVivier = true;
              } else if (
                departmentChecked.guadeloupe &&
                usersVivier[i].preferences_department?.department ===
                  'Guadeloupe'
              ) {
                isInVivier = true;
              }
            } else {
              isInVivier = true;
            }

            if (!isInVivier) {
              result[j].isDeleted = true;
            } else {
              result[j].viviers =
                usersVivier[i].viviers?.map((vivier) => vivier.name) || [];
            }
          }
        }
      }
      setResult(result);
      setUsersVivier([]);
      setInfoVivier({ listUsers: [] });
      setAddVivier([]);
    }
  }, [usersVivier]);

  return (
    <div className="flex flex-col space-y-8 h-full">
      <div className="flex flex-row items-center justify-between border-b border-gray-100 bg-white sticky top-0 z-20 py-3">
        <div className="">{data ? data : 0} candidats</div>
        <div className="flex gap-3">
          <div className={'my-auto relative'} ref={dropDownOsRef}>
            <Button color={'SECONDARY'} size={'SMI'} onClick={handleOs}>
              <div className={'flex gap-2'}>
                <div className={'my-auto flex gap-1'}>
                  <div className={'my-auto'}>
                    {
                      Object.keys(osChecked).filter((key) => osChecked[key])
                        .length
                    }
                  </div>
                  <div className={'my-auto'}>OS</div>
                </div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#374151'} />
                </div>
              </div>
            </Button>
            {dropdownOsOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setOsChecked({ ...osChecked, web: !osChecked.web });
                        }}
                        checked={osChecked.web}
                      />
                    </div>
                    <div className={'my-auto'}>Web</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setOsChecked({ ...osChecked, app: !osChecked.app });
                        }}
                        checked={osChecked.app}
                      />
                    </div>
                    <div className={'my-auto'}>App</div>
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className={'my-auto relative'} ref={dropDownDepartmentsRef}>
            <Button
              color={'SECONDARY'}
              size={'SMI'}
              onClick={handleDepartments}
            >
              <div className={'flex gap-2'}>
                <div className={'my-auto flex gap-1'}>
                  <div className={'my-auto'}>
                    {
                      Object.keys(departmentChecked).filter(
                        (key) => departmentChecked[key]
                      ).length
                    }
                  </div>
                  <div className={'my-auto'}>
                    {Object.keys(departmentChecked).filter(
                      (key) => departmentChecked[key]
                    ).length > 1
                      ? 'DÉPARTEMENTS'
                      : 'DÉPARTEMENT'}
                  </div>
                </div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#374151'} />
                </div>
              </div>
            </Button>
            {dropdownDepartmentsOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            martinique: !departmentChecked.martinique,
                          });
                        }}
                        checked={departmentChecked.martinique}
                      />
                    </div>
                    <div className={'my-auto'}>Martinique</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            guadeloupe: !departmentChecked.guadeloupe,
                          });
                        }}
                        checked={departmentChecked.guadeloupe}
                      />
                    </div>
                    <div className={'my-auto'}>Guadeloupe</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            other: !departmentChecked.other,
                          });
                        }}
                        checked={departmentChecked.other}
                      />
                    </div>
                    <div className={'my-auto'}>Aucun</div>
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className={'my-auto relative'} ref={dropDownSortRef}>
            <Button color={'SECONDARY'} size={'SMI'} onClick={handleSort}>
              <SortIcon wh={20} color={'#374151'} />
            </Button>
            {dropdownSortOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <div
                  className={`flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer ${
                    sortChecked.a_z && 'text-yellow-500'
                  }`}
                  onClick={() => {
                    setSortChecked({ a_z: true, recent: false });
                  }}
                >
                  A-Z
                </div>
                <div
                  className={`flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer ${
                    sortChecked.recent && 'text-yellow-500'
                  }`}
                  onClick={() => {
                    setSortChecked({ a_z: false, recent: true });
                  }}
                >
                  Récent
                </div>
              </div>
            )}
          </div>
          <div
            className={`${
              !listAddVivier.length > 0 && 'hidden '
            }my-auto relative cursor-pointer`}
            ref={dropDownMultipleActionsRef}
          >
            <Button onClick={handleMultipleActions} color={'PRIMARY'}>
              <div className={'flex gap-1'}>
                <div className={'my-auto'}>Actions multiples</div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#FFFFFF'} />
                </div>
              </div>
            </Button>
            {dropdownMultipleActionsOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => handleAddVivier()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <Plus wh={20} color={'#666666'} />
                    </div>
                    <div className={'my-auto'}>Ajouter dans un vivier</div>
                  </div>
                </div>
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => changeLocalisation()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <Localisation wh={20} color={'#666666'} />
                    </div>
                    <div className={'my-auto'}>Modifier la localisation</div>
                  </div>
                </div>
                <div className="border-b border-gray-100"></div>
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                  onClick={() => excludeAgency()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <BanIcon wh={20} color={'#DC2626'} />
                    </div>
                    <div className={'my-auto'}>Exclure de l'agence</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={'flex gap-3 my-auto'}>
            <div className="relative text-gray-500">
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="w-full h-10 pl-10 pr-8 ft-b border rounded-lg"
                type="text"
                placeholder="Rechercher ..."
                required
              />
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <SearchLoop wh={18} color={'#666666'} />
              </div>
            </div>
            <select
              className="h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
              onChange={(e) => setOptionSearch(e.target.value)}
            >
              <option value="">Tous</option>
              <option value="id_bestt">ID BeSTT</option>
              <option value="name">Nom</option>
              <option value="mail">Email</option>
              <option value="phone">Téléphone</option>
            </select>
          </div>
        </div>
      </div>
      {searchCandidatWaiting && (
        <div
          className={`flex flex-col ${
            width <= 460 ? 'gap-5' : 'gap-10'
          } justify-center items-center h-80`}
        >
          <div className={'animate-spin'}>
            <Loading2 wh={width <= 460 ? 40 : 60} color={'#FF9600'} />
          </div>
        </div>
      )}
      {!searchCandidatWaiting && (
        <TableVivier
          users={result}
          isWaiting={searchCandidatWaiting}
          setSortName={setSortName}
          sortName={sortName}
          setAddVivier={setAddVivier}
          list={listAddVivier}
        />
      )}
      {!searchCandidatWaiting &&
      currentPage &&
      totalPages > 1 &&
      setCurrentPage ? (
        <div className={'px-8 py-4'}>
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default BodyVivier;

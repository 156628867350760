import React from 'react';
import MediaQuery from "react-responsive";
import Licences from "pages/content/justificatifs/licences";
import Formations from "pages/content/justificatifs/formations";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ChevronRight} from "assets/icons";
import Identity from "pages/content/justificatifs/identity";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const Justificatif = () => {
    const {language} = UseContext()
    const {toggle}=useModal()
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()

    const linkMobile = [
        {
            path: 'identity',
            pTrad: 'proof.identity'
        },
        {
            path: 'degree',
            pTrad: 'proof.degree'
        },
        {
            path: 'licence',
            pTrad: 'proof.licence'
        }
    ]
    return (
        <>
            <MediaQuery minWidth={769}>
                <div className='col-span-12 flex justify-center items-center mb-32'>
                    <div className="w-[750px]">
                        <Identity/>
                        <div className="flex flex-col gap-4">
                            <div className="ft-xl text-gray-700">
                                Documents recommandés
                            </div>
                            <span className='ft-b text-gray-500'>Ces documents seront utilisés pour valider votre identité, créer votre contrat et vous payer.</span>
                            {/*Partie Formation*/}
                            <Formations/>
                            {/*Partie Permis*/}
                            <Licences/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                {pathname === '/profil/justificatifs' ?
                    <div className="col-span-12 mt-8 mb-28">
                        {linkMobile.map((item) =>
                            (<div onClick={e =>
                                navigate(`./${item.path}`) }
                                  key={item.path}>
                                <div
                                    className='flex items-center justify-between h-14 px-2 my-2'>
                                    <p className='ft-sm font-bold text-gray-900'>
                                        {language.getValue(item.pTrad)}
                                    </p>
                                    <ChevronRight color={'#111827'} wh={15}/>
                                </div>
                            </div>)
                        )}
                    </div>
                    :
                    <div className="col-span-12 flex justify-center items-center mb-32">
                        <Outlet/>
                    </div>
                }
            </MediaQuery>
        </>
    );
};

export default Justificatif;
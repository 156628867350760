import UseModal from "../../../../useModal";
import UseContext from "../../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ModalBody from "../../../../components/body";
import ModalFooter from "../../../../components/footer";
import Button from "../../../../../button";
import ApiDatabase from "../../../../../../server";
import {toast} from "react-toastify";
import DatePicker,{registerLocale} from "react-datepicker";

import { enGB,fr } from "date-fns/locale";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {
    CardIdentity,
    CardVitale,
    CheckIcon,
    Close,
    CrossIcon,
    Localisation,
    SelfieIdentity
} from "../../../../../../assets/icons";
import {dateToEpoch} from "../../../../../../helper/helper";
import Banner from "../../../../../banner/banner";
import Divider from "../../../../../divider/divider";
registerLocale('en-GB',enGB)
registerLocale('fr-FR',fr)

const AdminModalFileAcceptVisa = () => {
    const { toggle } = UseModal();
    const { adminLink, adminType, adminTempWorkerId, setUpdateTempWorker, setOpenModal } = UseContext();
    const links = Object.values(adminLink);
    const [tempWorkerInfos, setTempWorkerInfos] = useState({
        tempWorker: {},
        user: {},
    });
    const [numberVisa, setNumberVisa] = useState('');
    const [dateObtained, setDateObtained] = useState('');
    const [dateExpiration, setDateExpiration] = useState('');
    const token = localStorage.getItem('xsrfToken');
    const [docs, setDocs] = useState([]);
    const [viewerStyle, setViewerStyle] = useState({ maxHeight: 700, pdfVerticalScrollByDefault: true });
    const [isFileExist, setIsFileExist] = useState(false);
    const [isWaitingFile, setIsWaitingFile] = useState(false);

    useEffect(() => {
        manageFiles();
    }, [adminLink]);

    const manageFiles = async () => {
        if (adminLink) {
            setIsWaitingFile(true);
            for (const [key, value] of Object.entries(adminLink)) {
                if (value) {
                    const response = await checkFileExistence(value);
                    setIsFileExist(response);
                    if (response) {
                        const buffer = await fetch(value).then(res => res.arrayBuffer());
                        const blob = new Blob([buffer], { type: response.type });
                        const fileURL = URL.createObjectURL(blob);
                        setDocs(docs => [...docs, { uri: fileURL, fileType: response.type }]);
                    }
                }
            }
            setIsWaitingFile(false);
        }
    };

    const checkFileExistence = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf') || contentType.includes('image')) {
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: contentType });

                    return blob.size !== 0 ? { type: contentType } : false;
                }
                return false;
            }
            return false;
        } catch (error) {
            console.error("Error checking file existence:", error);
            return false;
        }
    };

    const type = {
        'identity': {
            text: 'Pièce d\'identité',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'selfie': {
            text: 'Selfie d\'identité',
            icon: <SelfieIdentity wh={24} color={'#6B7280'} />,
        },
        'visa': {
            text: 'Visa',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'card_vitality': {
            text: 'Carte Vitalité',
            icon: <CardVitale wh={24} color={'#6B7280'} />,
        },
        'proof_of_address': {
            text: 'Justificatif de domicile',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
        'accommodation_of_address': {
            text: 'Attestation de logement',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
    };

    useEffect(() => {
        if (token && adminTempWorkerId) {
            ApiDatabase.getTempWorkerById({ token, adminTempWorkerId },
                (data) => {
                    setTempWorkerInfos(prevData => ({
                        ...prevData,
                        tempWorker: data,
                    }));

                    setNumberVisa(data.visa?.number_visa ?? '');
                    setDateObtained(data.visa?.start_validity ? new Date(data.visa?.start_validity) : '');
                    setDateExpiration(data.visa?.end_validity ? new Date(data.visa?.end_validity) : '');

                    const id_user = data.id_user;
                    ApiDatabase.getUserById({ token, id_user },
                        (data) => {
                            const birthDay = dateToEpoch(data.birthday);
                            setTempWorkerInfos(prevData => ({
                                ...prevData,
                                user: {
                                    ...data,
                                    birthday: (('0' + birthDay.getDate()).toString().slice(-2)) + '/' + (('0' + (parseInt(birthDay.getMonth()) + 1)).toString().slice(-2)) + '/' + birthDay.getFullYear(),
                                },
                            }));
                        },
                        (err) => {
                            if (err.name !== "CanceledError") {
                                console.log(err);
                            }
                        });
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err);
                    }
                });
        } else {
            setTempWorkerInfos({
                tempWorker: {},
                user: {},
            });
            toast.error('Une erreur est survenue, veuillez réessayer ou contacter le support');
        }
    }, [adminTempWorkerId]);

    const modalAcceptSubmit = (e) => {
        e.preventDefault();

        if (token && adminTempWorkerId && adminType && numberVisa && dateObtained !== false && dateExpiration !== false) {
            ApiDatabase.postAcceptTempWorkerFileVisaAdmin({ token, adminTempWorkerId, adminType, numberVisa, dateObtained, dateExpiration },
                (data) => {
                    setUpdateTempWorker(data._id);
                    toggle();
                    toast.success('Le Visa a été accepté');
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err);
                    }
                });
        } else {
            toast.error('Veuillez remplir tous les champs');
        }
    };

    return (
        <>
            <ModalBody>
                <div className={'flex gap-3'}>
                    <div className={'w-2/3'}>
                        {isWaitingFile ? (
                            <>
                                Chargement du document...
                            </>
                        ) : (
                            <>
                                {
                                    isFileExist ? (
                                        docs && (
                                            <DocViewer documents={docs} style={viewerStyle} pluginRenderers={DocViewerRenderers}/>
                                        )
                                    ) : (
                                        <div className={'flex justify-center items-center h-full py-3 px-1 border rounded'}>
                                            <div className={'text-gray-500'}>
                                                Une erreur est survenue lors du chargement du document. Le fichier est peut-être corrompu ou inexistant.
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    <div className={'w-1/3 flex flex-col gap-3'}>
                        <div className={'w-full'}>
                            <div className={'flex justify-between gap-5'}>
                                <div className={'w-full'}>
                                    <div className={'p-3 border rounded flex gap-3'}>
                                        <div className={'my-auto'}>
                                            {type[adminType].icon}
                                        </div>
                                        <div className={'my-auto ft-sm'}>
                                            <div className={''}>
                                                {adminType === 'identity' && (tempWorkerInfos.tempWorker.identity?.type === 'Passeport' ? 'Passeport' : 'Carte d\'identité')}
                                                {adminType === 'visa' && (tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'Visa' : 'Titre de séjour')}
                                                {(adminType !== 'identity' && adminType !== 'visa') && type[adminType].text}
                                            </div>
                                            <div className={'text-gray-500'}>
                                                {
                                                    docs.length === 2 ? 'Recto & verso' : (docs.length > 2 ? 'Plusieurs documents' : 'Document unique')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={''}>
                                    <div className={'cursor-pointer'} onClick={toggle}>
                                        <CrossIcon wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'w-full py-3'}>
                            <div className={'grid grid-cols-2 gap-4 ft-sm'}>
                                {
                                    tempWorkerInfos.tempWorker.visa?.validated === '2' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'info'}>
                                                Le document est validé.
                                            </Banner>
                                        </div>
                                    )
                                }
                                {
                                    tempWorkerInfos.tempWorker.visa?.validated === '3' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'alert'}>
                                                Le document a été refusé. Le candidat a déjà été notifié.
                                            </Banner>
                                        </div>
                                    )
                                }
                                <div className={''}>Nom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.lastname}</div>
                                <div className={''}>Prenom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.firstname}</div>
                                <div className={''}>Date de naissance</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.birthday}</div>
                                <div className={''}>Nationalité</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.tempWorker.visa?.type ? 'Hors UE' : 'UE, EE, Suisse'}</div>
                                {
                                    tempWorkerInfos.tempWorker.visa?.validated === '1' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'warning'}>
                                                {tempWorkerInfos.tempWorker.identity?.type === 'Passeport' ? 'Un passeport' : 'Une carte d\'identité'} devra aussi être vérifié pour valider ce candidat.
                                            </Banner>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <Divider/>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    N° du Visa
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           value={numberVisa}
                                           onChange={e => setNumberVisa(e.target.value)}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Date d'obtention (optionnel)
                                </div>
                                <div>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={dateObtained}
                                        onChange={(date) => setDateObtained(date)}
                                        locale='fr-FR'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        popperPlacement="bottom"
                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Date de fin de séjour (optionnel)
                                </div>
                                <div>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={dateExpiration}
                                        onChange={(date) => setDateExpiration(date)}
                                        minDate={new Date()}
                                        locale='fr-FR'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        popperPlacement="bottom"
                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={() => {
                    toggle()
                    setOpenModal('ad_file_refuse')
                }}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <Close wh={20} color={'#374151'}/>
                        </div>
                        <div className={'my-auto'}>
                            Refuser
                        </div>
                    </div>
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={modalAcceptSubmit}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <CheckIcon w={14} h={10} color={'#ffff'}/>
                        </div>
                        <div className={'my-auto'}>
                            Accepter
                        </div>
                    </div>
                </Button>
            </ModalFooter>
        </>
    )
}

export default AdminModalFileAcceptVisa;
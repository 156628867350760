import React, {useEffect, useState} from 'react';
import {Add, Delete, ShowPassword} from "assets/icons";
import ApiDatabase from 'server'
import Pdf from "components/pdf/pdf";
import usefetchdata from "hooks/usefetchdata";
import useModal from "components/modal/useModal";
import CardProfile from "components/card/cardProfile/cardProfile";

const Cv = () => {
    const {tempWorkerData}=usefetchdata()
    const {toggle}=useModal()
    const [showPDF, setShowPDF] = useState(false)
    const [url,setUrl]=useState()

useEffect(()=>{
    if (tempWorkerData?.cv) {
        ApiDatabase.getPathFile({id: tempWorkerData.cv.file},
            (data) => {
                setUrl(data.filePath)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
    }},[])
    const handleCV = (e) => {
        e.preventDefault()
        toggle('sf_c_cv')
    }
    const handleShowPDF = (e) => {
        e.preventDefault()
        setShowPDF(!showPDF)
    }
    const handleDeletedPDF = (e) => {
        e.preventDefault()
        toggle('sf_d_cv')
    }

    return (
        <>
            <CardProfile>
                <div className="grid grid-cols-4 flex items-center">
                    <div className="col-span-3 ft-b text-gray-900">CV</div>
                    <div className='col-span-1 flex justify-end'>
                        {tempWorkerData?.cv ?
                            <div className='flex flex-row items-center gap-3'>
                                <a href={`${process.env.REACT_APP_FILE + url}`} target={"_blank"}
                                    className='rounded-full w-10 h-10 bg-gray-50 hover:bg-gray-100 flex items-center justify-center'>
                                        <ShowPassword wh={18} color={'#6B7280'}
                                                      strokeW={2}/>
                                </a>
                                <button
                                    onClick={handleDeletedPDF}
                                    className='rounded-full w-10 h-10 bg-red-50 hover:bg-red-100 flex items-center justify-center'>
                                    <Delete wh={18} color={'#EF4444'}/>
                                </button>
                            </div>

                            :
                            <button
                                onClick={handleCV}
                                className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'>
                                <Add wh={18} color={'#3B82F6'}/>
                            </button>}
                    </div>
                </div>
                <div className='w-full flex items-center justify-center'>
                    {showPDF ?
                        <Pdf path={tempWorkerData?.cv?.file}/>
                        : ''}
                </div>
            </CardProfile>
            {/*<Modaldeletepdf isShowing={modalDeletedPDF} hide={handleDeletedPDF}*/}
            {/*                userData={userData?.cv?.file}/>*/}

        </>
    );
};

export default Cv;
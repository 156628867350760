import SlideOverHeader from "../../../component/header";
import SlideOverBody from "../../../component/body";
import useSlideOver from "../../../useSlideOver";
import SlideOverFooter from "../../../component/footer";
import Button from "../../../../button";
import Divider from "../../../../divider/divider";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import AddressGeoApi from "../../../../AddressGeoApi/AddressGeoApi";
import ApiDatabase from "server";
import {compareObjects} from "../../../../../helper/helper";
import {useNavigate} from "react-router-dom";
import useContext from "../../../../../hooks/useContext";
import TextSelectCollectiveAgreement from "../../../../textSelect/components/textSelectCollectiveAgreement";
import TextSelectSearchCompany from "../../../../textSelect/components/textSelectSearchCompany";

const slideOverAddCompany = () => {
    const {removeAllParameters} = useContext();
    const {toggleSlideOver} = useSlideOver();
    const [searchCompany, setSearchCompany] = useState({})
    const [searchCollectiveAgreementDisplay, setSearchCollectiveAgreementDisplay] = useState('')
    const [addressLocation, setAddressLocation] = useState({})
    const [addressInvoice, setAddressInvoice] = useState('')
    const [isLeaderSameAsAdmin, setIsLeaderSameAsAdmin] = useState(true)
    const [isLocationSameAsInvoiceAddress, setIsLocationSameAsInvoiceAddress] = useState(true)
    const [isLoadingAddCompany, setIsLoadingAddCompany] = useState(false)
    const [addCompanyDatas, setAddCompanyDatas] = useState({
        reason_social: null,
        name_commercial: null,
        status: null,
        code_ape: null,
        conv_collective: null,
        siret: null,
        staff: null,
        capital: null,
        bank_transfer: true,
        cheque: false,
        location: {},
        invoice_address: '',
        email_admin: null,
        lastname_admin: null,
        firstname_admin: null,
        phone_admin:null,
        lastname_leader: null,
        firstname_leader: null,
        email_leader: null,
        phone_leader: null,
        coefficient_management: null,
        coefficient_delegation: null,
    })
    const navigate = useNavigate()
    const token = localStorage.getItem('xsrfToken')

    const submitCompany = () => {
        if(
            addCompanyDatas.reason_social &&
            addCompanyDatas.name_commercial &&
            addCompanyDatas.siret &&
            addCompanyDatas.status &&
            addCompanyDatas.bank_transfer !== null &&
            addCompanyDatas.cheque !== null &&
            addCompanyDatas.location &&
            addCompanyDatas.invoice_address &&
            addCompanyDatas.email_admin &&
            addCompanyDatas.lastname_admin &&
            addCompanyDatas.firstname_admin &&
            addCompanyDatas.phone_admin &&
            addCompanyDatas.email_leader &&
            addCompanyDatas.lastname_leader &&
            addCompanyDatas.firstname_leader &&
            addCompanyDatas.phone_leader
        ) {
            setIsLoadingAddCompany(true)
            ApiDatabase.postAddCompany({token: token, company: addCompanyDatas}, (data) => {
                toast.success('Entreprise ajoutée')
                closeSlideOver()
                navigate('/company?idCompany=' + data.company)
                setIsLoadingAddCompany(false)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    if (err?.response?.data?.message) toast.error(err?.response?.data?.message)
                    setIsLoadingAddCompany(false)
                }
            })
        } else {
            toast.error('Veuillez remplir tous les champs')
        }
    }

    useEffect(() => {
        if (!compareObjects(addCompanyDatas?.location, addressLocation) && Object.keys(addressLocation).length > 0) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, location: addressLocation}))
        }
    }, [addressLocation]);

    useEffect(() => {
        if (!compareObjects(addCompanyDatas?.invoice_address, addressInvoice) && Object.keys(addressInvoice).length > 0) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, invoice_address: addressInvoice}))
        }
    }, [addressInvoice]);

    useEffect(() => {
        if(isLeaderSameAsAdmin) {
            setAddCompanyDatas(prevDatas => ({
                ...prevDatas,
                lastname_leader: addCompanyDatas.lastname_admin,
                firstname_leader: addCompanyDatas.firstname_admin,
                email_leader: addCompanyDatas.email_admin,
                phone_leader: addCompanyDatas.phone_admin
            }))
        } else {
            setAddCompanyDatas(prevDatas => ({
                ...prevDatas,
                lastname_leader: '',
                firstname_leader: '',
                email_leader: '',
                phone_leader: ''
            }))
        }
    }, [isLeaderSameAsAdmin, addCompanyDatas.lastname_admin, addCompanyDatas.firstname_admin, addCompanyDatas.email_admin, addCompanyDatas.phone_admin]);

    useEffect(() => {
        if(isLocationSameAsInvoiceAddress) {
            setAddCompanyDatas(prevDatas => ({
                ...prevDatas,
                invoice_address: addCompanyDatas.location
            }))
            setAddressInvoice(addCompanyDatas.location)
        } else {
            setAddCompanyDatas(prevDatas => ({...prevDatas, invoice_address: ''}))
            setAddressInvoice('')
        }
    }, [isLocationSameAsInvoiceAddress, addCompanyDatas.location]);

    useEffect(() => {
        //remove , and . from siret
        if(addCompanyDatas.siret) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, siret: addCompanyDatas.siret.replace(/,/g, '').replace(/\./g, '').slice(0, 14)}))
        }
        if(addCompanyDatas.staff) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, staff: addCompanyDatas.staff.replace(/,/g, '').replace(/\./g, '').slice(0, 8)}))
        }
        if(addCompanyDatas.capital) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, capital: addCompanyDatas.capital.replace(/,/g, '').replace(/\./g, '').slice(0, 8)}))
        }
        //change , by .
        if(addCompanyDatas.coefficient_management) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, coefficient_management: addCompanyDatas.coefficient_management.replace(/,/g, '.').slice(0, 4)}))
        }
        if(addCompanyDatas.coefficient_delegation) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, coefficient_delegation: addCompanyDatas.coefficient_delegation.replace(/,/g, '.').slice(0, 4)}))
        }
        if(addCompanyDatas.email_admin) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, email_admin: addCompanyDatas.email_admin.toLowerCase().replace(/\s/g, '')}))
        }
        if(addCompanyDatas.email_leader) {
            setAddCompanyDatas(prevDatas => ({...prevDatas, email_leader: addCompanyDatas.email_leader.toLowerCase().replace(/\s/g, '')}))
        }
    }, [addCompanyDatas.siret, addCompanyDatas.staff, addCompanyDatas.capital, addCompanyDatas.email_admin, addCompanyDatas.email_leader, addCompanyDatas.coefficient_management, addCompanyDatas.coefficient_delegation])

    const closeSlideOver = () => {
        toggleSlideOver()
        removeAllParameters()
    }

    useEffect(() => {
        if (searchCompany) {
            const reasonSocial = searchCompany.nom_entreprise || null;
            const nameCommercial = searchCompany.siege?.nom_commercial ?? reasonSocial ?? null;
            const siretNumber = searchCompany.siege?.siret || null;
            const workforce = searchCompany.siege?.effectif ? searchCompany.siege.effectif.replace(/[^0-9]/g, '') : null;
            const apeCode = searchCompany.siege?.code_naf ? searchCompany.siege.code_naf.replace(/\./g, '') : null;
            const socialCapital = searchCompany.capital_formate ? Math.round(parseFloat(searchCompany.capital_formate.replace(/\s/g, '').replace(',', '.').replace(/[^0-9.]/g, ''))).toString() : null;
            const legalForm = searchCompany.forme_juridique ? searchCompany.forme_juridique.split(',')[0] : null;
            const collectiveAgreement = (searchCompany.conventions_collectives && searchCompany.conventions_collectives.length > 0) ? searchCompany.conventions_collectives[0].idcc : null;

            const street = searchCompany.siege?.libelle_voie || null;
            const addressComplement = searchCompany.siege?.complement_adresse || null;
            const city = searchCompany.siege?.ville || null;
            const postalCode = searchCompany.siege?.code_postal || null;
            const department = searchCompany.siege?.departement || null;

            const address = {
                street: street,
                complement: addressComplement,
                city: city,
                postcode: postalCode,
                department: department,
            };

            setAddCompanyDatas(prevDatas => ({
                ...prevDatas,
                reason_social: reasonSocial,
                name_commercial: nameCommercial,
                siret: siretNumber,
                staff: workforce,
                code_ape: apeCode,
                capital: socialCapital,
                status: legalForm
            }))

            setAddressLocation(address)
            if (collectiveAgreement) setSearchCollectiveAgreementDisplay(collectiveAgreement)
        }
    }, [searchCompany])

    return (
        <>
            <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                Ajouter une entreprise
            </SlideOverHeader>
            <SlideOverBody>
                <div className={'flex flex-col gap-6'}>
                    <TextSelectSearchCompany searchCompany={searchCompany} setSearchCompany={setSearchCompany}/>
                    <div className={'text-xl'}>Informations légales</div>
                    <div className={'w-full'}>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Raison sociale
                            </div>
                            <div>
                                <input type="text"
                                       className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                       value={addCompanyDatas.reason_social}
                                       onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, reason_social: e.target.value}))}
                                       maxLength={50}
                                       onInput={(e) => {
                                           e.target.value = e.target.value.toUpperCase()
                                       }}
                                />
                            </div>
                        </label>
                    </div>
                    <div className={'w-full'}>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Nom commercial
                            </div>
                            <div>
                                <input type="text"
                                       className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                       value={addCompanyDatas.name_commercial}
                                       onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, name_commercial: e.target.value}))}
                                       maxLength={50}
                                       onInput={(e) => {
                                           e.target.value = e.target.value.toUpperCase()
                                       }}
                                />
                            </div>
                        </label>
                    </div>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Siret
                                </div>
                                <div className={''}>
                                    <input type="number"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.siret}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, siret: e.target.value}))}
                                           maxLength={14}
                                           onInput={(e) => {
                                               //accept only numbers
                                               e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Effectif <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                </div>
                                <div>
                                    <input type="number"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.staff}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, staff: e.target.value}))}
                                           maxLength={8}
                                           onInput={(e) => {
                                               //accept only numbers
                                               e.target.value = e.target.value.replace(/[^0-9]/g, '')
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Code APE <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.code_ape}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, code_ape: e.target.value}))}
                                           maxLength={5}
                                           onInput={(e) => {
                                               //accept only letters and numbers
                                               e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                               e.target.value = e.target.value.toUpperCase()
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Capital social <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                </div>
                                <div>
                                    <input type="number"
                                           step="0.01"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.capital}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, capital: e.target.value}))}
                                           maxLength={8}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Forme juridique
                                </div>
                                <div>
                                    <select className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                            value={addCompanyDatas.status}
                                            onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, status: e.target.value}))}
                                    >
                                        <option value={null}>Choisir</option>
                                        <option value={'SAS'}>SAS</option>
                                        <option value={'SASU'}>SASU</option>
                                        <option value={'SARL'}>SARL</option>
                                        <option value={'EURL'}>EURL</option>
                                        <option value={'SEARL'}>SEARL</option>
                                        <option value={'EI'}>EI</option>
                                        <option value={'SAI'}>SAI</option>
                                        <option value={'GIE'}>GIE</option>
                                        <option value={'SCI'}>SCI</option>
                                        <option value={'SCOP'}>SCOP</option>
                                        <option value={'SICA'}>SICA</option>
                                        <option value={'SICA'}>SMIA</option>
                                        <option value={'SNC'}>SNC</option>
                                        <option value={'Association'}>Association</option>
                                        <option value={'Administration'}>Administration</option>
                                        <option value={'Société étrangère'}>Société étrangère</option>
                                        <option value={'AUTRES'}>AUTRES</option>
                                    </select>
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <TextSelectCollectiveAgreement
                                collectiveAgreement={addCompanyDatas.conv_collective}
                                setCollectiveAgreement={(data) => setAddCompanyDatas(prevDatas => ({...prevDatas, conv_collective: data}))}
                                searchCollectiveAgreementDisplay={searchCollectiveAgreementDisplay}
                            />
                        </div>
                    </div>
                    <Divider/>
                    <div className={'text-xl'}>Compte Administrateur</div>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Nom
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.lastname_admin}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, lastname_admin: e.target.value}))}
                                           onInput={(e) => {
                                               e.target.value = e.target.value.toUpperCase()
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Prénom
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.firstname_admin}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, firstname_admin: e.target.value}))}
                                           onInput={(e) => {
                                               //only first letter in uppercase
                                               e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Email
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.email_admin}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, email_admin: e.target.value}))}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Téléphone
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.phone_admin}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, phone_admin: e.target.value}))}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <Divider/>
                    <div className={'text-xl'}>Contact Dirigeant</div>
                    <div className={''}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="checkbox"
                                   className={'w-4 h-4 accent-yellow-400 my-auto'}
                                   onChange={() => setIsLeaderSameAsAdmin(!isLeaderSameAsAdmin)}
                                   checked={isLeaderSameAsAdmin}
                            />
                            <div className={'my-auto'}>
                                Le contact est le même que l'administrateur
                            </div>
                        </label>
                    </div>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Nom
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.lastname_leader}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, lastname_leader: e.target.value}))}
                                           {...isLeaderSameAsAdmin ? {disabled: true} : {}}
                                           onInput={(e) => {
                                               e.target.value = e.target.value.toUpperCase()
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Prénom
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.firstname_leader}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, firstname_leader: e.target.value}))}
                                           {...isLeaderSameAsAdmin ? {disabled: true} : {}}
                                           onInput={(e) => {
                                               //only first letter in uppercase
                                               e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Email
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.email_leader}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, email_leader: e.target.value}))}
                                           {...isLeaderSameAsAdmin ? {disabled: true} : {}}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Téléphone
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.phone_leader}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, phone_leader: e.target.value}))}
                                           {...isLeaderSameAsAdmin ? {disabled: true} : {}}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <Divider/>
                    <div className={'text-xl'}>Méthode de facturation</div>
                    <div className={'w-full flex gap-4'}>
                        <div className={`my-auto p-3 text-center w-full border rounded cursor-pointer ${addCompanyDatas.bank_transfer ? 'bg-yellow-500 text-white' : 'bg-gray-50'}`} onClick={() => {
                            setAddCompanyDatas(prevDatas => ({...prevDatas, bank_transfer: true, cheque: false}))
                        }}>
                            Transfert bancaire
                        </div>
                        <div className={`my-auto p-3 text-center w-full border rounded cursor-pointer ${addCompanyDatas.cheque ? 'bg-yellow-500 text-white' : 'bg-gray-50'}`} onClick={() => {
                            setAddCompanyDatas(prevDatas => ({...prevDatas, bank_transfer: false, cheque: true}))
                        }}>
                            Chèque
                        </div>
                    </div>
                    <Divider/>
                    <div className={'text-xl'}>Adresse de l'entreprise</div>
                    <div className={'w-full'}>
                        <AddressGeoApi
                          label={'Adresse'}
                          addressGeoValue={setAddressLocation}
                          addressValue={addressLocation}
                          page={'createCompany'}
                        />
                    </div>
                    <Divider/>
                    <div className={'text-xl'}>Adresse de facturation</div>
                    <div className={''}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="checkbox"
                                   className={'w-4 h-4 accent-yellow-400 my-auto'}
                                   onChange={() => setIsLocationSameAsInvoiceAddress(!isLocationSameAsInvoiceAddress)}
                                   checked={isLocationSameAsInvoiceAddress}
                            />
                            <div className={'my-auto'}>
                                L'adresse de facturation est la même que l'adresse de l'entreprise
                            </div>
                        </label>
                    </div>
                    {!isLocationSameAsInvoiceAddress && (
                        <div className={'w-full'}>
                            <AddressGeoApi
                              label={'Adresse'}
                              addressGeoValue={setAddressInvoice}
                              addressValue={addressInvoice}
                              page={'createCompany'}
                            />
                        </div>
                    )}
                    <Divider/>
                    <div className={'text-xl'}>Coefficients de facturation</div>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Gestion <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                </div>
                                <div>
                                    <input type="number"
                                           step="0.01"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.coefficient_management}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, coefficient_management: e.target.value}))}
                                           maxLength={4}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Délégation <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
                                </div>
                                <div>
                                    <input type="number"
                                           step="0.01"
                                           className='w-full h-10 px-3 ft-b border rounded-lg text-gray-900'
                                           value={addCompanyDatas.coefficient_delegation}
                                           onChange={(e) => setAddCompanyDatas(prevDatas => ({...prevDatas, coefficient_delegation: e.target.value}))}
                                           maxLength={4}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </SlideOverBody>
            <SlideOverFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={closeSlideOver}>Annuler</Button>
                {isLoadingAddCompany ? (
                    <Button size={'LG'} color={'DISABLED'}>Création de l'entreprise ...</Button>
                ) : (
                    <Button size={'LG'} color={'PRIMARY'} onClick={submitCompany}>Confirmer</Button>
                )}
            </SlideOverFooter>
        </>
    )
}

export default slideOverAddCompany
import {useEffect, useState} from 'react';
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";

const Usefetchdata = () => {
    const {refreshUser, refreshWorker, userData, setUserData, tempWorkerData, setTempWorkerData} = UseContext()

    useEffect(() => {
        if(!userData?._id) {
            const token = localStorage.getItem('xsrfToken')
            ApiDatabase.getUserProfile({token},
                (data => {
                    setUserData(data)
                }),
                (err => {
                    if (err.name !== "CanceledError") {
                        if (err.response?.status === 400 || err.response?.status === 204) {
                            localStorage.removeItem('xsrfToken')
                            localStorage.removeItem('rUser')
                            window.location.href = process.env.REACT_APP_ADMIN_LOGIN
                        }
                    }
                }))
        }
    }, [refreshUser, userData])

    useEffect(() => {
        if (!tempWorkerData?._id) {
            const token = localStorage.getItem('xsrfToken')
            ApiDatabase.getTempWorker({token},
                (data => {
                    setTempWorkerData(data)

                }),
                (err => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                }))
        }
    }, [refreshWorker, tempWorkerData])

    return {
        userData, tempWorkerData
    }
}

export default Usefetchdata;
import {EyeIcon} from "../../../assets/icons";
import useSlideOver from "../../SlideOver/useSlideOver";
import UseContext from "../../../hooks/useContext";
import {useEffect} from "react";
const {useNavigate} = require("react-router-dom");

const Tbodycompany = ({champsBody}) => {
    const {objID, setObjID}=UseContext()
    const {toggleSlideOver} = useSlideOver();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const idCompany = urlParams.get('idCompany');

    const openCompanyInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_company')
        navigate('/company?idCompany=' + id);
    }

    useEffect(() => {
        if(idCompany) {
            if(!objID){
                openCompanyInfo(idCompany)
            } else {
                if(idCompany !== objID) {
                    openCompanyInfo(idCompany)
                }
            }
        }
    }, [idCompany])

    return (
        <>
            {champsBody?.map((company, key) =>
                <tr key={key} className={'border-b-2 border-gray-100 '}>
                    <td className={'py-5'}>{company.name_commercial}</td>
                    <td className={'py-5'}>
                        {company.bigAccounts?.join(', ')}
                    </td>
                    <td className={'py-5'}>
                        {('0' + new Date(company.createdAt).getDate()).toString().slice(-2) + '/' + ('0' + (new Date(company.createdAt).getMonth() + 1)).toString().slice(-2) + '/' + new Date(company.createdAt).getFullYear()}
                    </td>
                    <td className={'py-5 flex items-center justify-center cursor-pointer'}>
                        <button className={'flex items-center justify-center'} onClick={() => {openCompanyInfo(company._id)}}>
                            <EyeIcon wh={20} color={'#374151'}/>
                        </button>
                    </td>
                </tr>
            )}
        </>
    )
}

export default Tbodycompany;
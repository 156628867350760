import React, {useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import useModal from "components/modal/useModal";
import Button from "components/button";

const ModalSignature = () => {
    const [signatureLink, setSignatureLink] = useState('')
    const {toggle} = useModal()
    const handleSubmit = e => {
        e.preventDefault()
        alert('test')
    }
    window.addEventListener('message', function (e) {
        if (e.data.event === 'success') {
            console.log('Signer has successfully signed')
        }
    })
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Signature du document</Modalheader>
            <ModalBody>

                <iframe className={'w-full h-[700px]'}
                        src={'https://yousign.app/signatures/786c0ad9-5f36-4169-901f-100f680cb396?s=5cb9fd389f8e6918db6be7946851cf980ce60c8649877311c99dee2256ee22cf1c17fb710643d815c9f8fe3a1766aeb85c0cf702b9af053c803b3a6a795f2abc&r=7670ea67fbfd5fedea26d180a9a08a98&source=email&lang=fr&magic_link_id=caa1facc-84aa-47b2-a64d-4278e09c597a&domain_id=198f68f4d5&k=zYsWln1RHVdXYaGx0pY7qk8YIk01RlH5&sandbox=true&disable_domain_validation=true'}></iframe>

            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>

            </ModalFooter>
        </>
    );
};

export default ModalSignature;
import Button from "../../../../../components/button";
import {Plus} from "../../../../../assets/icons";
import React from "react";
import useSlideOver from "../../../../../components/SlideOver/useSlideOver";
import UseContext from "../../../../../hooks/useContext";

const HeaderQualification = () => {
    const {setObjID} = UseContext();
    const {toggleSlideOver} = useSlideOver();

    const addQualification = () => {
        setObjID('')
        toggleSlideOver('qualification')
    }

    return (
        <div className={'flex justify-end border-b py-2 px-8'}>
            <Button color={'PRIMARY'} size={'LGI'} onClick={() => addQualification()}>
                <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                        <Plus wh={16} color={"#FFFFFF"}/>
                    </div>
                    <div className={'my-auto'}>
                        Créer une qualification
                    </div>
                </div>
            </Button>
        </div>
    )
}

export default HeaderQualification;
import React from 'react';

const Thead = ({champsHeader}) => {
    return (
        <>
            <tr>
                {
                    champsHeader?.map((item, key) =>
                        <th key={key} className={'m-3 px-2 text-left'}>{item.toUpperCase()}</th>
                    )
                }
                <th></th>
            </tr>
        </>
    );
};

export default Thead;
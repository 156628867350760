import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import {dateToEpoch} from "../../helper/helper";
import useSlideOver from "../SlideOver/useSlideOver";
import UseContext from "../../hooks/useContext";
import Divider from "../divider/divider";
import ScheduleWeekListMission from "./ScheduleWeekListMission";
import {ChevronDown, ChevronTop, Pencil, Plus, Warning} from "../../assets/icons";
import Banner from "../banner/banner";

const Schedule = ({mission, dateStart, dateEnd, isEditable, schedule}) => {
  const {toggleSlideOver} = useSlideOver();
  const [scheduleWeeksProv, setScheduleWeeksProv] = useState([]);
  const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0);
  const [dateStartSchedule, setDateStartSchedule] = useState(null);
  const [dateEndSchedule, setDateEndSchedule] = useState(null);
  const [isScheduleToChange, setIsScheduleToChange] = useState(false);
  const [dateStartMission, setDateStartMission] = useState(null);
  const [dateEndMission, setDateEndMission] = useState(null);
  const [schedulePrev, setSchedulePrev] = useState(null);
  const [dateStartPrev, setDateStartPrev] = useState(null);
  const [dateEndPrev, setDateEndPrev] = useState(null);
  const {
    scheduleWeeks,
    setScheduleWeeks,
    setMissionDetails,
    updSchedule,
    setUpdSchedule
  } = UseContext();
  const token = localStorage.getItem('xsrfToken');
  const [missionProv, setMissionProv] = useState(null);
  const [isScheduleDayMissing, setIsScheduleDayMissing] = useState(false);

  const fetchSchedule = (missionId) => {
    ApiDatabase.getScheduleByIdMission({token, idMission: missionId}, (res) => {
        setScheduleWeeksProv(res);
        setUpdSchedule(false);
      },
      (err) => {
        if (err.name !== "CanceledError") {
          console.log(err);
        }
      })
  };

  useEffect(() => {
    if ((mission?._id && missionProv !== mission) || updSchedule || schedulePrev !== schedule || dateStartPrev !== dateStart || dateEndPrev !== dateEnd) {
      setMissionProv(mission);
      setMissionDetails({
        _id: mission?._id,
        duration: {
          datestart: dateStart,
          dateend: dateEnd
        },
        schedule: mission?.schedule,
        title: mission?.title,
        id_mission: mission?.id_mission,
        name_commercial: mission?.company?.name_commercial,
      });
      if (!schedule) {
        fetchSchedule(mission?._id);
      } else {
        setScheduleWeeksProv(schedule);
      }
      setUpdSchedule(false);
      setSchedulePrev(schedule);
      setDateStartPrev(dateStart)
      setDateEndPrev(dateEnd)
    }
  }, [mission, updSchedule, schedule, dateStart, dateEnd]);

  useEffect(() => {
    if (scheduleWeeksProv.length > 0) {
      const scheduleWeeksProvTemp = scheduleWeeksProv;
      scheduleWeeksProvTemp.sort((a, b) => a.week_number - b.week_number);

      setDateStartSchedule(dateToEpoch(new Date(scheduleWeeksProvTemp[0].days[0].date)));
      setDateEndSchedule(dateToEpoch(new Date(scheduleWeeksProvTemp[scheduleWeeksProvTemp.length - 1].days[scheduleWeeksProvTemp[scheduleWeeksProvTemp.length - 1].days.length - 1].date)));

      let isAWeekChosen = false;
      for (let i = 0; i < scheduleWeeksProvTemp.length; i++) {
        scheduleWeeksProvTemp[i].days.sort((a, b) => a.date - b.date);

        const number_week = ('0' + (parseInt(scheduleWeeksProvTemp[i].week_number || i) + 1)).slice(-2);
        scheduleWeeksProvTemp[i].name = `Semaine ${number_week}`;
        scheduleWeeksProvTemp[i].isNext = !!scheduleWeeksProvTemp[i + 1];
        scheduleWeeksProvTemp[i].isPrev = !!scheduleWeeksProvTemp[i - 1];

        let isHidden = true;
        for (let j = 0; j < scheduleWeeksProvTemp[i].days.length; j++) {
          const scheduleDate = dateToEpoch(scheduleWeeksProvTemp[i].days[j].date);
          const today = dateToEpoch(new Date());

          if (scheduleDate === today) {
            setScheduleWeekDisplay(i);
            isHidden = false;
            isAWeekChosen = true;
          }

          if (scheduleWeeksProvTemp[i].days[j].periods) {
            if (scheduleWeeksProvTemp[i].days[j].periods?.periode_one_start === null && scheduleWeeksProvTemp[i].days[j].periods?.periode_one_end === null) {
              setIsScheduleDayMissing(true);
            }
          } else {
            setIsScheduleDayMissing(true);
          }
        }
        scheduleWeeksProvTemp[i].isHidden = isHidden;
        scheduleWeeksProvTemp[i].isActualWeek = !isHidden;
      }
      if (!isAWeekChosen) {
        scheduleWeeksProvTemp[0].isHidden = false;
      }

      setScheduleWeeks(scheduleWeeksProvTemp);
    } else {
      setScheduleWeeks([]);
    }
  }, [scheduleWeeksProv]);

  useEffect(() => {
    if (dateStart && dateEnd) {
      setDateStartMission(dateToEpoch(new Date(dateStart)));
      setDateEndMission(dateToEpoch(new Date(dateEnd)));
    } else {
      if (mission?.duration?.datestart && mission?.duration?.dateend) {
        setDateStartMission(dateToEpoch(new Date(mission?.duration?.datestart)));
        setDateEndMission(dateToEpoch(new Date(mission?.duration?.dateend)));
      }
    }
  }, [mission, dateStart, dateEnd]);

  useEffect(() => {
    if (dateStartSchedule && dateEndSchedule && dateStartMission && dateEndMission) {
      if (dateStartSchedule.getTime() !== dateStartMission.getTime() || dateEndSchedule.getTime() !== dateEndMission.getTime()) {
        setIsScheduleToChange(true);
      } else {
        setIsScheduleToChange(false);
      }
    }
  }, [dateStartSchedule, dateEndSchedule, dateStartMission, dateEndMission]);

  const editSchedule = () => {
    toggleSlideOver('get_schedule');
  };

  return (
    <>
      <Divider/>
      <div className={'flex flex-col gap-3'}>
        <div className={'flex justify-between gap-1 items-center'}>
          <div className={''}>Planning</div>
          <div className={'flex gap-3 items-center'}>
            {
              scheduleWeeksProv.length > 0 ? (
                <>
                  {isScheduleToChange && (
                    <div
                      className={'border rounded px-2 flex items-center gap-2 text-sm bg-red-100 text-red-800 h-fit my-auto'}>
                      <div className={''}>
                        <Warning wh={16} color={'#F87171'}/>
                      </div>
                      <div className={''}>
                        À modifier
                      </div>
                    </div>
                  )}
                  {isScheduleDayMissing && (
                    <div className={'border rounded px-2 flex gap-2 items-center text-sm bg-red-100 text-red-800 h-fit my-auto'}>
                      Manquant
                    </div>
                  )}
                  {isEditable && (
                    <div className={'border rounded p-2 cursor-pointer'} onClick={() => editSchedule()}>
                      <Pencil wh={18} color={'#374151'}/>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={'border rounded px-2 flex items-center gap-2 text-sm bg-red-100 text-red-800 h-fit'}>
                    Manquant
                  </div>
                  {isEditable && (
                    <div
                      className={'border rounded p-2 w-9 h-9 flex justify-center items-center cursor-pointer'}
                      onClick={() => editSchedule()}>
                      <Plus wh={18} color={"#374151"}/>
                    </div>
                  )}
                </>
              )
            }
          </div>
        </div>
        {
          (scheduleWeeks.length > 0) && (
            <>
              {isScheduleToChange && (
                <Banner type={'alert'}>
                  Les dates du planning ne correspondent pas avec celles de la mission
                </Banner>
              )}
            </>
          )
        }
        {
          scheduleWeeks.map((scheduleWeek, index) => {
            if (scheduleWeek.isNext !== undefined && scheduleWeek.isPrev !== undefined && scheduleWeek.isHidden !== undefined && scheduleWeek.isActualWeek !== undefined) {
              return (
                <ScheduleWeekListMission
                  key={index}
                  indexScheduleWeek={index}
                  scheduleWeek={scheduleWeek}
                  isNext={scheduleWeek.isNext}
                  isPrev={scheduleWeek.isPrev}
                  isHidden={scheduleWeekDisplay !== index}
                  isActualWeek={scheduleWeek.isActualWeek}
                  setScheduleWeekDisplay={setScheduleWeekDisplay}
                />
              )
            }
          })
        }
      </div>
      <Divider/>
    </>
  )
}

export default Schedule;

import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import React, {useEffect, useState} from "react";
import StyledInput from "../../../../styledInput/StyledInput";
import UseContext from "../../../../../hooks/useContext";
import Divider from "../../../../divider/divider";
import DatePicker from "react-datepicker";
import {Calendar, WarningCircle} from "../../../../../assets/icons";
import {dateToEpoch, dateWordShort} from "../../../../../helper/helper";

const ModalMissionDetailReason = () => {
  const {toggle} = useModal();
  const {modifyMissionDetailData, setModifyMissionDetailData} = UseContext()
  const [reasonModify, setReasonModify] = useState({})
  const [errorProof, setErrorProof] = useState('')
  const [errorProofFirstname, setErrorProofFirstname] = useState('')
  const [errorProofLastname, setErrorProofLastname] = useState('')
  const [errorProofDate, setErrorProofDate] = useState('')
  const [errorProofDescription, setErrorProofDescription] = useState('')
  const [errorProofJob, setErrorProofJob] = useState('')

  const [reasons, setReasons] = useState([
    {
      name: "Accroissement temporaire d'activité",
      type: "increase",
      checked: false,
    },
    {
      name: "Contrat temporaire d'usage",
      type: "temporary",
      checked: false,
    },
    {
      name: "Emploi à caractère saisonnier",
      type: "seasonal",
      checked: false,
    },
    {
      name: "En attente de recrutement",
      type: "recruitment",
      checked: false,
    },
    {
      name: "Remplacement d'un ou plusieurs salariés absents",
      type: "replacement",
      checked: false,
    },
  ])

  useEffect(() => {
    if (modifyMissionDetailData.reason) {
      const updatedReasons = reasons.map(reason => ({
        ...reason,
        checked: reason.type === modifyMissionDetailData.reason.type
      }));

      setReasons(updatedReasons);

      setReasonModify({
        ...modifyMissionDetailData.reason
      })
    }
  }, [modifyMissionDetailData]);

  useEffect(() => {
    const checkedReason = reasons.find(reason => reason.checked);
    if (checkedReason) {
      setReasonModify(prevData => ({
        ...prevData,
        reason: checkedReason.name,
        type: checkedReason.type
      }));
    }
  }, [reasons]);

  const handleSubmit = () => {
    setErrorProof('')
    setErrorProofFirstname('')
    setErrorProofLastname('')
    setErrorProofDate('')
    setErrorProofDescription('')
    setErrorProofJob('')

    if (reasonModify.type === "increase" || reasonModify.type === "temporary" || reasonModify.type === "seasonal") {
      if (!reasonModify.proof) {
        setErrorProof('Veuillez compléter le champs')
        return;
      }
    } else if (reasonModify.type === "recruitment") {
      if (!reasonModify.proof_lastname) {
        setErrorProofLastname('Veuillez compléter le champs')
        return;
      }
      if (!reasonModify.proof_firstname) {
        setErrorProofFirstname('Veuillez compléter le champs')
        return;
      }
      if (!reasonModify.proof_date) {
        setErrorProofDate('Veuillez compléter le champs')
        return;
      }
    } else if (reasonModify.type === "replacement") {
      if (!reasonModify.proof_lastname) {
        setErrorProofLastname('Veuillez compléter le champs')
        return;
      }
      if (!reasonModify.proof_firstname) {
        setErrorProofFirstname('Veuillez compléter le champs')
        return;
      }
      if (!reasonModify.proof_job) {
        setErrorProofJob('Veuillez compléter le champs')
        return;
      }
      if (!reasonModify.proof_description) {
        setErrorProofDescription('Veuillez compléter le champs')
        return;
      }
    }

    const updatedReasonModify = {
      ...reasonModify,
      proof: reasonModify.type === "replacement"
        ? reasonModify.proof_job + ' : ' + reasonModify.proof_description
        : reasonModify.type === "recruitment"
          ? reasonModify.proof_firstname + " " + reasonModify.proof_lastname + " - " + dateWordShort(reasonModify.proof_date)
          : reasonModify.proof,
    };

    setModifyMissionDetailData(prevData => ({
      ...prevData,
      reason: updatedReasonModify,
    }))

    toggle()
  }

  const updateReasons = (updatedReason) => {
    const updatedReasons = [...reasons];

    for (let i = 0; i < updatedReasons.length; i++) {
      for (let j = 0; j < updatedReason.length; j++) {
        if (updatedReasons[i].type === updatedReason[j].value) {
          updatedReasons[i].checked = updatedReason[j].checked;
        }
      }
    }

    setReasons(updatedReasons);
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Modifier le motif
      </Modalheader>
      <ModalBody>
        {reasonModify && (
          <div className={'flex flex-col gap-4 text-sm min-h-[300px]'}>
            <StyledInput
              type={"selectMultipleRadio"}
              label={"Sélectionner le motif"}
              value={
                reasons.filter(reasons => reasons.checked === true).length > 0
                  ? reasons
                    .filter(reasons => reasons.checked === true)
                    .map((reasons) => reasons.name)
                    .join(", ")
                  : "Qualification"
              }
              list={reasons.map((reasons) => {
                return {
                  value: reasons.type,
                  label: reasons.name,
                  checked: reasons.checked,
                };
              })}
              onChange={(updatedReasons) =>
                updateReasons(updatedReasons)
              }
              isSearchable
            />
            <Divider/>
            {(reasonModify.type === "increase" || reasonModify.type === "temporary" || reasonModify.type === "seasonal") && (
              <StyledInput
                type={"textarea"}
                label={"Motif"}
                value={reasonModify.proof}
                onChange={(e) => {
                  setReasonModify((prevData) => ({
                    ...prevData,
                    proof: e.target.value,
                    proof_firstname: null,
                    proof_lastname: null,
                    proof_description: null,
                    proof_date: null,
                  }))
                }}
                error={errorProof}
              />
            )}
            {reasonModify.type === "recruitment" && (
              <>
                <div className={'w-full flex gap-2'}>
                  <div className={'w-full'}>
                    <StyledInput
                      type={"text"}
                      label={'Nom'}
                      value={reasonModify.proof_lastname}
                      onChange={(e) => {
                        setReasonModify((prevData) => ({
                          ...prevData,
                          proof_lastname: e.target.value,
                          proof: null,
                          proof_description: null,
                        }))
                      }}
                      error={errorProofLastname}
                    />
                  </div>
                  <div className={'w-full'}>
                    <StyledInput
                      type={"text"}
                      label={'Prénom'}
                      value={reasonModify.proof_firstname}
                      onChange={(e) => {
                        setReasonModify((prevData) => ({
                          ...prevData,
                          proof_firstname: e.target.value,
                          proof: null,
                          proof_description: null,
                        }))
                      }}
                      error={errorProofFirstname}
                    />
                  </div>
                </div>
                <label className='ft-sm text-gray-500'>
                  <div>
                    Date
                  </div>
                  <div className='relative'>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={new Date(reasonModify.proof_date)}
                      onChange={(date) =>
                        setReasonModify((prevData) => ({
                          ...prevData,
                          proof_date: date,
                          proof: null,
                          proof_description: null,
                        }))
                      }
                      locale='fr-FR'
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      popperPlacement="bottom"
                      className='w-full h-10 pl-8 pr-8 ft-b border rounded-lg'
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                      <Calendar wh={18} color={'#666666'} strokeW={2}/>
                    </div>
                  </div>
                  {errorProofDate && (
                    <div className={"text-red-500 flex items-center gap-1 text-xs"}>
                      <WarningCircle wh={16} color={"#EF4444"}/>
                      {errorProofDate}
                    </div>
                  )}
                </label>
              </>
            )}
            {reasonModify.type === "replacement" && (
              <>
                <div className={'w-full flex gap-2'}>
                  <div className={'w-full'}>
                    <StyledInput
                      type={"text"}
                      label={'Nom'}
                      value={reasonModify.proof_lastname}
                      onChange={(e) => {
                        setReasonModify((prevData) => ({
                          ...prevData,
                          proof_lastname: e.target.value,
                          proof: null,
                          proof_date: null,
                        }))
                      }}
                      error={errorProofLastname}
                    />
                  </div>
                  <div className={'w-full'}>
                    <StyledInput
                      type={"text"}
                      label={'Prénom'}
                      value={reasonModify.proof_firstname}
                      onChange={(e) => {
                        setReasonModify((prevData) => ({
                          ...prevData,
                          proof_firstname: e.target.value,
                          proof: null,
                          proof_date: null,
                        }))
                      }}
                      error={errorProofFirstname}
                    />
                  </div>
                </div>
                <StyledInput
                  type={"text"}
                  label={'Poste'}
                  value={reasonModify.proof_job}
                  onChange={(e) => {
                    setReasonModify((prevData) => ({
                      ...prevData,
                      proof_job: e.target.value,
                      proof: null,
                      proof_date: null,
                    }))
                  }}
                  error={errorProofJob}
                />
                <StyledInput
                  type={"textarea"}
                  label={"Description du motif d'absence du salarié"}
                  value={reasonModify.proof_description}
                  onChange={(e) => {
                    setReasonModify((prevData) => ({
                      ...prevData,
                      proof_description: e.target.value,
                      proof: null,
                      proof_date: null,
                    }))
                  }}
                  error={errorProofDescription}
                />
              </>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
        <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
      </ModalFooter>
    </>
  );
}

export default ModalMissionDetailReason;
import React from 'react';

import DatePicker from "react-datepicker";
import Divider from "components/divider/divider";
import {Add, Calendar, Delete} from "assets/icons";
import UseContext from "hooks/useContext";

const Availabilities = () => {
    const {language} = UseContext()
    return (
        <div className='col-span-full bg-white rounded py-6'>
            <div className="grid grid-cols-2">
                <div className="col-span-2 tab:col-span-1 ">
                    <div className='grid grid-cols-3'>
                        <div className='flex flex-col  col-span-2 px-2'>
                            <h1 className='ft-lg text-gray-700'>{language.getValue('availabilities.dispo')}</h1>
                            <p className='ft-sm text-gray-500'>{language.getValue('availabilities.subtitleD')}</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                // onClick={handleCompetence}
                                className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'>
                                <Add wh={18} color={'#3B82F6'}/>
                            </button>
                        </div>
                    </div>
                    <Divider w={100}/>
                    <div className="col-span-2 px-2 flex items-center justify-around">
                        <Calendar wh={15} color={'#111827'}/>
                        <div className='ft-b text-gray-900'>
                            01-Jan-2022 > 06-Jan-2022
                        </div>
                        <Delete wh={15} color={'#111827'}/>
                    </div>
                    <span className='col-span-2 flex items-center justify-center'>
                    <Divider w={95}/>
                    </span>
                </div>

                <div
                    className=" hidden tab:block tab:col-span-1 tab:border-l tab:border-gray-300 p-2">
                    <div className='w-full'>
                        <DatePicker
                            locale='fr-FR'
                            shouldCloseOnSelect={false}
                            inline
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Availabilities;
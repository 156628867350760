import {useEffect, useState} from "react";
import ApiDatabase from "../../../server";
import {toast} from "react-toastify";
import TextSelect from "../textSelect";

const TextSelectSearchCompany = ({searchCompany, setSearchCompany}) => {
    const [search, setSearch] = useState('');
    const [optionSearch, setOptionSearch] = useState('name');
    const [dataList, setDataList] = useState([])
    const token = localStorage.getItem('xsrfToken')

    const searchCompanyManage = () => {
        if (optionSearch && search) {
            if (optionSearch === 'name') {
                ApiDatabase.searchCompanyInfosByName({token, name: search}, (data) => {
                    setDataList([])
                    for (let i = 0; i < data.companies?.resultats?.length; i++) {
                        const company = data.companies?.resultats[i]
                        setDataList(prevState => [...prevState, {id: company, names: [company.nom_entreprise, company.forme_juridique, company.siege?.siret, `${company.libelle_code_naf} (${company.code_naf})`, company.villes.join(', ')]}])
                    }
                }, (err) => {
                    if (err.name !== "CanceledError") {
                        //if error is 401, no more token
                        if (err.response.status === 401) {
                            toast.error('Il n\'y a plus de jeton disponible.')
                        }
                        console.error(err)
                    }
                })
            }
            else if (optionSearch === 'siret') {
                //detect if search is a siret that can be a number and have 14 characters
                if (search.length === 14 && !isNaN(search)) {
                    ApiDatabase.searchCompanyInfosBySiret({token, siret: search}, (data) => {
                        setDataList([])
                        const company = data.company
                        if (company) setDataList([{id: company, names: [company.nom_entreprise, company.forme_juridique, company.siege?.siret, `${company.libelle_code_naf} (${company.code_naf})`, company.villes?.join(', ') ?? company.greffe]}])
                    }, (err) => {
                        if (err.name !== "CanceledError") {
                            if (err.response.status === 401) {
                                toast.error('Il n\'y a plus de jeton disponible.')
                            }
                            console.error(err)
                        }
                    })
                } else {
                    toast.error('Le SIRET doit être composé de 14 chiffres.')
                }
            }
        }
    }

    let timerCompanySearch = '';
    useEffect(() => {
        timerCompanySearch = setTimeout(async () => {
            await searchCompanyManage()
        }, 400)
        return () => clearTimeout(timerCompanySearch)
    }, [search, optionSearch]);

    const onTextSearch = (text) => {
        setSearch(text)
    }

    const onSelect = (data) => {
        if (optionSearch === 'siret') {
            setOptionSearch('name')
            setSearchCompany(data)
        }
        else if (optionSearch === 'name') {
            if (data.siege?.siret) {
                ApiDatabase.searchCompanyInfosBySiret({token, siret: data.siege.siret}, (data) => {
                    if (data.company) setSearchCompany(data.company)
                    else toast.error('Une erreur est survenue à la récupération de l\'entreprise.')
                }, (err) => {
                    if (err.name !== "CanceledError") {
                        if (err.response.status === 401) {
                            toast.error('Il n\'y a plus de jeton disponible.')
                        }
                        console.error(err)
                    }
                })
            } else {
                toast.error('Une erreur est survenue à la récupération du SIRET.')
            }
        }
    }

    return (
        <>
            <div className={'flex gap-2 items-center'}>
                <div className={'w-full'}>
                    <div className={'w-full'}>
                        <div>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Recherche
                                </div>
                                <div>
                                    <TextSelect onTextSearch={onTextSearch} dataListFiltered={dataList} onSelect={onSelect}/>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'w-28'}>
                    <div>
                        <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                            Options
                        </label>
                        <select className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                onChange={(e) => setOptionSearch(e.target.value)}
                                value={optionSearch}
                        >
                            <option value="name">Nom</option>
                            <option value="siret">SIRET</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextSelectSearchCompany;
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import useModal from "../../../useModal";
import Button from "../../../../button";
import Divider from "../../../../divider/divider";
import {useEffect, useState} from "react";
import UseContext from "../../../../../hooks/useContext";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";
import Banner from "../../../../banner/banner";

const ModalVivierChangeVivierStatus = () => {
    const {toggle} = useModal();
    const {infoVivier, setUsersVivier}=UseContext()
    const [vivierName, setVivierName] = useState('');
    const [selectedVivierStatus, setSelectedVivierStatus] = useState('valid');
    const [isUserVivierInArchive, setIsUserVivierInArchive] = useState(false);
    const token = localStorage.getItem('xsrfToken')

    const kanbanColumns = [
        { name: 'wish', title: 'Souhait', icon: '🧞', description: 'Profils ayant indiqué vouloir participer à ce type de missions.' },
        { name: 'test', title: 'Test', icon: '⏳', description: 'Profils qualifiés par l\'équipe de recrutement.' },
        { name: 'valid', title: 'Validé', icon: '✅', description: 'Profils notés positivement par un client.' },
        { name: 'refused', title: 'Refusé', icon: '❌', description: 'Profils refusés par l\'équipe de recrutement.' },
        { name: 'archive', title: 'Archivé', icon: '📦', description: 'Profils indisponibles indéfiniment et/ou ne sont plus actif depuis plus de 45 jours.' }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (token && infoVivier.idVivier && selectedVivierStatus && !isUserVivierInArchive) {
            ApiDatabase.updateUsersVivierStatus({token, idUsers: infoVivier.listUsers, idVivier: infoVivier.idVivier, vivierStatus: selectedVivierStatus}, (data) => {
                setUsersVivier(data)
                toast.success('Le statut du vivier a été modifié avec succès');
                toggle();
            }, (err) => {
                console.warn(err)
                toast.error('Une erreur est survenue lors de la modification du statut du vivier');
            })
        } else {
            if (isUserVivierInArchive) {
                toast.error('Des utilisateurs sont archivés dans ce vivier. Vous ne pouvez pas changer le status de cet utilisateur.');
            }
        }
    }

    useEffect(() => {
        if (token && infoVivier.idVivier) {
            ApiDatabase.getVivierById({token, idVivier: infoVivier.idVivier}, (data) => {
                setVivierName(data?.name)
                for (let i = 0; i < data?.user_preferences?.length; i++) {
                    if (data?.user_preferences[i]?.status === 'archive') {
                        setIsUserVivierInArchive(true)
                        break;
                    }
                }
            }, (err) => {
                console.warn(err)
            })
        }
    }, [infoVivier]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Déplacer de colonne
            </Modalheader>
            <ModalBody>
                <div className={'flex flex-col gap-3'}>
                    {isUserVivierInArchive && (
                        <Banner type='alert'>
                            Des utilisateurs sont archivés dans ce vivier. Vous ne pouvez pas changer le status de cet utilisateur.
                        </Banner>
                    )}
                    <div className={'w-full'}>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Vivier
                            </div>
                            <div>
                                <input type="text"
                                       className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                       value={vivierName}
                                       disabled={true}
                                />
                            </div>
                        </label>
                    </div>
                    <Divider/>
                    <div className={'w-full'}>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Sélectionner la colonne
                            </div>
                            <div>
                                <select className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                        value={selectedVivierStatus}
                                        onChange={e => setSelectedVivierStatus(e.target.value)}
                                >
                                    {kanbanColumns.map((kanban, index) => (
                                        <option key={index} value={kanban.name}>{kanban.icon} - {kanban.title}</option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Fermer</Button>
                {(token && infoVivier.idVivier && selectedVivierStatus && !isUserVivierInArchive) ? (
                    <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
                ) : (
                    <Button size={'LG'} color={'DISABLED'} disabled={true}>Confirmer</Button>
                )}
            </ModalFooter>
        </>
    )
}

export default ModalVivierChangeVivierStatus;
import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import ModalBody from "../../../components/body";
import Button from "../../../../button";
import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";

const ModalVivierUserToArchive = () => {
    const {userVivierToArchive, setUserVivierToArchive, setUserVivierToArchiveUpdated} = UseContext()
    const {toggle} = useModal();
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = () => {
        if (userVivierToArchive.idUsers?.length > 0 && userVivierToArchive.idVivier && userVivierToArchive.vivierStatus) {
            ApiDatabase.updUserVivierPreferenceStatus({
                token,
                idUsers: userVivierToArchive.idUsers,
                idVivier: userVivierToArchive.idVivier,
                vivierStatus: userVivierToArchive.vivierStatus
            }, (data) => {
                toast.success(userVivierToArchive.idUsers.length > 1 ? 'Les utilisateurs ont été déplacés.' : 'L\'utilisateur a été déplacé.')
                setUserVivierToArchiveUpdated(userVivierToArchive)
                setUserVivierToArchive({})
                toggle()
            }, (err) => {
                console.log('err', err)
            })
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Mise en archive des utilisateurs
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <div className={'flex flex-col gap-5'}>
                    <div className={'flex flex-col gap-2'}>
                        <div className={'text-gray-500'}>
                            Voulez-vous déplacer les utilisateurs sélectionnés dans le vivier archive ?
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default ModalVivierUserToArchive;
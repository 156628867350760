import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import { toast } from "react-toastify";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";

const DeleteCandidateVivier = () => {
    const { toggle } = useModal()
    const { infoVivier, setUsersVivier } = UseContext()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = (e) => {
        e.preventDefault()
        ApiDatabase.deleteVivierUser({ token, idVivier: infoVivier.idVivier, idUsers: infoVivier.listUsers }, (res) => {
            toast.success(infoVivier.listUsers?.length > 1 ? 'Les candidats ont été supprimés du vivier' : `Le candidat ${infoVivier.nameUser} a été supprimé du vivier`)
            setUsersVivier(res)
            toggle()
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
            }
        })
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Supprimer un candidat</Modalheader>
            <ModalBody>
                Êtes-vous sûr de vouloir supprimer {infoVivier.listUsers.length > 1 ? 'les candidats' : infoVivier.nameUser} du vivier ? Cette action est irréversible.
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                    onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default DeleteCandidateVivier;
import React from 'react';

const Indicator = ({formIndex}) => {
    return (
        <div className={'flex justify-around mb-8 '}>
<div className={`pt-4  w-48 text-left  ft-sm font-medium text-gray-900 border-t-4
${formIndex === 1 ?'border-yellow-200':'border-yellow-500'}`}>
    Justificatif de domicile
</div>
            <div className={`pt-4  w-48 text-left  ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 2?'border-yellow-200':
            formIndex===3 || formIndex=== 5?'border-yellow-500':''}`}>
                Attestation de logement
            </div>
            <div className={`pt-4 w-48 text-left ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 3?'border-yellow-200':''}`}>
                Confirmation
            </div>
        </div>
    );
};

export default Indicator;
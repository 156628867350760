import {NavLink} from "react-router-dom";
import {ArrowLeft} from "../../../../../assets/icons";
import Divider from "../../../../../components/divider/divider";
import {TabsVRTW, TabVRTW} from "../../../../../components/tabs/tabVivierRatingTempWorker";

const HeaderVivierRatingTempWorker = () => {
    return (
        <>
            <div className={'px-8 py-4 flex justify-between'}>
                <NavLink to={'/vivier'} className={'my-auto flex gap-2 cursor-pointer'}>
                    <div className={'my-auto'}>
                        <ArrowLeft w={25} h={12} color={'#111827'}/>
                    </div>
                    <div className={'my-auto text-gray-500'}>Viviers</div>
                </NavLink>
                <div className={'my-auto flex gap-2'}>
                    <TabsVRTW>
                        <TabVRTW label={'rating-untreated'} tabName={'Non traité'}/>
                        <TabVRTW label={'rating-treated'} tabName={'Traité'}/>
                    </TabsVRTW>
                </div>
            </div>
            <Divider/>
        </>
    )
}

export default HeaderVivierRatingTempWorker;
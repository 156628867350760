import TextSelect from "../textSelect";
import {useEffect, useState} from "react";
import ApiDatabase from "server";
import Fuse from "fuse.js";

const TextSelectCollectiveAgreement = ({collectiveAgreement, setCollectiveAgreement, searchCollectiveAgreementDisplay}) => {
    const [textSearch, setTextSearch] = useState('')
    const [selectID, setSelectID] = useState('')
    const [collectiveAgreementDatas, setCollectiveAgreementDatas] = useState([])
    const [dataList, setDataList] = useState([])
    const [dataListFiltered, setDataListFiltered] = useState([])
    const token = localStorage.getItem('xsrfToken')
    const fuseOptions = {
        includeScore: true,
        keys: ['names']
    }

    useEffect(() => {
        if (token) {
            ApiDatabase.getCollectiveAgreement({token}, (data) => {
                setCollectiveAgreementDatas(data)
                setDataList([])
                for (let i = 0; i < data.length; i++) {
                    setDataList(prevState => [...prevState, {id: data[i]._id, names: [`${data[i].IDCC_id} - ${data[i].IDCC_name}`]}])
                }
            }, (err) => {
                console.log('err', err)
            })
        }
    }, [token]);

    const onSelect = (data) => {
        setCollectiveAgreement(data)
    }

    const onTextSearch = (text) => {
        setTextSearch(text)
    }

    useEffect(() => {
        //fuzzy search on dataList with textSearch
        const dataChoices = []
        if (dataList.length > 0 && textSearch) {
            const fuse = new Fuse(dataList, fuseOptions)
            const fuseResult = fuse.search(textSearch)

            for (let i = 0; i < fuseResult.length; i++) {
                dataChoices.push(fuseResult[i].item)
            }
        }
        setDataListFiltered(dataChoices)
    }, [dataList, textSearch]);

    useEffect(() => {
        if (searchCollectiveAgreementDisplay) {
            for (let i = 0; i < collectiveAgreementDatas.length; i++) {
                if (collectiveAgreementDatas[i].IDCC_id.toString() === searchCollectiveAgreementDisplay.toString()) {
                    setDataListFiltered([{id: collectiveAgreementDatas[i]._id, names: [`${collectiveAgreementDatas[i].IDCC_id} - ${collectiveAgreementDatas[i].IDCC_name}`]}])
                    setSelectID(collectiveAgreementDatas[i]._id)
                }
            }
        }
    }, [searchCollectiveAgreementDisplay]);

    return (
        <label className='ft-sm text-gray-500'>
            <div>
                N° convention collective <span className={'ft-sm text-gray-400'}>(Optionnel)</span>
            </div>
            <div>
                <TextSelect onTextSearch={onTextSearch} dataListFiltered={dataListFiltered} onSelect={onSelect} selectID={selectID}/>
            </div>
        </label>
    )
}

export default TextSelectCollectiveAgreement;
import ApiDatabase from "../../server";
import React, {useEffect, useState} from "react";

class TimeSheetsBeSTT extends React.Component {
    constructor(props) {
        super(props);

        const [timeSheetsBeSTT, setTimeSheetsBeSTT] = useState([]);
        const urlParams = new URLSearchParams(window.location.search);
        const date_start = urlParams.get('DebutPeriode');
        const date_end = urlParams.get('FinPeriode');
        const id_company_bestt = urlParams.get('client');
        const id_temp_worker_bestt = urlParams.get('Salarie');

        useEffect( () => {
            const getTimesheets = async () => {
                if (id_temp_worker_bestt && id_company_bestt && date_start && date_end) {
                    //format yyyymmdd to yyyy-mm-dd
                    const date_start_format = date_start.slice(0, 4) + '-' + date_start.slice(4, 6) + '-' + date_start.slice(6, 8);
                    const date_end_format = date_end.slice(0, 4) + '-' + date_end.slice(4, 6) + '-' + date_end.slice(6, 8);
                    await ApiDatabase.getTimeSheetsBeSTT({
                        id_temp_worker_bestt,
                        id_company_bestt,
                        date_start_format,
                        date_end_format
                    }, (data) => {
                        if (data?.timeSheetBeSTTFormat) {
                            setTimeSheetsBeSTT(data?.timeSheetBeSTTFormat);
                        }
                    });
                }
            }
            getTimesheets();
        }, []);
    }

    render() {
        return (
            <>
            <pre>
                {JSON.stringify(timeSheetsBeSTT, null, 2)}
            </pre>
            </>
        )
    };
}

export default TimeSheetsBeSTT;
import React from 'react';
import {ProfilIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitMobile from "components/sidebar/components/SidebarUnitMobile";

const ProfilML = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitMobile routeName={"Mon Profil"} routeSidebar={"/profil"}>
            <div className="flex flex-col justify-center items-center ">

            <span className='shrink-0'>
                            <ProfilIcon wh={45} color={`${pathname.includes('profil') ? '#FF9600' : '#6B7280'}`}/>
            </span>
                <span
                    className={`text-[9px] font-medium whitespace-normal text-center ${
                        pathname.includes("profil") && "!text-[#FF9600]"
                    }`}
                >
                    {language.getValue('sidebar.profil','Mon Profil')}
          </span>
            </div>
        </SidebarUnitMobile>
    );
};

export default ProfilML;
import {useEffect, useState} from "react";
import ApiDatabase from "server";
import Table from "../../../../../components/table/table";
import {SearchLoop} from "../../../../../assets/icons";
import UseContext from "../../../../../hooks/useContext";

const BodyQualification = () => {
    const {refreshQualification, setRefreshQualification, refreshQualifications} = UseContext()
    const [qualifications, setQualifications] = useState([])
    const [search, setSearch] = useState('')
    const [optionSearch, setOptionSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [limitElement, setLimitElement] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const [countPerPage, setCountPerPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)
    const [isWaiting, setIsWaiting] = useState(true)

    const token = localStorage.getItem('xsrfToken')
    let timerQualificationSearch = null

    const titles = ['Nom du poste', 'Secteur', 'Créé le']

    const manageQualification = async () => {
        setIsWaiting(true)
        ApiDatabase.getAllQualifications({token, search, limit: limitElement, page: currentPage, optionSearch}, data => {
            setQualifications(data.qualifications)
            setTotalPages(data.countPages)
            setCountPerPage(data.countPerPage)
            setCountTotal(data.count)
            setIsWaiting(false)
        }, err => {
            if (err.name !== "CanceledError") {
                setIsWaiting(false)
                console.log(err)
            }
        })
    }

    useEffect(() => {
        setCurrentPage(1)
        timerQualificationSearch = setTimeout(async () => {
            await manageQualification();
        }, 400);
        return () => clearTimeout(timerQualificationSearch);
    }, [search, optionSearch, limitElement, refreshQualifications])

    useEffect(() => {
        manageQualification();
    }, [currentPage])

    useEffect(() => {
        if(refreshQualification && qualifications.length > 0){
            //edit qualification in refreshQualification
            for (let i = 0; i < qualifications.length; i++) {
                if (qualifications[i]._id === refreshQualification._id || qualifications[i].name === refreshQualification.name) {
                    qualifications[i] = refreshQualification
                }
            }
            setQualifications(qualifications)
            setRefreshQualification(false)
        }
    }, [refreshQualification])

    return (
        <>
            <div className={'px-8 h-full'}>
                <div className={'flex justify-end py-6'}>
                    <div className={'flex gap-3'}>
                        <div className="relative text-gray-500 ">
                            <input
                                name="search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                className="w-full h-10 pl-10 pr-8 ft-b border rounded-lg"
                                type="text"
                                placeholder='Rechercher ...'
                                required
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <SearchLoop wh={18} color={'#666666'} />
                            </div>
                        </div>
                        <select className="h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg" onChange={(e) => setOptionSearch(e.target.value)}>
                            <option value="">Tous</option>
                            <option value="idBestt">ID BeSTT</option>
                            <option value="name">Titre</option>
                            <option value="codePCS">PCS</option>
                            <option value="codeROME">ROME</option>
                        </select>
                    </div>
                </div>
                {isWaiting ? (
                    <>
                        <table className="animate-pulse w-full animate-fadeIn">
                            {
                                [1, 2, 3, 4, 5].map((i, index) => (
                                    <tr key={index}>
                                        {
                                            titles?.map((title, index) => (
                                                <td key={index} className={'px-2 py-7'}>
                                                    <div className="h-2 bg-gray-200 rounded"></div>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </table>

                    </>
                ) : (
                    <>
                        {qualifications?.length > 0 ? (
                            <Table champsBody={qualifications} champsHeader={titles} currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                        ) : (
                            <div className={'flex justify-center'}>
                                <div className={'text-2xl text-gray-400'}>
                                    Aucune qualification trouvée
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default BodyQualification;
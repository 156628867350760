import React, {useState} from 'react';

import Requirements
    from "components/modal/dataModal/data/modaljustify/identity/components/requirements";
import Pichoice
    from "components/modal/dataModal/data/modaljustify/identity/components/images/pichoice";
import {CheckIcon} from "assets/icons";
import Importfile from "components/formcomponents/importfile";

const Pi = ({pi, setPi, nationality, check, setCheck}) => {
    const [titleFileR, setTitleFileR] = useState('')
    const [titleFileV, setTitleFileV] = useState('')

    // name,change,titleFile
    const handlerSingleFileR = e => {
        setPi(prevState => {
            return ({
                ...prevState,
                fileR: e.target.files[0]
            })
        })
        setTitleFileR(e.target.files[0].name)
    }
    const handlerSingleFileV = e => {
        setPi(prevState => {
            return ({
                ...prevState,
                fileV: e.target.files[0]
            })
        })
        setTitleFileV(e.target.files[0].name)
    }

    const handleCheck = e => {
        e.preventDefault()
        setCheck(!check)
    }

    const handleChange = (e) => {
        e.preventDefault()
        setPi(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }
    return (
        <div className={'h-[600px] overflow-y-auto px-2'}>
            <Requirements/>
            <div className={'flex flex-col gap-3'}>
                <form className={'flex flex-col gap-3'}>
                    <div>
                        <label
                            className={'ft-sm font-medium text-gray-500'}>Type
                            de document</label>
                        <select name="type" id=""
                                className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                onChange={handleChange}
                                value={pi.type}
                        >
                            <option value=""></option>
                            <option value="Passeport">Passeport
                            </option>
                            <option value="CNI">Carte nationale
                                d'identité
                            </option>
                        </select>
                    </div>
                    <div>

                        <label
                            className={'ft-sm font-medium text-gray-500'}>Nationalité</label>
                        {nationality === '' ?
                            <select name="nationality" id=""
                                    className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                    onChange={handleChange}
                                    value={pi.nationality}
                                    disabled
                            >
                                <option value=""></option>
                                <option value="UE">Nationalité
                                    européenne
                                    (UE,EEE,Suisse)
                                </option>
                                <option value="NUE">Nationalité non
                                    européenne
                                </option>
                            </select>
                      :
                        <select name="nationality" id=""
                                className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                onChange={handleChange}
                                value={nationality}
                        >
                            <option value=""></option>
                            <option value="UE">Nationalité européenne
                                (UE,EEE,Suisse)
                            </option>
                            <option value="NUE">Nationalité non
                                européenne
                            </option>
                        </select>
                        }
                    </div>
                </form>
                <Pichoice pi={pi}/>

                {/*CNI | Passeport*/}
                <Importfile name={'Recto'}
                            titleFile={titleFileR}
                            change={handlerSingleFileR}/>
                {pi.type === 'CNI' ?
                    <Importfile name={'Verso'}
                                titleFile={titleFileV}
                                change={handlerSingleFileV}/>

                    :
                    <></>
                }

                <div
                    className={'w-full ft-sm mt-4 flex items-start' +
                        ' justify-center' +
                        ' gap-4 border b-gray-50 p-2 rounded-md'}
                    onClick={handleCheck}>
                    <div className={`flex items-center justify-center mt-1 h-4 w-4 border border-gray-300 rounded-sm ' +
                        ' bg-white ${check ? 'bg-yellow-600 border-yellow-600' : ''} +
                        ' focus:outline-none transition duration-200  cursor-pointe `}>{check ?
                        <CheckIcon w={14} h={10}
                                   color={'#ffff'}/> : ''}</div>
                    <div className={'flex flex-col w-[500px]'}>
                        <span
                            className={'ft-sm font-bold text-gray-700'}>Attestation sur l’honneur</span>
                        <p className="ft-sm font-medium text-gray-500">
                            Lors de l'ajout de la pièce d'identité
                            l'utilisateur devra désormais cocher une
                            case stipulant qu'il atteste sur l'honneur
                            de l'authenticité de la pièce
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pi;
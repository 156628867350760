import {
    TimeSheetDisabledIcon,
    TimeSheetIcon,
} from "assets/icons";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiDatabase from "../../server";
import DatePicker from "react-datepicker";
import {dateToEpoch} from "../../helper/helper";

const ProfileDocuments = () => {
    const [nbTimesheetHoursVerified, setNbTimesheetHoursVerified] = useState(0);
    const [isWaitingTimesheetHoursVerified, setIsWaitingTimesheetHoursVerified] = useState(true);

    //first date of the month and last date of the month
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const token = localStorage.getItem('xsrfToken')
    const rUser = localStorage.getItem('rUser')

    useEffect(() => {
        if (startDate && endDate) {
            setIsWaitingTimesheetHoursVerified(true);
            ApiDatabase.getTimeSheetHoursVerified({token, dateRange: [dateToEpoch(startDate), dateToEpoch(endDate)]},
                (data) => {
                    setNbTimesheetHoursVerified({
                        totalHours: data.totalHours.toFixed(2),
                        totalHoursTime: data.totalHoursTime,
                    });
                    setIsWaitingTimesheetHoursVerified(false);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                })
        }
    }, [dateRange])

    const documentsToComplete = [
        {
            text: 'Heure vérifiée',
            textPlural: 'Heures vérifiées',
            number: nbTimesheetHoursVerified.totalHours,
            second_number: nbTimesheetHoursVerified.totalHoursTime,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            /*add date range*/
            link: `/timeSheet/checked?dateRange=${(startDate && endDate) && JSON.stringify(dateRange)}`,
            icon: <TimeSheetIcon wh={64}/>,
            iconDisabled: <TimeSheetDisabledIcon wh={64}/>,
            header: <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={true}
                className='min-w-[250px] h-10 pl-3 pr-8 ft-b border rounded-lg'
                locale='fr-FR'
                dateFormat="dd/MM/yyyy"
                placeholderText="Périodes"
            />,
            isWaiting: isWaitingTimesheetHoursVerified,
            allowedRoles: ['500'],
        },
    ];

    useEffect(() => {
        const currentMonth = new Date();
        const startDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

        // Set the date range in the state
        setDateRange([startDate, endDate]);
    }, []);

    useEffect(() => {
        console.log('dateRange', dateRange)
    }, [dateRange]);

    return (
        <>
            <div className={'grid grid-cols-3 gap-1'}>
                {
                    documentsToComplete.map((document, key) => {
                        if (document.allowedRoles.includes(rUser)) {
                            return (
                                <div key={key}>
                                    {document.header && <div className={'flex justify-between py-4 w-fit'}>{document.header}</div>}
                                    {document.isWaiting ? (
                                        <>
                                            <div className={'animate-fadeIn animate-pulse'}>
                                                <div className={'flex justify-between px-5 py-4 border rounded-lg'}>
                                                    <div className={`my-auto flex flex-col gap-3`}>
                                                    <div className={'text-xs'}>
                                                            <div className={'h-3 bg-gray-200 rounded w-28'}></div>
                                                        </div>
                                                        <div className={'text-3xl font-bold'}>
                                                            <div className={'h-6 bg-gray-200 rounded w-12'}></div>
                                                        </div>
                                                        {document.second_number && (
                                                            <div className={'text-sm'}>
                                                                <div className={'h-3 bg-gray-200 rounded w-12'}></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={'my-auto ml-3'}>
                                                        <div className={'h-16 bg-gray-200 rounded w-16'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <NavLink to={document.link} className={'flex justify-between px-5 py-4 border rounded-lg'}>
                                                <div
                                                    className={`my-auto ${document.number > 0 ? document.textColor : document.textColorDisabled}`}>
                                                    <div className={'text-xs'}>{document.number > 1 ? document.textPlural : document.text}</div>
                                                    <div className={'text-3xl font-bold'}>{document.number}</div>
                                                    {document.second_number && <div className={'text-sm'}>{document.second_number}</div>}
                                                </div>
                                                <div className={'my-auto ml-3'}>
                                                    {document.number > 0 ? document.icon : document.iconDisabled}
                                                </div>
                                            </NavLink>
                                        </>
                                    )}
                                </div>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default ProfileDocuments;
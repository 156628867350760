import React, {useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ArrowLeft} from "assets/icons";
import UseContext from "hooks/useContext";

const Subnavmobile = () => {
    const {language} = UseContext()
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()
    const pRoute = {
        '/profil/compte': 'profil.account',
        '/profil/savoir_faire': 'profil.knowledge',
        '/profil/justificatifs': 'profil.proof',
        '/profil/disponibilites': 'profil.availabilities',
        '/profil/parametres': 'profil.params',
        '/profil/savoir_faire/cv': 'savoir.cv',
        '/profil/savoir_faire/experiences': 'savoir.exp',
        '/profil/savoir_faire/skills': 'savoir.skills',
        '/profil/savoir_faire/language': 'savoir.lang',
        '/profil/savoir_faire/tools': 'savoir.tools',
        '/profil/justificatifs/identity': 'proof.identity',
        '/profil/justificatifs/degree': 'proof.degree',
        '/profil/justificatifs/licence': 'proof.licence',
        '/profil/disponibilites/myhours':'availabilities.myhours',
        '/profil/disponibilites/availabilities':'availabilities.dispo',
    }


    return (
        <div
            className='col-span-full h-14 w-full flex items-center justify-center gap-2 ft-b font-bold text-gray-700 border-b b-gray-500'>
            {pathname === '/profil' ? language.getValue('profil.profil', 'Mon profil') :
                <div className='flex items-center justify-center'>
                <span className='absolute left-0 pl-4' onClick={e => {
                    if (pathname.split('/')[2] === 'savoir_faire' && pathname.split('/')[3]) navigate('./savoir_faire')
                    else if (pathname.split('/')[2] === 'justificatifs' && pathname.split('/')[3]) navigate('./justificatifs')
                    else navigate('/profil')
                }
                }>
            <ArrowLeft w={15} h={12}/>
                </span>
                    <h1 className='text-center'>{language.getValue(pRoute[pathname])}</h1>
                        < /div>
                    }


                </div>
                );
            };

            export default Subnavmobile;
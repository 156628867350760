import React from 'react';
import Cardheader from "components/card/components/cardheader";
import AvatarCompagny from "components/avatar/avatarcompany";
import Button from "components/button";
import {Building, Calendar, CreditCard, DotsV, Localisation} from "assets/icons";
import Cardbody from "components/card/components/cardbody";
import Cardfooter from "components/card/components/cardfooter";
import Badge from "components/badge/badge";
import Divider from "components/divider/divider";

const CardMission = () => {
    return (
        <>
            <div className='bg-white shadow-md w-72 '>
                <Cardheader add={'rounded-t-md flex justify-between'}>
                    <AvatarCompagny size={"MD"}/>
                    <Button color={'DEFAULT'} size={'SMI'}>
                        <DotsV color={"#374151"} w={14} h={14}/>
                    </Button>
                </Cardheader>
                <Cardbody>
                    <div>
                        <h4 className='ft-2xl font-bold text-gray-900'>Preparateur de commande</h4>
                        <span className='flex gap-2 items-center'><Localisation
                            color={'#374151'} wh={14}/> Fort de france </span>
                    </div>
                    <Divider w={100}/>
                    <div>
                        <span className='flex gap-2 items-center'><Building wh={15} color={'#374151'}/> Wiaks</span>
                        <span className='flex gap-2 items-center'><Calendar color={'#374151'} wh={15}/> 25/11/21 > 21/12/21 </span>
                        <span className='flex gap-2 items-center'><CreditCard color={'#374151'} w={15} h={11}/> 10,26€/h </span>
                    </div>

                </Cardbody>
                <Cardfooter add={'bg-green-400 flex items-center justify-end' +
                    ' rounded-b-md '}>
                    <Badge type={'VALID'}>En cours</Badge>
                </Cardfooter>
            </div>

        </>
    );
};

export default CardMission;
import {
    useLocation,
    Navigate,
    Outlet,
    useParams
} from "react-router-dom";
import {useEffect, useState} from "react";



const RequireAuth = ({ allowedRoles }) => {

    const useToken=()=>{
        const token =localStorage.getItem('xsrfToken')
        return !!token;
    }

    const useRole=()=>{
        return localStorage.getItem('rUser')
    }
    const [rAuth,setRAuth]=useState(useRole())
    const [tAuth,setTAuth]=useState(useToken())
    const location = useLocation();

    return (
        allowedRoles?.includes(rAuth)
            ? <Outlet />
            : tAuth
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : window.location.href=process.env.REACT_APP_ADMIN_LOGIN
    );
}

export default RequireAuth;
import React from 'react';


//import Component
import PageForm from "components/pageForm/PageForm";
import ProfileValidation from "components/Dashboard/profileValidation";
import ProfileCompletion from "components/Dashboard/profileCompletion";
import ProfileDocuments from "components/Dashboard/profileDocuments";
import MissionSearch from "components/Dashboard/missionSearch";


const Dashboard = () => {

    return (
        <>
            <PageForm>
                <div className={'col-span-12 md:m-8'}>
                    {/* Documents */}
                    <ProfileDocuments/>
                </div>
            </PageForm>
        </>
    );
};

export default Dashboard;

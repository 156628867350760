import React from 'react';
import Indicator from "components/modal/dataModal/data/modaljustify/address/component/indicator";
import Firstform from "components/modal/dataModal/data/modaljustify/address/component/body/firstform";
import Secondform from "components/modal/dataModal/data/modaljustify/address/component/body/secondform";
import Threeform from "components/modal/dataModal/data/modaljustify/address/component/body/threeform";
import Fourform
    from "components/modal/dataModal/data/modaljustify/address/component/body/fourform";
import Fiveform
    from "components/modal/dataModal/data/modaljustify/address/component/body/fiveform";
 
const Multiform = ({formIndex,setFormIndex,setCheck,proof,attest}) => {

    const elements=[
        <Firstform single={proof}/>,
        <Secondform attest={attest}/>,
        <Threeform setFormIndex={setFormIndex} setCheck={setCheck} />,
        <Fourform single={proof} />,
        <Fiveform attest={attest}/>
    ]

    return (
        <>
           <Indicator formIndex={formIndex}/>
            {elements.map((item, index) => {
                if((index + 1) === formIndex){
                    return elements[index]
                }
            })}
        </>
    );
};

export default Multiform;
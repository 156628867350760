import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import StyledInput from "../../../../styledInput/StyledInput";
import {EMAIL_REGEX, NAME_REGEX} from "../../../../../helper/helper";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";

const ModalAddCompanyMember = () => {
  const {toggle} = useModal();
  const {modalData, setModalDataReturn} = UseContext()
  const token = localStorage.getItem("xsrfToken");
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({ firstName: '', lastName: '', email: '', role: '' });
  const roleList = [
    {
      value: null,
      label: '',
    },
    {
      value: 200,
      label: 'Observateur',
    },
    {
      value: 205,
      label: 'Manageur',
    },
    {
      value: 210,
      label: 'Responsable',
    },
    {
      value: 215,
      label: 'Directeur',
    },
    {
      value: 220,
      label: 'Superviseur',
    }
  ]

  useEffect(() => {
    const newErrors = {
      firstName: firstName
        ? (NAME_REGEX.test(firstName) ? '' : 'Prénom invalide.')
        : '',
      lastName: lastName
        ? (NAME_REGEX.test(lastName) ? '' : 'Nom invalide.')
        : '',
      email: email
        ? (EMAIL_REGEX.test(email) ? '' : 'Email invalide.')
        : '',
      role: ''
    };

    setErrors(newErrors);
  }, [firstName, lastName, email, role]);

  const handleSubmit = () => {
    if (!errors.firstName && !errors.lastName && !errors.email && !errors.role) {
      if (!firstName) {
        setErrors(prevData => ({
          ...prevData,
          firstName: 'Le champs est requis.'
        }))
      }
      if (!lastName) {
        setErrors(prevData => ({
          ...prevData,
          lastName: 'Le champs est requis.'
        }))
      }
      if (!email) {
        setErrors(prevData => ({
          ...prevData,
          email: 'Le champs est requis.'
        }))
      }
      if (!role) {
        setErrors(prevData => ({
          ...prevData,
          role: 'Le champs est requis.'
        }))
      }

      if (!errors.firstName && !errors.lastName && !errors.email && !errors.role && firstName && lastName && email && role) {
        setIsLoading(true)
        ApiDatabase.postAddCompanyMember({token, idCompany: modalData.idCompany, employee: {firstName, lastName, email, role}}, (data) => {
          if (data.user) {
            toast.success('Le compte employé a bien été créé et assigné à l\'entreprise.')
            setModalDataReturn({type: 'employee_created', user: data.user})
            toggle()
            setIsLoading(false)
          }
        }, (err) => {
          console.log(err);
          toast.error('Une erreur est survenue, veuillez réessayer ou contacter le support');
          setIsLoading(false)
        })
      }
    }
  }

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Inviter par email
      </Modalheader>
      <ModalBody>
        <div className={'flex flex-col gap-6 text-sm'}>
          <StyledInput
            label={"E-mail"}
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={errors.email}
          />
          <div className={'flex gap-6 w-full'}>
            <div className={'w-full'}>
              <StyledInput
                label={"Prénom"}
                type={"text"}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                error={errors.firstName}
              />
            </div>
            <div className={'w-full'}>
              <StyledInput
                label={"Nom"}
                type={"text"}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                error={errors.lastName}
              />
            </div>
          </div>
          <StyledInput
            label={"Rôle"}
            type={"select"}
            value={role}
            list={roleList}
            onChange={(e) => {
              setRole(e.target.value)
            }}
            error={errors.role}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>Fermer</Button>
            <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Fermer</Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
          </>
        )}
      </ModalFooter>
    </>
  )
}

export default ModalAddCompanyMember;
import React, {useEffect, useRef, useState} from "react";
import {Close, Plus} from "../../assets/icons";

const TextSelectMultiple = (
    {
        label,
        isSearchOnFocus = true,
        startSearch,
        setStartSearch,
        searchValues = [],
        setSearchValues,
        inputValue,
        setInputValue,
        suggestForm = false,
        formToDisplay = false,
        suggestFormDisplay,
        setSuggestFormDisplay,
        setClickValue,
        forceSelectMultipleDown,
        setForceSelectMultipleDown,
        isDeleteValue,
        setDeleteValue
    }) => {
    const [inputFocus, setInputFocus] = useState(false)
    const [previousInputValue, setPreviousInputValue] = useState(false)
    const selectMultipleRef = useRef()

    useEffect(() => {
        if(inputFocus){
            if(isSearchOnFocus){
                if(inputFocus) {
                    if(previousInputValue !== inputValue) {
                        setSuggestFormDisplay(false)
                    }
                    setStartSearch(true)
                }
                else setStartSearch(false)
            } else {
                if(inputValue.replace(/\s/g, '')) setStartSearch(true)
                else setStartSearch(false)
            }

            if(!suggestFormDisplay) setSearchValues([])
            setPreviousInputValue(inputValue)

            setForceSelectMultipleDown(false)
        }
    }, [inputFocus, inputValue]);

    useEffect(() => {
        let handler = (e) => {
            if (!selectMultipleRef.current.contains(e.target)) {
                setForceSelectMultipleDown(true)
            }
        }
        document.addEventListener("mousedown",handler)

        return()=>{
            document.removeEventListener("mousedown",handler)
        }
    })

    return (
        <label className='ft-sm text-gray-500' ref={selectMultipleRef}>
            {
                label && <div>{label}</div>
            }
            <div className={'relative'}>
                <input type="text"
                       className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                       value={inputValue}
                       onChange={(e) => setInputValue(e.target.value)}
                       onFocus={() => {
                           setInputFocus(true)
                       }}
                       onBlur={() => {
                           setInputFocus(false)
                       }}
                />
                {
                    !suggestFormDisplay
                        ? ((inputFocus || searchValues || startSearch) && !forceSelectMultipleDown) && (
                            <div className={'absolute w-full bg-white border rounded shadow-2xl'}>
                                <div className={'flex flex-col gap-2 max-h-56 overflow-y-auto'}>
                                    {(startSearch && searchValues.length === 0) && (
                                        <div className={'py-4 px-3'}>
                                            <div className={'h-3 bg-gray-200 rounded w-4/5'}></div>
                                        </div>
                                    )}
                                    {(!startSearch && searchValues.length > 0) && (
                                        searchValues.map((value, index) => (
                                            <div key={index} className={'flex justify-between gap-2 py-4 px-3 border-b'}>
                                                <div className={'my-auto cursor-pointer w-full'} onClick={() => setClickValue(value._id)}>
                                                    {value.name}
                                                </div>
                                                {
                                                    isDeleteValue && (
                                                        <div className={'my-auto cursor-pointer'}>
                                                            <div className={'cursor-pointer p-1 border rounded'} onClick={() => setDeleteValue(value._id)}>
                                                                <Close wh={18} color={'#374151'}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ))
                                    )}
                                    {(!startSearch && searchValues.length === 0) && (
                                        <div className={'py-4 px-3 text-center'}>
                                            Aucun résultat
                                        </div>
                                    )}
                                    {
                                        suggestForm && (
                                            <div className="py-5 px-6 cursor-pointer text-gray-500 border-solid border-1 border-t border-gray-100 add-address-gouv-suggestion flex"
                                                 onClick={() => setSuggestFormDisplay(true)}
                                            >
                                                <div className="my-auto">
                                                    <Plus wh="16" color="#374151"/>
                                                </div>
                                                <div className="ml-2 my-auto">{suggestForm}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                        : (
                            <div className={'absolute w-full bg-white border rounded shadow-2xl py-4 px-3'}>
                                {formToDisplay}
                            </div>
                        )
                }
            </div>
        </label>
    )
}

export default TextSelectMultiple
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
const Multirangeslider = ({min, max, onChange}) => {
    const [minVal, setMinVal] = useState(min)
    const [maxVal, setMaxVal] = useState(max)
    const minValRef = useRef(null)
    const maxValRef = useRef(null)
    const range = useRef(null)

    //convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100), [min, max]
    )

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value) // Preceding with '+' converts the value from type string to type number
            if (range.current) {
                range.current.style.left = `${minPercent}%`
                range.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [minVal, getPercent])

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value)
            const maxPercent = getPercent(maxVal)
            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [maxVal, getPercent])

    //get min and max values when their state changes
    useEffect(() => {
        onChange({min: minVal, max: maxVal})
    }, [minVal, maxVal, onChange])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={(e) => {
                    const value = Math.min(+e.target.value, maxVal - 1)
                    setMinVal(value)
                    e.target.value = value.toString()
                }}
                className={`thumb ${minVal>max-100 ?'thumb--zindex-3':'thumb--zindex-5'}`}
            />
            <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={(event) => {
                    const value = Math.max(+event.target.value, minVal + 1);
                    setMaxVal(value);
                    event.target.value = value.toString();
                }}
                className="thumb thumb--zindex-4"
            />
            <div className="slider">
                <div className="slider__track" />
                <div ref={range} className="slider__range" />
                <div className="slider__left-value">{minVal}€</div>
                <div className="slider__right-value">{maxVal}€</div>
            </div>
        </>
    );
};
Multirangeslider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};
export default Multirangeslider;
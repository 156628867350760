import { ArrowLeft} from 'assets/icons/index.js';
import TbodyListVivier from "./components/tbody.list.vivier";

const Thead = (setSortName, sortName) => {
    const handleSortName = () => {
        setSortName((prevState) => !prevState)
    };
    return (
        <>
            <tr>
                <th scope="col" className="py-3.5 px-4 font-normal text-left rtl:text-right text-sm text-gray-700 sticky left-0 w-80 bg-white z-10 " onClick={handleSortName}>
                    <div className='flex items-center gap-8'>
                        <div className='text-left rtl:text-right text-sm text-gray-700 '>
                            NOM
                        </div>
                        <div className={`${sortName ? "rotate-90" : "-rotate-90"} `}>
                            <ArrowLeft w={12} />
                        </div>
                    </div>
                </th>
                {/* <th scope="col" className="relative py-3.5 px-4 w-56 text-left text-sm text-gray-500 bg-white">
                            SECTEUR
                        </th>   */}
                <th scope="col" className="relative py-3.5 px-4 w-28 text-left text-sm text-gray-500 bg-white">
                    NB PROFILS
                </th>
                <th scope="col" className="relative py-3.5 px-4 w-28 text-left text-sm text-gray-500 bg-white">
                    MARTINIQUE
                </th>
                <th scope="col" className="relative py-3.5 px-4 w-28 text-left text-sm text-gray-500 bg-white">
                    GUADELOUPE
                </th>
                <th scope="col" className="relative py-3.5 px-4 w-36 text-left text-sm text-gray-500 bg-white">
                    CRÉE LE
                </th>
                <th scope="col" className="relative py-3.5 px-4 w-36 text-left text-sm text-gray-500 bg-white">
                    UTILISÉ LE
                </th>
                <th scope="col" className="relative py-3.5 px-4 w-12 text-left text-sm text-gray-500 bg-white"></th>
            </tr>
        </>
    );
};


function TableListVivier({ viviers, isWaiting, setSortName, sortName, navigateToFicheVivier }) {
    return (
        <div className={'overflow-y-auto'}>
            <table className={`table-fixed w-full divide-y divide-gray-200 bg-white animate-fadeIn`}>
                <thead >
                    {Thead(setSortName, sortName)}
                </thead>
                <tbody>
                    {(viviers?.length > 0) && viviers.map((vivier, index) => <TbodyListVivier key={index} champsBody={vivier} navigateToFicheVivier={navigateToFicheVivier} />)}
                </tbody>
            </table>
        </div>
    )
}

export default TableListVivier
import React from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import useModal from "components/modal/useModal";

const Tbodycontract = ({champsBody}) => {
    const {toggle} = useModal()

    const handleSign = (e) => {
        toggle('doc_c_signature')
    }
    return (
        <>
            {champsBody?.map(item=>
            <tr>
                <td className={'m-3'}>{item.contrat}</td>
                <td className={'m-3'}>{item.entreprise}</td>
                <td className={'m-3'}>{item.mission}</td>
                <td className={'m-3'}>{item.nMission}</td>
                <td className={'m-3'}><Chip type={'INDICATOR'}
                                            color={item.etat === 0 ? 'ALERT' : 'INFO'}>{item.etat === 0 ? 'À signer' : 'Signé'}</Chip>
                </td>
                <td className={'m-3'}>{item.date}</td>
                <td className={'m-3 flex items-center justify-center'}>{
                    item.etat === 0 ? <span onClick={handleSign}>
                            <Signer wh={20} color={'#374151'}/>
                        </span>
                        :
                        <a target='_blank'
                           href={item.link}>
                            <DocumentIcon wh={30}
                                          color={'#111827'}/>
                        </a>
                }</td>
            </tr>
            )}
        </>
    );
};

export default Tbodycontract;
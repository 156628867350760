import CopyToClipboard from "../copyToClipboard";
import React from "react";

const TempWorkerMission = ({user}) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'flex gap-1 items-center w-full flex-1 min-w-0'}>
        <div className={'text-base text-gray-900 truncate'}>
          <CopyToClipboard
            text={user.firstname + ' ' + user.lastname}>
            <div className={'truncate'}>
              {user.firstname + ' ' + user.lastname}
            </div>
          </CopyToClipboard>
        </div>
        <div>
          <CopyToClipboard
            text={user.id_bestt}>
            N°{user.id_bestt}
          </CopyToClipboard>
        </div>
      </div>
      <div>
        <CopyToClipboard
          text={user.email}>
          <a href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </CopyToClipboard>
      </div>
      <div>
        <CopyToClipboard
          text={user.phone}>
          <a href={`tel:${user.phone}`}>
            {user.phone}
          </a>
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default TempWorkerMission
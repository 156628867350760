import React,{useState} from 'react';
import PageForm from "components/pageForm/PageForm";
import CardMission from "components/card/mission";

import MediaQuery from "react-responsive";
import SubNavMission from "components/subnav/mission/desktop";
import Button from "components/button";
import {Outlet} from "react-router-dom";


const Mission = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleText=(e)=>{
        e.preventDefault()
        alert(e.target.textContent)
    }

    return (
        <PageForm>
            <MediaQuery minWidth={769}>
                <div className="col-span-12 bg-white sticky z-10 top-16 ">
                        <SubNavMission/>
                </div>
                <div
                    className="col-span-12 mt-8 mb-28 flex items-center justify-evenly flex-wrap gap-4">
                    <Outlet/>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <div className="col-span-12 bg-white   top-16 ">
                    <div
                        className="col-span-full h-14 flex items-center justify-between px-2 gap-2 border-b b-gray-500">
                        <h1 className='ft-b font-normal text-yellow-500 '>
                            Mes missions
                        </h1>
                        <div className='relative flex items-center justify-center'
                             onClick={handleDots}>
                            <Button size={'SMI'} color={'DEFAULT'} onClick={handleDots}>En cours</Button>
                            <div className={`${
                                dropdownOpen ? "" : "hidden"
                            }  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 top-10 right-1 z-30 bg-gray-50 flex flex-col gap-2`}>
                                <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                     onClick={handleText}>Terminées
                                </div>
                                <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                     onClick={handleText}>En cours
                                </div>
                                <div
                                    className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                    onClick={handleText}>A venir
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div
                    className="col-span-12 mt-8 mb-28 flex items-center justify-evenly flex-wrap gap-4">
                    <CardMission/>
                    <CardMission/>
                    <CardMission/>
                    <CardMission/>
                </div>

            </MediaQuery>
        </PageForm>
    );
};

export default Mission;
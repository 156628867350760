import React from 'react';
import {DocumentIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitMobile from "components/sidebar/components/SidebarUnitMobile";

const DocumentsML = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitMobile routeName={"Mes documents"} routeSidebar={"/documents"}>
            <div className="flex flex-col justify-center items-center ">

            <span className='shrink-0'>
                            <DocumentIcon wh={45} color={`${pathname === '/documents' ? '#FF9600' : '#6B7280'}`}/>
            </span>
                <span
                    className={`text-[9px] font-medium whitespace-normal text-center ${
                        pathname.includes("documents") && "!text-[#FF9600]"
                    }`}
                >
            {language.getValue('sidebar.file','Documents')}
          </span>
            </div>
        </SidebarUnitMobile>
    );
};

export default DocumentsML;
import React, {useEffect, useState} from 'react';
import {CheckIcon, Close, Modify} from "assets/icons";
import Chip from "components/chip/chip";
import Divider from "components/divider/divider";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const TBodyHoursCompany = ({champsBody}) => {
    const {
        timeCheck,
        setTimeCheck,
        setTimeData,
        objID,
        setObjID,
    } = UseContext()
    const {toggle} = UseModal();
    const [check, setCheck] = useState(null)

    const pColorBg={
        2:'bg-red-50',
        3:'bg-green-100'
    }
    const pColorIcon={
        2:'#B91C1C',
        3:'#047857'
    }
    const statusIcon = {
        2: <Modify wh={15} color={'#111827'}/>,
        //3: <CheckIcon w={24} h={12} color={'#111827'}/>
    }

    const checkIcon = (e, p, pS) => {
        e.preventDefault()
        let updateTimeSheet = true;
        for (let i in timeCheck) {
            if(timeCheck[i] === pS && i === p){
                updateTimeSheet = false
            }
        }
        if(updateTimeSheet){
            setTimeCheck(prevData => ({
                ...prevData,
                [p]: pS
            }))
        }
        else{
            setTimeCheck(prevData => ({
                ...prevData,
                [p]: ""
            }))
        }
    }

    const handleModal = (e, dayValidation, index, id) => {
        e.preventDefault()
        if (dayValidation === 1 || dayValidation === 2) {
            toggle('doc_c_completedHours')
            setObjID(champsBody.id_time_sheet)

            setTimeData(prevData => ({
                ...prevData,
                index: index,
                id: id,
                id_time_sheet: objID
            }))
        }
    }

    useEffect(() => {
        if(champsBody?.days){
            setTimeout(() => {
                for (let i = 0; i < champsBody?.days.length; i++) {
                    if(champsBody.days[i].validated !== 1){
                        setTimeCheck(prevData => ({
                            ...prevData,
                            [champsBody.days[i]._id]: champsBody.days[i].validated
                        }))
                    }
                }
            }, 200)
        }
    }, [champsBody])

    return (
        <>
            {champsBody?.days.map((item, index) =>
                <>
                    <div key={index} className={'flex items-center grid grid-cols-5 '}>
                        <div className={`ft-sm w-auto ${timeCheck[item?._id] === 2 ? 'text-yellow-500' : 'text-gray-900'} font-medium`}>
                            {item.name}
                        </div>
                        <div className={`col-span-3 flex flex-col gap-2 ft-sm ${timeCheck[item?._id] === 2 ? 'text-yellow-200' : 'text-gray-500'}`}>
                            <span className={`ft-sm ${timeCheck[item?._id] === 2 ? 'text-yellow-500' : 'text-gray-900'} font-medium`}>{item?.periode_one_company?.start !== '00:00' ? (item.day_hours_ent || item.day_hours_ent === '0' ? `${item.day_hours_ent} travaillées` : null) : ((item.day_hours_ent !== '0h0' || item.day_hours_ent !== '0h')) ?? `${item.day_hours_ent} travaillées`}</span>
                            <div className={''}>
                                {
                                    (!item?.periode_one_company || item.validated !== 2) ? (
                                        <>
                                            {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                            {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                            {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                        </>
                                    ) : (
                                        <>
                                            <del>
                                                {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                                {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                                {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                            </del>
                                            <div className={'text-yellow-200'}>
                                                {item?.periode_one_company && item?.periode_one_company?.start !== '00:00' ? (item?.periode_one_company?.start !== '' ? `${item?.periode_one_company.start} > ${item?.periode_one_company.end}` : null) : 'Jour non travaillé'}
                                                {item?.periode_two_company && item?.periode_two_company?.start !== '' ? `, ${item?.periode_two_company?.start} > ${item?.periode_two_company?.end}` : null}
                                                {item?.periode_three_company && item?.periode_three_company?.start !== '' ? `, ${item?.periode_three_company?.start} > ${item?.periode_three_company?.end}` : null}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'flex items-center justify-end gap-6'}>
                            <div onClick={(e) => {handleModal(e, item.validated, index, item._id)}}
                                 className={'flex items-center justify-end cursor-pointer'}>
                                {statusIcon[item.validated]}
                            </div>
                            {
                                champsBody.validated !== 2 ? (
                                    <div onClick={(e) => {checkIcon(e, item?._id, 2)}}
                                         className={`flex items-center justify-center h-8 w-8 rounded-md ${timeCheck[item?._id] === 2 ? pColorBg[timeCheck[item?._id]]:''} cursor-pointer`}
                                    >
                                        <Close wh={24} color={timeCheck[item?._id] === 2 ? pColorIcon[timeCheck[item?._id]] :'#374151'}/>
                                    </div>
                                ) : null
                            }
                            <div onClick={(e) => {checkIcon(e, item?._id, 3)}}
                                className={`flex items-center justify-center h-8 w-8 rounded-md ${timeCheck[item?._id] === 3 ?  pColorBg[timeCheck[item?._id]]:''} cursor-pointer`}
                            >
                                <CheckIcon w={15} color={timeCheck[item?._id] === 3 ? pColorIcon[timeCheck[item?._id]] :'#374151'}/>
                            </div>
                        </div>
                    </div>
                    <Divider w={100}/>
                </>
            )}
        </>
    );
};

export default TBodyHoursCompany;
import SidebarUnitDesktop from "../../components/SidebarUnitDesktop";
import {GraphIcon} from "../../../../assets/icons";

const HrFlowDL = ({pathname, tooltip}) => {
    return (
        <SidebarUnitDesktop routeName={"hrflow"} routeSidebar={"/hrflow/general"}>
            <span className='shrink-0'>
                <GraphIcon wh={24} color={`${pathname.includes("hrflow") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("hrflow") && "!text-[#FF9600]"
                }`}
            >
          {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                HrFlow
        </span>
        </SidebarUnitDesktop>
    );
}

export default HrFlowDL;
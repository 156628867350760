import React from 'react';
import {Tab, Tabs} from "components/tabs/tabs";

import {useLocation, useNavigate} from "react-router-dom";
import {TabF, TabsF} from "components/tabs/tabfile";
import {ArrowLeft} from "assets/icons";
import UseContext from "hooks/useContext";

const SubNavFiles = () => {
    const {objID,objType}=UseContext()
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()

    function subNav(p) {
        if (p.includes('contracts')) {
            return <TabsF>
                <TabF label={"contracts/nosign"}
                      tabName={'À signer'}/>
                <TabF label={"contracts/sign"} tabName={'Signé'}/>
            </TabsF>
        } else if (p.includes('hours')) {
            return <TabsF>
                <TabF label={"hours/completed"}
                      tabName={'Terminées'}/>
                <TabF label={"hours/nocompleted"}
                      tabName={'En cours'}/>
            </TabsF>
        } else {
            return <></>
        }
    }

    return (
        <>
            {pathname.includes('ficheHour') ?
                <div className='flex items-center justify-between h-14 px-4'>
                    <div className='flex items-center gap-2 cursor-pointer'>
                        <span onClick={e => navigate(-1)}>
                        <ArrowLeft w={15} h={12} color={'#111827'}/>
                        </span>
                        Relevé {objType} n° {objID}
                    </div>
                </div>
                :
                <>
                    <Tabs>
                        <Tab label={'contracts/nosign'}
                             tabName={'Contrat'}/>
                        <Tab label={'hours/nocompleted'}
                             tabName={'Relevé d’heure'}/>
                        <Tab label={'payslip'}
                             tabName={'Fiche de paie'}/>
                    </Tabs>
                    {subNav(pathname)}
                </>
            }
        </>
    );
};

export default SubNavFiles;
import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import useModal from "../../../useModal";
import ApiDatabase from "server";
import UseContext from "../../../../../hooks/useContext";
import {toast} from "react-toastify";

const ModalExcludeAgency = () => {
    const { toggle } = useModal()
    const {infoVivier, setUsersVivier} = UseContext()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = () => {
        toggle()
        ApiDatabase.postExcludeAgency({token, idUsers: infoVivier.listUsers}, (res) => {
            toast.success(infoVivier.listUsers?.length > 1 ? 'Les candidats ont étés exclus de l\'agence' : 'Le candidat a été exclu de l\'agence')
            setUsersVivier(res)
            toggle()
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
            }
        })
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Exclure de l’agence
            </Modalheader>
            <ModalBody>
                <div className={'text-gray-500'}>
                    Êtes-vous sûr de vouloir exclure ce candidat de l'agence ? Il ne fera plus parti d'aucun vivier et ne recevra plus de notifications.
                </div>
            </ModalBody>
            <ModalFooter >
                <Button onClick={toggle} color={'DEFAULT'} size={'LG'}>Annuler</Button>
                <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default ModalExcludeAgency;
import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";

const ModalSendMailLinkDescriptionCompany = () => {
  const {toggle} = useModal();
  const {modalData, setModalData, setModalDataReturn} = UseContext();
  const token = localStorage.getItem("xsrfToken");
  const [isWaiting, setIsWaiting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [employee, setEmployee] = useState({})

  useEffect(() => {
    if (modalData.idCompany && modalData.idEmployee) {
      setIsWaiting(true)
      ApiDatabase.getCompanyEmployee({token, idCompany: modalData.idCompany, idEmployee: modalData.idEmployee}, (data) => {
        if (data.employee?.firstname && data.employee?.lastname && data.employee?.email && data.employee?.role) {
          setEmployee({
            firstname: data.employee?.firstname,
            lastname: data.employee?.lastname,
          })
        }
        setIsWaiting(false)
      }, (err) => {
        console.log(err);
        setIsWaiting(false)
      })
    }
  }, [modalData]);

  const handleSubmit = () => {
    if (modalData.idCompany && modalData.idEmployee) {
      setIsLoading(true)
      ApiDatabase.postSendMailLinkCompanyEmployee({token, idCompany: modalData.idCompany, idEmployee: modalData.idEmployee}, (data) => {
        toast.success('Un mail de d\'invitation a été envoyé.')
        toggle()
        setIsLoading(false)
      }, (err) => {
        setIsLoading(false)
      })
    }
  }

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Renvoie du mail d'invitation
      </Modalheader>
      <ModalBody>
        <div className={'text-sm text-gray-500'}>
          {isWaiting ? (
            <div className={'animate-pulse'}>
              <div className={'h-4 bg-gray-200 rounded w-full'}></div>
            </div>
          ) : `Êtes-vous sûr de vouloir renvoyer un mail d'invitation à ${employee.firstname} ${employee.lastname} ?`}
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>Fermer</Button>
            <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Fermer</Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
          </>
        )}
      </ModalFooter>
    </>
  )
}

export default ModalSendMailLinkDescriptionCompany;

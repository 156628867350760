import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";

const AdminModalMissionSign = () => {
    const {toggle}=useModal()

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Confirmation de l'envoi pour signature de la mission
            </Modalheader>
            <ModalBody>
                <p>Êtes-vous sûr de vouloir envoyer pour signature ?</p>
            </ModalBody>
            <ModalFooter >
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default AdminModalMissionSign
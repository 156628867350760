import Tooltip from "../tooltips/Tooltips";
import {CopyIcon} from "../../assets/icons";
import {useEffect, useState} from "react";

const CopyToClipboardLarge = ({ libelle, text, children }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(r => {
            setIsCopied(true);
        });
    };

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    }, [isCopied]);

    return (
        <div className="flex justify-between items-center w-full">
            <div className={'flex flex-col'}>
                <div className={''}>{libelle}</div>
                <div className={'my-auto truncate cursor-pointer text-gray-500 text-sm'}>
                    {text}
                </div>
            </div>
            <div className={'flex rounded border w-8 h-8 items-center justify-center'}>
                <Tooltip
                    text={isCopied ? 'Copié !' : 'Copier dans le presse papier'}
                    placement="right"
                >
                    <button onClick={copyToClipboard}>
                        <CopyIcon wh={20}/>
                    </button>
                </Tooltip>
            </div>
        </div>
    );
}

export default CopyToClipboardLarge

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UseContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Button from '../../../../button';
import StyledInput from '../../../../styledInput/StyledInput';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ModalBonusesAndAllowancesRestaurantTicket = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingRestaurantTicket, setIsWaitingRestaurantTicket] =
    useState(false);
  const [isWaitingRestaurantTicketList, setIsWaitingRestaurantTicketList] =
    useState(false);
  const [restaurantTicket, setRestaurantTicket] = useState({
    employerShare: '',
    modality: '',
  });
  const [errorRestaurantTicket, setErrorRestaurantTicket] = useState({
    employerShare: '',
    modality: '',
  });
  const [restaurantTicketModalityList, setRestaurantTicketModalityList] =
    useState([]);

  const token = localStorage.getItem('xsrfToken');

  const handleChange = (value, type) => {
    setRestaurantTicket({
      ...restaurantTicket,
      [type]: value,
    });

    if (type === 'employerShare') {
      if (!value || value < 0 || value > 100) {
        setErrorRestaurantTicket({
          ...errorRestaurantTicket,
          [type]: 'La valeur doit être entre 0 et 100',
        });
      }
    }
  };

  const validateRestaurantTicket = () => {
    const newError = {
      employerShare:
        !restaurantTicket.employerShare ||
        restaurantTicket.employerShare < 0 ||
        restaurantTicket.employerShare > 100
          ? 'La valeur doit être entre 0 et 100'
          : null,
      modality: !restaurantTicket.modality
        ? 'Veuillez choisir une modalité'
        : null,
    };

    setErrorRestaurantTicket(newError);

    return !(newError.employerShare || newError.modality);
  };

  const handleSubmit = () => {
    if (validateRestaurantTicket() && modalData?.idCompany) {
      setIsLoading(true);
      ApiDatabase.postRestaurantTicketCompany(
        { token, idCompany: modalData?.idCompany, restaurantTicket },
        (data) => {
          setIsLoading(false);
          toast.success(
            'La majoration des heures de dimanche à été mise à jour'
          );
          setModalDataReturn({
            type: 'restaurant_ticket_updated',
            restaurantTicket: {
              employer_share: restaurantTicket.employerShare,
              modality: restaurantTicketModalityList.find(
                (item) =>
                  item.value.toString() === restaurantTicket.modality.toString()
              )?.label,
            },
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaitingRestaurantTicket(true);

    ApiDatabase.getRestaurantTicketCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        setRestaurantTicket({
          employerShare: data.restaurantTicket.employer_share,
          modality: data.restaurantTicket.modality,
        });
        setIsWaitingRestaurantTicket(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaitingRestaurantTicket(false);
        }
      }
    );

    setIsWaitingRestaurantTicketList(true);

    ApiDatabase.getRestaurantTicketModalityListCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        setRestaurantTicketModalityList([
          { value: '', label: '-' },
          ...data.restaurantTicketModalityList.map((item) => ({
            ...item,
            value: item._id,
            label: item.name,
          })),
        ]);
        setIsWaitingRestaurantTicketList(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaitingRestaurantTicketList(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Ticket restaurant
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-2.5 text-sm">
          {isWaitingRestaurantTicket || isWaitingRestaurantTicketList ? (
            <div className={'animate-pulse'}>
              <div className="flex flex-col gap-2">
                <div className={'h-16 bg-gray-200 rounded w-full'}></div>
                <div className={'h-16 bg-gray-200 rounded w-full'}></div>
              </div>
            </div>
          ) : (
            <>
              <div>
                <StyledInput
                  type="number"
                  label="Part patronale"
                  value={restaurantTicket.employerShare}
                  unit={'%'}
                  onChange={(e) =>
                    handleChange(e.target.value, 'employerShare')
                  }
                  error={errorRestaurantTicket.employerShare}
                />
              </div>
              <div>
                <StyledInput
                  type="select"
                  label="Modalité"
                  value={restaurantTicket.modality}
                  list={restaurantTicketModalityList}
                  onChange={(e) => handleChange(e.target.value, 'modality')}
                  error={errorRestaurantTicket.modality}
                />
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>
              Annuler
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Confirmation ...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Annuler
            </Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalBonusesAndAllowancesRestaurantTicket;

import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import useModal from "../../../useModal";
import UseContext from "hooks/useContext";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";

const AdminModalMissionUnAssign = () => {
    const {userUnAssigned, setUserUnAssigned, setModalDataReturn} = UseContext()
    const {toggle}=useModal()
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    const handleUnAssign = () => {
        setUserUnAssigned({})
        toggle()
    }

    const unAssignMission = () => {
        if(userUnAssigned){
            if(userUnAssigned.idMission && userUnAssigned.userAssigned){
                setLoading(true)
                ApiDatabase.unassignMission({token: token, idMission: userUnAssigned.idMission, userAssigned: userUnAssigned.userAssigned}, async (data) => {
                    toast.success('La mission a bien été désassignée')
                    setUserUnAssigned({})
                    setModalDataReturn('mission unassigned')
                    toggle()
                }, (err) => {
                    toast.error('Une erreur est survenue lors de la désassignation de la mission : ' + err.response?.data?.error)
                    setLoading(false)
                })
            } else {
                toast.error('Une erreur est survenue lors de la désassignation de la mission')
            }
        } else {
            toast.error('Une erreur est survenue lors de la désassignation de la mission')
        }
    }

    return (
        <>
            <Modalheader hide={handleUnAssign} hideShow={true}>
                Confirmer la modification
            </Modalheader>
            <ModalBody>
                <p>Êtes-vous sûr de vouloir dés-attribuer cette mission ?</p>
            </ModalBody>
            <ModalFooter>
                {loading ? (
                  <>
                      <Button size={'LG'} color={'DISABLED'}>Annuler</Button>
                      <Button size={'LG'} color={'DISABLED'}>Dés-attribution de la mission...</Button>
                  </>
                ) : (
                  <>
                      <Button size={'LG'} color={'SECONDARY'} onClick={handleUnAssign}>Annuler</Button>
                      <Button size={'LG'} onClick={unAssignMission}>Confirmer</Button>
                  </>
                )}
            </ModalFooter>
        </>
    )
}

export default AdminModalMissionUnAssign
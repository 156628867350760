import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";

const Modalmodifydeleted = () => {
const {toggle}=useModal()
    const handleSubmit=(e)=>{
        e.preventDefault()
        alert('test')
    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>
                    Suppression de compte
                </Modalheader>
                <ModalBody>
                    En
                    attente
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                </ModalFooter>

        </>
    );
};

export default Modalmodifydeleted;
import {Star, HalfStar} from "../../assets/icons";

const StarRating = ({ rating }) => {
    const maxStars = 5;
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating - filledStars >= 0.5;

    const stars = Array.from({ length: maxStars }, (_, index) => {
        if (index < filledStars) {
            return <Star wh={19} color={'#FF9600'} key={index}/>;
        } else if (index === filledStars && hasHalfStar) {
            return <HalfStar wh={19} key={index}/>;
        } else {
            return <Star wh={19} color={'#FFE4C1'} key={index}/>;
        }
    });

    return <div className={'flex gap-0.5'}>{stars}</div>;
}

export default StarRating;
import CopyToClipboard from "../copyToClipboard";
import {useEffect, useState} from "react";
import {SpinnerIcon} from "assets/icons";
import ApiDatabase from "server";
import AdminCollapseFile from "./AdminCollapseFile";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AdminCollapse = ({tempWorker, tempWorkerToEdit, isWaiting}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [nbFiles, setNbFiles] = useState(0);
    const [nbFilesTotal, setNbFilesTotal] = useState(0);
    const [isCNIFiles, setIsCNIFiles] = useState(false);
    const [isPassportFiles, setIsPassportFiles] = useState(false);
    const [isSelfieFiles, setIsSelfieFiles] = useState(false);
    const [isVisaFiles, setIsVisaFiles] = useState(false);
    const [isVitalCardFiles, setIsVitalCardFiles] = useState(false);
    const [isProofOfAddressFiles, setIsProofOfAddressFiles] = useState(false);
    const [isAccommodationFiles, setIsAccommodationFiles] = useState(false);
    const [statusCNIFiles, setStatusCNIFiles] = useState(1);
    const [statusPassportFiles, setStatusPassportFiles] = useState(1);
    const [statusSelfieFiles, setStatusSelfieFiles] = useState(1);
    const [statusVisaFiles, setStatusVisaFiles] = useState(1);
    const [statusVitalCardFiles, setStatusVitalCardFiles] = useState(1);
    const [statusProofOfAddressFiles, setStatusProofOfAddressFiles] = useState(1);
    const [statusAccommodationFiles, setStatusAccommodationFiles] = useState(1);
    const [filePathIdentityRFiles, setFilePathIdentityRFiles] = useState('');
    const [filePathIdentityVFiles, setFilePathIdentityVFiles] = useState('');
    const [filePathSelfieFiles, setFilePathSelfieFiles] = useState('');
    const [filePathVisaRFiles, setFilePathVisaRFiles] = useState('');
    const [filePathVisaVFiles, setFilePathVisaVFiles] = useState('');
    const [filePathVitalCardFiles, setFilePathVitalCardFiles] = useState('');
    const [filePathProofOfAddressFiles, setFilePathProofOfAddressFiles] = useState('');
    const [filePathAccommodationFiles, setFilePathAccommodationFiles] = useState('');
    const [hideThisCollapse, setHideThisCollapse] = useState(false);
    const [tempWorkerResult, setTempWorkerResult] = useState(tempWorker);
    const {height, width} = useWindowDimensions();

    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if (isOpen){

        }
    }, [isOpen])

    useEffect(() => {
        let nbTotalFiles = 0;
        let nbFiles = 0;

        if(tempWorkerResult?.identity?.fileR || tempWorkerResult?.identity?.fileV){
            if(tempWorkerResult?.identity?.type === 'CNI'){
                setIsCNIFiles(true);
                setStatusCNIFiles(parseInt(tempWorkerResult?.identity?.validated))
            }
            if(tempWorkerResult?.identity?.type === 'Passeport'){
                setIsPassportFiles(true);
                setStatusPassportFiles(parseInt(tempWorkerResult?.identity?.validated))
            }
            if(parseInt(tempWorkerResult?.identity?.validated) === 2 || parseInt(tempWorkerResult?.identity?.validated) === 3){
                nbFiles++;
            }
            if(tempWorkerResult?.identity?.fileR) setFilePathIdentityRFiles(process.env.REACT_APP_FILE + tempWorkerResult?.identity?.fileR?.filePath)
            if(tempWorkerResult?.identity?.fileV) setFilePathIdentityVFiles(process.env.REACT_APP_FILE + tempWorkerResult?.identity?.fileV?.filePath)
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.identity?.validated) === 1) {
                setIsCNIFiles(true);
                setStatusCNIFiles(parseInt(tempWorkerResult?.identity?.validated))
                nbTotalFiles++;
            }
        }
        if(tempWorkerResult?.selfie?.file){
            setIsSelfieFiles(true);
            setStatusSelfieFiles(parseInt(tempWorkerResult?.selfie?.validated))
            setFilePathSelfieFiles(process.env.REACT_APP_FILE + tempWorkerResult?.selfie?.file?.filePath)
            if(parseInt(tempWorkerResult?.selfie?.validated) === 2 || parseInt(tempWorkerResult?.selfie?.validated) === 3){
                nbFiles++;
            }
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.selfie?.validated) === 1) {
                setIsSelfieFiles(true);
                setStatusSelfieFiles(parseInt(tempWorkerResult?.selfie?.validated))
                nbTotalFiles++;
            }
        }
        if(tempWorkerResult?.visa?.fileR || tempWorkerResult?.visa?.fileV){
            setIsVisaFiles(true);
            setStatusVisaFiles(parseInt(tempWorkerResult?.visa?.validated))
            if(tempWorkerResult?.visa?.fileR) setFilePathVisaRFiles(process.env.REACT_APP_FILE + tempWorkerResult?.visa?.fileR?.filePath)
            if(tempWorkerResult?.visa?.fileV) setFilePathVisaVFiles(process.env.REACT_APP_FILE + tempWorkerResult?.visa?.fileV?.filePath)
            if(parseInt(tempWorkerResult?.visa?.validated) === 2 || parseInt(tempWorkerResult?.visa?.validated) === 3){
                nbFiles++;
            }
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.visa?.validated) === 1) {
                setIsVisaFiles(true);
                setStatusVisaFiles(parseInt(tempWorkerResult?.visa?.validated))
                nbTotalFiles++;
            }
        }
        if(tempWorkerResult?.card_vitality?.file){
            setIsVitalCardFiles(true);
            setStatusVitalCardFiles(parseInt(tempWorkerResult?.card_vitality?.validated))
            if(tempWorkerResult?.card_vitality?.file) setFilePathVitalCardFiles(process.env.REACT_APP_FILE + tempWorkerResult?.card_vitality?.file?.filePath)
            if(parseInt(tempWorkerResult?.card_vitality?.validated) === 2 || parseInt(tempWorkerResult?.card_vitality?.validated) === 3){
                nbFiles++;
            }
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.card_vitality?.validated) === 1) {
                setIsVitalCardFiles(true);
                setStatusVitalCardFiles(parseInt(tempWorkerResult?.card_vitality?.validated))
                nbTotalFiles++;
            }
        }
        if(tempWorkerResult?.proof_of_address?.file){
            setIsProofOfAddressFiles(true);
            setStatusProofOfAddressFiles(parseInt(tempWorkerResult?.proof_of_address?.validated))
            if(tempWorkerResult?.proof_of_address?.file) setFilePathProofOfAddressFiles(process.env.REACT_APP_FILE + tempWorkerResult?.proof_of_address?.file?.filePath)
            if(parseInt(tempWorkerResult?.proof_of_address?.validated) === 2 || parseInt(tempWorkerResult?.proof_of_address?.validated) === 3){
                nbFiles++;
            }
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.proof_of_address?.validated) === 1) {
                setIsProofOfAddressFiles(true);
                setStatusProofOfAddressFiles(parseInt(tempWorkerResult?.proof_of_address?.validated))
                nbTotalFiles++;
            }
        }
        if(tempWorkerResult?.accommodation_of_address?.file){
            setIsAccommodationFiles(true);
            setStatusAccommodationFiles(parseInt(tempWorkerResult?.accommodation_of_address?.validated))
            if(tempWorkerResult?.accommodation_of_address?.file) setFilePathAccommodationFiles(process.env.REACT_APP_FILE + tempWorkerResult?.accommodation_of_address?.file?.filePath)
            if(parseInt(tempWorkerResult?.accommodation_of_address?.validated) === 2 || parseInt(tempWorkerResult?.accommodation_of_address?.validated) === 3){
                nbFiles++;
            }
            nbTotalFiles++;
        } else {
            if (parseInt(tempWorkerResult?.accommodation_of_address?.validated) === 1) {
                setIsAccommodationFiles(true);
                setStatusAccommodationFiles(parseInt(tempWorkerResult?.accommodation_of_address?.validated))
                nbTotalFiles++;
            }
        }

        setNbFiles(nbFiles);
        setNbFilesTotal(nbTotalFiles);
    }, [tempWorkerResult])

    useEffect(()=> {
        if(tempWorkerToEdit.length > 0){
            /*console.log('tempWorkerToEdit', tempWorker._id === tempWorkerToEdit[0]._id)
            console.log('tempWorkerToEdit[0].isTempWorkerValidate', tempWorkerToEdit[0].isTempWorkerValidate)*/
            if(tempWorkerResult._id === tempWorkerToEdit[0]._id){
                setTempWorkerResult(tempWorkerToEdit[0]);

                if(tempWorkerToEdit[0].isTempWorkerValidate){
                    setTimeout(() => {
                        setHideThisCollapse(true)
                    }, 400)
                }
            }
        }
    }, [tempWorkerToEdit])

    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className="flex shadow border rounded-md animate-fadeIn animate-pulse">
                            <div className="items-center w-full my-2">
                                <h3 className={`${width > 768 ? 'flex justify-between flex-1' : ''} grep-3 p-4 font-semibold`}>
                                    <div className={'h-3 bg-gray-200 rounded w-1/4 mx-3'}></div>
                                    <div className={'h-3 bg-gray-200 rounded w-1/4 mx-3'}></div>
                                    <div className={'h-3 bg-gray-200 rounded w-1/4 mx-3'}></div>
                                    <div className={'h-3 bg-gray-200 rounded w-1/4 mx-3'}></div>
                                </h3>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {
                            !hideThisCollapse ? (
                                <details className={`bg-white shadow rounded group animate-fadeIn ${width > 768 ? '' : (width > 768 ? '' : 'inline-block w-6/12')} ${tempWorkerToEdit.length > 0 ? ((tempWorkerResult._id === tempWorkerToEdit[0]._id && tempWorkerToEdit[0].isTempWorkerValidate) ? 'animate-slideFadeOutL' : '') : ''}`}
                                         onClick={e => setIsOpen(!isOpen)}>
                                    <summary className={`list-none ${width > 768 ? 'flex flex-wrap' : ''} items-center cursor-pointer focus-visible:outline-none focus-visible:ring focus-visible:ring-pink-500 rounded group-open:rounded-b-none group-open:z-[1] relative`}>
                                        <h3 className={`${width > 768 ? 'flex justify-between flex-1' : ''} grep-3 p-4 font-semibold`}>
                                            <div>
                                                {tempWorkerResult.user.firstname + ' ' + tempWorkerResult.user.lastname}
                                            </div>
                                            <div>
                                                <CopyToClipboard text={tempWorkerResult.user.email}>
                                                    <a className={'underline'} href={`mailto:${tempWorkerResult.user.email}`}>
                                                        {tempWorkerResult.user.email}
                                                    </a>
                                                </CopyToClipboard>
                                            </div>
                                            <div>
                                                <CopyToClipboard text={tempWorkerResult.user.phone}>
                                                    <a className={'underline'} href={`tel:${tempWorkerResult.user.phone}`}>
                                                        {tempWorkerResult.user.phone}
                                                    </a>
                                                </CopyToClipboard>
                                            </div>
                                            <div>
                                                {nbFiles + '/' + nbFilesTotal}
                                            </div>
                                        </h3>
                                        <div className="flex w-10 items-center justify-center">
                                            <div className="border-8 border-white border-l-gray-600 ml-2 group-open:rotate-90 transition-transform origin-left"></div>
                                        </div>
                                    </summary>
                                    <div className="p-4">
                                        {
                                            isCNIFiles ? (
                                                <AdminCollapseFile title={'Carte d\'identité'}
                                                                   dateAdd={tempWorkerResult.identity.fileR?.createdAt || tempWorkerResult.identity.file?.createdAt}
                                                                   status={statusCNIFiles === 1 ? 4 : (statusCNIFiles === 2 ? 5 : statusCNIFiles)}
                                                                   link={{filePathIdentityRFiles, filePathIdentityVFiles}}
                                                                   type={'identity'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isPassportFiles ? (
                                                <AdminCollapseFile title={'Passeport'}
                                                                   dateAdd={tempWorkerResult.identity.fileR?.createdAt || tempWorkerResult.identity.file?.createdAt}
                                                                   status={statusPassportFiles === 1 ? 4 : (statusPassportFiles === 2 ? 5 : statusPassportFiles)}
                                                                   link={{filePathIdentityRFiles, filePathIdentityVFiles}}
                                                                   type={'identity'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isSelfieFiles ? (
                                                <AdminCollapseFile title={'Selfie'}
                                                                   dateAdd={tempWorkerResult.selfie.fileR?.createdAt || tempWorkerResult.selfie.file?.createdAt}
                                                                   status={statusSelfieFiles === 1 ? 4 : (statusSelfieFiles === 2 ? 5 : statusSelfieFiles)}
                                                                   link={{filePathSelfieFiles}}
                                                                   type={'selfie'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isVisaFiles ? (
                                                <AdminCollapseFile title={'Visa'}
                                                                   dateAdd={tempWorkerResult.visa.fileR?.createdAt || tempWorkerResult.visa.file?.createdAt}
                                                                   status={statusVisaFiles === 1 ? 4 : (statusVisaFiles === 2 ? 5 : statusVisaFiles)}
                                                                   link={{filePathVisaRFiles, filePathVisaVFiles}}
                                                                   type={'visa'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isVitalCardFiles ? (
                                                <AdminCollapseFile title={'Carte vitale'}
                                                                   dateAdd={tempWorkerResult.card_vitality.fileR?.createdAt || tempWorkerResult.card_vitality.file?.createdAt}
                                                                   status={statusVitalCardFiles === 1 ? 4 : (statusVitalCardFiles === 2 ? 5 : statusVitalCardFiles)}
                                                                   link={{filePathVitalCardFiles}}
                                                                   type={'card_vitality'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isProofOfAddressFiles ? (
                                                <AdminCollapseFile title={'Justificatif de domicile'}
                                                                   dateAdd={tempWorkerResult.proof_of_address.fileR?.createdAt || tempWorkerResult.proof_of_address.file?.createdAt}
                                                                   status={statusProofOfAddressFiles === 1 ? 4 : (statusProofOfAddressFiles === 2 ? 5 : statusProofOfAddressFiles)}
                                                                   link={{filePathProofOfAddressFiles}}
                                                                   type={'proof_of_address'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                        {
                                            isAccommodationFiles ? (
                                                <AdminCollapseFile title={'Attestation d\'hébergement'}
                                                                   dateAdd={tempWorkerResult.accommodation_of_address.fileR?.createdAt || tempWorkerResult.accommodation_of_address.file?.createdAt}
                                                                   status={statusAccommodationFiles === 1 ? 4 : (statusAccommodationFiles === 2 ? 5 : statusAccommodationFiles)}
                                                                   link={{filePathAccommodationFiles}}
                                                                   type={'accommodation_of_address'}
                                                                   _id={tempWorkerResult._id}
                                                />
                                            ) : null
                                        }
                                    </div>
                                </details>
                            ) : null
                        }
                    </>
                )
            }
        </>
    );
}

export default AdminCollapse;
import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import ApiDatabase from "server";


const DeleteVivier = () => {
    const {toggle}=useModal()
    const {infoVivier, setInfoVivier}=UseContext()
    const navigate = useNavigate()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = (e) => {
        e.preventDefault()
        ApiDatabase.deleteVivier({token, idVivier:infoVivier.idVivier}, (res) => {
            toggle()
            toast.success(`Le vivier ${infoVivier.name} est supprimée.`)
            setInfoVivier({})
            navigate('/listAllVivier')
        }, (err) => {
            toast.error('Une erreur est survenue')
        })
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>Supprimer un vivier </Modalheader>
            <ModalBody>
                Êtes-vous sûr de vouloir supprimer {infoVivier.name} ? Cette action est irréversible.
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default DeleteVivier;
import React from 'react';
import {DocumentIcon} from "assets/icons";
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";

const Payslip = () => {
    const {userData}=usefetchdata()
    return (
    <>
        {userData?.payslip?.length >0 ?
        <div className='overflow-auto lg:overflow-visible '>
            <table
                className=" table-fixed w-full text-gray-500 border-separate space-y-6 ft-sm">
                <thead>
                <tr>
                    <th className={'m-3 text-left'}>MOIS</th>
                    <th className={'m-3 text-right'}>MONTANT</th>
                    <th className={'m-3 text-right'}>DATE</th>
                    <th className={'m-3 text-right'}></th>
                </tr>
                </thead>
                <tbody>
                <tr className={'border-b-2 border-gray-500'}>
                    <td className={'m-3'}>MOIS</td>
                    <td className={'m-3 text-right'}>1230€</td>
                    <td className={'m-3 text-right'}>DATE</td>
                    <td className={'p-3 flex items-center justify-center'}><a target='_blank' href={'https://docs.telerik.com/blazor-ui/knowledge-base/common-navigate-from-button'}>
                            <DocumentIcon wh={30} color={'#111827'}/>
                        </a>
                    </td>
                </tr>
                <tr className={'border-b border-gray-300'}>
                    <td className={'m-3'}>MOIS</td>
                    <td className={'m-3 text-right'}>1230€</td>
                    <td className={'m-3 text-right'}>DATE</td>
                    <td className={'p-3 flex items-center justify-center'}><a target='_blank' href={'https://docs.telerik.com/blazor-ui/knowledge-base/common-navigate-from-button'}>
                        <DocumentIcon wh={30} color={'#111827'}/>
                    </a>
                    </td>
                </tr>
                <tr>

                </tr>
                </tbody>
            </table>
        </div>
                :
        <EmptyState type={'payslip'}/> }
    </>
    );
};

export default Payslip;
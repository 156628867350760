import React, {useEffect, useState} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = ({ initialContent, onEditorStateChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (initialContent) {
            const contentState = ContentState.createFromBlockArray(
                convertFromHTML(initialContent)
            );
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [initialContent]);

    const handleEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);

        // Convertir le contenu de l'éditeur en HTML
        const contentState = newEditorState.getCurrentContent();
        const contentAsHtml = draftToHtml(convertToRaw(contentState));

        // Appeler la fonction de rappel avec les données converties en HTML
        if (onEditorStateChange) {
            onEditorStateChange(contentAsHtml);
        }
    };

    const toolbarOptions = {
        options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
        },
        blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        textAlign: {
            options: ['left', 'center', 'right'],
        },
    };

    return (
        <Editor
            editorState={editorState}
            toolbar={toolbarOptions}
            wrapperClassName="rich-text-wrapper"
            editorClassName="rich-text-editor border px-4 py-0 ft-sm cursor-text"
            editorStyle={{ minHeight: '120px' }}
            onEditorStateChange={handleEditorStateChange}
        />
    );
};

export default RichTextEditor;

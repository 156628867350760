import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CrossIcon } from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Button from '../../../../button';
import StyledInput from '../../../../styledInput/StyledInput';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ModalNightHoursCompany = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [thresholds, setThresholds] = useState([
    {
      start: '',
      end: '',
      increase: '',
    },
  ]);
  const [errors, setErrors] = useState([
    {
      start: null,
      end: null,
      increase: null,
    },
  ]);
  const token = localStorage.getItem('xsrfToken');

  const addThreshold = () => {
    setThresholds((prevThresholds) => [
      ...prevThresholds,
      { start: '', end: '', increase: '' },
    ]);
    setErrors((prevErrors) => [
      ...prevErrors,
      { start: null, end: null, increase: null },
    ]);
  };

  const removeThreshold = (index) => {
    setThresholds((prevThresholds) =>
      prevThresholds.length > 1
        ? prevThresholds.filter((_, i) => i !== index)
        : prevThresholds
    );
    setErrors((prevErrors) =>
      prevErrors.length > 1
        ? prevErrors.filter((_, i) => i !== index)
        : prevErrors
    );
  };

  const validateThresholds = () => {
    const newErrors = thresholds.map((threshold) => {
      const errors = {
        start:
          !threshold.start || threshold.start > 24
            ? 'La valeur doit être entre 0 et 24'
            : null,
        end:
          !threshold.end || threshold.end > 24
            ? 'La valeur doit être entre 0 et 24'
            : null,
        increase:
          !threshold.increase || threshold.increase > 100
            ? 'La valeur doit être entre 0 et 100'
            : null,
      };

      if (
        threshold.start === threshold.end &&
        threshold.start &&
        threshold.end
      ) {
        errors.start = errors.end =
          'Les valeurs ne doivent pas être identiques';
      }

      return errors;
    });

    const intervals = [];
    for (let index = 0; index < thresholds.length; index++) {
      if (!newErrors[index].start && !newErrors[index].end) {
        const start = Number(thresholds[index].start);
        let end = Number(thresholds[index].end);

        if (start > end) {
          end += 24;
        }

        intervals.push({ start, end, index });
      }
    }

    intervals.sort((a, b) => a.start - b.start);

    for (let i = 0; i < intervals.length - 1; i++) {
      const current = intervals[i];
      const next = intervals[i + 1];

      if (next.start < current.end) {
        newErrors[current.index].start = 'Les périodes se chevauchent';
        newErrors[current.index].end = 'Les périodes se chevauchent';
        newErrors[next.index].start = 'Les périodes se chevauchent';
        newErrors[next.index].end = 'Les périodes se chevauchent';
      }
    }

    setErrors(newErrors);

    return newErrors.every(
      (error) => !error.start && !error.end && !error.increase
    );
  };

  const handleSubmit = () => {
    if (validateThresholds() && modalData?.idCompany) {
      setIsLoading(true);
      ApiDatabase.postNightHoursCompany(
        { token, idCompany: modalData?.idCompany, nightHours: thresholds },
        (data) => {
          setIsLoading(false);
          toast.success('Les heures de nuit ont étés mises à jour');
          setModalDataReturn({
            type: 'night_hours_updated',
            nightHours: thresholds,
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const handleChange = (index, field, value) => {
    setThresholds((prevThresholds) =>
      prevThresholds.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, i) => {
        if (i !== index) return error;

        const updatedError = { ...error };
        if (field === 'start' || field === 'end') {
          updatedError[field] =
            value > 24 ? 'La valeur doit être entre 0 et 24' : null;
        }
        if (field === 'increase') {
          updatedError.increase =
            value > 100 ? 'La valeur doit être entre 0 et 100' : null;
        }
        return updatedError;
      })
    );
  };

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaiting(true);

    ApiDatabase.getNightHoursCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        const provThreshold = data.nightHours?.length
          ? data.nightHours.map(({ start, end, increase }) => ({
              start,
              end,
              increase,
            }))
          : [
              {
                start: '',
                end: '',
                increase: '',
              },
            ];
        setThresholds(provThreshold);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaiting(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Heures de nuit
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-2.5 text-sm">
          {isWaiting ? (
            <div className={'animate-pulse'}>
              {[1, 2, 3].map((i, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div className="w-56 h-4 bg-gray-200 rounded"></div>
                      <div className="w-8 h-8 bg-gray-200 rounded"></div>
                    </div>
                    <div className="flex gap-3 w-full">
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                    <div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {thresholds.map((threshold, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div>PÉRIODE {index + 1}</div>
                      {thresholds.length > 1 && (
                        <div>
                          <Button
                            size={'ICON'}
                            color={'ICON'}
                            onClick={() => removeThreshold(index)}
                          >
                            <CrossIcon wh={20} color={'#374151'} />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-3 w-full">
                      <div className={'w-full'}>
                        <StyledInput
                          type="number"
                          label="À partir de"
                          value={threshold.start}
                          unit={'H'}
                          onChange={(e) =>
                            handleChange(index, 'start', e.target.value)
                          }
                          error={errors[index]?.start}
                        />
                      </div>
                      <div className={'w-full'}>
                        <StyledInput
                          type="number"
                          label="Jusqu'à"
                          value={threshold.end}
                          unit={'H'}
                          onChange={(e) =>
                            handleChange(index, 'end', e.target.value)
                          }
                          error={errors[index]?.end}
                        />
                      </div>
                    </div>
                    <div>
                      <StyledInput
                        type="number"
                        label="Majoration"
                        value={threshold.increase}
                        unit={'%'}
                        onChange={(e) =>
                          handleChange(index, 'increase', e.target.value)
                        }
                        error={errors[index]?.increase}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'w-full flex gap-1 justify-between items-center'}>
          <div>
            <Button size={'LG'} color={'SECONDARY'} onClick={addThreshold}>
              Ajouter un seuil
            </Button>
          </div>
          <div className={'flex gap-3'}>
            {isLoading ? (
              <>
                <Button size={'LG'} color={'SECONDARY'}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'DISABLED'}>
                  Confirmation ...
                </Button>
              </>
            ) : (
              <>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
                  Confirmer
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalNightHoursCompany;

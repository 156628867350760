import {ExperienceIcon} from "../../../assets/icons";

const ExperienceItem = ({ experience, index }) => {
    return (
        <div key={index} className={'flex gap-2 items-center'}>
            <div className={'w-10 h-10 min-w-10 min-h-10 flex justify-center items-center bg-gray-100 rounded'}>
                <ExperienceIcon wh={20} color={'#374151'}/>
            </div>
            <div className={'flex flex-col gap-1'}>
                <div className={''}>{experience.name}</div>
                <div className={'text-gray-500'}>{experience.company?.name} · {experience.company?.location}</div>
            </div>
        </div>
    )
}

export default ExperienceItem

import { Add, ChevronDown, ChevronTop } from 'assets/icons';
import CardProfile from 'components/card/cardProfile/cardProfile';
import CardExperience from 'components/card/cardsavoirfaire/cardexperience';
import Divider from 'components/divider/divider';
import useModal from 'components/modal/useModal';
import usefetchdata from 'hooks/usefetchdata';
import { useState } from 'react';

const Experience = () => {
  const { tempWorkerData } = usefetchdata();
  const { toggle } = useModal();
  const [showExp, setShowExp] = useState(false);
  const [visibility, setVisibility] = useState(3);

  const handleExp = (e) => {
    e.preventDefault();
    toggle('sf_c_exp');
  };
  const handleShowExp = () => {
    setShowExp(!showExp);
    if (showExp) {
      setVisibility((prevValue) => prevValue - 3);
    } else {
      setVisibility((prevValue) => prevValue + 3);
    }
  };
  return (
    <>
      <CardProfile>
        <div className="grid grid-cols-4 flex items-center ">
          <div className="col-span-3 ft-b text-gray-900">Expériences</div>
          <div className="col-span-1 flex justify-end">
            <button
              onClick={handleExp}
              className="rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center"
            >
              <Add wh={18} color={'#3B82F6'} />
            </button>
          </div>
        </div>
        <Divider w={100} />
        {tempWorkerData?.experiences?.slice(0, visibility).map((item) => (
          <CardExperience key={item._id} item={item} />
        ))}
        {tempWorkerData.experiences?.length >= 4 ? (
          <div
            className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3"
            onClick={handleShowExp}
          >
            {showExp ? (
              <ChevronTop wh={20} color={'#FF9600'} />
            ) : (
              <ChevronDown wh={20} color={'#FF9600'} />
            )}
            En savoir
            {showExp ? ' moins' : ' plus'}
          </div>
        ) : (
          ''
        )}
      </CardProfile>
    </>
  );
};

export default Experience;

import ApiDataBase from "../../../server";
import {useEffect, useState} from "react";
import UseContext from "../../../hooks/useContext";

const TbodyCompanyCoeff = ({champsBody}) => {
    const {coeffs, setCoeffs} = UseContext()
    const [minimumWage, setMinimumWage] = useState(0)

    useEffect(() => {
        ApiDataBase.getMinimumWage(
            (data) => {
                if(data?.hourly?.gross){
                    setMinimumWage(data.hourly.gross)
                }
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            }
        )
    }, [])

    useEffect(() => {
        if(champsBody.length > 0){
            for (let i = 0; i < champsBody.length; i++) {
                champsBody[i] = {
                    ...champsBody[i],
                    editManagement: false,
                    editDelegation: false,
                    error: false,
                }
            }
            setCoeffs(champsBody)
        }
    }, [champsBody])

    const editCoeff = (type, id_range, action) => {
        setCoeffs(coeffs.map((item) => {
            if(item.id_range_coefficient === id_range){
                if(type === 'management'){
                    item.editManagement = action
                } else {
                    item.editDelegation = action
                }
            }
            return item
        }))
    }

    const changeCoeff = (type, id_range, value) => {
        if(value === '' || value === null || value === undefined || isNaN(value)){
            setCoeffs(coeffs.map((item) => {
                if(item.id_range_coefficient === id_range){
                    if(type === 'management'){
                        item.management = null
                    } else {
                        item.delegation = null
                    }
                    item.error = true
                }
                return item
            }))
        } else {
            setCoeffs(coeffs.map((item) => {
                if(item.id_range_coefficient === id_range){
                    if(type === 'management'){
                        item.management = parseFloat(value)
                    } else {
                        item.delegation = parseFloat(value)
                    }
                    item.error = false
                }
                return item
            }))
        }
    }

    return (
        <>
            {
                coeffs.length > 0 && (
                    coeffs?.map((coeff, key) => (
                        <tr key={key} className={'border-b-2 border-gray-100'}>
                            <td className={'py-5'}>
                                <div className={'flex gap-1'}>
                                    <div className={'my-auto'}>
                                        >
                                    </div>
                                    {
                                        key === 0 && (
                                            <>
                                                <div className={'my-auto'}>
                                                    {minimumWage} -
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className={'my-auto'}>
                                        {(parseFloat(minimumWage) + (parseFloat(minimumWage) * (coeff.percentage / 100))).toFixed(2)} (+{coeff.percentage}%)
                                    </div>
                                </div>
                            </td>
                            <td className={'py-5'}>
                                <div onClick={() => editCoeff('delegation', coeff.id_range_coefficient, true)}>
                                    {
                                        coeff.editDelegation ? (
                                            <>
                                                <input type={'text'}
                                                       className={'border rounded px-2 py-1'}
                                                       onBlur={() => editCoeff('delegation', coeff.id_range_coefficient, false)}
                                                       defaultValue={coeff.delegation}
                                                       autoFocus={'autofocus'}
                                                       onChange={(e) => changeCoeff('delegation', coeff.id_range_coefficient, e.target.value)}
                                                />
                                                {
                                                    coeff.error && (
                                                        <>
                                                            <div className={'text-red-500 text-xs'}>
                                                                Veuillez saisir un nombre
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <div className={'py-1'}>
                                                {coeff.delegation ?? '-'}
                                            </div>
                                        )
                                    }
                                </div>
                            </td>
                            <td className={'py-5'}>
                                <div onClick={() => editCoeff('management', coeff.id_range_coefficient, true)}>
                                    {
                                        coeff.editManagement ? (
                                            <>
                                                <input type={'text'}
                                                       className={'border rounded px-2 py-1'}
                                                       onBlur={() => editCoeff('management', coeff.id_range_coefficient, false)}
                                                       defaultValue={coeff.management}
                                                       autoFocus={'autofocus'}
                                                       onChange={(e) => changeCoeff('management', coeff.id_range_coefficient, e.target.value)}
                                                />
                                                {
                                                    coeff.error && (
                                                        <>
                                                            <div className={'text-red-500 text-xs'}>
                                                                Veuillez saisir un nombre
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <div className={'py-1'}>
                                                {coeff.management ?? '-'}
                                            </div>
                                        )
                                    }
                                </div>
                            </td>
                            <td className={'py-5'}>

                            </td>
                        </tr>
                    ))
                )
            }
        </>
    )
}

export default TbodyCompanyCoeff;
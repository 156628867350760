import React from 'react';
import MediaQuery from "react-responsive";
import {ChevronRight} from "assets/icons";
import { useLocation, useNavigate} from "react-router-dom";
import Myhours from "pages/content/disponibilites/myhours";
import Availabilities from "pages/content/disponibilites/availabilities";
import UseContext from "hooks/useContext";
import EmptyState from "components/EmptyState/EmptyState";


const Disponibilite = () => {
    const {language} = UseContext
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()
    const linkMobile = [
        {
            path: 'myhours',
            pTrad: 'availabilities.myhours'
        },
        {
            path: 'availabilities',
            pTrad: 'availabilities.dispo'
        }
    ]
    return (
        <>
            <EmptyState type={'coming_soon'}/>
            {/*<MediaQuery minWidth={769}>
                <div className='col-span-12 flex  justify-center items-center'>
                    <div className=' w-[800px]'>
                        <div className="grid grid-cols-12 gap-8">
                            <Myhours/>
                            <Availabilities/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                {pathname === '/profil/disponibilites' ?
                    <div className="col-span-12 mt-8 mb-28">
                        {linkMobile.map((item) =>
                            (<div key={item.path} onClick={e =>
                                navigate(`./${item.path}`)
                            }>
                                <div
                                    className='flex items-center justify-between h-14 px-2 my-2'>
                                    <p className='ft-sm font-bold text-gray-900'>
                                        {language ? language.getValue(item.pTrad) : ''}
                                    </p>
                                    <ChevronRight color={'#111827'} wh={15}/>
                                </div>
                            </div>)
                        )}
                    </div>
                    :
                    <div className="col-span-12 flex justify-center items-center mb-32">
                        <Outlet/>
                    </div>
                }
            </MediaQuery>*/}
        </>
    );
};

export default Disponibilite;
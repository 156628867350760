import SlideOverCompany from "./data/slideOverCompany/slideOverCompany";
import UseContext from "../../../hooks/useContext";
import SlideOverTempWorker from "./data/slideOverTempWorker/SlideOverTempWorker";
import SlideOverSchedule from "./data/slideOverSchedule/slideOverSchedule";
import SlideOverAddCompany from "./data/slideOverCompany/slideOverAddCompany";
import SlideOverQualification from "./data/slideOverQualification/slideOverQualification";

const dataSlideOver=new Map()
dataSlideOver.set('add_company', <SlideOverAddCompany/>)
dataSlideOver.set('get_company', <SlideOverCompany/>)
dataSlideOver.set('get_temp_worker', <SlideOverTempWorker/>)
dataSlideOver.set('get_schedule', <SlideOverSchedule/>)
dataSlideOver.set('qualification', <SlideOverQualification/>)


const DataSlideOver = () => {
    const {slideOverType}=UseContext()
    return (
        <>
            {dataSlideOver.get(slideOverType)}
        </>
    );
};

export default DataSlideOver;
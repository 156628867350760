import React, {useEffect, useState} from 'react';
import Modalheader
    from "components/modal/components/header";
import useModal from "components/modal/useModal";

import ModalBody from "components/modal/components/body";
import ModalFooter
    from "components/modal/components/footer";
import Firstform
    from "components/modal/dataModal/data/modaljustify/address/component/body/firstform";
import Multiform
    from "components/modal/dataModal/data/modaljustify/address/component/multiform";
import Button from "components/button";
import {CheckIcon} from "assets/icons";
import axios from "axios";
import {toast} from "react-toastify";
import usefetchdata from "hooks/usefetchdata";
import Footerbutton
    from "components/modal/dataModal/data/modaljustify/address/component/footer/footerbutton";
import UseContext from "hooks/useContext";

const Proofaddress = () => {
    const {toggle} = useModal()
    const {tempWorkerData} = usefetchdata()
    const {language, refreshLoadWorker} = UseContext()
    const [check, setCheck] = useState(true)
    const [formIndex, setFormIndex] = useState(1)
    const [proof, setProof] = useState('')
    const [files, setFiles] = useState(false)
    const [attestation, setAttestattion] = useState('')

    const pAddressMulti =tempWorkerData?.proof_of_address?.validated === '1' && tempWorkerData?.accommodation_of_address?.validated === '1'

    const pAddressSingle =tempWorkerData?.proof_of_address?.validated === '1'
    useEffect(() => {
        if ((pAddressMulti && formIndex <4) && (pAddressSingle && formIndex <4)) {
            setFormIndex(3)
            setFiles(true)
            setCheck(false)
        }
    }, [formIndex, tempWorkerData])

    const singleFile = async (data) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}upload/singleFile/proofAddress`, data)
                .then((res) => {
                    if (res.data) {
                        if (formIndex === 4) {
                            toast.success('Le document "Justificatif de domicile" est' +
                                ' modifiée.')
                            setFormIndex(3)
                            refreshLoadWorker()
                        } else {
                            toast.success('Le document "Justificatif de domicile" est' +
                                ' ajoutée.')
                            refreshLoadWorker()
                        }
                    }
                    if (res.error) {
                        toast.error('Un problème est survenu')
                    }
                })
                .catch((e) => {
                    toast.error('Un problème est survenu')
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    }

    const secondFile = async (data) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}upload/singleFile/accommodationCertificate`, data)
                .then((res) => {
                    if (res.data) {
                        toast.success('Le document "Attestation de' +
                            ' domicile" est modifiée.')
                        refreshLoadWorker()
                        setFormIndex(3)
                    }
                    if (res.errors) {
                        toast.error('Un problème est survenu')
                    }
                })
                .catch((e) => {
                    toast.error('Un problème est survenu')
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    }

    const multiFiles = async (firstFile, secondFile) => {
        const files = [
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/proofAddress`,
                file: firstFile
            },
            {
                url: `${process.env.REACT_APP_API_URL}upload/singleFile/accommodationCertificate`,
                file: secondFile
            }
        ]

        const [res1, res2] = await Promise.all([
            files.map(f => axios.post(f.url, f.file))
        ])

        const err = res1.errors | res2.errors
        if (err) {
            toast.error("Un problème est survenu")
            console.warn(err)
        } else {
            toast.success('Le document "Justificatif de domicile" est' +
                ' ajouté.')
            setProof('')
            setAttestattion('')
            refreshLoadWorker()
        }

    }

    const handleCheck = e => {
        e.preventDefault()
        setCheck(!check)
    }

    const handleIndex = e => {
        setFormIndex(formIndex + 1)
    }

    const handleSubmitSingle = async (e) => {
        e.preventDefault()
        if (proof !== '') {
            const token = localStorage.getItem('xsrfToken')
            const formData = new FormData()
            formData.append('file', proof)
            formData.append('token', token)
            await singleFile(formData)
        } else toast.warning('Veuillez télécharger un fichier')

    }

    const handleSubmitMultiSingle = async (e) => {
        if (proof !== '' && attestation !== '' && !files) {
            e.preventDefault()
            const token = localStorage.getItem('xsrfToken')
            const formData = new FormData()
            formData.append('file', proof)
            formData.append('token', token)
            const formDataSecond = new FormData()
            formDataSecond.append('file', attestation)
            formDataSecond.append('token', token)
            await multiFiles(formData, formDataSecond)
        } else toggle()

    }

    const handleSubmitProof = async (e) => {
        e.preventDefault()
        if (proof !== '') {
            const token = localStorage.getItem('xsrfToken')
            const formData = new FormData()
            formData.append('file', proof)
            formData.append('token', token)
            await singleFile(formData)
        } else
            toast.warning('Veuillez télécharger un fichier')
    }

    const handleSubmitAttest = async (e) => {
        e.preventDefault()
        if (attestation !== '') {
            const token = localStorage.getItem('xsrfToken')
            const formDataSecond = new FormData()
            formDataSecond.append('file', attestation)
            formDataSecond.append('token', token)
            await secondFile(formDataSecond)
        } else
            toast.warning('Veuillez télécharger un fichier')
    }

    return (
        <>
            <Modalheader hideShow={true} hide={toggle}>
                {language.getValue('justify.m_proof_title_c')}
            </Modalheader>
            <ModalBody>
                {check ?
                    <Firstform single={setProof}/>
                    :
                    <Multiform formIndex={formIndex}
                               setFormIndex={setFormIndex}
                               proof={setProof}
                               attest={setAttestattion}
                               setCheck={setCheck}/>
                }
                {
                    formIndex !== 1 ? '' :
                        <div
                            className={' ft-sm mt-4 flex items-center gap-2'}
                            onClick={handleCheck}>
                            <div className={`flex items-center justify-center h-4 w-4 border border-gray-300 rounded-sm ' +
                        ' bg-white ${check ? 'bg-yellow-600 border-yellow-600' : ''} +
                        ' focus:outline-none transition duration-200  cursor-pointe `}>{check ?
                                <CheckIcon w={14} h={10}
                                           color={'#ffff'}/> : ''}</div>
                            Le justificatif d’adresse est à mon nom
                        </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                {check ?
                    <Button size={'LG'}
                            onClick={handleSubmitSingle}>Confirmer</Button>
                    :
                    <Footerbutton formIndex={formIndex}
                                  oC1={handleIndex}
                                  oC2={handleSubmitMultiSingle}
                                  oC3={handleSubmitProof}
                                  oC4={handleSubmitAttest}/>
                }

            </ModalFooter>
        </>
    );
};

export default Proofaddress;
import React, {useContext} from 'react';
import {SearchIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import SidebarUnitMobile from "components/sidebar/components/SidebarUnitMobile";

const SearchMissionML = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitMobile routeName={"Recherche de missions"} routeSidebar={"/searchMission"}>
            <div className="flex flex-col justify-center items-center ">

            <span className='shrink-0'>
                            <SearchIcon wh={45} color={`${pathname === '/searchMission' ? '#FF9600' : '#6B7280'}`}/>
            </span>
                <span
                    className={`text-[9px] font-medium whitespace-normal text-center ${
                        pathname.includes("searchMission") && "!text-[#FF9600]"
                    }`}
                >
                    {language.getValue('sidebar.search','Recherche de missions')}

          </span>
            </div>
        </SidebarUnitMobile>
    );
};

export default SearchMissionML;
import UseModal from "../../../../useModal";
import UseContext from "../../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ModalBody from "../../../../components/body";
import ModalFooter from "../../../../components/footer";
import Button from "../../../../../button";
import ApiDatabase from "../../../../../../server";
import {toast} from "react-toastify";
import DatePicker,{registerLocale} from "react-datepicker";

import { enGB,fr } from "date-fns/locale";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {
    CardIdentity,
    CardVitale,
    CheckIcon,
    Close,
    CrossIcon,
    Localisation,
    SelfieIdentity
} from "../../../../../../assets/icons";
import {dateToEpoch} from "../../../../../../helper/helper";
import Banner from "../../../../../banner/banner";
import Divider from "../../../../../divider/divider";
import TextSelectNationality from "../../../../../textSelect/components/textSelectNationality";
import Usefetchdata from "../../../../../../hooks/usefetchdata";
registerLocale('en-GB',enGB)
registerLocale('fr-FR',fr)

const AdminModalFileAcceptIdentity = () => {
    const { toggle } = UseModal();
    const { adminLink, adminType, adminTempWorkerId, setUpdateTempWorker, setOpenModal } = UseContext();
    const { userData } = Usefetchdata();
    const links = Object.values(adminLink);
    const [tempWorkerInfos, setTempWorkerInfos] = useState({
        tempWorker: {},
        user: {},
    });
    const [numberIdentity, setNumberIdentity] = useState('');
    const [placeOfBirthday, setPlaceOfBirthday] = useState('');
    const [dateObtained, setDateObtained] = useState('');
    const [dateExpiration, setDateExpiration] = useState('');
    const [nationality, setNationality] = useState('');
    const [nationalityList, setNationalityList] = useState('');
    const token = localStorage.getItem('xsrfToken');
    const [docs, setDocs] = useState([]);
    const [viewerStyle, setViewerStyle] = useState({ maxHeight: 700, pdfVerticalScrollByDefault: true });
    const [isFileExist, setIsFileExist] = useState(false);
    const [isWaitingFile, setIsWaitingFile] = useState(false);

    useEffect(() => {
        if (userData._id) {
            manageFiles();
        }
    }, [adminLink, userData]);

    const manageFiles = async () => {
        if (adminLink) {
            setIsWaitingFile(true);
            for (const [key, value] of Object.entries(adminLink)) {
                if (value) {
                    const response = await checkFileExistence(value);
                    setIsFileExist(response);
                    if (response) {
                        const buffer = await fetch(value).then(res => res.arrayBuffer());
                        const blob = new Blob([buffer], { type: response.type });
                        const fileURL = URL.createObjectURL(blob);
                        setDocs(docs => [...docs, { uri: fileURL, fileType: response.type }]);
                    }
                }
            }
            setIsWaitingFile(false);
        }
    };

    const checkFileExistence = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf') || contentType.includes('image')) {
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: contentType });

                    return blob.size !== 0 ? { type: contentType } : false;
                }
                return false;
            }
            return false;
        } catch (error) {
            console.error("Error checking file existence:", error);
            return false;
        }
    };

    const type = {
        'identity': {
            text: 'Pièce d\'identité',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'selfie': {
            text: 'Selfie d\'identité',
            icon: <SelfieIdentity wh={24} color={'#6B7280'} />,
        },
        'visa': {
            text: 'Visa',
            icon: <CardIdentity wh={24} color={'#6B7280'} />,
        },
        'card_vitality': {
            text: 'Carte Vitalité',
            icon: <CardVitale wh={24} color={'#6B7280'} />,
        },
        'proof_of_address': {
            text: 'Justificatif de domicile',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
        'accommodation_of_address': {
            text: 'Attestation de logement',
            icon: <Localisation wh={24} color={'#6B7280'} />,
        },
    };

    useEffect(() => {
        if (userData._id) {
            if (token && adminTempWorkerId) {
                ApiDatabase.getTempWorkerById({ token, adminTempWorkerId },
                    (data) => {
                        setTempWorkerInfos(prevData => ({
                            ...prevData,
                            tempWorker: data,
                        }));

                        setNumberIdentity(data.identity?.number_identity ?? undefined);
                        setPlaceOfBirthday(data.identity?.birthplace ?? undefined);
                        setDateObtained(data.identity?.start_validity ? new Date(data.identity?.start_validity) : '');
                        setDateExpiration(data.identity?.end_validity ? new Date(data.identity?.end_validity) : '');
                        setNationality(data.identity?.nationality ?? undefined);

                        const id_user = data.id_user;
                        ApiDatabase.getUserById({ token, id_user },
                            (data) => {
                                // if is not date format, change to date format
                                let birthDate = new Date(data.birthday);
                                if (isNaN(birthDate.getTime())) {
                                    const dateParts = data.birthday.split('-');
                                    birthDate = new Date(dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0]);
                                }
                                const birthDay = dateToEpoch(birthDate);
                                setTempWorkerInfos(prevData => ({
                                    ...prevData,
                                    user: {
                                        ...data,
                                        birthday: (('0' + birthDay.getDate()).toString().slice(-2)) + '/' + (('0' + (parseInt(birthDay.getMonth()) + 1)).toString().slice(-2)) + '/' + birthDay.getFullYear(),
                                    },
                                }));
                            },
                            (err) => {
                                if (err.name !== "CanceledError") {
                                    console.log(err);
                                }
                            });
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            console.log(err);
                        }
                    });
            } else {
                setTempWorkerInfos({
                    tempWorker: {},
                    user: {},
                });
                toast.error('Une erreur est survenue, veuillez réessayer ou contacter le support');
            }
        }
    }, [adminTempWorkerId, userData]);

    const modalAcceptSubmit = (e) => {
        e.preventDefault();
        if (token && adminTempWorkerId && numberIdentity && placeOfBirthday && dateObtained && dateExpiration && nationality) {
            ApiDatabase.postAcceptTempWorkerFileIdentityAdmin({ token, adminTempWorkerId, adminType, numberIdentity, placeOfBirthday, dateObtained, dateExpiration, idNationality: nationality },
                (data) => {
                    toast.success('Fichier d\'identité accepté avec succès');
                    toggle();
                    setUpdateTempWorker(data._id);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err);
                    }
                }
            );
        } else {
            toast.error('Veuillez remplir tous les champs');
        }
    };

    useEffect(() => {
        if (token) {
            ApiDatabase.getNationalities({ token },
                (data) => {
                    setNationalityList(data);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err);
                    }
                }
            );
        }
    }, []);

    const selectNationality = (nationalitySelected) => {
        setNationality(nationalitySelected._id);
    };

    return (
        <>
            <ModalBody>
                <div className={'flex gap-3'}>
                    <div className={'w-2/3'}>
                        {isWaitingFile ? (
                            <>
                                Chargement du document...
                            </>
                        ) : (
                            <>
                                {
                                    isFileExist ? (
                                        docs && (
                                            <DocViewer documents={docs} style={viewerStyle}
                                                       pluginRenderers={DocViewerRenderers}/>
                                        )
                                    ) : (
                                        <div
                                            className={'flex justify-center items-center h-full py-3 px-1 border rounded'}>
                                            <div className={'text-gray-500'}>
                                                Une erreur est survenue lors du chargement du document. Le fichier
                                                est peut-être corrompu ou inexistant.
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    <div className={'w-1/3 flex flex-col gap-3'}>
                        <div className={'w-full'}>
                            <div className={'flex justify-between gap-5'}>
                                <div className={'w-full'}>
                                    <div className={'p-3 border rounded flex gap-3'}>
                                        <div className={'my-auto'}>
                                            {type[adminType].icon}
                                        </div>
                                        <div className={'my-auto ft-sm'}>
                                            <div className={''}>
                                                {adminType === 'identity' && (tempWorkerInfos.tempWorker.identity?.type === 'Passeport' ? 'Passeport' : 'Carte d\'identité')}
                                                {adminType === 'visa' && (tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'Visa' : 'Titre de séjour')}
                                                {(adminType !== 'identity' && adminType !== 'visa') && type[adminType].text}
                                            </div>
                                            <div className={'text-gray-500'}>
                                                {
                                                    docs.length === 2 ? 'Recto & verso' : (docs.length > 2 ? 'Plusieurs documents' : 'Document unique')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={''}>
                                    <div className={'cursor-pointer'} onClick={toggle}>
                                        <CrossIcon wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'w-full py-3'}>
                            <div className={'grid grid-cols-2 gap-4 ft-sm'}>
                                {
                                    tempWorkerInfos.tempWorker.identity?.validated === '2' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'info'}>
                                                Le document est validé.
                                            </Banner>
                                        </div>
                                    )
                                }
                                {
                                    tempWorkerInfos.tempWorker.identity?.validated === '3' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'alert'}>
                                                Le document a été refusé. Le candidat a déjà été notifié.
                                            </Banner>
                                        </div>
                                    )
                                }
                                <div className={''}>Nom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.lastname}</div>
                                <div className={''}>Prenom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.firstname}</div>
                                <div className={''}>Date de naissance</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.birthday}</div>
                                <div className={''}>Nationalité</div>
                                <div
                                    className={'text-gray-500'}>{tempWorkerInfos.tempWorker.visa?.type ? 'Hors UE' : 'UE, EE, Suisse'}</div>
                                {
                                    tempWorkerInfos.tempWorker.visa?.validated === '1' && (
                                        <div className={'col-span-2'}>
                                            <Banner type={'warning'}>
                                                Un {tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'visa' : 'titre de séjour'} devra
                                                aussi être vérifié pour valider ce candidat.
                                            </Banner>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <Divider/>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    N° de la pièce d'identité
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           value={numberIdentity}
                                           onChange={e => setNumberIdentity(e.target.value)}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Lieu de naissance
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900 uppercase'
                                           value={placeOfBirthday}
                                           onChange={e => setPlaceOfBirthday(e.target.value)}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Date d'obtention
                                </div>
                                <div>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={dateObtained}
                                        onChange={(date) => setDateObtained(date)}
                                        locale='fr-FR'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        popperPlacement="bottom"
                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Date de fin de validité
                                </div>
                                <div>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={dateExpiration}
                                        onChange={(date) => setDateExpiration(date)}
                                        locale='fr-FR'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        popperPlacement="bottom"
                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center'
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'w-full'}>
                            <TextSelectNationality dataList={nationalityList} onSelect={(data) => selectNationality(data)}
                                                   setData={nationality}/>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={() => {
                    toggle()
                    setOpenModal('ad_file_refuse')
                }}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <Close wh={20} color={'#374151'}/>
                        </div>
                        <div className={'my-auto'}>
                            Refuser
                        </div>
                    </div>
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={modalAcceptSubmit}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <CheckIcon w={14} h={10} color={'#ffff'}/>
                        </div>
                        <div className={'my-auto'}>
                            Accepter
                        </div>
                    </div>
                </Button>
            </ModalFooter>
        </>
    )
}

export default AdminModalFileAcceptIdentity
import PageForm from "../../../components/pageForm/PageForm";
import { useLocation} from "react-router-dom";
import HeaderVivierRatingTempWorker from "./components/header/header.vivier-rating-temp-worker";
import BodyVivierRatingTempWorker from "./components/content/body.vivier-rating-temp-worker";

const VivierRatingTempWorker = () => {
    const pathname = useLocation().pathname;

    return (
        <>
            <PageForm>
                <HeaderVivierRatingTempWorker/>
                <div className={'p-8'}>
                    <BodyVivierRatingTempWorker treated={pathname.includes('rating-treated')}/>
                </div>
            </PageForm>
        </>
    )
}

export default VivierRatingTempWorker;
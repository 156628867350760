import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const PositionedDiv = ({ parentRef, position = 'left', content = '', size = 256, children }) => {
    const portalRoot = parentRef.current;

    if (!portalRoot) {
        console.error("You need to add a div with id 'portal-root' to your HTML file.");
        return null;
    }

    const [positionStyle, setPositionStyle] = useState({});
    const portalContainerRef = useRef(null);

    useEffect(() => {
        const portalContainer = document.createElement('div');
        portalRoot.appendChild(portalContainer);
        portalContainerRef.current = portalContainer;

        const calculatePosition = () => {
            if (!parentRef.current || !portalContainerRef.current) {
                return;
            }

            const parentRect = parentRef.current.getBoundingClientRect();
            const topPosition = parentRect.top + window.scrollY;
            let leftPosition;

            if (position === 'left') {
                leftPosition = parentRect.left + window.scrollX - size / 1.27;
            } else if (position === 'right') {
                leftPosition = parentRect.right + window.scrollX;
            } else if (position === 'top') {
                leftPosition = parentRect.left + window.scrollX;
            } else if (position === 'bottom') {
                leftPosition = parentRect.left + window.scrollX;
            }

            setPositionStyle({
                position: 'fixed',
                top: `${topPosition}px`,
                left: `${leftPosition}px`,
                maxWidth: `${size}px`,
            });
        };

        calculatePosition();

        const handleResize = () => {
            calculatePosition();
        };

        const handleScroll = () => {
            calculatePosition();
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);

            if (portalContainerRef.current && portalContainerRef.current.parentNode) {
                portalContainerRef.current.parentNode.removeChild(portalContainerRef.current);
            }
        };
    }, [parentRef, position, portalRoot, size]);

    const portalContent = (
        <div className={`fixed`} style={{ ...positionStyle }}>
            {content && <p className="text-white">{content}</p>}
            {children}
        </div>
    );

    const portalContainer = portalContainerRef.current;

    return portalContainer ? ReactDOM.createPortal(portalContent, portalContainer) : null;
};

export default PositionedDiv;

import React, {useEffect} from 'react';
import {Cadenas, Delete, Mail} from "assets/icons";
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";

const Parametre = () => {
const {userData}=usefetchdata()
    const {toggle} = useModal()
    useEffect(()=>{if(userData.tokenmail) toggle('tok_u_tokenEmail')},[userData])
    const handleEmail = (e) => {
        e.preventDefault()
        toggle('par_u_email')
    }
    const handlePwd = (e) => {
        e.preventDefault()
        toggle('par_u_pwd')
    }
    const handleDelete = (e) => {
        e.preventDefault();
        toggle('par_u_compte')
    }
    return (
        <>

            <div className='col-span-full flex flex-col items-center justify-start gap-6 '>
                <div className='inline-flex items-center gap-6 w-[300px] cursor-pointer '
                     onClick={handlePwd}>
                    <div
                        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                        <Cadenas wh={18} color={'#111827'}/>
                    </div>
                    <p>Changer le mot de passe</p>
                </div>
                <div
                    className='inline-flex items-center justify-start gap-6 w-[300px] cursor-pointer'
                    onClick={handleEmail}>
                    <div
                        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                        <Mail wh={18} color={'#111827'}/>
                    </div>

                    <p>Changer le mail</p>
                </div>
                <div
                    className='inline-flex items-center gap-6 justify-start w-[300px] cursor-pointer'
                    onClick={handleDelete}>
                    <div
                        className='w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center'>
                        <Delete wh={18} color={'#111827'}/>

                    </div>
                    <p>Supprimer le compte</p>
                </div>
            </div>
        </>
    );
};

export default Parametre;
import useModal from '../../../useModal';
import UseContext from '../../../../../hooks/useContext';
import Modalheader from '../../../components/header';
import React, { useEffect, useState } from 'react';
import ModalFooter from '../../../components/footer';
import Button from '../../../../button';
import ModalBody from '../../../components/body';
import StyledInput from '../../../../styledInput/StyledInput';
import ApiDatabase from '../../../../../server';
import { toast } from 'react-toastify';

const ModalSundayHoursCompany = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [increase, setIncrease] = useState('');
  const [errorIncrease, setErrorIncrease] = useState('');

  const token = localStorage.getItem('xsrfToken');

  const handleChange = (value) => {
    setIncrease(value);

    if (!value || value < 0 || value > 100) {
      setErrorIncrease('La valeur doit être entre 0 et 100');
    }
  };

  const validateIncrease = () => {
    const newError =
      !increase || increase < 0 || increase > 100
        ? 'La valeur doit être entre 0 et 100'
        : null;

    setErrorIncrease(newError);

    return !newError;
  };

  const handleSubmit = () => {
    if (validateIncrease() && modalData?.idCompany) {
      setIsLoading(true);
      ApiDatabase.postSundayHoursCompany(
        { token, idCompany: modalData?.idCompany, increase },
        (data) => {
          setIsLoading(false);
          toast.success(
            'La majoration des heures de dimanche à été mise à jour'
          );
          setModalDataReturn({
            type: 'sunday_hours_updated',
            increase,
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaiting(true);

    ApiDatabase.getSundayHoursCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        setIncrease(data.sundayHours);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaiting(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Heures du dimanche
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-2.5 text-sm">
          {isWaiting ? (
            <div className={'animate-pulse'}>
              <div className={'h-16 bg-gray-200 rounded w-full'}></div>
            </div>
          ) : (
            <>
              <div>
                <StyledInput
                  type="number"
                  label="Majoration"
                  value={increase}
                  unit={'%'}
                  onChange={(e) => handleChange(e.target.value)}
                  error={errorIncrease}
                />
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>
              Annuler
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Confirmation ...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Annuler
            </Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalSundayHoursCompany;

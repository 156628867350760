import TextSelectMultiple from "./textSelectMultiple";
import React, {useEffect, useState} from "react";
import Button from "../button";
import {toast} from "react-toastify";
import ApiDatabase from "server";
import UseContext from "../../hooks/useContext";

const TextSelectMultipleBigAccount = () => {
    const {objID, refreshCompanyBigAccount, setRefreshCompanyBigAccount}=UseContext()
    const [startSearch, setStartSearch] = useState(false)
    const [searchValues, setSearchValues] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        isLinked: true
    })
    const [suggestFormDisplay, setSuggestFormDisplay] = useState(false)
    const [submitFormLoading, setSubmitFormLoading] = useState(false)
    const [linkBigAccount, setLinkBigAccount] = useState(false)
    const [forceSelectMultipleDown, setForceSelectMultipleDown] = useState(true)
    const [deleteBigAccount, setDeleteBigAccount] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if(startSearch) {
            let timerBigAccountSuggestions;

            timerBigAccountSuggestions = setTimeout(async () => {
                await manageBigAccountSuggestions(inputValue)
            }, 300);

            return () => clearTimeout(timerBigAccountSuggestions);
        }
    }, [startSearch]);

    const manageBigAccountSuggestions = async () => {
        await ApiDatabase.getBigAccounts({token, search: inputValue}, (data) => {
            setStartSearch(false)
            setSearchValues(data.bigAccounts !== searchValues ? data.bigAccounts : [])
        }, (error) => {
            toast.error('Une erreur est survenue lors de la récupération des grands comptes', error)
        })
    }

    const addBigAccount = () => {
        if(!formData.name) return toast.error('Veuillez renseigner un nom')
        setSubmitFormLoading(true)

        ApiDatabase.addBigAccount({token, name: formData.name, isLinked: formData.isLinked, idCompany: objID}, (data) => {
            setSubmitFormLoading(false)
            if(formData.isLinked) {
                toast.success('Le grand compte a bien été ajouté et lié à l\'entreprise')
                setSuggestFormDisplay(false)
                setSearchValues([])
                setInputValue('')
                setFormData({
                    name: '',
                    isLinked: false
                })
                setRefreshCompanyBigAccount(!refreshCompanyBigAccount)
            } else {
                toast.success('Le grand compte a bien été ajouté')
                setFormData({
                    ...formData,
                    name: '',
                })
            }
        }, (error) => {
            setSubmitFormLoading(false)
            console.log('error', error)
            toast.error(`Une erreur est survenue lors de la création du grand compte : ${error.response.data.message}.`)
        })
    }

    useEffect(() => {
        setFormData({
            name: inputValue,
            isLinked: true
        })
    }, [suggestFormDisplay]);

    const formToDisplay = () => {
        return (
            <div className={'flex flex-col gap-4'}>
                <label className='ft-sm text-gray-500'>
                    <div>
                        Nom
                    </div>
                    <div>
                        <input type="text"
                               className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                               value={formData.name}
                               onChange={e =>
                                   setFormData({
                                       ...formData,
                                        name: e.target.value
                                   })
                               }
                        />
                    </div>
                </label>
                <div>
                    <label className={'flex gap-3'}>
                        <div className={'my-auto'}>Lier cette entreprise à ce grand compte</div>
                        <div className={'my-auto flex'}>
                            <input type="checkbox" className={'my-auto'} checked={formData.isLinked} onChange={() =>
                                setFormData({
                                    ...formData,
                                    isLinked: !formData.isLinked
                                })
                            }/>
                        </div>
                    </label>
                </div>
                <div className={'flex justify-end gap-2 mt-2'}>
                    <Button size={'SM'} color={'SECONDARY'} onClick={() => setSuggestFormDisplay(false)}>Annuler</Button>
                    {submitFormLoading
                        ? <Button size={'SM'} color={'DISABLED'} disabled>Création ...</Button>
                        : <Button size={'SM'} color={'PRIMARY'} onClick={() => addBigAccount()}>Créer</Button>
                    }
                </div>
            </div>
        )
    }

    useEffect(() => {
        if(linkBigAccount) {
            ApiDatabase.linkCompanyToBigAccount({token, idCompany: objID, idBigAccount: linkBigAccount}, (data) => {
                toast.success('L\'entreprise a bien été liée au grand compte')
                setSearchValues([])
                setInputValue('')
                setLinkBigAccount(false)
                setForceSelectMultipleDown(true)
                setRefreshCompanyBigAccount(!refreshCompanyBigAccount)
            }, (error) => {
                setSearchValues([])
                setInputValue('')
                setLinkBigAccount(false)
                toast.error(`Une erreur est survenue lors de la liaison de l\'entreprise au grand compte ${error.response.data.message}.`)
            })
        }
    }, [linkBigAccount]);

    useEffect(() => {
        if(deleteBigAccount){
            ApiDatabase.deleteBigAccount({token, idBigAccount: deleteBigAccount}, (data) => {
                setRefreshCompanyBigAccount(!refreshCompanyBigAccount)
                setDeleteBigAccount(false)
                toast.success('Le grand compte n\'est plus lié à cette entreprise')
            }, (error) => {
                setDeleteBigAccount(false)
                toast.error(`Une erreur est survenue lors de la suppression du grand compte ${error.response.data.message}.`)
            })
        }
    }, [deleteBigAccount]);

    return (
        <TextSelectMultiple label={'Grand compte'}
                            startSearch={startSearch}
                            setStartSearch={setStartSearch}
                            searchValues={searchValues}
                            setSearchValues={setSearchValues}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            suggestForm={'Ajouter un grand compte'}
                            formToDisplay={formToDisplay()}
                            suggestFormDisplay={suggestFormDisplay}
                            setSuggestFormDisplay={setSuggestFormDisplay}
                            setClickValue={setLinkBigAccount}
                            forceSelectMultipleDown={forceSelectMultipleDown}
                            setForceSelectMultipleDown={setForceSelectMultipleDown}
                            isDeleteValue={true}
                            setDeleteValue={setDeleteBigAccount}
        />
    )
}

export default TextSelectMultipleBigAccount;
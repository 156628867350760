import { useEffect } from 'react';
import {
  CrossIcon,
  InformationCircle,
  Mail,
  Pencil,
  Plus,
} from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Badge from '../../../../badge/badge';
import Button from '../../../../button';
import CopyToClipboard from '../../../../copyToClipboard';
import UseModal from '../../../../modal/useModal';

const SlideOverCompanyMembers = ({ companyInfo, setCompanyInfo }) => {
  const { toggle } = UseModal();
  const { setModalData, modalDataReturn, setModalDataReturn } = UseContext();
  const token = localStorage.getItem('xsrfToken');

  const roleName = {
    200: 'Observateur',
    205: 'Manageur',
    210: 'Responsable',
    215: 'Directeur',
    220: 'Superviseur',
    225: 'Administrateur',
  };

  const addCompanyMember = (idCompany) => {
    setModalData({ idCompany });
    toggle('add_company_member');
  };

  const editCompanyMember = (idCompany, idEmployee) => {
    setModalData({ idCompany, idEmployee });
    toggle('edit_company_member');
  };

  const deleteCompanyMember = (idCompany, idEmployee) => {
    setModalData({ idCompany, idEmployee });
    toggle('delete_company_member');
  };

  useEffect(() => {
    if (modalDataReturn?.type === 'employee_created' && modalDataReturn?.user) {
      setCompanyInfo((prev) => ({
        ...prev,
        employees: [
          ...prev.employees,
          { role: modalDataReturn.user.role, user: modalDataReturn.user },
        ],
      }));

      setModalData({});
      setModalDataReturn({});
    }
    if (modalDataReturn?.type === 'employee_edited' && modalDataReturn?.user) {
      setCompanyInfo((prev) => ({
        ...prev,
        employees: prev.employees.map((emp) =>
          emp.user._id === modalDataReturn.user._id
            ? {
                ...emp,
                role: modalDataReturn.user.role,
                user: modalDataReturn.user,
              }
            : emp
        ),
      }));

      setModalData({});
      setModalDataReturn({});
    }
    if (
      modalDataReturn?.type === 'employee_deleted' &&
      modalDataReturn?.idEmployee
    ) {
      setCompanyInfo((prev) => ({
        ...prev,
        employees: prev.employees.filter(
          (emp) => emp.user._id !== modalDataReturn.idEmployee
        ),
      }));

      setModalData({});
      setModalDataReturn({});
    }
  }, [modalDataReturn, setCompanyInfo, setModalData, setModalDataReturn]);

  const sendMailMember = (idCompany, idEmployee) => {
    setModalData({ idCompany, idEmployee });
    toggle('send_mail_link_company_member');
  };

  useEffect(() => {
    if (!companyInfo?.employees) return;

    const fetchPhoneNumbers = async () => {
      const employeesToUpdate = companyInfo.employees.filter(
        (employee) =>
          employee.user.email &&
          !employee.user.phone &&
          !employee.hubspotSearched
      );

      if (employeesToUpdate.length === 0) return;

      employeesToUpdate.forEach((employee) => {
        ApiDatabase.getHubSpotContactByEmail(
          { token, email: employee.user.email },
          (data) => {
            setCompanyInfo((prevData) => ({
              ...prevData,
              employees: prevData.employees.map((emp) =>
                emp.user._id === employee.user._id
                  ? {
                      ...emp,
                      user: { ...emp.user, phone: data.phone },
                      hubspotSearched: true,
                    }
                  : emp
              ),
            }));
          },
          (err) => {
            if (err.name !== 'CanceledError') {
              console.error(err);
              setCompanyInfo((prevData) => ({
                ...prevData,
                employees: prevData.employees.map((emp) =>
                  emp.user._id === employee.user._id
                    ? { ...emp, hubspotSearched: true }
                    : emp
                ),
              }));
            }
          }
        );
      });
    };

    fetchPhoneNumbers();
  }, [companyInfo.employees.length, token, setCompanyInfo]);

  return (
    <>
      <div className={'flex gap-1 justify-between'}>
        <div className={'flex gap-2 items-center'}>
          <div className={''}>Membres</div>
          <div
            className={
              'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'
            }
          >
            {companyInfo?.employees?.length}
          </div>
        </div>
        <div className={'flex gap-1 items-center'}>
          <Button
            color={'SECONDARY'}
            size={'SM'}
            onClick={() => addCompanyMember(companyInfo?._id)}
          >
            <div className={'flex gap-1 items-center'}>
              <div className={''}>
                <Plus wh={20} color={'#374151'} />
              </div>
              <div className={''}>Ajouter</div>
            </div>
          </Button>
        </div>
      </div>
      {companyInfo?.employees?.length > 0 &&
        companyInfo?.employees?.map((item, key) => (
          <div key={key} className={'py-2 border-b'}>
            <div className={'flex justify-between gap-1 items-center'}>
              <div className={''}>
                <div className={'flex gap-1'}>
                  <CopyToClipboard text={item.user?.firstname}>
                    {item.user?.firstname}
                  </CopyToClipboard>
                  <CopyToClipboard text={item.user?.lastname}>
                    {item.user?.lastname}
                  </CopyToClipboard>
                </div>
                <div className={'text-gray-500 flex gap-1'}>
                  <CopyToClipboard text={item.user?.email}>
                    {item.user?.email}
                  </CopyToClipboard>
                  {item.user?.phone && (
                    <>
                      <div>·</div>
                      <CopyToClipboard text={item.user?.phone}>
                        {item.user?.phone}
                      </CopyToClipboard>
                    </>
                  )}
                </div>
              </div>
              <div className={'flex gap-2 justify-end items-center'}>
                {item.user?.validation === 0 && (
                  <div
                    className={'text-xs text-gray-500 flex gap-1 items-center'}
                  >
                    <InformationCircle wh={14} color={'#6B7280'} />
                    En attente
                  </div>
                )}
                <Badge type={'SECONDARY'}>{roleName[item.role]}</Badge>
                <div className={'flex items-center'}>
                  {item.user?.validation === 0 && (
                    <Button
                      size={'ICON'}
                      color={'ICON'}
                      onClick={() =>
                        sendMailMember(companyInfo?._id, item.user?._id)
                      }
                    >
                      <Mail wh={20} color={'#111827'} />
                    </Button>
                  )}
                  <Button
                    size={'ICON'}
                    color={'ICON'}
                    onClick={() =>
                      editCompanyMember(companyInfo?._id, item.user?._id)
                    }
                  >
                    <Pencil wh={20} color={'#111827'} />
                  </Button>
                  <Button
                    size={'ICON'}
                    color={'ICON'}
                    onClick={() =>
                      deleteCompanyMember(companyInfo?._id, item.user?._id)
                    }
                  >
                    <CrossIcon wh={20} color={'#111827'} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default SlideOverCompanyMembers;

import React from 'react';

const IndicatorIdentity = ({formIndex, pi,nationality}) => {
    return (
        <>
        {pi.nationality || nationality === 'NUE'?
        <div className={'flex gap-4 justify-around mb-8 '}>
            <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 1 || formIndex === 6 ? 'border-yellow-200' : 'border-yellow-500'}`}>
                Pièce d’identité
            </div>
            <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 2 || formIndex=== 7 ? 'border-yellow-200' :
                formIndex === 3 || formIndex === 8 || formIndex ===5? 'border-yellow-500' : ''}`}>
                Selfie d’identité
            </div>
            <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 3 ? 'border-yellow-200' :
                formIndex === 4 ||formIndex === 8 || formIndex ===5? 'border-yellow-500' : ''}`}>
                Autorisation de travail
            </div>
            <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 4 ||formIndex === 5 ? 'border-yellow-200' :''}`}>
                Confirmation
            </div>
        </div>
            :
            <div className={'flex gap-4 justify-around mb-8 '}>
                <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 1 || formIndex === 5 ? 'border-yellow-200' : 'border-yellow-500'}`}>
                    Pièce d’identité
                </div>
                <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 2 ? 'border-yellow-200' :
                    formIndex === 3 || formIndex === 6 || formIndex===4 ? 'border-yellow-500' : ''}`}>
                    Selfie d’identité
                </div>
                <div className={`pt-4 w-40    ft-sm font-medium text-gray-900 border-t-4
            ${formIndex === 3 || formIndex===4 ? 'border-yellow-200' :''}`}>
                    Confirmation
                </div>
            </div>
        }

        </>
    );
};

export default IndicatorIdentity;
import React, {forwardRef} from 'react';
import {ArrowRight, Interrogation} from "assets/icons";
import {NavLink} from "react-router-dom";

const Banner = forwardRef(({type, children, ...props}, ref) => (

    <>
        <div ref={ref}
             className={` w-full ${body[type]} border {${border[type]} px-4 py-3 rounded relative`}
             role="alert">
            <div className="flex flex-row items-center justify-start mb-4 gap-2">
                <Interrogation
                    color={InterrogationColors[type]}
                    wh="24"
                    strokeW="2"
                ></Interrogation>
                <strong className={`ml-1 font-bold ${title[type]}`}>{props.title}</strong>
            </div>
            <div className="flex items-center justify-start ml-8">
    <span className={` ${content[type]} block sm:inline`}>
{children}
    </span>
            </div>
        </div>

    </>
))
const InterrogationColors = {
    info: "#60A5FA",
    warning: "#FFA43D",
    alert: "#F87171",
};
const title = {
    info: `text-blue-900`,
    warning: `text-yellow-900`,
    alert: `text-red-900`,
}
const body = {
    info: `bg-blue-50`,
    warning: `bg-yellow-50`,
    alert: `bg-red-50`,
}
const border = {
    info: `border-blue-50`,
    warning: `border-yellow-50`,
    alert: `border-red-50`,
}
const content = {
    info: `text-blue-700`,
    warning: `text-yellow-700`,
    alert: `text-red-700`,
}
export default Banner;
import React from 'react';
import SidebarUnitDesktop from "components/sidebar/components/SidebarUnitDesktop";
import {Calendar} from "assets/icons";

const TimeSheetDL = ({pathname}) => {
    return (
        <SidebarUnitDesktop routeName={"Relevés d'heures"} routeSidebar={"/timeSheet/toBeChecked"}>
            <span className='shrink-0'>
                <Calendar wh={24} color={`${pathname.includes("timeSheet") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("timeSheet") && "!text-[#FF9600]"
                }`}
            >
          {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                Relevés d'heures
        </span>
        </SidebarUnitDesktop>
    );
};

export default TimeSheetDL;
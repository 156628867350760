import React from "react";
import PageForm from "../../../components/pageForm/PageForm";
import {Tab, Tabs} from "../../../components/tabs/tabs";
import {Outlet} from "react-router-dom";

const HrFlow = () => {
    return (
        <>
            <PageForm>
                <Tabs>
                    <Tab label={'general'} tabName={'General'}/>
                    <Tab label={'jobs'} tabName={'Jobs'}/>
                </Tabs>
                <div className={'col-span-12 p-3 h-full'}>
                    <Outlet/>
                </div>
            </PageForm>
        </>
    )
}

export default HrFlow
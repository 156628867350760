import Modalheader from "../../../components/header";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import useModal from "../../../useModal";
import React, {useEffect, useState} from "react";
import ApiDatabase from 'server'
import UseContext from "../../../../../hooks/useContext";
import {toast} from "react-toastify";

const ModalLocalisationPreference = () => {
    const {toggle}=useModal()
    const [localisation, setLocalisation] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isWaiting, setIsWaiting] = useState(true);
    const { infoVivier, setUsersVivier } = UseContext()

    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if (infoVivier?.listUsers?.length === 1) {
            setIsWaiting(true)
            ApiDatabase.getLocalisationPreferenceUser({token, idUser: infoVivier?.listUsers[0]}, (data) => {
                setLocalisation(data?.department)
                setIsWaiting(false)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    setIsWaiting(false)
                    console.log(err)
                }
            })
        } else {
            setIsWaiting(false)
        }
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true)
        ApiDatabase.updLocalisationPreferenceUsers({token, idUsers: infoVivier?.listUsers, localisation}, (data) => {
            toast.success('Le département a bien été modifié')
            setUsersVivier(data)
            toggle()
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
                setIsLoading(false)
            }
        })
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Changer le département
            </Modalheader>
            <ModalBody>
                <div className={'flex flex-col gap-4'}>
                    <div className={'text-gray-500'}>
                        Le profil verra uniquement les missions disponibles correspondant au département sélectionné à chaque fois. Il pourra modifier sa localisation ultérieurement.
                    </div>
                    {isWaiting ? (
                        <div className="h-10 w-full bg-gray-200 rounded"></div>
                    ) : (
                        <label>
                            <div className={'ft-sm text-gray-500'}>
                                Sélectionner
                            </div>
                            <select className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                    onChange={(e) => setLocalisation(e.target.value)}
                                    value={localisation}
                            >
                                <option value={null}>Sélectionner un département</option>
                                <option value={'Martinique'}>Martinique</option>
                                <option value={'Guadeloupe'}>Guadeloupe</option>
                            </select>
                        </label>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                {isLoading ? (
                    <>
                        <Button color={'DEFAULT'} size={'LG'}>Annuler</Button>
                        <Button color={'DEFAULT'} size={'LG'}>Confirmation ...</Button>
                    </>
                ) : (
                    <>
                        <Button onClick={toggle} color={'DEFAULT'} size={'LG'}>Annuler</Button>
                        <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
                    </>
                )}
            </ModalFooter>
        </>
    )
}

export default ModalLocalisationPreference;
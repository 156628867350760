import React from 'react';
import DashboardML from "./Links/Mobile/DashboardML";
import ProfilML from "./Links/Mobile/ProfilML";
import SearchMissionML from "./Links/Mobile/SearchMissionML";
import DocumentsML from "./Links/Mobile/DocumentsML";
import MissionML from "./Links/Mobile/MissionML";
import {useLocation} from "react-router-dom";
import IdentityValidationML from "./Links/Mobile/IdentityValidationML";

const SidebarMobile = () => {
    const route = useLocation();
    const {pathname} = route;
    return (
        <>
            <div className="absolute bottom-0 z-30 p-2  bg-[#FCFCFC] h-[80px] w-full">
                <ul className="flex gap-1 items-start justify-evenly ">
                    <DashboardML pathname={pathname}  />
                    <IdentityValidationML pathname={pathname}  />
                    <ProfilML pathname={pathname} />
                    <SearchMissionML pathname={pathname} />
                    <DocumentsML pathname={pathname} />
                    <MissionML pathname={pathname} />
                </ul>
            </div>
        </>
    );
};

export default SidebarMobile;
import React from 'react';
import EmptyState from "components/EmptyState/EmptyState";
import usefetchdata from "hooks/usefetchdata";

const Completed = () => {
    const {tempWorkerData}=usefetchdata()
    return (
        <div>
            {tempWorkerData?.jobs?.length ?'test':
                <EmptyState type={'mission_done'}/>
            }

        </div>
    );
};

export default Completed;
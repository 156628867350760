import React from 'react';
import {TabF, TabsF} from "components/tabs/tabfile";

const TimeSheetSubNav = () => {
    return (
        <>
            <TabsF>
                <TabF label={"toBeCompleted"} tabName={'Non compléter'}/>
                <TabF label={"toBeChecked"} tabName={'À vérifier'}/>
                <TabF label={"litigation"} tabName={'Litige'}/>
                <TabF label={"checked"} tabName={'Vérifié'}/>
            </TabsF>
        </>
    );
};

export default TimeSheetSubNav;
import { TrainingIcon } from "../../../assets/icons";

const TrainingItem = ({ formation, index }) => {
    return (
        <div key={index} className={'flex gap-2 items-center'}>
            <div className={'w-10 h-10 min-w-10 min-h-10 flex justify-center items-center bg-gray-100 rounded'}>
                <TrainingIcon wh={20} color={'#374151'} />
            </div>
            <div className={'flex flex-col gap-1'}>
                <div className={''}>{formation.name}</div>
                <div className={'text-gray-500'}>{formation.description}</div>
            </div>
        </div>
    );
}

export default TrainingItem;

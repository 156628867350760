import React, {useContext} from 'react';
import SidebarUnitDesktop from "components/sidebar/components/SidebarUnitDesktop";
import UseContext from "hooks/useContext";
import {CardIdentity} from "../../../../assets/icons";



const IdentityValidationDL = ({pathname}) => {
    const {language}=UseContext()
    return (
        <SidebarUnitDesktop routeName={"Validation d'identité"} routeSidebar={"/identity_validation"}>
            <span className='shrink-0'>
                <CardIdentity wh={24} color={`${pathname === '/identity_validation' ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("identity_validation") && "!text-[#FF9600]"
                }`}
            >
          {language.getValue('sidebar.identity_validation','Validation d\'identité')}
        </span>
        </SidebarUnitDesktop>
    );
};

export default IdentityValidationDL;
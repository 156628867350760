import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import ApiDatabase from "../../../../../server";
import Button from "../../../../button";
import {dateDiff} from "../../../../../helper/helper";
import Banner from "../../../../banner/banner";
import {toast} from "react-toastify";

const ModalMissionNotifyGlobal = () => {
  const {toggle} = useModal();
  const {objID, setObjID} = UseContext()
  const [infoMission, setInfoMission] = useState({})
  const [isWaiting, setIsWaiting] = useState(false)
  const [sendNotifLoading, setSendNotifLoading] = useState(false)
  const [lastNotificationMission, setLastNotificationMission] = useState("")
  const token = localStorage.getItem('xsrfToken')

  useEffect(() => {
    setIsWaiting(true)
    ApiDatabase.getMissionDetailsById({token: token, idMission: objID}, data => {
      setIsWaiting(false)
      setInfoMission(data)
    }, err => {

    })
    ApiDatabase.getLastMissionNotificationGlobal({token, idMission: objID}, data => {
      setLastNotificationMission(dateDiff(data.lastNotificationDate))
    }, err => {
      setLastNotificationMission('')
    })
  }, [token, objID]);

  const handleSubmit = () => {
    setSendNotifLoading(true)
    ApiDatabase.postViviersMissionNotificationGlobal({token, idMission: objID}, data => {
      setSendNotifLoading(false)
      toast.success('La notification a été envoyée.');
      setObjID('')
      toggle()
    }, err => {
      toast.error(err.response?.data?.message || 'Une erreur est survenue.');
      setSendNotifLoading(false)
    })
  }

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Notifier globalement la mission
      </Modalheader>
      <ModalBody>
        <div className={'flex flex-col gap-8 text-sm'}>
          {lastNotificationMission && (
            <Banner type={'info'}>
              La mission a été notifiée il y a {lastNotificationMission.day > 0 ?
              `${lastNotificationMission.day} jours`
              : lastNotificationMission.hour > 0 ?
                `${lastNotificationMission.hour} heures`
                : lastNotificationMission.min > 0 ?
                  `${lastNotificationMission.min} minutes`
                  : lastNotificationMission.sec > 0 ?
                    `${lastNotificationMission.sec} secondes` : ''
            }
            </Banner>
          )}
          {isWaiting ? (
            <div className={'animate-pulse'}>
              <div className={'h-4 bg-gray-200 rounded w-3/4'}></div>
            </div>
          ) : (
            <>
              Vous vous apprétez à notifier globalement la mission {infoMission.title} n°{infoMission.id_mission}.
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {sendNotifLoading ? (
          <div className={'flex gap-2'}>
            <Button size={'LG'} color={'DISABLED'}>Annuler</Button>
            <Button size={'LG'} color={'DISABLED'}>Confirmer ...</Button>
          </div>
        ) : (
          <div className={'flex gap-2'}>
            <Button onClick={toggle} size={'LG'} color={'SECONDARY'}>Annuler</Button>
            <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
          </div>
        )}
      </ModalFooter>
    </>
  )
}

export default ModalMissionNotifyGlobal;
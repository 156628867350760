import Tbodycontract from 'components/table/components/tbodycontract';
import Thead from 'components/table/components/thead';
import { useLocation } from 'react-router-dom';
import Pagination from '../pagination/pagination';
import Tbodycompany from './components/tbodycompany';
import TbodyCompanyBonusAndCompensation from './components/tbodyCompanyBonusAndCompensation';
import Tbodycompanycoeff from './components/Tbodycompanycoeff';
import Tbodyhours from './components/tbodyhours';
import TbodyQualification from './components/tbodyQualification';
import TbodyVivierRatingTempWorker from './components/tbodyVivierRatingTempWorker';

const Table = ({
  champsHeader,
  champsBody,
  type,
  currentPage,
  countPerPage,
  countTotal,
  totalPages,
  setCurrentPage,
}) => {
  const route = useLocation();
  const { pathname } = route;

  return (
    <div className="overflow-auto">
      <table className="table-auto w-full text-gray-500 space-y-6 ft-sm">
        <thead>
          <Thead champsHeader={champsHeader} />
        </thead>
        <tbody>
          {pathname.includes('contract') ? (
            <Tbodycontract champsBody={champsBody} />
          ) : null}
          {pathname.includes('timeSheet') ? (
            <Tbodyhours champsBody={champsBody} />
          ) : null}
          {pathname.includes('company') &&
          type !== 'coeff' &&
          type !== 'companyBonusAndCompensation' ? (
            <Tbodycompany champsBody={champsBody} />
          ) : null}
          {type === 'coeff' ? (
            <Tbodycompanycoeff champsBody={champsBody} />
          ) : null}
          {pathname.includes('rating-treated') ? (
            <TbodyVivierRatingTempWorker champsBody={champsBody} />
          ) : null}
          {pathname.includes('rating-untreated') ? (
            <TbodyVivierRatingTempWorker champsBody={champsBody} />
          ) : null}
          {pathname.includes('qualification') ? (
            <TbodyQualification champsBody={champsBody} />
          ) : null}
          {type === 'companyBonusAndCompensation' && (
            <TbodyCompanyBonusAndCompensation champsBody={champsBody} />
          )}
        </tbody>
      </table>
      {currentPage && totalPages > 1 && setCurrentPage ? (
        <div className={'px-8 py-4'}>
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Table;

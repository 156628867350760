import SidebarUnitDesktop from "../../components/SidebarUnitDesktop";
import {Lightning} from "../../../../assets/icons";

const QualificationDL = ({pathname, tooltip}) => {
    return (
        <>
            <SidebarUnitDesktop routeName={"Qualification"} routeSidebar={"/qualification"}>
                <span className='shrink-0'>
                    <Lightning wh={24} color={`${pathname.includes("qualification") ? '#FF9600' : '#6B7280'}`}/>
                </span>
                <span className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("qualification") && "!text-[#FF9600]"
                }`}>
                    Qualification
                </span>
            </SidebarUnitDesktop>
        </>
    )
}

export default QualificationDL;
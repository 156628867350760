import React from 'react';
import useModal from "components/modal/useModal";
import {Mail} from "assets/icons";
const MessagerieNavMobile = () => {
    const {toggle}=useModal()
    const handleModal=e=>{
        e.preventDefault()
        toggle('msg_c_messagerie')
    }
    return (
        <>
            <div className=" flex flex-row gap-4 items-center p-3 cursor-pointer" onClick={handleModal}>
                    <Mail wh={18} color={"#6B7280"}/>
                <span className="text-sm font-medium text-[#6B7280]">Messagerie</span>
            </div>
        </>
    );
};

export default MessagerieNavMobile;
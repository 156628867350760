import { useState } from 'react';
import { ChevronDown, ChevronTop } from '../../assets/icons';
import ExperienceItem from './components/experienceItem';

const Experiences = ({ experiences }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {experiences
        .slice(0, open ? experiences.length : 3)
        .map((experience, index) => (
          <ExperienceItem key={index} experience={experience} index={index} />
        ))}
      {experiences.length > 3 && (
        <div
          className={'text-yellow-500 underline cursor-pointer'}
          onClick={() => setOpen(!open)}
        >
          <div className={'flex gap-1 items-center'}>
            <div>{open ? 'Voir moins' : 'Voir plus'}</div>
            <div>
              {open ? (
                <ChevronTop wh={20} color={'#FF9600'} />
              ) : (
                <ChevronDown wh={20} color={'#FF9600'} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Experiences;

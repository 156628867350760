export const LangFr = {
    name: 'fr',
    data: {
        "sidebar": {
            'dashboard': 'Tableau de bord',
            'identity_validation': 'Validation d’identité',
            "profil": "Mon profil",
            'search': 'Recherche de missions',
            'file': 'Mes documents',
            'mission': 'Mes missions',
            'help': 'Aide'
        },
        'profil': {
            'profil': 'Mon profil',
            'account': 'Mon compte',
            'knowledge': 'Mon savoir faire',
            'proof': 'Mes justificatifs',
            'availabilities': 'Mes disponibilités',
            'params': 'Paramètres'
        },
        'savoir': {
            "cv": 'Curriculum Vite',
            'exp': 'Expériences',
            'skills': 'Compétences',
            'lang': 'Languages',
            'tools': 'Matériels possédés'
        },
        'proof': {
            'identity': 'Justificatif d’identité',
            'degree': 'Formations',
            'licence': 'Permis'
        },
        'availabilities': {
            'myhours':'Mes horaires',
            'dispo':'Périodes d\'indisponibilité',
            'subtitleH':'Indiquez à quelles tranches horaires vous êtes disponible' +
                ' pour travailler.',
            'subtitleD':'Indiquez les périodes où vous n\'êtes pas du tout disponible.'

        },
        'justify':{
            'm_vitale_title_c':'Ajouter une carte vitale',
            'm_vitale_title_u':'Modifier la carte vitale',
            'm_vitale_title_d':'Supprimer une carte vitale',
            'm_vitale_d_content':'Êtes-vous sûr de vouloir supprimer votre carte' +
                ' vitale ? Cette action est irréversible.',
            'm_iban_title_c':'Ajouter une identité bancaire',
            'm_iban_title_u':'Modifier l\'identité bancaire',
            'm_iban_title_d':'Supprimer une identité bancaire',
            'm_identity_title_c':'Validation d’identité',
            'm_proof_title_c':'Ajouter un justificatif de domicile',
        }

    }
}
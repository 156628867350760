import React, {useContext, useRef, useState} from 'react';

import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {Import} from "assets/icons";

import axios from "axios";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import {toast} from "react-toastify";

const Modalcv = () => {

    const {refreshLoadWorker} = UseContext()
    const {toggle} = useModal()
    const [singleFile, setSingleFile] = useState('')
    const [titleFile, setTitleFile] = useState('')


    const handlerSingleFile = (e) => {
        setSingleFile(e.target.files[0]);
        setTitleFile(e.target.files[0].name)
    }

    const singleUpload = async (data) => {
        try {

            await axios.post(`${process.env.REACT_APP_API_URL}upload/singleFile/CV`, data)
                .then((res) => {
                    if (res.data) {
                        refreshLoadWorker()
                        setTitleFile('')
                        toggle()
                        toast.success('Le document "CV" est ajoutée.')
                    }
                    if (res.errors) {
                        setTitleFile('')
                        toast.error('Votre fichier doit être au format JPG ou PDF')
                    }
                })
                .catch((e) => {
                    setTitleFile('')
                    toast.error('Votre fichier doit être au format JPG ou PDF')
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        if (titleFile !== '') {
            const token = localStorage.getItem('xsrfToken')
            const formData = new FormData();
            formData.append('file', singleFile);
            formData.append('token', token)
            await singleUpload(formData)
        } else {
            toast.error('Veuillez télécharger un fichier')
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Ajouter un CV
            </Modalheader>
            <ModalBody>
                <p className='ft-sm text-gray-500 my-3'>Ajoutez un CV
                    à votre espace
                    pour
                    avoir plus de chance d’être sélectionné à une
                    mission.</p>
                <div className="flex flex-col w-full gap-2">
                    <form id='uploadForm'>
                        <label
                            className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                            <div
                                className='ft-sm text-gray-700 w-1/4 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                                <Import color={'#374151'} wh={14}/>
                                Importer
                            </div>
                            <div
                                className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                                {titleFile ? titleFile : 'Aucun fichier'}
                            </div>

                            <input type="file" id='file'
                                   accept='image/jpeg,image/jpg,application/pdf'
                                   className='hidden'
                                   onChange={(e) => handlerSingleFile(e)}/>
                        </label>

                        <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'}
                        onClick={handleUpload}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Modalcv;
import React from 'react';
import {Help} from "assets/icons";
const HelpNavMobile = () => {
    return (
        <>
            <div className=" flex flex-row gap-4 items-center p-3">
<Help wh={24} color={'#6B7280'}/>

                <span className="text-sm font-medium text-[#6B7280]">Aide</span>

            </div>
        </>
    );
};

export default HelpNavMobile;
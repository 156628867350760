import React, {useContext} from 'react';
import Badge from "components/badge/badge";
import Button from "components/button";
import {Delete} from "assets/icons";
import {pFormation, sBadge, tBadge} from "helper/helper";
import useModal from "components/modal/useModal";
import Divider from "components/divider/divider";
import UseContext from "hooks/useContext";

const CardJustify = ({item, type}) => {
    const {toggle} = useModal()
    const {setObjID,setObjType} = UseContext()
    const handleDelete = (e) => {
        e.preventDefault()
        setObjID(item._id)
        setObjType(type)
        toggle('j_d_delete')
    }
    return (
        <>
            <div className="grid grid-cols-2">
                <div className="col-span-1 flex flex-col gap-3">
                    <div>
                        {item.licence || pFormation(item.certificate)}
                    </div>
                    <div className='w-32'>
                        <Badge
                            type={sBadge(item.validation)}>{tBadge(item.validation)}</Badge>
                    </div>
                </div>
                <div
                    className="col-span-1 flex flex-row items-center justify-end gap-4">
                    <Button size={'SMI'} color={'DEFAULT'} onClick={handleDelete}>
                        <Delete color={'#111827'} wh={15}/>
                    </Button>
                </div>
            </div>
            <Divider w={100}/>
        </>

    );
};

export default CardJustify;
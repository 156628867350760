import {
    Add,
    CardBank,
    CardIdentity,
    CardProofAddress,
    CardVitale,
 Pencil
} from "assets/icons";
import BadgeSquare from "components/badge/badgeSquare";
import React, {forwardRef} from "react";
import CircleStatus from "components/CircleStatus/CircleStatus";

const CardIdentityValidationLine = ({type, status,onClick})=>{
    const text = {
        'identity': 'Validation d\'identité',
        'vital_card': 'Carte vitale',
        'proof_address': 'Justificatif d\'adresse',
        'bank_id': 'Identité bancaire',
    }

    const logo = {
        'identity': <CardIdentity wh={24} color={'#111827'}/>,
        'vital_card': <CardVitale wh={24} color={'#111827'}/>,
        'proof_address': <CardProofAddress wh={24} color={'#111827'}/>,
        'bank_id': <CardBank wh={24} color={'#111827'}/>,
    }
    const iconEdit = {
        '0': <button onClick={onClick} className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'><Add wh={18} color={'#3B82F6'}/></button>,
        '3': <button onClick={onClick} className='rounded-full w-10 h-10 flex items-center justify-center'><Pencil wh={22} color={'#374151'}/></button>,
        '1': <button onClick={onClick} className='rounded-full w-10 h-10 flex items-center justify-center'><Pencil wh={22} color={'#374151'}/></button>,
    }
    return (
        <>
            <div className="col-span-12 bg-white rounded-md py-3 px-4 rounded-lg">
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className='relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                            {logo[type]}
                            <CircleStatus status={status}/>
                        </div>
                        <div className="col-span-3 ft-b text-gray-900 my-auto">
                            <div className={`text-gray-900`}>{text[type]}</div>
                        </div>
                    </div>
                    <div className='col-span-1 flex'>
                        <div className='my-auto mr-2'>
                            <BadgeSquare type={status}/>
                        </div>
                        <div className='my-auto'>
                            {iconEdit[status]}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CardIdentityValidationLine;
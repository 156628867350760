import ApiDatabase from "server";
import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import {toast} from "react-toastify";

const HrFlowJobs = () => {
    const token = localStorage.getItem('xsrfToken')
    const [jobs, setJobs] = useState([])
    const [selectedJob, setSelectedJob] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [jobDescription, setJobDescription] = useState('')
    const [loadingAddUpdateJob, setLoadingAddUpdateJob] = useState(false)
    const [loadingDeleteJob, setLoadingDeleteJob] = useState(false)

    useEffect(() => {
        getJobs()
    }, [])

    const getJobs = () => {

        ApiDatabase.getJobs({token: token},
            (data) => {
                setJobs(data.jobs)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        if(selectedJob === ''){
            setJobTitle('')
            setJobDescription('')
        } else {
            if(jobs.length > 0){
                const job = jobs.find(job => job._id === selectedJob)
                setJobTitle(job.title)
                setJobDescription(job.description)
            }
        }
    }, [selectedJob])

    const addUpdateJob = () => {
        setLoadingAddUpdateJob(true)
        if(jobTitle && jobDescription){
            if (selectedJob === '') {
                ApiDatabase.postJob({token: token, title: jobTitle, description: jobDescription},
                    (data) => {
                        setLoadingAddUpdateJob(false)
                        toast.success('Job ajouté')
                        getJobs()
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            setLoadingAddUpdateJob(false)
                            toast.error('Erreur lors de l\'ajout du job')
                            console.log(err)
                        }
                    })
            } else {
                ApiDatabase.putJob({token: token, jobID: selectedJob, title: jobTitle, description: jobDescription},
                    (data) => {
                        setLoadingAddUpdateJob(false)
                        toast.success('Job modifié')
                        getJobs()
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            setLoadingAddUpdateJob(false)
                            toast.error('Erreur lors de la modification du job')
                            console.log(err)
                        }
                    })
            }
        } else {
            setLoadingAddUpdateJob(false)
            toast.error('Veuillez remplir tous les champs')
        }
    }

    const deleteJob = () => {
        if (selectedJob !== '') {
            setLoadingDeleteJob(true)
            ApiDatabase.patchJob({token: token, jobID: selectedJob},
                (data) => {
                    setLoadingDeleteJob(false)
                    toast.success('Job supprimé')
                    setSelectedJob('')
                    getJobs()
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        setLoadingDeleteJob(false)
                        toast.error('Erreur lors de la suppression du job')
                        console.log(err)
                    }
                })
        }
    }

    return (
        <div className={'flex flex-col gap-5'}>
            <div>
                <label className='ft-sm text-gray-500'>
                    <div>
                        Job
                    </div>
                    <div>
                        <select className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                value={selectedJob}
                                onChange={e => setSelectedJob(e.target.value)}
                        >
                            <option value={''}>Créer un Job</option>
                            {
                                jobs.map((job, index) => {
                                    return (
                                        <option key={index} value={job._id}>{job.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </label>
            </div>
            <div>
                <label className='ft-sm text-gray-500'>
                    <div>
                        Titre
                    </div>
                    <div>
                        <input className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                 value={jobTitle}
                                    onChange={e => setJobTitle(e.target.value)}
                        />
                    </div>
                </label>
            </div>
            <div>
                <label className='ft-sm text-gray-500'>
                    <div>
                        Description
                    </div>
                    <div>
                        <textarea cols="30"
                                  rows="10"
                                  className="w-full pl-3 py-2 border border-gray-300 rounded-md"
                                  required
                                  minLength={3}
                                  wrap="true"
                                  value={jobDescription}
                                  onChange={e => setJobDescription(e.target.value)}
                        />
                    </div>
                </label>
            </div>
            <div>
                <div className={'flex gap-2 justify-end'}>
                    {
                        selectedJob !== '' && (
                            loadingDeleteJob ? (
                                <Button size={'LG'} color={'DISABLED'}>
                                    Supprimer le Job
                                </Button>
                            ) : (
                                <Button size={'LG'} color={'SECONDARY'} onClick={() => {
                                    deleteJob()
                                }}>
                                    Supprimer le Job
                                </Button>
                            )
                        )
                    }
                    {
                        loadingAddUpdateJob ? (
                            <Button size={'LG'} color={'DISABLED'}>
                                {selectedJob !== '' ? `Modifier le Job ${jobTitle}` : 'Créer'}
                            </Button>
                        ) : (
                            <Button size={'LG'} color={'PRIMARY'} onClick={() => {
                                addUpdateJob()
                            }}>
                                {selectedJob !== '' ? `Modifier le Job ${jobTitle}` : 'Créer'}
                            </Button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default HrFlowJobs
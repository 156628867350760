import React from 'react';
import {ChevronRight, ExternalLink, Pencil} from "../../assets/icons";
import BadgeSquare from "../badge/badgeSquare";
import CopyToClipboard from "../copyToClipboard";

const IdentityFileItem = ({
  title,
  isOpen,
  onToggle,
  openDocument,
  id,
  filePathFinal,
  validatedType,
  details
}) => {
  return (
    <div className={'p-2 border rounded h-fit'}>
      <div className={'flex justify-between gap-2 select-none'}>
        <div className={'my-auto flex gap-1'}>
          <div className={'my-auto'}>
            {title}
          </div>
          <>
            {
              (filePathFinal && validatedType !== "0" && validatedType !== 0) && (
                <>
                  <div className={'my-auto cursor-pointer'}
                       onClick={() => openDocument(id, 'view', filePathFinal)}>
                    <ExternalLink wh={18} color={'#235fe6'} />
                  </div>
                  <div className={'my-auto cursor-pointer'}
                       onClick={() => openDocument(id, 'edit', filePathFinal)}>
                    <Pencil wh={18} color={'#235fe6'} />
                  </div>
                </>
              )
            }
          </>
        </div>
        <div className={'my-auto flex gap-2 cursor-pointer'} onClick={onToggle}>
          <div className={'my-auto'}>
            <BadgeSquare type={validatedType} />
          </div>
          {onToggle && (
            <div className={'my-auto'}>
              <ChevronRight wh={20} color={'#374151'} />
            </div>
          )}
        </div>
      </div>
      {isOpen && details && details.length > 0 && (
        <div className={'flex flex-col gap-2 text-sm mt-2'}>
          {details.map((detail, index) => (
            <div className={'flex gap-1'} key={index}>
              <div className={'my-auto font-extrabold'}>{detail.label} :</div>
              <div className={'my-auto'}>
                {detail.value ? (
                  <CopyToClipboard text={detail.value}>
                    <div className={'cursor-pointer'}>{detail.value}</div>
                  </CopyToClipboard>
                ) : 'NaN'}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IdentityFileItem;
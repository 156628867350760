import {dateWordShort} from "../../../../../../../helper/helper";
import {useEffect, useState} from "react";
import {Star} from "../../../../../../../assets/icons";
import Usefetchdata from "../../../../../../../hooks/usefetchdata";
import ApiDatabase from "server";

const TbodyListVivier = ({champsBody, navigateToFicheVivier}) => {
    const {userData} = Usefetchdata()
    const [nbMartinique, setNbMartinique] = useState(0)
    const [nbGuadeloupe, setNbGuadeloupe] = useState(0)
    const [isAdminFavorite, setIsAdminFavorite] = useState(false)
    const token = localStorage.getItem('xsrfToken')
    //get selectedVivier in params
    const params = new URLSearchParams(window.location.search)
    const selectedVivier = params.get('selectedVivier')

    useEffect(() => {
        if (champsBody?.user_preferences) {
            let nbMartinique = 0
            let nbGuadeloupe = 0
            champsBody.user_preferences.map(user => {
                if(user.preferences_department?.department === 'Martinique'){
                    nbMartinique++
                } else if (user.preferences_department?.department === 'Guadeloupe'){
                    nbGuadeloupe++
                }
            })
            setNbMartinique(nbMartinique)
            setNbGuadeloupe(nbGuadeloupe)
        }
    }, [champsBody])

    useEffect(() => {
        if (userData?._id && champsBody) {
            if (champsBody.admin_favorites?.length > 0) {
                for (let i = 0; i < champsBody.admin_favorites.length; i++) {
                    if (champsBody.admin_favorites[i].id_user === userData._id) {
                        setIsAdminFavorite(true)
                        break
                    }
                }
            }
        }
    }, [userData, champsBody])

    const removeAdminVivierFavorite = (idVivier) => {
        ApiDatabase.deleteAdminVivierFavorite({token, idVivier}, (data) => {
                setIsAdminFavorite(false)
            },
            (err) => {
                console.warn(err)
            })
    }

    const addAdminVivierFavorite = (idVivier) => {
        ApiDatabase.postAdminVivierFavorite({token, idVivier}, (data) => {
                setIsAdminFavorite(true)
            },
            (err) => {
                console.warn(err)
            })
    }

    useEffect(() => {
        //scroll to specific div
        if (selectedVivier) {
            const elmnt = document.getElementById(selectedVivier);
            if (elmnt) elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }, [selectedVivier]);

    return (
        <>
            <tr id={champsBody._id} key={champsBody} className={'border-b-2 border-gray-100 h-20 '}>
                <td className='py-3.5 px-4 sticky left-0 bg-white'>
                    <div className={'my-auto cursor-pointer'} onClick={() => navigateToFicheVivier(champsBody._id)}>
                        <p className="text-sm text-gray-500">
                            {champsBody.name}
                        </p>
                    </div>
                </td>
                <td className='py-3.5 px-4 bg-white'>
                    <p className="text-sm text-gray-500">
                        {champsBody.user_preferences.length}
                    </p>
                </td>
                <td className='py-3.5 px-4  bg-white'>
                    <p className="text-sm text-gray-500">
                        {nbMartinique}
                    </p>
                </td>
                <td className='py-3.5 px-4  bg-white'>
                    <p className="text-sm text-gray-500">
                        {nbGuadeloupe}
                    </p>
                </td>
                <td className='py-3.5 px-4 bg-white'>
                    <p className="text-sm text-gray-500">
                        {dateWordShort(champsBody.createdAt)}
                    </p>
                </td>
                <td className='py-3.5 px-4 bg-white'>
                    <p className="text-sm text-gray-500">
                        {dateWordShort(champsBody.updatedAt)}
                    </p>
                </td>
                <td className='py-3.5 px-4 bg-white'>
                    {champsBody._id !== '64e8cfd10b4f73f442432c9f' && (
                        <>
                            {isAdminFavorite ? (
                                <div className={'cursor-pointer w-8 h-8 flex justify-center items-center'} onClick={() => removeAdminVivierFavorite(champsBody._id)}>
                                    <Star wh={20} color={'#FF9600'}/>
                                </div>
                            ) : (
                                <div className={'cursor-pointer w-8 h-8 flex justify-center items-center'} onClick={() => addAdminVivierFavorite(champsBody._id)}>
                                    <Star wh={20} color={'#F3F4F6'}/>
                                </div>
                            )}
                        </>
                    )}
                </td>
            </tr>
        </>
    )
}

export default TbodyListVivier
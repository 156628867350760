import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import React from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";

const ConfirmSchedule = () => {
    const {toggle}=useModal()
    const {setData, data, handleNext} = UseContext()

    const handleSubmit = (e) => {
        e.preventDefault()
        data.isConfirmedSchedule = true
        handleNext()
        toggle()
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Confirmer la sélection
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={'flex flex-col'}>
                    <div className={'ft-sm text-gray-500'}>
                        Les jours non remplis seront indiquées comme des journées aux horaires non renseignées pour l'intérimaire, êtes-vous sûr de vouloir continuer ?
                    </div>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={'LG'} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );
}

export default ConfirmSchedule;

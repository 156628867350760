import React from 'react';
import Table from "components/table/table";
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";


const Nosign = () => {
    const {tempWorkerData}=usefetchdata()
    const champsHeader=['N° de contrat','Entreprise','MISSION','N° De mission','ÉTAT','DATE']
    return (
        <>
            {tempWorkerData?.jobs?.length >0?
                <Table  champsHeader={champsHeader}  status={0}/>
                :
                <EmptyState type={'contract'}/>
            }
        </>
    );
};

export default Nosign;
import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import useModal from "components/modal/useModal";
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";

const Modaltoolsdeleted = () => {
    const {refreshLoad,objID}= UseContext()
    const {toggle}=useModal()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = (e) => {
        e.preventDefault()
        ApiDatabase.postUserToolDelete({token: token, tool: objID},
            (data) => {
                refreshLoad()
                toggle()
                toast.success(`Le matériel ${objID}est supprimé.`)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })

    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>Supprimer un matériel
                    possédé</Modalheader>
                <ModalBody>
                        Êtes-vous sûr de vouloir supprimer {objID} ? Cette action est irréversible.
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'}
                            onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                </ModalFooter>
        </>
    );
};

export default Modaltoolsdeleted;
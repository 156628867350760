import PageForm from "../../../components/pageForm/PageForm";
import HeaderQualification from "./components/header/header.qualification";
import BodyQualification from "./components/content/body.qualification";

const Qualification = () => {
    return (
        <>
            <PageForm>
                <HeaderQualification/>
                <BodyQualification/>
            </PageForm>
        </>
    )
}

export default Qualification;
import React from 'react'
import PageForm from 'components/pageForm/PageForm.js'
import HeaderVivier from './components/header/header.vivier.js'
import BodyFicheVivier from './components/content/body.fiche.vivier.js'

function FicheVivier() {
  return (
    <>
    <PageForm>
        <div className={'col-span-12 md:p-8 h-full space-y-4 bg-white'}>
          <BodyFicheVivier/>
        </div>
    </PageForm>
</>
  )
}

export default FicheVivier
import React, { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Table from "components/table/table";
import EmptyState from "../../../components/EmptyState/EmptyState";

const LitigationContent = () => {
    const {
        timeSheetsToDisplay,
        currentPage,
        setCurrentPage,
        totalPages,
        countPerPage,
        countTotal,
        isWaiting,
        setStatus
    } = useOutletContext();

    useEffect(() => {
        setStatus('2');
    }, [setStatus]);

    const titles = ['N° DE RELEVÉ', 'RAISON SOCIALE', 'POSTE', 'N° DE MISSION', 'INTÉRIMAIRE', 'PÉRIODE'];

    return (
        <div className={'flex flex-col gap-8'}>
            {
                isWaiting ? (
                    <>
                        <table className="animate-pulse w-full animate-fadeIn">
                            <tbody>
                            {
                                [1, 2, 3, 4, 5].map((i, index)=>(
                                    <tr key={index}>
                                        {
                                            titles?.map((title, index) => (
                                                <td key={index} className={'px-2 py-7'}>
                                                    <div className="h-2 bg-gray-200 rounded"></div>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </>
                ) : (
                    <>
                        {timeSheetsToDisplay?.length > 0 ?
                            <Table champsBody={timeSheetsToDisplay} champsHeader={titles} currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                            :
                            <EmptyState type={'time_sheet_check'} />
                        }
                    </>
                )
            }
        </div>
    );
};

export default LitigationContent;
import React, {useEffect, useState} from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import {dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import {useNavigate, useLocation} from "react-router-dom";
import useSlideOver from "../../SlideOver/useSlideOver";


const Tbodyhours = ({champsBody}) => {
    const {toggleSlideOver} = useSlideOver();
    const {setObjID, roleUser, setContent} = UseContext();
    const {toggle} = UseModal();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedId, setSelectedId] = useState(null);

    const rUser = localStorage.getItem('rUser');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        if (id) {
            setSelectedId(id);
        }
    }, [location]);

    const handleModal=(e,i,item)=>{
        e.preventDefault();
        setObjID(i);
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        });

        const currentUrl = window.location.pathname;
        const params = new URLSearchParams(window.location.search);
        params.set('id', i);
        window.history.replaceState({}, '', `${currentUrl}?${params.toString()}`);

        navigate(`../ficheHour/${i}`);
    };

    const handleModalConsult=(e,i,item)=>{
        e.preventDefault();
        setObjID(i);
        setContent({
            title: item.title,
            idMission: item.id_mission_reel,
            nameCompany: item.name_commercial
        });

        const currentUrl = window.location.pathname;
        const params = new URLSearchParams(window.location.search);
        params.set('id', i);
        window.history.replaceState({}, '', `${currentUrl}?${params.toString()}`);

        navigate(`../ficheHour/${i}`);
    };

    const openTempWorkerInfo = (id) => {
        setObjID(id);
        toggleSlideOver('get_temp_worker');
    };

    return (
      <>
          {champsBody?.map((item, key) => {
              const rowClass = selectedId === item._id ? 'bg-yellow-50' : 'hover:bg-gray-50';

              return (
                <tr key={key} className={`border-b border-gray-100 ${rowClass}`}>
                    <td className={'py-5 px-2'}>{item.id_time_sheet}</td>
                    <td className={'py-5 px-2'}>{item.name_commercial}</td>
                    <td className={'py-5 px-2'}>{item.title}</td>
                    <td className={'py-5 px-2'}>{item.id_mission_reel}</td>
                    <td className={`py-5 px-2 cursor-pointer ${rowClass}`} onClick={() => openTempWorkerInfo(item.id_user)}>
                        {item.firstname} {item.lastname}
                    </td>
                    <td className={'py-5 px-2'}>{dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}</td>
                    {item.validated !== 0 && (
                      <td className={'py-5 px-2 flex items-center justify-center cursor-pointer'}>
                          {
                              item.validated === 1 || item.validated === 2 ?
                                <span onClick={(e) => {handleModal(e,item._id, item)}}>
                                        <Signer wh={24}/>
                                    </span>
                                :
                                <span onClick={(e) => {handleModalConsult(e,item._id, item)}}>
                                        <DocumentIcon wh={24} color={'#111827'}/>
                                    </span>
                          }
                      </td>
                    )}
                </tr>
              );
          })}
      </>
    );
};

export default Tbodyhours;
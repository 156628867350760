import React, {useState} from 'react';
import Divider from "components/divider/divider";
import {
    Morning,
    Night,
    Afternoon,
    Evening,
} from "assets/icons"
import UseContext from "hooks/useContext";

const Myhours = () => {
    const {language} = UseContext()
    const semaine=['Lun','Mar','Mer','Jeu','Ven','Sam','Dim']
    const [test,setTest]=useState({
        l:{
            m:false,
            a:false,
            s:false,
            n:false
        }
    })

    return (
        <div className='col-span-full bg-white rounded py-6'>
            <div className='px-2'>
                <h1 className='ft-lg text-gray-700'>{language.getValue('availabilities.myhours')}</h1>
                <p className='ft-sm text-gray-500'>{language.getValue('availabilities.subtitleH')}</p>

            </div>
            <Divider w={100}/>
            <div className=' grid grid-cols-2  mt-10 gap-8 '>
                <div className='col-span-full tab:col-span-1 grid grid-cols-2 order-last tab:order-none flex items-center justify-center gap-4'>
                    <div className=' col-span-2 flex items-center justify-end gap-2 px-2 '>
                        <div className={test ?'text-green-500':'text-red-500'} ><Night wh={24}/></div>
                        <div className={test ?'text-green-500':'text-red-500'} ><Morning wh={24}/> </div>
                        <div className={test ?'text-green-500':'text-red-500'} ><Afternoon wh={24}/> </div>
                        <div className={test ?'text-green-500':'text-red-500'} ><Evening wh={24}/> </div>

                    </div>
                    {semaine.map(item=>
                    <div className=" col-span-2  px-2 flex  items-center justify-between">
                        <div className='ft-b text-gray-900'>{item}</div>
                        <div className='flex gap-2'>
                            <div className={test ?'text-green-500':'text-red-500'} ><Night wh={24}/></div>
                            <div className={test ?'text-green-500':'text-red-500'} ><Morning wh={24}/> </div>
                            <div className={test ?'text-green-500':'text-red-500'} ><Afternoon wh={24}/> </div>
                            <div className={test ?'text-green-500':'text-red-500'} ><Evening wh={24}/> </div>
                        </div>
                    </div>
                    )}
                </div>
                <div className='col-span-full tab:col-span-1 flex flex-wrap items-center justify-center gap-2 '>
                    <div
                        className='flex flex-col items-center justify-center gap-2 h-24 w-32 tab:w-40 border border-gray-300 rounded'>
                        <Morning wh={24}/>
                        <p className='ft-sm text-gray-700'>06h - 12h</p>
                    </div>
                    <div
                        className='flex flex-col items-center justify-center gap-2 h-24 w-32 tab:w-40 border border-gray-300 rounded'>
                        <Afternoon wh={24}/>
                        <p className='ft-sm text-gray-700'>12h - 18h</p>
                    </div>
                    <div
                        className='flex flex-col items-center justify-center gap-2 h-24 w-32 tab:w-40 border border-gray-300 rounded'>
                        <Evening wh={24}/>
                        <p className='ft-sm text-gray-700'>18h - 00h</p>
                    </div>
                    <div
                        className='flex flex-col items-center justify-center gap-2 h-24 w-32 tab:w-40 border border-gray-300 rounded'>
                        <Night wh={24}/>
                        <p className='ft-sm text-gray-700'>00h - 06h</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Myhours;
import SidebarUnitDesktop from "../../components/SidebarUnitDesktop";
import {Building} from "../../../../assets/icons";

const CompanyDL = ({pathname, tooltip}) => {
    return (
        <SidebarUnitDesktop routeName={"company"} routeSidebar={"/company"}>
            <span className='shrink-0'>
                <Building wh={24} color={`${pathname.includes("company") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("company") && "!text-[#FF9600]"
                }`}
            >
          {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                Entreprise
        </span>
        </SidebarUnitDesktop>
    )
}

export default CompanyDL;
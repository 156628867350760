import { useEffect, useState } from 'react';
import ApiDatabase from 'server';
import { ChevronDown, ChevronRight, Vivier } from '../../../../assets/icons';
import UseContext from '../../../../hooks/useContext';
import Usefetchdata from '../../../../hooks/usefetchdata';
import Badge from '../../../badge/badge';
import SidebarUnitDesktop from '../../components/SidebarUnitDesktop';

const VivierTaleez = ({ pathname, tooltip }) => {
  const { refreshUntreated } = UseContext();
  const { userData } = Usefetchdata();
  const [vivierExpanded, setVivierExpanded] = useState(
    localStorage.getItem('vivier-expanded') === 'true'
  );
  const [nbRatingUntreatedTempWorkers, setNbRatingUntreatedTempWorkers] =
    useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const token = localStorage.getItem('xsrfToken');

  useEffect(() => {
    localStorage.setItem('vivier-expanded', vivierExpanded);
  }, [vivierExpanded]);

  useEffect(() => {
    setIsWaiting(true);
    ApiDatabase.getNbRatingUntreatedTempWorkers(
      { token },
      (data) => {
        setNbRatingUntreatedTempWorkers(data.nbReviews);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
          setIsWaiting(false);
        }
      }
    );
  }, [refreshUntreated]);

  return (
    <>
      <SidebarUnitDesktop routeName={'vivier'} routeSidebar={'/vivier'}>
        <span className="shrink-0">
          <Vivier
            wh={24}
            color={`${pathname.includes('vivier') ? '#FF9600' : '#6B7280'}`}
          />
        </span>
        <span
          className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
            pathname.includes('vivier') && '!text-[#FF9600]'
          }`}
        >
          Viviers
        </span>
        <div className={'ml-auto'}>
          <div onClick={() => setVivierExpanded(!vivierExpanded)}>
            {vivierExpanded ? (
              <ChevronDown
                wh={20}
                color={`${pathname.includes('vivier') ? '#FF9600' : '#6B7280'}`}
              />
            ) : (
              <ChevronRight
                wh={20}
                color={`${pathname.includes('vivier') ? '#FF9600' : '#6B7280'}`}
              />
            )}
          </div>
        </div>
      </SidebarUnitDesktop>
      {vivierExpanded && (
        <SidebarUnitDesktop
          routeName={'Avis intérimaires'}
          routeSidebar={'/vivier-rating-temp-worker/rating-untreated'}
        >
          <div
            className={`text-sm ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
              pathname.includes('vivier-rating-temp-worker') &&
              '!text-[#FF9600]'
            }`}
          >
            Avis intérimaires
          </div>
          <div className={'ml-auto'}>
            {isWaiting ? (
              <div className="w-6 h-6 bg-gray-200 rounded"></div>
            ) : (
              <Badge color={'SECONDARY'}>
                <div
                  className={`${
                    pathname.includes('vivier-rating-temp-worker') &&
                    '!text-[#FF9600]'
                  }`}
                >
                  {nbRatingUntreatedTempWorkers}
                </div>
              </Badge>
            )}
          </div>
        </SidebarUnitDesktop>
      )}
    </>
  );
};

export default VivierTaleez;

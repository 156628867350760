import React, {useState} from 'react';
import {Localisation, Metier} from "assets/icons";
import Multirangeslider from "../multirangeslider/multirangeslider";

const Filtersearchmission = ({
                                 job, jobV,
                                 location, locationV,
                                 secteur,
                                 secteurV,
                                 salaryMin,
                                 salaryMinV,
                                 salaryMax,
                                 salaryMaxV
                             }) => {

    return (
        <>
            <div
                className='flex flex-col tab:mt-10 mx-3 w-full  sticky absolute top-28 gap-6'>
                <div className='ft-lg text-gray-900'>
                    Filtrer par :
                </div>
                <div className='flex items-center mx-2'>
                    <form>
                        <div className="relative text-gray-500 ">
                            <input
                                name="email"
                                value={jobV}
                                onChange={(e) => {
                                    job(e.target.value)
                                }}
                                className="w-48 h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                                type="text"
                                placeholder='Métier'
                                required
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Metier wh={18} color={'#666666'} strokeW={2}/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='flex items-center mx-2'>
                    <form>
                        <div className="relative text-gray-500 ">
                            <input
                                name="localisation"
                                value={locationV}
                                onChange={(e) => {
                                    location(e.target.value)
                                }}
                                className="w-48 h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                                type="text"
                                placeholder='Lieu de travail'
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Localisation wh={18} color={'#666666'} strokeW={2}/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='flex items-center mx-2'>
                    <form>
                        <div className="relative text-gray-500 ">
                            <select name="" id=""
                                    value={secteurV}
                                    onChange={(e) => {
                                        secteur(e.target.value)
                                    }}
                                    className='w-48 h-10 pl-3 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md '>
                                <option value="">Secteur</option>
                                <option value="Logistique">Logistique</option>
                                <option value="Vente">Vente</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div className='flex mx-2 flex-col gap-2'>
                    <p className='ft-sm text-gray-900 text-left'>Taux horaire</p>
                    <span className='ft-xs text-gray-500'>0€ - 20€</span>
                    <div className='h-16 flex items-center '>
                        <Multirangeslider min={0} max={20} onChange={({
                                                                                           min,
                                                                                           max
                                                                                       }) => {
                            salaryMin(min)
                            salaryMax(max)
                        }}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filtersearchmission;
import React from 'react';
import Button from "components/button";

const Footerbutton = ({formIndex,oC1,oC2,oC3,oC4}) => {
    const elements=[
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        <Button size={'LG'} onClick={oC2}>Confirmer</Button>,
        <Button size={'LG'} onClick={oC3}>Modifier P</Button>,
        <Button size={'LG'} onClick={oC4}>Modifier A</Button>
    ]
    return (
        <>
            {elements.map((item, index) => {
                if((index + 1) === formIndex){
                    return elements[index]
                }
            })}
        </>
    );
};

export default Footerbutton;
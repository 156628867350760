import React from 'react'
import { NavLink } from 'react-router-dom'

function CardVivier({vivier}) {

  // const secteur = (sectVivier)=>{
  //   switch (sectVivier) {
  //     case "Martinique":
  //        return "MQ"
  //     case "Guadeloupe":
  //       return "GP"
  //     default:
  //       break;
  //   }
  // }

  return (
    <NavLink to={`../ficheVivier/${vivier._id}`}>
      <div className='flex flex-col items-start p-3 border border-gray-100 gap-3 rounded h-20 w-56'>
          <div className="w-full truncate text-lg text-gray-900 ">
                  {vivier.name}
          </div>
          <div className='flex items-center justify-between w-full'> 
            <div className=" text-sm text-gray-700">
                {vivier.user_preferences?.length ?? 0}
            </div>
            {/* <div className=" text-sm text-gray-700">
                {secteur(vivier.secteur)}
            </div> */}
          </div>

      </div>
    </NavLink>
  )
}

export default CardVivier
import {useEffect, useState} from "react";
import Divider from "../../../../divider/divider";
import ApiDatabase from "server";
import {MissionIcon} from "../../../../../assets/icons";
import {dateExp} from "../../../../../helper/helper";

const HistoryTempWorker = ({idUser}) => {
    const [missions, setMissions] = useState([])
    const [groupedMissions, setGroupedMissions] = useState({
        past: [],
        current: [],
        future: [],
    })
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if(token && idUser) {
            ApiDatabase.getUserMissions({token, idUser}, (data) => {
                setMissions(data)
            }, (err) => {
                console.warn(err)
            })
        }
    }, [idUser, token]);

    useEffect(() => {
        //make 3 groups : past, current, future missions
        const pastMissions = []
        const currentMissions = []
        const futureMissions = []
        const now = new Date()
        for (let mission of missions) {
            const dateStart = new Date(mission.duration.datestart)
            const dateEnd = new Date(mission.duration.dateend)
            if (dateEnd < now) {
                pastMissions.push(mission)
            } else if (dateStart < now && dateEnd > now) {
                currentMissions.push(mission)
            } else {
                futureMissions.push(mission)
            }
        }
        setGroupedMissions({
            past: pastMissions,
            current: currentMissions,
            future: futureMissions,
        })
    }, [missions]);

    return (
        <div className={'border rounded p-3 flex flex-col gap-6'}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex justify-between gap-2 items-center'}>
                    <div>Historique de missions</div>
                    <div className={'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'}>
                        {missions.length}
                    </div>
                </div>
                {missions.length > 0 && (
                    <div className={'flex flex-col gap-4 text-sm'}>
                        {groupedMissions.current.map((mission, index) => (
                            <div key={index} className={'flex justify-between gap-2'}>
                                <div className={'flex gap-2 items-center'}>
                                    <div className={'bg-green-50 w-10 h-10 flex justify-center items-center rounded'}>
                                        <MissionIcon wh={20} color={'#059669'}/>
                                    </div>
                                    <div className={'flex flex-col'}>
                                        <div>{mission.title} n°{mission.id_mission}</div>
                                        <div className={'text-gray-500'}>{mission.name_commercial} · {mission.address?.city}</div>
                                    </div>
                                </div>
                                <div className={'text-gray-500'}>
                                    {dateExp(new Date(mission.duration.datestart))} > {dateExp(new Date(mission.duration.dateend))}
                                </div>
                            </div>
                        ))}
                        {(groupedMissions.current.length > 0 && (groupedMissions.past.length > 0 || groupedMissions.future.length > 0)) && (
                            <Divider/>
                        )}
                        {groupedMissions.future.map((mission, index) => (
                            <div key={index} className={'flex justify-between gap-2'}>
                                <div className={'flex gap-2 items-center'}>
                                    <div className={'bg-gray-100 w-10 h-10 flex justify-center items-center rounded'}>
                                        <MissionIcon wh={20} color={'#6B7280'}/>
                                    </div>
                                    <div className={'flex flex-col'}>
                                        <div>{mission.title} n°{mission.id_mission}</div>
                                        <div className={'text-gray-500'}>{mission.name_commercial} · {mission.address?.city}</div>
                                    </div>
                                </div>
                                <div className={'text-gray-500'}>
                                    {dateExp(new Date(mission.duration.datestart))} > {dateExp(new Date(mission.duration.dateend))}
                                </div>
                            </div>
                        ))}
                        {groupedMissions.past.map((mission, index) => (
                            <div key={index} className={'flex justify-between gap-2'}>
                                <div className={'flex gap-2 items-center'}>
                                    <div className={'bg-red-100 w-10 h-10 flex justify-center items-center rounded'}>
                                        <MissionIcon wh={20} color={'#DC2626'}/>
                                    </div>
                                    <div className={'flex flex-col'}>
                                        <div>{mission.title} n°{mission.id_mission}</div>
                                        <div className={'text-gray-500'}>{mission.name_commercial} · {mission.address?.city}</div>
                                    </div>
                                </div>
                                <div className={'text-gray-500'}>
                                    {(() => {
                                        const dateStart = new Date(mission.duration.datestart)
                                        const dateEnd = new Date(mission.duration.dateend)
                                        if (dateStart.getMonth() === dateEnd.getMonth() && dateStart.getFullYear() === dateEnd.getFullYear()) {
                                            return dateExp(dateStart)
                                        } else {
                                            return dateExp(dateStart) + ' > ' + dateExp(dateEnd)
                                        }
                                    })()}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HistoryTempWorker;

import React from 'react';

const Badge = ({ type, children }) => {
    const TYPE_COLOR = {
        'PRIMARY': 'bg-gray-100 text-gray-800',
        'SECONDARY': 'border border-gray-200 rounded bg-gray-50 text-gray-700',
        'ALERT': 'bg-red-100 text-red-800',
        'WARNING': 'bg-yellow-100 text-yellow-800',
        'VALID': 'bg-green-100 text-green-800',
        'APP': 'bg-[#FAF5FF] text-[#9333EA]',
        'WEB': 'bg-yellow-50 text-yellow-600',
        'INDEED': 'bg-blue-50 text-blue-600'
    }

    const tag_color = TYPE_COLOR[type || 'PRIMARY']
    return (
        <>
            <div className={`h-6 px-2 ft-sm rounded-md flex flex-row items-center justify-center gap-4 ${tag_color}`}>
                {children}
            </div>
        </>
    );
};

export default Badge;
import React from 'react';
import {Add} from "assets/icons";
import Divider from "components/divider/divider";
import CardJustify from "components/card/cardjustify";
import useModal from "components/modal/useModal";
import usefetchdata from "hooks/usefetchdata";
import CardProfile from "components/card/cardProfile/cardProfile";


const Formations = () => {
    const {toggle} = useModal()
    const {tempWorkerData} = usefetchdata()

    const handleFormation = (e) => {
        e.preventDefault()
        toggle('j_c_formation')
    }
    return (
        <>
            <CardProfile>
                <div className="grid grid-cols-4 flex items-center ">
                    <div className="col-span-3 ft-b text-gray-900">
                        Formations
                    </div>
                    <div className='col-span-1 flex justify-end'>
                        <button
                            onClick={handleFormation}
                            className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'>
                            <Add wh={18} color={'#3B82F6'}/>
                        </button>
                    </div>
                </div>
                <Divider w={100}/>
                {tempWorkerData?.formations?.map(item =>
                    <CardJustify type={'training'} item={item} key={item._id}/>
                )
                }
            </CardProfile>

        </>
    );
};

export default Formations;
import React from 'react';
import NotificationNavDesktop from "./NavbarComponents/NotificationNavDesktop";
import LogoutNavDesktop from "./NavbarComponents/LogoutNavDesktop";
import MessagerieNavDesktop from "./NavbarComponents/MessagerieNavDesktop";

const NavbarDesktop = () => {
    return (
        <>
            <NotificationNavDesktop/>
            <MessagerieNavDesktop/>
            <LogoutNavDesktop/>
        </>
    );
};

export default NavbarDesktop;
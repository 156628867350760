import {
  ArrowLeft,
  Camion,
  ChevronDown,
  ChevronTop,
  Drapeau,
  Localisation,
  Warning,
} from 'assets/icons';
import AvatarCompagny from 'components/avatar/avatarcompany';
import Bannertitle from 'components/banner/bannertitle';
import Button from 'components/button';
import Cardbody from 'components/card/components/cardbody';
import Cardfooter from 'components/card/components/cardfooter';
import Cardheader from 'components/card/components/cardheader';
import Chip from 'components/chip/chip';
import Divider from 'components/divider/divider';
import PageForm from 'components/pageForm/PageForm';
import { statutMission } from 'helper/helper';
import usefetchdata from 'hooks/usefetchdata';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiDatabase from 'server';

const Missionfiche = () => {
  const navigate = useNavigate();
  const { userData } = usefetchdata();
  const [infoMission, setInfoMission] = useState({});
  const [candidate, setCandidate] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState('');
  const [showTools, setShowTools] = useState(false);
  const [visibilityTools, setVisibilityTools] = useState(3);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const route = useLocation();
  const { pathname } = route;
  const status = statutMission(infoMission?.duration);

  useEffect(() => {
    ApiDatabase.getInfoMission(
      { id },
      (data) => {
        setInfoMission(data ?? {});
        setRefresh(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
        }
      }
    );
  }, [id, refresh]);

  useEffect(() => {
    const token = localStorage.getItem('xsrfToken');
    ApiDatabase.postVerifyUser(
      { mission: id, token: token },
      (data) => {
        if (data.id) {
          const result = !!infoMission[0].users.find(
            (item) => item.user_id === data.id
          );
          const idUser = infoMission[0].users.find(
            (item) => item.user_id === data.id
          );
          setDeleteCandidate(idUser?._id);
          setCandidate(result);
        }
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
        }
      }
    );
  }, [refresh]);

  const handleShowExp = () => {
    setShowTools(!showTools);
    if (showTools) {
      setVisibilityTools((prevValue) => prevValue - 3);
    } else {
      setVisibilityTools((prevValue) => prevValue + 3);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('xsrfToken');
    ApiDatabase.postCandidate(
      { mission: id, token: token },
      (data) => {
        setRefresh(!refresh);
        setCandidate(false);
        toast.success(`Votre candidature a bien été prise en compre`);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
        }
      }
    );
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    ApiDatabase.deleteUserMission(
      {
        mission: id,
        missionUser: deleteCandidate,
      },
      (data) => {
        setRefresh(!refresh);
        setCandidate(true);
        // setCandidate(false)
        toast.success(`Votre candidature a bien été supprimée`);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
        }
      }
    );
  };

  return (
    <PageForm>
      <div className="col-span-12  bg-white sticky z-10 top-16 ">
        <div className="flex items-center justify-between h-14 px-4">
          <div className="flex items-center gap-2 cursor-pointer">
            <span onClick={(e) => navigate('/searchMission')}>
              <ArrowLeft w={15} h={12} color={'#111827'} />
            </span>
            {infoMission[0]?.title}
          </div>
          {userData?.validation === 2 ? (
            <div className="flex items-center justify-center gap-4">
              {candidate ? (
                <Button size={'SM'} onClick={handleSubmitDelete}>
                  Annuler la candidature
                </Button>
              ) : (
                <Button size={'SM'} onClick={handleSubmit}>
                  Postuler
                </Button>
              )}
              {/*<Print wh={20}/>*/}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {userData?.validation === 1 ? (
        <div className="flex mt-10 justify-center col-span-full md:col-start-3 md:col-end-11">
          <Bannertitle type={'alert'} title={'Profil non validé'}>
            Validez votre identité pour postuler à une de ces offres.
          </Bannertitle>
        </div>
      ) : (
        ''
      )}
      <div className="flex mt-20 mb-32 justify-center col-span-full md:col-start-3 md:col-end-11">
        <div className="block rounded-lg shadow-lg bg-white w-full text-center">
          <Cardheader>
            <div className=" grid grid-cols-2 tab:grid-cols-4 flex gap-2">
              <div className="col-span-1 flex items-center justify-start md:justify-center">
                <AvatarCompagny size={'LG'} />
              </div>
              <div className="col-span-2 order-last tab:order-none  ">
                <div className="grid  grid-col-3 text-left">
                  <div className="col-span-1 ft-sm text-gray-500">
                    {new Date(
                      infoMission[0]?.duration?.datestart
                    ).toLocaleString('FR-fr', {
                      weekday: undefined,
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })}{' '}
                    -{' '}
                    {new Date(infoMission[0]?.duration?.dateend).toLocaleString(
                      'FR-fr',
                      {
                        weekday: undefined,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }
                    )}
                  </div>
                  <div className="col-span-1 ft-2xl font-medium text-gray-700 ">
                    {infoMission[0]?.title}
                  </div>
                  <div className="col-span-1 ft-xs text-gray-500">
                    {infoMission[0]?.infoCompany[0]?.name_commercial} ·{' '}
                    {infoMission[0]?.activity} ·
                    {infoMission[0]?.address[0]?.city} ,{' '}
                    {infoMission[0]?.address[0]?.department?.split(',')[1]}
                  </div>
                </div>
              </div>
              {pathname.includes('mission') ? (
                <div className="col-span-1 flex justify-end">
                  <Chip color={status?.color}>{status?.text}</Chip>
                </div>
              ) : (
                ''
              )}
            </div>
          </Cardheader>

          <Cardbody>
            <Divider w={100} />
            <div className="grid grid-cols-1 tab:grid-cols-2 text-left ">
              <div className="col-span-1 tab:col-span-2 ">
                <h5 className="text-gray-900 ft-b font-medium ">Description</h5>
                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                  {infoMission[0]?.description ?? ''}
                </p>
              </div>
              <div className="col-span-1 ">
                <h6 className="text-gray-900 ft-b font-medium ">Période</h6>
                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                  {new Date(infoMission[0]?.duration?.datestart).toLocaleString(
                    'FR-fr',
                    {
                      weekday: undefined,
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    }
                  )}{' '}
                  -{' '}
                  {new Date(infoMission[0]?.duration?.dateend).toLocaleString(
                    'FR-fr',
                    {
                      weekday: undefined,
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    }
                  )}
                </p>
              </div>
              <div className="col-span-1">
                <h6 className="text-gray-900 ft-b font-medium ">
                  Salaire horaire brut
                </h6>
                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                  {infoMission[0]?.salary?.wage_per_hour} €
                </p>
              </div>
            </div>
            <Divider w={100} />
            <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
              <div className="col-span-1 tab:col-span-3">
                <h5 className="ft-xl text-gray-700">Pré-requis</h5>
              </div>
              <div className="col-span-1 my-2">
                <div className="flex flex-nowrap items-center">
                  <Drapeau wh={18} color={'#111827'} />
                  <h6 className="ft-xl text-gray-900 ml-2">Langues</h6>
                </div>
                <div className="grid grid-cols-1">
                  {infoMission[0]?.conditions?.languages
                    ? infoMission[0]?.conditions.languages.map((item) => (
                        <div className="col-span-1 ft-sm text-gray-800">
                          {item}
                        </div>
                      ))
                    : ''}
                </div>
              </div>
              <div className="col-span-1 my-2">
                <div className="flex flex-nowrap items-center">
                  <Warning wh={18} color={'#111827'} />
                  <h6 className="text-xl text-gray-900 ml-2">Matériels</h6>
                </div>
                <div className="grid grid-cols-1 flex gap-3">
                  {infoMission[0]?.conditions &&
                  Array.isArray(infoMission[0]?.conditions.tools)
                    ? infoMission[0]?.conditions.tools
                        .slice(0, visibilityTools)
                        .map((item) => (
                          <div className="col-span-1 ft-sm text-gray-800">
                            {item}
                          </div>
                        ))
                    : ''}
                  {infoMission[0]?.conditions?.tools.length < 3 ? (
                    ''
                  ) : (
                    <div
                      className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3"
                      onClick={handleShowExp}
                    >
                      {showTools ? (
                        <ChevronTop wh={20} color={'#FF9600'} />
                      ) : (
                        <ChevronDown wh={20} color={'#FF9600'} />
                      )}
                      En savoir
                      {showTools ? ' moins' : ' plus'}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-1 my-2">
                <div className="flex flex-nowrap items-center">
                  <Camion w={18} h={14} color={'#111827'} />
                  <h6 className="ft-xl text-gray-900 ml-2">Permis</h6>
                </div>
                <div className="grid grid-cols-1">
                  {infoMission[0]?.conditions?.licence
                    ? infoMission[0]?.conditions.licence.map((item) => (
                        <div className="col-span-1 ft-sm text-gray-800">
                          {item}
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
            <Divider w={100} />
            <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
              <div className="col-span-2 ">
                <h5 className="ft-xl text-gray-900">Lieu de travail</h5>
              </div>
              <div className="col-span-1">
                <h6 className="ft-xl text-gray-900 ml-2">
                  {infoMission[0]?.infoCompany[0]?.name_commercial}
                </h6>
                <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                  {infoMission[0]?.address[0]?.street}
                </p>
                <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                  {infoMission[0]?.address[0]?.city},{' '}
                  {infoMission[0]?.address[0]?.postcode},{' '}
                  {infoMission[0]?.address[0]?.department?.split(',')[1]}
                </p>
              </div>
              <div className="col-span-1 flex justify-end items-start">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${infoMission[0]?.address[0]?.street} ${infoMission[0]?.address[0]?.postcode} ${infoMission[0]?.address[0]?.city}`}
                  target="_blank"
                >
                  <Button color={'SECONDARY'} size={'LG'}>
                    <Localisation wh={15} color={'#111827'} />
                    Voir l'adresse
                  </Button>
                </a>
              </div>
            </div>
          </Cardbody>
          <Cardfooter>
            <div className="ft-sm text-center tab:text-right text-gray-500">
              Mission créée le{' '}
              {new Date(infoMission[0]?.createdAt).toLocaleString('FR-fr', {
                weekday: undefined,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: undefined,
              })}
            </div>
          </Cardfooter>
        </div>
      </div>
    </PageForm>
  );
};

export default Missionfiche;

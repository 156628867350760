import Button from 'components/button';
import ModalBody from 'components/modal/components/body';
import ModalFooter from 'components/modal/components/footer';
import Modalheader from 'components/modal/components/header';
import useModal from 'components/modal/useModal';
import StyledInput from 'components/styledInput/StyledInput';
import UseContext from 'hooks/useContext';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiDatabase from '../../../../../server';

const ModalEditBonusesAndAllowances = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const token = localStorage.getItem('xsrfToken');
  const [isWaiting, setIsWaiting] = useState(false);
  const [isWaitingBonusList, setIsWaitingBonusList] = useState(false);
  const [isWaitingBonusUnitList, setIsWaitingBonusUnitList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [bonusUnitList, setBonusUnitList] = useState([]);
  const [bonusesAndAllowances, setBonusesAndAllowances] = useState({
    name: '',
    unit: '',
    ref: '',
    payed: '',
    coef: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    unit: '',
    payed: '',
    coef: '',
  });

  useEffect(() => {
    if (modalData.idCompany) {
      setIsWaitingBonusList(true);
      ApiDatabase.getBonusListCompany(
        { token, idCompany: modalData.idCompany },
        (data) => {
          // Initialiser bonusList sans la propriété 'checked'
          const initializedBonusList = [
            { value: '', label: '-' },
            ...data.bonusList.map((item) => ({
              ...item,
              value: item._id,
              label: item.name,
            })),
          ];
          setBonusList(initializedBonusList);
          setIsWaitingBonusList(false);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            setIsWaitingBonusList(false);
          }
        }
      );

      setIsWaitingBonusUnitList(true);
      ApiDatabase.getBonusUnitListCompany(
        { token, idCompany: modalData.idCompany },
        (data) => {
          setBonusUnitList([
            { value: '', label: '-' },
            ...data.bonusUnitList.map((item) => ({
              ...item,
              value: item._id,
              label: item.name + ' - ' + item.description,
            })),
          ]);
          setIsWaitingBonusUnitList(false);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            setIsWaitingBonusUnitList(false);
          }
        }
      );
    }
  }, [modalData.idCompany]);

  useEffect(() => {
    if (modalData.idCompany && modalData.idBonusesAndAllowances) {
      setIsWaiting(true);
      ApiDatabase.getBonusAndAllowance(
        {
          token,
          idCompany: modalData.idCompany,
          idBonusesAndAllowances: modalData.idBonusesAndAllowances,
        },
        (data) => {
          if (data.bonusAndAllowance) {
            setBonusesAndAllowances(data.bonusAndAllowance);
          }
          setIsWaiting(false);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            setIsWaiting(false);
          }
        }
      );
    }
  }, [modalData.idCompany, modalData.idBonusesAndAllowances]);

  const validateBonusesAndAllowances = () => {
    const newErrors = {
      name: !bonusesAndAllowances.name ? 'Veuillez sélectionner une prime' : '',
      unit: !bonusesAndAllowances.unit ? 'Veuillez sélectionner une unité' : '',
      payed:
        bonusesAndAllowances.payed === '' ||
        bonusesAndAllowances.payed === null ||
        bonusesAndAllowances.payed <= 0
          ? 'Le montant payé doit être supérieur à 0'
          : '',
      coef:
        bonusesAndAllowances.coef === '' ||
        bonusesAndAllowances.coef === null ||
        bonusesAndAllowances.coef <= 0
          ? 'Le coefficient doit être supérieur à 0'
          : '',
    };

    setErrors(newErrors);

    // Retourne true s'il n'y a pas d'erreurs
    return !(
      newErrors.name ||
      newErrors.unit ||
      newErrors.payed ||
      newErrors.coef
    );
  };

  const handleSubmit = () => {
    if (validateBonusesAndAllowances() && modalData.idCompany) {
      setIsLoading(true);

      if (modalData.idBonusesAndAllowances) {
        ApiDatabase.postUpdBonusesAndAllowances(
          {
            token,
            idCompany: modalData.idCompany,
            idBonusesAndAllowances: modalData.idBonusesAndAllowances,
            bonusesAndAllowances,
          },
          (data) => {
            setIsLoading(false);
            toast.success('Les primes ont été mises à jour');
            setModalDataReturn({
              type: 'bonuses_and_allowances_updated',
              bonusesAndAllowances: data.bonus,
            });
            toggle();
          },
          (err) => {
            if (err.name !== 'CanceledError') {
              console.error(err);
              setIsLoading(false);
            }
          }
        );
      } else {
        ApiDatabase.postAddBonusesAndAllowances(
          { token, idCompany: modalData.idCompany, bonusesAndAllowances },
          (data) => {
            setIsLoading(false);
            toast.success('Les primes ont été ajoutées');
            setModalDataReturn({
              type: 'bonuses_and_allowances_added',
              bonusesAndAllowances: data.bonus,
            });
            toggle();
          },
          (err) => {
            if (err.name !== 'CanceledError') {
              console.error(err);
              setIsLoading(false);
            }
          }
        );
      }
    }
  };

  const handleChangeBonusList = (updatedBonusList) => {
    // Trouver l'élément sélectionné
    const checkedItem = updatedBonusList.find((item) => item.checked);

    // Mettre à jour bonusesAndAllowances.name avec la valeur (ID) de l'élément sélectionné
    setBonusesAndAllowances((prevData) => ({
      ...prevData,
      name: checkedItem?.value || '',
    }));

    // Mettre à jour les erreurs
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: checkedItem?.value ? '' : 'Veuillez sélectionner une prime',
    }));
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        {modalData.idBonusesAndAllowances
          ? 'Modifier une prime'
          : 'Ajouter une prime'}
      </Modalheader>
      <ModalBody>
        {isWaiting || isWaitingBonusList || isWaitingBonusUnitList ? (
          <div className="flex flex-col gap-2.5 text-sm animate-pulse">
            <div className={'h-16 bg-gray-200 rounded w-full'}></div>
            <div className={'h-16 bg-gray-200 rounded w-full'}></div>
            <div className={'h-16 bg-gray-200 rounded w-full'}></div>
            <div className={'h-16 bg-gray-200 rounded w-full'}></div>
            <div className={'h-16 bg-gray-200 rounded w-full'}></div>
          </div>
        ) : (
          <div className="flex flex-col gap-2.5 text-sm">
            <StyledInput
              type="selectMultipleRadio"
              label="Sélectionner une prime"
              value={
                bonusList.find(
                  (item) => item.value === bonusesAndAllowances.name
                )?.label || '-'
              }
              list={bonusList.map((item) => ({
                ...item,
                checked: item.value === bonusesAndAllowances.name,
              }))}
              onChange={handleChangeBonusList}
              isSearchable
              error={errors.name}
            />
            <StyledInput
              type="select"
              label="Sélection des unités"
              value={bonusesAndAllowances?.unit}
              list={bonusUnitList}
              onChange={(e) => {
                setBonusesAndAllowances((prevData) => ({
                  ...prevData,
                  unit: e.target.value,
                }));
              }}
              error={errors.unit}
            />
            <StyledInput
              label={'Référence'}
              type={'text'}
              value={bonusesAndAllowances?.ref}
              onChange={(e) => {
                setBonusesAndAllowances((prevData) => ({
                  ...prevData,
                  ref: e.target.value,
                }));
              }}
              isOptional
            />
            <StyledInput
              label={'Payé'}
              type={'number'}
              value={bonusesAndAllowances?.payed}
              step="0.01"
              onChange={(e) => {
                const value = e.target.value;
                setBonusesAndAllowances((prevData) => ({
                  ...prevData,
                  payed: value !== '' ? parseFloat(value) : '',
                }));
              }}
              error={errors.payed}
            />
            <StyledInput
              label={'Coefficient'}
              type={'number'}
              value={bonusesAndAllowances?.coef}
              step="0.01"
              onChange={(e) => {
                const value = e.target.value;
                setBonusesAndAllowances((prevData) => ({
                  ...prevData,
                  coef: value !== '' ? parseFloat(value) : '',
                }));
              }}
              error={errors.coef}
            />
            <StyledInput
              label={'Facturé'}
              type={'number'}
              value={
                bonusesAndAllowances?.payed && bonusesAndAllowances?.coef
                  ? parseFloat(
                      parseFloat(bonusesAndAllowances.payed) *
                        parseFloat(bonusesAndAllowances.coef)
                    ).toFixed(2)
                  : ''
              }
              isDisabled
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>
              Annuler
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Confirmation ...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Annuler
            </Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalEditBonusesAndAllowances;

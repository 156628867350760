import React,{useState} from 'react';
import Requirements
    from "components/modal/dataModal/data/modaljustify/identity/components/requirements";
import Selfieimage
    from "components/modal/dataModal/data/modaljustify/identity/components/images/selfieimage";
import Importfile from "components/formcomponents/importfile";


const Selfie = ({setSelfie}) => {
    const [titleFileR, setTitleFileR] = useState('')

    // name,change,titleFile
    const handlerSingleFileR = e => {
        setSelfie( e.target.files[0])
        setTitleFileR(e.target.files[0].name)
    }
    return (
        <div className={'h-[500px] overflow-y-auto px-2'}>
            <Requirements/>
            <Selfieimage/>
            <div className={'mt-6'}>
                <Importfile name={'Importer'}
                            titleFile={titleFileR}
                            change={handlerSingleFileR}/>
            </div>

        </div>
    );
};

export default Selfie;
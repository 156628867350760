import React from "react";

// IMPORT COMPONENTS
import Navbar from "components/Navbar/Navbar";
import MediaQuery from "react-responsive";
import SidebarDesktop from "components/sidebar/SidebarDesktop";
import SidebarMobile from "components/sidebar/SidebarMobile";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const PageForm = (props) => {
    return (
        <>
            <div className="flex h-screen overflow-y-hidden tab:overflow-hidden">
                {/* Sidebar */}
                <MediaQuery minWidth={769}><SidebarDesktop/></MediaQuery>
                <MediaQuery maxWidth={768}><SidebarMobile/></MediaQuery>
                {/* Content area */}
                <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/* Navbar */}
                    <Navbar></Navbar>
                    <main className={'w-full h-full flex flex-col overflow-y-auto'}>
                        {/* SubNav */}
                        {/*<div className="w-full">*/}
                        {/*    <SecondNavBar/>*/}
                        {/*</div>*/}
                        {/* Main Content */}
                        <div className="flex flex-col w-full h-full bg-white">{props.children}</div>
                    </main>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
};

export default PageForm;
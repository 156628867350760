import {ArrowLeft} from 'assets/icons/index.js';
import React, { useEffect, useState } from 'react'
import TbodyFicheVivier from "./components/tbody.fiche.vivier";

function TableFicheVivier({ users, setSortName, sortName, setAddVivier, list, idVivier }) {
    const [selectAll, setSelectAll] = useState(false);
    // Vérifiez si toutes les cases à cocher individuelles sont cochées, puis mettez à jour l'état de la case à cocher d'en-tête
    useEffect(() => {
        if (users && Array.isArray(users)) {
            if (list > 0) {
                const allChecked = users.every((user) => list.includes(user._id));
                setSelectAll(allChecked);
            }
        }
    }, [users, list]);

    const handleSortName = () => {
        setSortName((prevState) => !prevState)
    };

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        // Mise à jour de la liste des ID en fonction de l'état de la case à cocher d'en-tête
        if (!selectAll) {
            // Si la case à cocher d'en-tête est cochée, ajouter tous les ID au tableau
            setAddVivier(users.map((user) => user._id));
        } else {
            // Sinon, supprimer tous les ID du tableau
            setAddVivier([]);
        }
    };

    return (
        <table className='table-fixed w-full divide-y divide-gray-200 bg-white overflow-y-auto'>
            <thead>
            <tr>
                <th scope="col"
                    className="py-3.5 px-4 font-normal text-left rtl:text-right text-sm text-gray-700 bg-white">
                    <div className="flex items-center gap-x-3">
                        <input type="checkbox" checked={selectAll} onChange={toggleSelectAll}/>
                        <button className="flex items-center gap-x-2" onClick={handleSortName}>
                            <span>CANDIDAT</span>
                            <span className={`${sortName ? "rotate-90" : "-rotate-90"} `}>
                                <ArrowLeft w={12}/>
                            </span>
                        </button>
                    </div>
                </th>
                {idVivier !== '64e8cfd10b4f73f442432c9f' && (
                    <th scope="col" className="relative py-3.5 px-4 text-left text-sm text-gray-700 bg-white">
                        NOTE
                    </th>
                )}
                <th scope="col" className="relative py-3.5 px-4 text-left text-sm text-gray-700 bg-white">
                    CONTACT
                </th>
                {idVivier !== '64e8cfd10b4f73f442432c9f' && (
                    <th scope="col" className="relative py-3.5 px-4 text-left text-sm text-gray-700 bg-white">
                        VIVIER
                    </th>
                )}
                <th scope="col" className="relative py-3.5 px-4 text-left text-sm text-gray-700 bg-white"></th>
            </tr>
            </thead>
            <tbody>
            {(users?.length > 0) && users.map((user, key) => <TbodyFicheVivier key={key} champsBody={user} list={list} setAddVivier={setAddVivier} idVivier={idVivier} isExclude={idVivier === '64e8cfd10b4f73f442432c9f'}/>)}
            </tbody>
        </table>
    )
}

export default TableFicheVivier
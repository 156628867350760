import { useEffect, useState } from 'react';
import {
  ChevronRight,
  CrossIcon,
  Pencil,
  Plus,
} from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import Button from '../../../../button';
import useModal from '../../../../modal/useModal';

const SlideOverCompanyRemunerationConditions = ({
  companyInfo,
  setCompanyInfo,
  setIsShowCoeff,
  setIsCoeffSend,
}) => {
  const { toggle } = useModal();
  const { setModalData, modalDataReturn, setModalDataReturn } = UseContext();
  const [isCoeffCompleted, setIsCoeffCompleted] = useState(false);

  useEffect(() => {
    if (companyInfo?.coefficients) {
      if (companyInfo?.coefficients?.length > 0) {
        for (let i = 0; i < companyInfo?.coefficients.length; i++) {
          if (
            companyInfo?.coefficients[i]?.management === null ||
            companyInfo?.coefficients[i]?.delegation === null
          ) {
            setIsCoeffCompleted(false);
            break;
          } else {
            setIsCoeffCompleted(true);
            setIsCoeffSend(true);
          }
        }
      } else {
        setIsCoeffCompleted(false);
      }
    } else {
      setIsCoeffCompleted(false);
    }
  }, [companyInfo]);

  const editOvertime = (idCompany) => {
    setModalData({ idCompany });
    toggle('overtime_company');
  };

  const editNightHours = (idCompany) => {
    setModalData({ idCompany });
    toggle('night_hours_company');
  };

  const editSundayHours = (idCompany) => {
    setModalData({ idCompany });
    toggle('sunday_hours_company');
  };

  const editHolidayHours = (idCompany) => {
    setModalData({ idCompany });
    toggle('holiday_hours_company');
  };

  const groupedNightHours = {};
  const groupedOvertimes = {};

  if (companyInfo?.nightHours) {
    companyInfo.nightHours.forEach((nightHour) => {
      const increase = nightHour.increase;
      if (!groupedNightHours[increase]) {
        groupedNightHours[increase] = [];
      }
      groupedNightHours[increase].push(nightHour);
    });
  }
  if (companyInfo?.overtimes) {
    companyInfo.overtimes.forEach((overtime) => {
      const increase = overtime.increase;
      if (!groupedOvertimes[increase]) {
        groupedOvertimes[increase] = [];
      }
      groupedOvertimes[increase].push(overtime);
    });
  }

  useEffect(() => {
    if (
      modalDataReturn?.type === 'night_hours_updated' &&
      modalDataReturn?.nightHours
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        nightHours: modalDataReturn.nightHours,
      }));
    }
    if (
      modalDataReturn?.type === 'overtimes_updated' &&
      modalDataReturn?.overtimes
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        overtimes: modalDataReturn.overtimes,
      }));
    }
    if (
      modalDataReturn?.type === 'sunday_hours_updated' &&
      modalDataReturn?.increase
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        sundayHours: modalDataReturn.increase,
      }));
    }
    if (
      modalDataReturn?.type === 'holiday_hours_updated' &&
      modalDataReturn?.increase
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        holidayHours: modalDataReturn.increase,
      }));
    }

    setModalDataReturn(null);
  }, [modalDataReturn]);

  return (
    <>
      <button
        type={'button'}
        className={'border rounded p-3'}
        onClick={() => setIsShowCoeff(true)}
      >
        <div className={'flex justify-between'}>
          <div className={'my-auto'}>
            <div>Coefficient de facturation</div>
            {!isCoeffCompleted && (
              <div className={'text-gray-500 flex gap-2'}>
                <div className={'my-auto'}>
                  <CrossIcon wh={20} color={'#EF4444'} />
                </div>
                <div className={'my-auto'}>La page n'est pas complète</div>
              </div>
            )}
          </div>
          <div className={'my-auto'}>
            <ChevronRight wh={24} color={'#111827'} />
          </div>
        </div>
      </button>
      <div className={''}>
        <div
          className={`border rounded p-3 flex justify-between gap-2 ${
            !companyInfo?.overtimes && 'items-center'
          }`}
        >
          <div className={''}>
            <div className={''}>Heures supplémentaires</div>
            {companyInfo?.overtimes && (
              <div className={''}>
                <div className={'text-gray-500'}>
                  Majoration appliquée lorsqu'un intérimaire travaille :
                </div>
                <ul className={'list-disc list-inside'}>
                  {Object.entries(groupedOvertimes).map(
                    ([increase, overtimesArray]) => {
                      const timeRangesElements = [];

                      overtimesArray.forEach((overtime, index) => {
                        if (index > 0) {
                          timeRangesElements.push(' et ');
                        }
                        timeRangesElements.push(
                          <span key={index} className={'text-gray-900'}>
                            {overtime.start}h
                          </span>
                        );
                      });

                      return (
                        <li key={increase} className={''}>
                          <span className={'text-gray-500'}>
                            Plus de {timeRangesElements} sur une semaine :{' '}
                            {increase}%
                          </span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className={''}>
            <Button
              size={'SMI'}
              color={'SECONDARY'}
              onClick={() => editOvertime(companyInfo?._id)}
            >
              {companyInfo?.overtimes ? (
                <Pencil wh={20} color={'#111827'} />
              ) : (
                <Plus wh={20} color={'#111827'} />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className={''}>
        <div
          className={`border rounded p-3 flex justify-between gap-2 ${
            !companyInfo?.nightHours && 'items-center'
          }`}
        >
          <div className={''}>
            <div className={''}>Heures de nuit</div>
            {companyInfo?.nightHours && (
              <div className={''}>
                <div className={'text-gray-500'}>
                  Majoration appliquée lorsqu'un intérimaire travaille :
                </div>
                <ul className={'list-disc list-inside'}>
                  {Object.entries(groupedNightHours).map(
                    ([increase, nightHoursArray]) => {
                      const timeRangesElements = [];

                      nightHoursArray.forEach((nightHour, index) => {
                        if (index > 0) {
                          timeRangesElements.push(' et ');
                        }
                        timeRangesElements.push(
                          <span key={index} className={'text-gray-900'}>
                            {nightHour.start}h et {nightHour.end}h
                          </span>
                        );
                      });

                      return (
                        <li key={increase} className={''}>
                          <span className={'text-gray-500'}>
                            Entre {timeRangesElements} : {increase}%
                          </span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className={''}>
            <Button
              size={'SMI'}
              color={'SECONDARY'}
              onClick={() => editNightHours(companyInfo?._id)}
            >
              {companyInfo?.nightHours ? (
                <Pencil wh={20} color={'#111827'} />
              ) : (
                <Plus wh={20} color={'#111827'} />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className={''}>
        <div
          className={`border rounded p-3 flex justify-between gap-2 ${
            !companyInfo?.sundayHours && 'items-center'
          }`}
        >
          <div className={''}>
            <div className={''}>Heures du dimanche</div>
            {companyInfo?.sundayHours && (
              <div className={'text-gray-500'}>
                Majoration appliquée lorsqu'un intérimaire travaille le dimanche
                : {companyInfo.sundayHours}%
              </div>
            )}
          </div>
          <div className={''}>
            <Button
              size={'SMI'}
              color={'SECONDARY'}
              onClick={() => editSundayHours(companyInfo?._id)}
            >
              {companyInfo?.sundayHours ? (
                <Pencil wh={20} color={'#111827'} />
              ) : (
                <Plus wh={20} color={'#111827'} />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className={''}>
        <div
          className={`border rounded p-3 flex justify-between gap-2 ${
            !companyInfo?.holidayHours && 'items-center'
          }`}
        >
          <div className={''}>
            <div className={''}>Heures jours fériés travaillés</div>
            {companyInfo?.holidayHours && (
              <div className={'text-gray-500'}>
                Majoration appliquée lorsqu'un intérimaire lors d’un jour férié
                : {companyInfo.holidayHours}%
              </div>
            )}
          </div>
          <div className={''}>
            <Button
              size={'SMI'}
              color={'SECONDARY'}
              onClick={() => editHolidayHours(companyInfo?._id)}
            >
              {companyInfo?.holidayHours ? (
                <Pencil wh={20} color={'#111827'} />
              ) : (
                <Plus wh={20} color={'#111827'} />
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideOverCompanyRemunerationConditions;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Close, Pencil } from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import usefetchdata from '../../../../../hooks/usefetchdata';
import ApiDatabase from '../../../../../server';
import AddressGeoApi from '../../../../AddressGeoApi/AddressGeoApi';
import Button from '../../../../button';
import useModal from '../../../../modal/useModal';
import StyledInput from '../../../../styledInput/StyledInput';
import TextSelectMultipleBigAccount from '../../../../textSelectMultiple/textSelectMultipleBigAccount';

const SlideOverCompanyOverview = ({ companyInfo, setCompanyInfo }) => {
  const { userData } = usefetchdata();
  const {
    objID,
    setModalData,
    modalDataReturn,
    setModalDataReturn,
    refreshCompanyBigAccount,
  } = UseContext();
  const { toggle } = useModal();
  const [isCostEstimateDisplay, setIsCostEstimateDisplay] = useState(false);
  const [bigAccount, setBigAccount] = useState('');
  const [modifyCompanyOverview, setModifyCompanyOverview] = useState(false);
  const [companyInfoModified, setCompanyInfoModified] = useState({});
  const [collectiveAgreementDatas, setCollectiveAgreementDatas] = useState([]);
  const [isChangesModifyCompanyOverview, setIsChangesModifyCompanyOverview] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfoErrors, setCompanyInfoErrors] = useState({
    errorSiret: '',
    errorCodeApe: '',
    errorStaff: '',
  });
  const statusList = [
    {
      value: null,
      label: 'Choisir',
    },
    {
      value: 'SAS',
      label: 'SAS',
    },
    {
      value: 'SASU',
      label: 'SASU',
    },
    {
      value: 'SARL',
      label: 'SARL',
    },
    {
      value: 'EURL',
      label: 'EURL',
    },
    {
      value: 'SEARL',
      label: 'SEARL',
    },
    {
      value: 'EI',
      label: 'EI',
    },
    {
      value: 'SAI',
      label: 'SAI',
    },
    {
      value: 'GIE',
      label: 'GIE',
    },
    {
      value: 'SCI',
      label: 'SCI',
    },
    {
      value: 'SCOP',
      label: 'SCOP',
    },
    {
      value: 'SICA',
      label: 'SICA',
    },
    {
      value: 'SMIA',
      label: 'SMIA',
    },
    {
      value: 'SNC',
      label: 'SNC',
    },
    {
      value: 'Association',
      label: 'Association',
    },
    {
      value: 'Administration',
      label: 'Administration',
    },
    {
      value: 'Société étrangère',
      label: 'Société étrangère',
    },
    {
      value: 'AUTRES',
      label: 'AUTRES',
    },
  ];
  const token = localStorage.getItem('xsrfToken');
  const [role, setRole] = useState(500);

  useEffect(() => {
    if (userData?.role) {
      setRole(userData.role);
    }
  }, [userData]);

  useEffect(() => {
    ApiDatabase.getDisplayCostEstimate(
      { token: token, idCompany: objID },
      (data) => {
        setIsCostEstimateDisplay(data?.displayCostEstimate);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
        }
      }
    );
  }, []);

  const manageDisplayCostEstimate = () => {
    ApiDatabase.putDisplayCostEstimate(
      {
        token: token,
        idCompany: objID,
        displayCostEstimate: !isCostEstimateDisplay,
      },
      (data) => {
        setIsCostEstimateDisplay(!isCostEstimateDisplay);
        toast.success(
          'Les estimations de coût sont maintenant visible par le client'
        );
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
        }
      }
    );
  };

  const editDescriptionCompany = () => {
    setModalData({ idCompany: companyInfo?._id });
    toggle('edit_description_company');
  };

  useEffect(() => {
    if (modalDataReturn?.description) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        description: modalDataReturn.description,
      }));

      setModalDataReturn({});
    }
  }, [modalDataReturn]);

  useEffect(() => {
    ApiDatabase.getCompanyBigAccount(
      { token: token, idCompany: objID },
      (data) => {
        setBigAccount(data?.bigAccount ?? '');
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
        }
      }
    );
  }, [refreshCompanyBigAccount]);

  const deleteCompanyBigAccount = () => {
    ApiDatabase.deleteCompanyBigAccount(
      { token: token, idCompany: objID },
      (data) => {
        toast.success('Le grand compte a bien été supprimé');
        setBigAccount('');
      },
      (error) => {
        toast.error(
          `Une erreur est survenue lors de la suppression du grand compte ${error.response.data.message}.`
        );
      }
    );
  };

  useEffect(() => {
    if (companyInfo) {
      setCompanyInfoModified({
        reason_social: companyInfo.reason_social,
        name_commercial: companyInfo.name_commercial,
        address: companyInfo.address,
        siret: companyInfo.siret,
        status: companyInfo.status,
        code_ape: companyInfo.code_ape,
        staff: companyInfo.staff,
        conv_collective: companyInfo.conv_collective,
      });
    }
  }, [companyInfo]);

  useEffect(() => {
    if (token && companyInfo) {
      ApiDatabase.getCollectiveAgreement(
        { token },
        (data) => {
          const updatedData = data.map((item) => ({
            id: item._id,
            name: `${item.IDCC_id} - ${item.IDCC_name}`,
            checked:
              parseInt(companyInfo?.conv_collective) === parseInt(item.IDCC_id),
          }));
          setCollectiveAgreementDatas(updatedData);
        },
        (err) => {
          console.log('err', err);
        }
      );
    }
  }, [token]);

  const updateCollectiveAgreement = (updatedCollectiveAgreement) => {
    const updatedCollectiveAgreements = [...collectiveAgreementDatas];

    for (let i = 0; i < updatedCollectiveAgreements.length; i++) {
      for (let j = 0; j < updatedCollectiveAgreement.length; j++) {
        if (
          updatedCollectiveAgreements[i].id ===
          updatedCollectiveAgreement[j].value
        ) {
          updatedCollectiveAgreements[i].checked =
            updatedCollectiveAgreement[j].checked;
        }
      }
    }

    setCollectiveAgreementDatas(updatedCollectiveAgreements);

    const selectedAgreement = updatedCollectiveAgreements.find(
      (agreement) => agreement.checked
    );

    setCompanyInfoModified((prevState) => ({
      ...prevState,
      conv_collective: selectedAgreement ? selectedAgreement.id : null,
    }));
  };

  useEffect(() => {
    if (companyInfoModified) {
      const errors = {};

      if (
        companyInfoModified.siret &&
        !/^\d{14}$/.test(companyInfoModified.siret)
      ) {
        errors.errorSiret = 'Le siret doit être composé de 14 chiffres.';
      } else {
        errors.errorSiret = '';
      }

      if (
        companyInfoModified.code_ape &&
        !/^\d{4}[A-Za-z]$/.test(companyInfoModified.code_ape)
      ) {
        errors.errorCodeApe =
          "Le code APE doit être composé de 4 chiffres suivis d'une lettre.";
      } else {
        errors.errorCodeApe = '';
      }

      if (
        companyInfoModified.staff &&
        !/^\d+$/.test(companyInfoModified.staff)
      ) {
        errors.errorStaff =
          'Le staff doit être composé uniquement de chiffres.';
      } else {
        errors.errorStaff = '';
      }

      setCompanyInfoErrors(errors);
    }
  }, [companyInfoModified]);

  const fieldsToCompare = [
    { path: 'reason_social', normalizeAsString: true },
    { path: 'name_commercial', normalizeAsString: true },
    { path: 'address.city', normalizeAsString: true },
    { path: 'address.postcode', normalizeAsString: true },
    { path: 'address.street', normalizeAsString: true },
    { path: 'siret', normalizeAsString: true },
    { path: 'status', normalizeAsString: true },
    { path: 'code_ape', normalizeAsString: true },
    { path: 'staff', normalizeAsString: false },
    { path: 'conv_collective', normalizeAsString: true },
  ];

  const getValue = (obj, path) => {
    return path
      .split('.')
      .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
  };

  const normalizeValue = (value, asString) => {
    if (value === undefined || value === null) return '';
    return asString ? value.toString() : value;
  };

  const setValue = (obj, path, value) => {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!(key in current)) {
        current[key] = {};
      }
      current = current[key];
    }
    current[keys[keys.length - 1]] = value;
  };

  useEffect(() => {
    const hasChanges = fieldsToCompare.some(({ path, normalizeAsString }) => {
      const modifiedValue = normalizeValue(
        getValue(companyInfoModified, path),
        normalizeAsString
      );
      const originalValue = normalizeValue(
        getValue(companyInfo, path),
        normalizeAsString
      );
      return modifiedValue !== originalValue;
    });

    const errorsAreEmpty =
      !companyInfoErrors.errorSiret &&
      !companyInfoErrors.errorCodeApe &&
      !companyInfoErrors.errorStaff;

    setIsChangesModifyCompanyOverview(hasChanges && errorsAreEmpty);
  }, [companyInfoModified, companyInfo, companyInfoErrors]);

  const submitModifyCompanyOverview = () => {
    if (
      !companyInfoErrors.errorSiret &&
      !companyInfoErrors.errorCodeApe &&
      !companyInfoErrors.errorStaff &&
      companyInfoModified
    ) {
      let modifiedFields = {};
      let addressModified = false;

      fieldsToCompare.forEach(({ path, normalizeAsString }) => {
        const modifiedValue = normalizeValue(
          getValue(companyInfoModified, path),
          normalizeAsString
        );
        const originalValue = normalizeValue(
          getValue(companyInfo, path),
          normalizeAsString
        );

        if (modifiedValue !== originalValue) {
          if (path.startsWith('address.')) {
            addressModified = true;
          } else {
            setValue(modifiedFields, path, getValue(companyInfoModified, path));
          }
        }
      });

      if (addressModified) {
        modifiedFields.address = companyInfoModified.address;
      }

      setIsLoading(true);
      ApiDatabase.postUpdateCompanyOverview(
        { token, idCompany: companyInfo?._id, companyInfo: modifiedFields },
        (data) => {
          setCompanyInfo((prevCompanyInfo) => {
            let updatedCompanyInfo = {
              ...prevCompanyInfo,
              ...modifiedFields,
            };

            if (modifiedFields.address) {
              updatedCompanyInfo.address = modifiedFields.address;
            }
            if (modifiedFields.conv_collective) {
              const agreement = collectiveAgreementDatas.find(
                (collectiveAgreement) =>
                  collectiveAgreement.id.toString() ===
                  modifiedFields.conv_collective
              );

              if (agreement) {
                updatedCompanyInfo.conv_collective = agreement.name;
              }
            }

            return updatedCompanyInfo;
          });

          setCompanyInfoModified((prevCompanyInfoModified) => ({
            ...prevCompanyInfoModified,
            ...modifiedFields,
          }));

          setModifyCompanyOverview(false);
          setIsLoading(false);

          toast.success(
            "Les informations de l'entreprise ont été mises à jour avec succès."
          );
        },
        (err) => {
          console.log('Erreur:', err);
          toast.error(
            'Une erreur est survenue lors de la mise à jour des informations.'
          );
          setIsLoading(false);
        }
      );
    }
  };

  return (
    <>
      <div className={''}>
        <div className={'border rounded p-3 flex justify-between gap-2'}>
          <div className={'my-auto'}>
            Affichage des estimations de coût du mois en cours
          </div>
          <div className={'my-auto'}>
            <input
              type="checkbox"
              checked={isCostEstimateDisplay}
              onChange={() => manageDisplayCostEstimate()}
            />
          </div>
        </div>
      </div>
      <div className={''}>
        <div
          className={
            'border rounded p-3 flex justify-between items-center gap-2'
          }
        >
          <div className={'flex flex-col'}>
            <div>Brief de l'entreprise</div>
            {companyInfo?.description && (
              <div className={'text-gray-500'}>{companyInfo.description}</div>
            )}
          </div>
          <div className={''}>
            <Button
              size={'ICON'}
              color={'ICON'}
              onClick={() => editDescriptionCompany()}
            >
              <Pencil wh={20} color={'#374151'} />
            </Button>
          </div>
        </div>
      </div>
      <div className={''}>
        <div className={'border rounded p-3 flex justify-between gap-2'}>
          <div className={'my-auto w-full'}>Associé au groupe :</div>
          <div className={'my-auto w-full'}>
            {bigAccount ? (
              <div className={'p-3 border rounded'}>
                <div className={'flex justify-between'}>
                  <div>
                    <div>{bigAccount?.name}</div>
                    <div className={'text-gray-500 text-sm'}>
                      le{' '}
                      {bigAccount?.companies.find(
                        (item) => item.id_company === objID
                      )?.date &&
                        new Date(
                          bigAccount?.companies.find(
                            (item) => item.id_company === objID
                          )?.date
                        ).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                    </div>
                  </div>
                  <div>
                    <div
                      className={'cursor-pointer p-1 border rounded'}
                      onClick={() => deleteCompanyBigAccount()}
                    >
                      <Close wh={18} color={'#374151'} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <TextSelectMultipleBigAccount />
            )}
          </div>
        </div>
      </div>
      <div className={'flex flex-1 flex-col gap-6 text-sm h-full'}>
        {(role === 500 || role === 515) && (
          <>
            <div className={'flex gap-2 justify-between items-center'}>
              <div className={''}>Informations de l’entreprise</div>
              {!modifyCompanyOverview ? (
                <div className={''}>
                  <Button
                    size={'SM'}
                    color={'SECONDARY'}
                    onClick={() => setModifyCompanyOverview(true)}
                  >
                    <div className={'flex gap-1 items-center'}>
                      <div>
                        <Pencil wh={20} color={'#374151'} />
                      </div>
                      Modifier
                    </div>
                  </Button>
                </div>
              ) : (
                <div className={''}>
                  <Button size={'SM'} color={'DISABLED'}>
                    <div className={'flex gap-1 items-center'}>
                      <div>
                        <Pencil wh={20} color={'#6B7280'} />
                      </div>
                      Modifier
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        <div className={'flex flex-1 flex-col gap-6 text-sm h-full'}>
          {!modifyCompanyOverview ? (
            <>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Raison sociale</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.reason_social || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Nom commercial</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.name_commercial || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Adresse</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.address
                    ? companyInfo.address.street +
                      ' ' +
                      companyInfo.address.postcode +
                      ' ' +
                      companyInfo.address.city +
                      ', ' +
                      companyInfo.address.department
                    : '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>SIRET</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.siret || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Forme juridique</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.status || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Code Ape</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.code_ape || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Effectif</div>
                <div className={'w-1/2 text-gray-700'}>
                  {companyInfo?.staff || '-'}
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>
                  N° convention collective
                </div>
                <div className={'w-1/2 text-gray-700'}>
                  {collectiveAgreementDatas?.find(
                    (agreement) => agreement.checked
                  )?.name ||
                    companyInfo?.conv_collective ||
                    '-'}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Raison sociale</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'text'}
                    value={companyInfoModified?.reason_social}
                    onChange={(e) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        reason_social: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Nom commercial</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'text'}
                    value={companyInfoModified?.name_commercial}
                    onChange={(e) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        name_commercial: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Adresse</div>
                <div className={'w-1/2 text-gray-700'}>
                  <AddressGeoApi
                    addressGeoValue={(address) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        address: address,
                      }));
                    }}
                    addressValue={companyInfoModified.address}
                    page={'editCompany'}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>SIRET</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'number'}
                    value={companyInfoModified?.siret}
                    onChange={(e) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        siret: e.target.value,
                      }));
                    }}
                    error={companyInfoErrors?.errorSiret}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Forme juridique</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'select'}
                    value={companyInfoModified.status}
                    list={statusList}
                    onChange={(e) => {
                      setCompanyInfoModified((prevDatas) => ({
                        ...prevDatas,
                        status: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Code Ape</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'text'}
                    value={companyInfoModified?.code_ape}
                    onChange={(e) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        code_ape: e.target.value,
                      }));
                    }}
                    error={companyInfoErrors?.errorCodeApe}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>Effectif</div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'number'}
                    value={companyInfoModified?.staff}
                    onChange={(e) => {
                      setCompanyInfoModified((prevData) => ({
                        ...prevData,
                        staff: e.target.value,
                      }));
                    }}
                    error={companyInfoErrors?.errorStaff}
                  />
                </div>
              </div>
              <div className={'flex gap-1 items-center'}>
                <div className={'w-1/2 font-medium'}>
                  N° convention collective
                </div>
                <div className={'w-1/2 text-gray-700'}>
                  <StyledInput
                    type={'selectMultipleRadio'}
                    value={
                      collectiveAgreementDatas.filter(
                        (qualification) => qualification.checked === true
                      ).length > 0
                        ? collectiveAgreementDatas
                            .filter(
                              (qualification) => qualification.checked === true
                            )
                            .map((qualification) => qualification.name)
                            .join(', ')
                        : '-'
                    }
                    list={collectiveAgreementDatas.map(
                      (collectiveAgreement) => {
                        return {
                          value: collectiveAgreement.id,
                          label: collectiveAgreement.name,
                          checked: collectiveAgreement.checked,
                        };
                      }
                    )}
                    onChange={(updatedCollectiveAgreement) =>
                      updateCollectiveAgreement(updatedCollectiveAgreement)
                    }
                    isSearchable
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {modifyCompanyOverview && (
          <div
            className={
              'flex items-center justify-end px-6 py-0 rounded-b gap-2'
            }
          >
            <Button
              size={'SM'}
              color={'SECONDARY'}
              onClick={() => setModifyCompanyOverview(false)}
            >
              Annuler
            </Button>
            {isChangesModifyCompanyOverview ? (
              <>
                {isLoading ? (
                  <Button size={'SM'} color={'DISABLED'}>
                    Confirmation ...
                  </Button>
                ) : (
                  <Button
                    size={'SM'}
                    color={'PRIMARY'}
                    onClick={() => submitModifyCompanyOverview()}
                  >
                    Confirmer
                  </Button>
                )}
              </>
            ) : (
              <Button size={'SM'} color={'DISABLED'}>
                Confirmer
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SlideOverCompanyOverview;

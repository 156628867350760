import UseContext from "hooks/useContext";


const useSlideOver = () => {
    const {slideOverShow,setSlideOverShow,setSlideOverType}=UseContext()

    function toggleSlideOver(v) {
        setSlideOverType(v)
        setSlideOverShow(!slideOverShow)
    }

    return {
        toggleSlideOver
    };
};

export default useSlideOver;
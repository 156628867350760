import React, { useEffect, useState } from 'react';
import ApiDataBase from 'server';
import Chip from 'components/chip/chip';
import StyledInput from '../styledInput/StyledInput';

const Salary = ({ salary, onChange }) => {
  const [smic, setSmic] = useState('');
  const [salaryData, setSalaryData] = useState(salary || '');

  useEffect(() => {
    ApiDataBase.getMinimumWage(
      (data) => {
        if (data?.hourly?.gross) {
          setSmic(data.hourly.gross);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    setSalaryData(salary || '');
  }, [salary]);

  const handleSelect = () => {
    if (salaryData === smic) {
      setSalaryData('');
      onChange('');
    } else {
      setSalaryData(smic);
      onChange(smic);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSalaryData(value);
    onChange(value);
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="relative">
        <StyledInput
          type="text"
          value={salaryData}
          onChange={handleInputChange}
        />
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          €/h
        </div>
      </div>
      <div className={'w-fit'}>
        <Chip
          icon={salaryData === smic}
          select={salaryData === smic}
          type={salaryData === smic ? 'SELECTON' : 'SELECTOFF'}
          onClick={handleSelect}
        >
          SMIC : {smic}€/h
        </Chip>
      </div>
    </div>
  );
};

export default Salary;
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

// import components
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import axios from "axios";
import useModal from "components/modal/useModal";
import Button from "components/button";
import Api from "server"
const ModalCreateVivier = () => {
    const [nameVivier, setNameVivier] = useState('')
    // const [secteurVivier, setSecteurVivier] = useState('')
    const {toggle}=useModal()
    const dataSecteur = ["Guadeloupe","Martinique"]
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(nameVivier !=="")
        {axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}vivier/create`,
            data: {
                nameVivier:nameVivier,
            }
        })
            .then((res) => {
                window.location.reload()
                    toggle()                
            })
            .catch((err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })}
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Création d'un vivier
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <form className='flex flex-col w-full gap-8'>
                    <div>
                        <label htmlFor='token'>Nom du vivier :</label>
                        <input
                            className='w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg '
                            name='nameVivier'
                            onChange={(e) => setNameVivier(e.target.value)}
                            value={nameVivier}
                        />
                    </div>
                    {/* <div>
                        <label htmlFor='secteur'>Secteur du vivier :</label>
                        <select name="secteur" size='1'
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg  "
                                onChange={(e) => {
                                    setSecteurVivier(e.target.value)
                                }}>
                            <option value="">Selection</option>
                            {dataSecteur.map((data) =>
                                <option key={data}
                                        value={`${data}`}>{data}</option>
                            )}

                        </select>
                    </div> */}


                </form>
            </ModalBody>
            <ModalFooter >
                <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};


export default ModalCreateVivier;

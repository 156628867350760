import { useState } from 'react';
import { ChevronDown, ChevronTop } from '../../assets/icons';
import LicenceItem from './components/licenceItem';

const Licences = ({ licences }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {licences.slice(0, open ? licences.length : 3).map((licence, index) => (
        <LicenceItem key={index} licence={licence} index={index} />
      ))}
      {licences.length > 3 && (
        <div
          className={'text-yellow-500 cursor-pointer underline'}
          onClick={() => setOpen(!open)}
        >
          <div className={'flex gap-1 items-center'}>
            <div>{open ? 'Voir moins' : 'Voir plus'}</div>
            <div>
              {open ? (
                <ChevronTop wh={20} color={'#FF9600'} />
              ) : (
                <ChevronDown wh={20} color={'#FF9600'} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Licences;

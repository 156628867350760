import React from 'react';
import usefetchdata from "hooks/usefetchdata";
import EmptyState from "components/EmptyState/EmptyState";

const Progress = () => {
    const {tempWorkerData}=usefetchdata()
    return (
        <>
            {tempWorkerData?.jobs?.length ?'test':
            <EmptyState type={'mission_in_progress'}/>
            }
        </>
    );
};

export default Progress;
import ApiDatabase from "../../../../../../server";
import {useEffect, useState} from "react";
import UserKanbanVivier from "./components/user.kanban.vivier";
import {toast} from "react-toastify";
import UseContext from "../../../../../../hooks/useContext";
import useModal from "../../../../../../components/modal/useModal";
import Tooltip from "../../../../../../components/tooltips/Tooltips";
import {InformationCircle} from "../../../../../../assets/icons";

const KanbanFicheVivier = ({ users, setSortName, sortName, setAddVivier, list, idVivier, isExclude }) => {
    const {setUserVivierToArchive, userVivierToArchiveUpdated, setUserVivierToArchiveUpdated} = UseContext()
    const {toggle} = useModal();
    const [userGroupedByVivierStatus, setUserGroupedByVivierStatus] = useState({});
    const [usersSelected, setUsersSelected] = useState([])
    const [vivierEntered, setVivierEntered] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const kanbanColumns = [
        { name: 'wish', title: 'Souhait', icon: '🧞', description: 'Profils ayant indiqué vouloir participer à ce type de missions.' },
        { name: 'test', title: 'Test', icon: '⏳', description: 'Profils qualifiés par l\'équipe de recrutement.' },
        { name: 'valid', title: 'Validé', icon: '✅', description: 'Profils notés positivement par un client.' },
        { name: 'refused', title: 'Refusé', icon: '❌', description: 'Profils refusés par l\'équipe de recrutement.' },
        { name: 'archive', title: 'Archivé', icon: '📦', description: 'Profils indisponibles indéfiniment et/ou ne sont plus actif depuis plus de 45 jours.' }
    ];
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        setUserGroupedByVivierStatus(users)
    }, [users]);

    const dragEnter = event => {
        setVivierEntered(event.currentTarget.dataset.column)
    }

    const dragLeave = event => {
        //console.log('dragLeave', event.currentTarget.dataset.column)
    }

    const drop = event => {
        event.preventDefault()
        const column = event.currentTarget.dataset.column
        const originalUpdatedUsers = {...userGroupedByVivierStatus};
        const updatedUsers = {...userGroupedByVivierStatus};
        let usersToUpdate = []

        for (let i = 0; i < usersSelected.length; i++) {
            for (const key in updatedUsers) {
                if (updatedUsers.hasOwnProperty(key)) {
                    const users = updatedUsers[key];

                    const userToUpdate = users.find(user => user._id === usersSelected[i]);
                    updatedUsers[key] = users.filter(user => user._id !== usersSelected[i]);

                    if (userToUpdate) {
                        usersToUpdate.push(userToUpdate._id);
                        userToUpdate.vivier_status = column;
                        if (updatedUsers[column]) {
                            updatedUsers[column].push(userToUpdate);
                        } else {
                            updatedUsers[column] = [userToUpdate];
                        }
                    }
                }
            }
        }
        setUserGroupedByVivierStatus(updatedUsers);

        usersToUpdate = usersToUpdate.filter((value, index, self) => self.indexOf(value) === index);
        if (usersToUpdate.length > 0 && idVivier && column) {
            if (column === 'archive') {
                setUserVivierToArchive({
                    idUsers: usersToUpdate,
                    idVivier,
                    vivierStatus: column
                })

                setUserGroupedByVivierStatus(originalUpdatedUsers);

                toggle('vivier_user_archive')
            } else {
                ApiDatabase.updUserVivierPreferenceStatus({
                    token,
                    idUsers: usersToUpdate,
                    idVivier,
                    vivierStatus: column
                }, (data) => {
                    if (!isToastVisible) {
                        setIsToastVisible(true);
                        toast.success(usersToUpdate.length > 1 ? 'Les utilisateurs ont été déplacés.' : 'L\'utilisateur a été déplacé.')
                    }
                }, (err) => {
                    console.log('err', err)
                })
            }
        }

        setVivierEntered(false)
        setUsersSelected([])
    }

    const allowDrop = event => {
        event.preventDefault()
    }

    useEffect(() => {
        if (isToastVisible) {
            const timeoutId = setTimeout(() => {
                setIsToastVisible(false);
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [isToastVisible]);

    useEffect(() => {
        if (usersSelected.length > 1) setAddVivier(usersSelected)
        else setAddVivier([])
    }, [usersSelected])

    useEffect(() => {
        if (userVivierToArchiveUpdated.idUsers && userVivierToArchiveUpdated.idVivier && userVivierToArchiveUpdated.vivierStatus) {
            const updatedUsers = {...userGroupedByVivierStatus};
            for (let i = 0; i < userVivierToArchiveUpdated.idUsers.length; i++) {
                for (const key in updatedUsers) {
                    if (updatedUsers.hasOwnProperty(key)) {
                        const users = updatedUsers[key];

                        const userToUpdate = users.find(user => user._id === userVivierToArchiveUpdated.idUsers[i]);
                        updatedUsers[key] = users.filter(user => user._id !== userVivierToArchiveUpdated.idUsers[i]);

                        if (userToUpdate) {
                            userToUpdate.vivier_status = userVivierToArchiveUpdated.vivierStatus;
                            if (updatedUsers[userVivierToArchiveUpdated.vivierStatus]) {
                                updatedUsers[userVivierToArchiveUpdated.vivierStatus].push(userToUpdate);
                            } else {
                                updatedUsers[userVivierToArchiveUpdated.vivierStatus] = [userToUpdate];
                            }
                        }
                    }
                }
            }
            setUserGroupedByVivierStatus(updatedUsers);
            setUserVivierToArchiveUpdated({})
        }
    }, [userVivierToArchiveUpdated]);

    return (
        <div className={'flex gap-4 h-full overflow-y-auto'}>
            {kanbanColumns.map((kanbanColumn, index) => (
                <div key={index}
                     className={`rounded bg-gray-100 p-4 w-full h-full min-w-[250px] transition-all ease-in-out border-4 ${vivierEntered === kanbanColumn.name ? 'border-gray-200 border-dashed' : 'border-white'}`}
                     data-column={kanbanColumn.name}
                     onDragEnter={dragEnter}
                     onDragLeave={dragLeave}
                     onDragOver={allowDrop}
                     onDrop={drop}
                >
                    <div className={'flex flex-col gap-4 h-full'}>
                        <div className={'flex justify-between gap-1'}>
                            <div className={'flex gap-2 my-auto'}>
                                <div className={'my-auto text-xl'}>{kanbanColumn.icon}</div>
                                <div className={'my-auto'}>{kanbanColumn.title}</div>
                            </div>
                            <div className={'my-auto flex gap-1 items-center'}>
                                <div className={''}>
                                    <Tooltip text={kanbanColumn.description} placement='top'>
                                        <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                    </Tooltip>
                                </div>
                                <div className={`p-1 rounded w-fit h-fit text-sm`}>
                                    {userGroupedByVivierStatus[kanbanColumn.name]?.length || 0}
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col gap-2 h-full overflow-y-auto no-scrollbar text-sm'}>
                            {userGroupedByVivierStatus[kanbanColumn.name]?.map((user, index) => <UserKanbanVivier
                                key={index} user={user} usersSelected={usersSelected}
                                setUsersSelected={setUsersSelected} setVivierEntered={setVivierEntered}
                                idVivier={idVivier} isExclude={isExclude} vivierStatus={kanbanColumn.name}/>)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default KanbanFicheVivier;
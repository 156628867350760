import UseContext from "../../../../../../../hooks/useContext";
import useSlideOver from "../../../../../../../components/SlideOver/useSlideOver";
import useModal from "../../../../../../../components/modal/useModal";
import Badge from "../../../../../../../components/badge/badge";
import CopyToClipboard from "../../../../../../../components/copyToClipboard";
import {BanIcon, Dots, EyeIcon, Localisation, Plus} from "../../../../../../../assets/icons";
import {useEffect, useRef, useState} from "react";

const TbodyVivier = ({champsBody, setAddVivier, list, selectAll}) => {
    const {objID, setObjID, setInfoVivier} = UseContext()
    const {toggleSlideOver} = useSlideOver();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropDownRef = useRef()
    const {toggle} = useModal()

    const handleCheckboxChange = () => {
        // Vérifiez si la case à cocher d'en-tête est cochée pour décider de l'état des cases à cocher individuelles
        const checked = list.includes(champsBody._id);

        // Mettez à jour l'état de la case à cocher individuelle
        if (checked) {
            setAddVivier(list.filter((item) => item !== champsBody._id));
        } else {
            setAddVivier([...list, champsBody._id]);
        }
    };

    const openTempWorkerInfo = () => {
        setObjID(champsBody?._id);
        toggleSlideOver('get_temp_worker');
    };

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    const handleDropDown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const excludeAgencySelected = () => {
        setInfoVivier({ listUsers: [champsBody._id] });
        toggle('exclude_agency');
    };

    const addVivierSelected = () => {
        setInfoVivier({ listUsers: [champsBody._id] });
        toggle('vivier_add');
    };

    const changeLocalisation = () => {
        setInfoVivier({ listUsers: [champsBody._id] });
        toggle('localisation_preferences');
    };

    return (
        <>
            {!champsBody?.isDeleted && (
                <tr className={'border-b-2 border-gray-100 h-20'}>
                    <td className='flex items-center gap-4 py-3.5 px-4 sticky w-64 left-0 bg-white cursor-pointer' onClick={() => handleCheckboxChange()}>
                        <div className='flex items-center gap-4'>
                            <input type="checkbox" checked={list?.includes(champsBody._id)}/>
                            <div>
                                <div className='text-sm text-gray-900 flex gap-1 items-center'>
                                    <div className='truncate'>
                                        {champsBody.firstname} {champsBody.lastname}
                                    </div>
                                    <div className='text-gray-500 text-xs flex-shrink-0'>
                                        <CopyToClipboard text={champsBody.id_bestt}>
                                            (N° {champsBody.id_bestt})
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div className='flex gap-4'>
                                    {champsBody.token_refresh_mobile ? <Badge type={'APP'}>App</Badge> : ""}
                                    {champsBody.token_refresh ? <Badge type={'WEB'}>Web</Badge> : ""}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='py-3.5 px-4 bg-white'>
                        {champsBody.phone ?
                            <CopyToClipboard text={champsBody.phone}>
                            <span className='text-sm text-gray-700'>
                                {champsBody.phone}
                            </span>
                            </CopyToClipboard>
                            : null
                        }
                        {
                            champsBody.email ?
                                <CopyToClipboard text={champsBody.email}>
                                <span className='text-sm text-gray-500'>
                                    {champsBody.email}
                                </span>
                                </CopyToClipboard>
                                : null
                        }
                    </td>
                    <td className='py-3.5 px-4 bg-white'>
                        <p className="truncate text-sm text-gray-500">
                            {champsBody?.viviers?.join("/ ")}
                        </p>
                    </td>
                    <td className='py-3.5 px-4 bg-white '>
                        <div className='flex items-center'>
                            <div className={'cursor-pointer w-9 h-9 flex justify-center items-center rounded'} onClick={() => openTempWorkerInfo()}>
                                <EyeIcon wh={24} color={'#374151'}/>
                            </div>
                        </div>
                    </td>
                    <td className='flex items-center py-3.5 px-4 w-16 sticky h-20 right-0 bg-white'>
                        <div className={'my-auto relative cursor-pointer w-9 h-9 flex justify-center items-center rounded hover:bg-gray-100'} ref={dropDownRef} onClick={() => handleDropDown()}>
                            <Dots w={14} h={4} color={'#374151'}/>
                            {dropdownOpen &&
                                <div
                                    className={`${dropdownOpen ? "" : "hidden"} absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 right-7 -bottom-8 bg-gray-50`}>
                                    <div className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                         onClick={() => addVivierSelected()}>
                                        <div className={'flex gap-2'}>
                                            <div className={'my-auto'}>
                                                <Plus wh={20} color={'#374151'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                Ajouter dans un vivier
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                                         onClick={() => changeLocalisation()}>
                                        <div className={'flex gap-2'}>
                                            <div className={'my-auto'}>
                                                <Localisation wh={20} color={'#374151'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                Modifier la localisation
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-100"></div>
                                    <div
                                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                                        onClick={() => excludeAgencySelected()}>
                                        <div className={'flex gap-2'}>
                                            <div className={'my-auto'}>
                                                <BanIcon wh={20} color={'#DC2626'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                Exclure de l'agence
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}

export default TbodyVivier;
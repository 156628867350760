import React from 'react';
import {Warning} from "assets/icons";
import Divider from "components/divider/divider";

const Requirements = () => {
    return (
        <>
            <p className='ft-sm text-gray-500 my-3'>Critères de validation du
                document :</p>
            <span className='flex  ft-sm gap-2 items-center'><Warning wh={17}
                                                                      color={"#EF4444"}/> Le document doit être de bonne qualité</span>
            <Divider w={100}/>
            <span className='flex  ft-sm gap-2 items-center'><Warning wh={17}
                                                                      color={"#EF4444"}/>Les informations doivent être lisibles</span>
            <Divider w={100}/>
            <span className='flex ft-sm gap-2 items-center'><Warning wh={17}
                                                                     color={"#EF4444"}/>Les informations doivent être à votre nom</span>
            <Divider w={100}/>
        </>
    );
};

export default Requirements;
import AdminModalFileRefuse from 'components/modal/dataModal/data/AdminModalFile/Refuse/AdminModalFileRefuse';
import AdminModalFileView from 'components/modal/dataModal/data/AdminModalFile/View/AdminModalFileView';
import Proofaddress from 'components/modal/dataModal/data/modaljustify/address/proofaddress';
import Modaldelete from 'components/modal/dataModal/data/modaljustify/documents/modaldelete';
import Modalformation from 'components/modal/dataModal/data/modaljustify/documents/modalformation';
import Modallicence from 'components/modal/dataModal/data/modaljustify/documents/modallicence';
import Iban from 'components/modal/dataModal/data/modaljustify/iban/iban';
import Modifyiban from 'components/modal/dataModal/data/modaljustify/iban/modifyiban';
import Modalidentity from 'components/modal/dataModal/data/modaljustify/identity/modalidentity';
import Cardvitale from 'components/modal/dataModal/data/modaljustify/vitale/cardvitale';
import ModalLogout from 'components/modal/dataModal/data/ModalLogout/ModalLogout';
import ModalMessagerie from 'components/modal/dataModal/data/ModalMessagerie/ModalMessagerie';
import ModalNotification from 'components/modal/dataModal/data/ModalNotification/ModalNotification';
import Modalmodifydeleted from 'components/modal/dataModal/data/modalparametre/modalmodifydeleted';
import Modalmodifyemail from 'components/modal/dataModal/data/modalparametre/modalmodifyemail';
import Modalmodifypwd from 'components/modal/dataModal/data/modalparametre/modalmodifypwd';
import Deletelanguage from 'components/modal/dataModal/data/modalsavoirfaire/deletelanguage';
import Modalcompetence from 'components/modal/dataModal/data/modalsavoirfaire/modalcompetence';
import Modalcv from 'components/modal/dataModal/data/modalsavoirfaire/modalcv';
import Modaldeletepdf from 'components/modal/dataModal/data/modalsavoirfaire/modaldeletepdf';
import Modalexperience from 'components/modal/dataModal/data/modalsavoirfaire/modalexperience';
import Modalexperiencedelete from 'components/modal/dataModal/data/modalsavoirfaire/modalexperiencedelete';
import Modalexperiencemodify from 'components/modal/dataModal/data/modalsavoirfaire/modalexperiencemodifiy';
import Modallangue from 'components/modal/dataModal/data/modalsavoirfaire/modallangue';
import Modalmateriel from 'components/modal/dataModal/data/modalsavoirfaire/modalmateriel';
import Modaltoolsdeleted from 'components/modal/dataModal/data/modalsavoirfaire/modaltoolsdeleted';
import ModalSignature from 'components/modal/dataModal/data/ModalSignature';
import ModalTask from 'components/modal/dataModal/data/modalTask';
import ModalToken from 'components/modal/dataModal/data/ModalToken/token';
import Tokenemail from 'components/modal/dataModal/data/ModalToken/tokenemail';
import ModalTokenPassword from 'components/modal/dataModal/data/ModalToken/tokenPwd';
import UseContext from 'hooks/useContext';
import AdminModalFileAcceptIdentity from './data/AdminModalFile/Accept/AdminModalFileAcceptIdentity';
import AdminModalFileAcceptSimple from './data/AdminModalFile/Accept/AdminModalFileAcceptSimple';
import AdminModalFileAcceptVisa from './data/AdminModalFile/Accept/AdminModalFileAcceptVisa';
import AdminModalFileAcceptVitalCard from './data/AdminModalFile/Accept/AdminModalFileAcceptVitalCard';
import AdminModalMissionAssignationConfirmation from './data/AdminModalMission/AdminModalMissionAssignationConfirmation';
import AdminModalMissionDatesMissionConfirmation from './data/AdminModalMission/AdminModalMissionDatesMissionConfirmation';
import AdminModalMissionSign from './data/AdminModalMission/AdminModalMissionSign';
import AdminModalMissionUnAssign from './data/AdminModalMission/AdminModalMissionUnAssign';
import ModalComment from './data/ModalComment/modalComment';
import ModalAddCompanyMember from './data/modalCompany/ModalAddCompanyMember';
import ModalBonusesAndAllowancesRestaurantTicket from './data/modalCompany/ModalBonusesAndAllowancesRestaurantTicket';
import ModalDelBonusesAndAllowances from './data/modalCompany/ModalDelBonusesAndAllowances';
import ModalDelCompanyMember from './data/modalCompany/ModalDelCompanyMember';
import ModalEditBonusesAndAllowances from './data/modalCompany/ModalEditBonusesAndAllowances';
import ModalEditBonusesAndAllowancesOther from './data/modalCompany/ModalEditBonusesAndAllowancesOther';
import ModalEditCompanyMember from './data/modalCompany/ModalEditCompanyMember';
import ModalEditDescriptionCompany from './data/modalCompany/ModalEditDescriptionCompany';
import ModalHolidayHoursCompany from './data/modalCompany/ModalHolidayHoursCompany';
import ModalNightHoursCompany from './data/modalCompany/ModalNightHoursCompany';
import ModalOvertimeCompany from './data/modalCompany/ModalOvertimeCompany';
import ModalSendMailLinkDescriptionCompany from './data/modalCompany/ModalSendMailLinkCompanyMember';
import ModalSundayHoursCompany from './data/modalCompany/ModalSundayHoursCompany';
import ModalLocalisationPreference from './data/modalLocalisation/modalLocalisationPreference';
import ModalMissionCancel from './data/ModalMission/ModalMissionCancel';
import ModalMissionDelete from './data/ModalMission/ModalMissionDelete';
import ModalMissionDetailReason from './data/modalMissionDetailReason/ModalMissionDetailReason';
import ModalMissionDetailSubmit from './data/modalMissionDetailSubmit/ModalMissionDetailSubmit';
import ModalMissionNotify from './data/modalMissionNotify/ModalMissionNotify';
import ModalMissionNotifyGlobal from './data/modalMissionNotifyGlobal/ModalMissionNotifyGlobal';
import RatingTempWorkerValidation from './data/ModalRating/ratingTempWorkerValidation';
import AddEditSchedule from './data/modalSchedule/addEditSchedule';
import ConfirmSchedule from './data/modalSchedule/confirmSchedule';
import DeleteScheduleWeek from './data/modalSchedule/deleteScheduleWeek';
import DeleteScheduleWeekDay from './data/modalSchedule/deleteScheduleWeekDay';
import Completeday from './data/modalTimeSheet/hours/completeday';
import Validatesemaine from './data/modalTimeSheet/hours/validatesemaine';
import ValidateTime from './data/modalTimeSheet/validateTime';
import ActivityVivier from './data/modalVivier/activityVivier';
import ModalAddVivier from './data/modalVivier/addvivier.js';
import ModalCreateVivier from './data/modalVivier/createvivier.js';
import DeleteCandidateVivier from './data/modalVivier/deletecandidatevivier.js';
import DeleteVivier from './data/modalVivier/deleteVivier.js';
import ModalExcludeAgency from './data/modalVivier/modalExcludeAgency';
import ModalVivierChangeVivierStatus from './data/modalVivier/modalVivierChangeVivierStatus';
import ModalVivierUserToArchive from './data/modalVivier/modalVivierUserToArchive';
import ModalNotificationVivier from './data/modalVivier/notificationvivier.js';
import ModalUpdateVivier from './data/modalVivier/updatevivier.js';
//Import Modal

// Function
// nomination des modales
//Premiere partie:
// j-> justificatif *** msg->messagerie *** ntf->notification *** par-> parametres ***
// sf-> savoir faire *** tok-> token *** log->logout *** doc->documents
//Seconde partie:
//u-Update *** c->create *** d->delete ***g->get
//Troisieme partie:
//nom de la modale
const dataModal = new Map();
dataModal.set('j_d_delete', <Modaldelete />);
dataModal.set('j_c_formation', <Modalformation />);
dataModal.set('j_c_identity', <Modalidentity />);
dataModal.set('j_c_vitale', <Cardvitale />);
dataModal.set('j_c_licence', <Modallicence />);
dataModal.set('j_c_iban', <Iban />);
dataModal.set('j_u_iban', <Modifyiban />);
dataModal.set('j_c_proof', <Proofaddress />);
dataModal.set('log_d_logout', <ModalLogout />);
dataModal.set('msg_c_messagerie', <ModalMessagerie />);
dataModal.set('ntf_g_notif', <ModalNotification />);
dataModal.set('par_u_email', <Modalmodifyemail />);
dataModal.set('par_u_pwd', <Modalmodifypwd />);
dataModal.set('par_u_compte', <Modalmodifydeleted />);
dataModal.set('sf_c_competence', <Modalcompetence />);
dataModal.set('sf_c_cv', <Modalcv />);
dataModal.set('sf_d_cv', <Modaldeletepdf />);
dataModal.set('sf_c_exp', <Modalexperience />);
dataModal.set('sf_u_exp', <Modalexperiencemodify />);
dataModal.set('sf_d_exp', <Modalexperiencedelete />);
dataModal.set('sf_c_lang', <Modallangue />);
dataModal.set('sf_d_lang', <Deletelanguage />);
dataModal.set('sf_c_tool', <Modalmateriel />);
dataModal.set('sf_d_tool', <Modaltoolsdeleted />);
dataModal.set('tok_u_token', <ModalToken />);
dataModal.set('tok_u_tokenPwd', <ModalTokenPassword />);
dataModal.set('tok_u_tokenEmail', <Tokenemail />);
dataModal.set('doc_c_signature', <ModalSignature />);
dataModal.set('tasks', <ModalTask />);
dataModal.set('ad_file_view', <AdminModalFileView />);
dataModal.set('ad_file_refuse', <AdminModalFileRefuse />);
dataModal.set('ad_file_accept_simple', <AdminModalFileAcceptSimple />);
dataModal.set('ad_file_accept_identity', <AdminModalFileAcceptIdentity />);
dataModal.set('ad_file_accept_visa', <AdminModalFileAcceptVisa />);
dataModal.set(
  'ad_file_accept_card_vitality',
  <AdminModalFileAcceptVitalCard />
);
dataModal.set('ts_validateCheck', <ValidateTime />);
dataModal.set('doc_c_completedHours', <Completeday />);
dataModal.set('doc_u_validWeek', <Validatesemaine />);
dataModal.set(
  'ad_mission_date_confirmation',
  <AdminModalMissionDatesMissionConfirmation />
);
dataModal.set(
  'ad_mission_assignation_confirmation',
  <AdminModalMissionAssignationConfirmation />
);
dataModal.set('ad_mission_sign_confirmation', <AdminModalMissionSign />);
dataModal.set('vivier_create', <ModalCreateVivier />);
dataModal.set('vivier_update', <ModalUpdateVivier />);
dataModal.set('vivier_delete', <DeleteVivier />);
dataModal.set('vivier_add', <ModalAddVivier />);
dataModal.set('d_unassign_mission', <AdminModalMissionUnAssign />);
dataModal.set('vivier_notification', <ModalNotificationVivier />);
dataModal.set('vivier_delete_candidate', <DeleteCandidateVivier />);
dataModal.set('cM_addScheduleWeek', <AddEditSchedule />);
dataModal.set('cM_confirmSchedule', <ConfirmSchedule />);
dataModal.set('cM_deleteScheduleWeekDay', <DeleteScheduleWeekDay />);
dataModal.set('cM_deleteScheduleWeek', <DeleteScheduleWeek />);
dataModal.set('rating_temp_worker_validation', <RatingTempWorkerValidation />);
dataModal.set('exclude_agency', <ModalExcludeAgency />);
dataModal.set('localisation_preferences', <ModalLocalisationPreference />);
dataModal.set('vivier_history', <ActivityVivier />);
dataModal.set('vivier_user_archive', <ModalVivierUserToArchive />);
dataModal.set('vivier_change_status', <ModalVivierChangeVivierStatus />);
dataModal.set('user_delete_comment', <ModalComment />);
dataModal.set('mission_cancel', <ModalMissionCancel />);
dataModal.set('mission_delete', <ModalMissionDelete />);
dataModal.set('mission_detail_reason', <ModalMissionDetailReason />);
dataModal.set('mission_detail_submit', <ModalMissionDetailSubmit />);
dataModal.set('mission_notify', <ModalMissionNotify />);
dataModal.set('mission_notify_global', <ModalMissionNotifyGlobal />);
dataModal.set('edit_description_company', <ModalEditDescriptionCompany />);
dataModal.set('add_company_member', <ModalAddCompanyMember />);
dataModal.set('edit_company_member', <ModalEditCompanyMember />);
dataModal.set('delete_company_member', <ModalDelCompanyMember />);
dataModal.set(
  'send_mail_link_company_member',
  <ModalSendMailLinkDescriptionCompany />
);
dataModal.set('night_hours_company', <ModalNightHoursCompany />);
dataModal.set('overtime_company', <ModalOvertimeCompany />);
dataModal.set('sunday_hours_company', <ModalSundayHoursCompany />);
dataModal.set('holiday_hours_company', <ModalHolidayHoursCompany />);
dataModal.set(
  'edit_bonuses_and_allowances_restaurant_ticket',
  <ModalBonusesAndAllowancesRestaurantTicket />
);
dataModal.set('edit_bonuses_and_allowances', <ModalEditBonusesAndAllowances />);
dataModal.set(
  'delete_bonuses_and_allowances',
  <ModalDelBonusesAndAllowances />
);
dataModal.set(
  'edit_bonuses_and_allowances_other',
  <ModalEditBonusesAndAllowancesOther />
);

const Datamodal = () => {
  const { modalType } = UseContext();
  return <>{dataModal.get(modalType)}</>;
};

export default Datamodal;

import React, {useContext, useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5'
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";

const Pdf = ({path}) => {
    const {refresh}=UseContext()
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [url,setUrl]=useState('')

    useEffect(()=>{

        ApiDatabase.getPathFile({id:path},
            (data)=>{
                setUrl(data.filePath)
            },
            (err)=> {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
        })
    },[path,refresh])
    function onDocumentLoadsuccess({numPages}) {
        setNumPages(numPages)
        setPageNumber(1)
    }

    function changePage(offSet) {
        setPageNumber(prevPageNumber => prevPageNumber + offSet)
    }

    function changePageBack() {
        changePage(-1)
    }

    function changePageNext() {
        changePage(+1)
    }

    return (
        <>
            {url ?
                <div className='flex flex-col justify-center items-center'>
                    <Document className='w-full'   file={`${process.env.REACT_APP_FILE + url}`} onLoadSuccess={onDocumentLoadsuccess}>
                        <Page className='flex items-center justify-center '  pageNumber={pageNumber} />
                    </Document>
                    <p>
                        Page {pageNumber} / {numPages}
                    </p>
                </div>
                : null}
        </>
    );
};

export default Pdf;
import React from 'react';
import Table from "components/table/table";
import EmptyState from "components/EmptyState/EmptyState";
import usefetchdata from "hooks/usefetchdata";

const Sign = () => {
    const {tempWorkerData}=usefetchdata()
    const champsHeader=['N° de contrat','Entreprise','MISSION','N° De mission','ÉTAT','DATE']
    const  test=[
        {contrat:1,entreprise:'Leader Price',mission:'Manutentionnaire',nMission:'1',etat:0,date:'11/10/2022',link:'https://stackoverflow.com/questions/25953551/moment-js-get-the-week-number-based-on-a-specific-day-also-past-years'},
        {contrat:2,entreprise:'Lidl',mission:'Manutentionnaire',nMission:'2',etat:1,date:'12/10/2022',link:'https://stackoverflow.com/questions/25953551/moment-js-get-the-week-number-based-on-a-specific-day-also-past-years'},
        {contrat:3,entreprise:'Aldi',mission:'Manutentionnaire',nMission:'3',etat:0,date:'13/10/2022',link:'https://stackoverflow.com/questions/25953551/moment-js-get-the-week-number-based-on-a-specific-day-also-past-years'},
        {contrat:4,entreprise:'Carrefour',mission:'Manutentionnaire',nMission:'4',etat:1,date:'14/10/2022',link:'https://stackoverflow.com/questions/25953551/moment-js-get-the-week-number-based-on-a-specific-day-also-past-years'},
    ]
    return (
        <>
            {/*{tempWorkerData?.jobs?.length >0?*/}
            {/*    <Table/>*/}
            {/*    :*/}
            {/*    <EmptyState type={'contract'}/>*/}
            {/*}*/}
            <Table champsHeader={champsHeader} champsBody={test}/>
        </>
    );
};

export default Sign;
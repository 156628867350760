import { useEffect, useState } from 'react';

// import components
import {
  ChevronDown,
  ChevronTop,
  Loading2,
  SearchLoop,
} from 'assets/icons/index.js';
import axios from 'axios';
import Button from 'components/button';
import ModalBody from 'components/modal/components/body';
import ModalFooter from 'components/modal/components/footer';
import Modalheader from 'components/modal/components/header';
import useModal from 'components/modal/useModal';
import UseContext from 'hooks/useContext.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import { toast } from 'react-toastify';
import useAbortController from '../../../../../hooks/useAbortController';
import Divider from '../../../../divider/divider';
import EmptyState from '../../../../EmptyState/EmptyState';

const ModalNotificationVivier = () => {
  const { toggle } = useModal();

  const { refreshVivierLog, setRefreshVivierLog, objID } = UseContext();
  const [search, setSearch] = useState('');
  const [result, setResult] = useState([]);
  const [resultUsers, setResultUsers] = useState([]);
  const [searchMissionWaiting, setSearchMissionWaiting] = useState(false);
  const [listAddVivierMission, setListAddVivierMission] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [countPerPage, setCountPerPage] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [data, setData] = useState();
  const { height, width } = useWindowDimensions();
  const [sendNotifLoading, setSendNotifLoading] = useState(false);
  const [userGroupedByVivierStatus, setUserGroupedByVivierStatus] = useState(
    {}
  );
  const [selectedColumn, setSelectedColumn] = useState([
    'wish',
    'test',
    'valid',
  ]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [openMissionVivier, setOpenMissionVivier] = useState([]);
  const [totalUsersAvailable, setTotalUsersAvailable] = useState([]);
  const token = localStorage.getItem('xsrfToken');
  const getAbortController = useAbortController();

  const kanbanColumns = [
    { name: 'wish', title: 'Souhait', icon: '🧞' },
    { name: 'test', title: 'Test', icon: '⏳' },
    { name: 'valid', title: 'Validé', icon: '✅' },
  ];

  const handleCheckboxChange = (item) => {
    // Créer une copie du tableau d'état
    const listAddVivierMissionProv = listAddVivierMission.slice();

    const allMissionsPresent = item.missions.every((mission) =>
      listAddVivierMissionProv.includes(mission._id)
    );

    if (allMissionsPresent) {
      item.missions.forEach((mission) => {
        const indexToRemove = listAddVivierMissionProv.indexOf(mission._id);
        if (indexToRemove !== -1) {
          listAddVivierMissionProv.splice(indexToRemove, 1);
        }
      });
    } else {
      item.missions.forEach((mission) => {
        if (!listAddVivierMissionProv.includes(mission._id)) {
          listAddVivierMissionProv.push(mission._id);
        }
      });
    }

    setListAddVivierMission(listAddVivierMissionProv);
  };

  // #endregion **** HandleClick ****
  // #region **** Function ****
  const manageMissionSearch = async () => {
    try {
      if (objID && selectedColumn.length > 0 && selectedDepartment) {
        const controller = getAbortController();

        setSearchMissionWaiting(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}vivier/missionVivier`,
          {
            signal: controller.signal,
            params: {
              idVivier: objID,
              vivierStatus: JSON.stringify(selectedColumn),
              department: selectedDepartment,
              page: currentPage,
              search: search,
            },
          }
        );
        setTotalPages(data.countPages);
        setCountPerPage(limitElement);
        setCountTotal(data.count);
        setData(data.count);
        setResult(data.mission);
        setSearchMissionWaiting(false);
        const t2 = Date.now();
      } else {
        setResult([]);
      }
    } catch (error) {
      if (error.name !== 'CanceledError') {
        setSearchMissionWaiting(false);
        console.error(error);
      }
    }
  };
  // #endregion **** Function ****
  // #region **** UseEffect ****
  let timerViverSearch = -1;
  useEffect(() => {
    if (timerViverSearch !== -1) clearTimeout(timerViverSearch);
    timerViverSearch = setTimeout(async () => {
      await manageMissionSearch();
      timerViverSearch = -1;
    }, 500);
    return () => {
      if (timerViverSearch !== -1) clearTimeout(timerViverSearch);
    };
  }, [search, currentPage, selectedColumn, selectedDepartment, objID]);

  // #endregion **** UseEffect ****
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      objID &&
      listAddVivierMission.length > 0 &&
      selectedColumn.length > 0 &&
      selectedDepartment
    ) {
      setSendNotifLoading(true);
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}vivier/notificationVivier`,
        data: {
          token: token,
          idVivier: objID,
          vivierStatus: selectedColumn,
          department: selectedDepartment,
          listMissions: listAddVivierMission,
        },
      })
        .then((res) => {
          toast.success('Vivier Notifié');
          if (res.status === 400 && res.data?.message)
            toast.error(res.data.message);
          setRefreshVivierLog(!refreshVivierLog);
          setSendNotifLoading(false);
          toggle();
        })
        .catch((err) => {
          if (
            err.response?.status === 400 &&
            err.response?.data?.error === 'no users to send'
          )
            toast.error("Aucun candidat n'est disponible pour ces missions");
          else toast.error('Une erreur est survenue');
          setSendNotifLoading(false);
        });
    } else {
      toast.error('Veuillez remplir le formulaire');
    }
  };

  useEffect(() => {
    if (resultUsers && Array.isArray(resultUsers)) {
      const groupedUsers = resultUsers.reduce((acc, user) => {
        const vivierStatus = user.vivier_status;
        acc[vivierStatus] = acc[vivierStatus] || [];
        acc[vivierStatus].push(user);
        return acc;
      }, {});
      setUserGroupedByVivierStatus(groupedUsers);
    }
  }, [resultUsers]);

  const manageUsers = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}vivier/vivierFiche`,
      {
        params: {
          page: currentPage,
          limit: 9999,
          search: '',
          optionSearch: '',
          profil: false,
          id: objID,
          filterOptions: JSON.stringify({}),
        },
      }
    );

    setResultUsers(data.users);
  };

  useEffect(() => {
    manageUsers();
  }, []);

  useEffect(() => {
    const usersAvailable = [];
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < result[i].missions.length; j++) {
        for (let k = 0; k < listAddVivierMission.length; k++) {
          if (result[i].missions[j]._id === listAddVivierMission[k]) {
            for (
              let l = 0;
              l < result[i].missions[j].usersAvailable.length;
              l++
            ) {
              //add only if doesn't exist
              if (
                !usersAvailable.includes(
                  result[i].missions[j].usersAvailable[l]
                )
              ) {
                usersAvailable.push(result[i].missions[j].usersAvailable[l]);
              }
            }
          }
        }
      }
    }

    setTotalUsersAvailable(usersAvailable);
  }, [listAddVivierMission, result]);

  useEffect(() => {
    setListAddVivierMission([]);
  }, [selectedColumn, selectedDepartment]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Notifier un vivier
      </Modalheader>
      {/*Body*/}
      <ModalBody>
        <div className={'flex flex-col gap-8'}>
          <div className={'flex flex-col gap-4'}>
            <div className={'text-sm'}>1. Sélectionner l'audience</div>
            <div className={'flex gap-4'}>
              {kanbanColumns.map((kanbanColumn, index) => (
                <div
                  key={index}
                  className={`rounded p-3 w-full h-full cursor-pointer border ${
                    selectedColumn.includes(kanbanColumn.name)
                      ? 'border-yellow-500 bg-yellow-50'
                      : 'border-gray-100 bg-gray-100'
                  }`}
                  onClick={() => {
                    //select multiple and can be deselected
                    if (selectedColumn.includes(kanbanColumn.name)) {
                      setSelectedColumn(
                        selectedColumn.filter(
                          (item) => item !== kanbanColumn.name
                        )
                      );
                    } else {
                      setSelectedColumn([...selectedColumn, kanbanColumn.name]);
                    }
                  }}
                >
                  <div className={'flex justify-between gap-1'}>
                    <div className={'flex gap-2 my-auto'}>
                      <div className={'my-auto text-xl'}>
                        {kanbanColumn.icon}
                      </div>
                      <div className={'my-auto'}>{kanbanColumn.title}</div>
                    </div>
                    <div className={'my-auto'}>
                      <div
                        className={`p-1 rounded w-fit h-fit text-sm ${
                          selectedColumn.includes(kanbanColumn.name)
                            ? 'bg-yellow-200 text-yellow-800'
                            : 'bg-gray-200'
                        }`}
                      >
                        {userGroupedByVivierStatus[kanbanColumn.name]?.length ||
                          0}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Divider />
          <div className={'flex flex-col gap-4'}>
            <div className={'text-sm'}>2. Sélectionner le département</div>
            <div className={'flex gap-4'}>
              {['Martinique', 'Guadeloupe'].map((department, index) => (
                <div
                  key={index}
                  className={`rounded p-3 w-full h-full cursor-pointer border ${
                    selectedDepartment === department
                      ? 'border-yellow-500 bg-yellow-50'
                      : 'border-gray-100 bg-gray-50'
                  }`}
                  onClick={() => setSelectedDepartment(department)}
                >
                  {department}
                </div>
              ))}
            </div>
          </div>
          <Divider />
          <div className={'flex flex-col gap-4'}>
            <div className={'text-sm'}>3. Sélectionner les missions</div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex items-center gap-x-4">
                <div className="relative text-gray-500 w-full">
                  <input
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="w-full h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                    type="text"
                    placeholder="Nom de la mission"
                    required
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                    <SearchLoop wh={18} color={'#666666'} />
                  </div>
                </div>
              </div>
              {searchMissionWaiting ? (
                <div
                  className={`flex flex-col ${
                    width <= 460 ? 'gap-5' : 'gap-10'
                  } justify-center items-center h-40`}
                >
                  <div className={'animate-spin'}>
                    <Loading2 wh={width <= 460 ? 40 : 60} color={'#FF9600'} />
                  </div>
                </div>
              ) : (
                <>
                  {result?.length > 0 &&
                  selectedColumn.length > 0 &&
                  selectedDepartment ? (
                    <>
                      <div className="overflow-auto">
                        {!searchMissionWaiting &&
                          result?.length > 0 &&
                          result?.map((item) => (
                            <>
                              <div
                                className="flex flex-col py-2"
                                key={item._id}
                              >
                                <div
                                  className={
                                    'py-2 flex items-center justify-between cursor-pointer'
                                  }
                                >
                                  <label className="flex items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className={
                                        'w-4 h-4 accent-yellow-400 my-auto'
                                      }
                                      checked={item?.missions?.every(
                                        (mission) =>
                                          listAddVivierMission.some(
                                            (selectedItem) =>
                                              selectedItem === mission._id
                                          )
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(item)
                                      }
                                    />
                                    <div className="flex flex-col pl-4">
                                      {item._id}
                                    </div>
                                  </label>
                                  <div
                                    className={'p-2 flex justify-end flex-1'}
                                    onClick={() => {
                                      if (
                                        openMissionVivier.includes(item._id)
                                      ) {
                                        setOpenMissionVivier(
                                          openMissionVivier.filter(
                                            (itemOpen) => itemOpen !== item._id
                                          )
                                        );
                                      } else {
                                        setOpenMissionVivier([
                                          ...openMissionVivier,
                                          item._id,
                                        ]);
                                      }
                                    }}
                                  >
                                    {openMissionVivier.includes(item._id) ? (
                                      <ChevronDown wh={20} color={'#374151'} />
                                    ) : (
                                      <ChevronTop wh={20} color={'#374151'} />
                                    )}
                                  </div>
                                </div>
                                {openMissionVivier.includes(item._id) && (
                                  <>
                                    <div className={'flex flex-col gap-2 ml-6'}>
                                      {item?.missions?.map((mission, index) => (
                                        <div key={index}>
                                          {index === 0 && (
                                            <div className={'mt-4'}>
                                              <Divider />
                                            </div>
                                          )}
                                          <div className={'p-2 cursor-pointer'}>
                                            <label className="flex items-center gap-2 cursor-pointer">
                                              <input
                                                type="checkbox"
                                                className={
                                                  'w-4 h-4 accent-yellow-400 my-auto'
                                                }
                                                checked={listAddVivierMission.some(
                                                  (selectedItem) =>
                                                    selectedItem === mission._id
                                                )}
                                                onChange={() => {
                                                  if (
                                                    listAddVivierMission.some(
                                                      (selectedItem) =>
                                                        selectedItem ===
                                                        mission._id
                                                    )
                                                  ) {
                                                    setListAddVivierMission(
                                                      listAddVivierMission.filter(
                                                        (selectedItem) =>
                                                          selectedItem !==
                                                          mission._id
                                                      )
                                                    );
                                                  } else {
                                                    setListAddVivierMission([
                                                      ...listAddVivierMission,
                                                      mission._id,
                                                    ]);
                                                  }
                                                }}
                                              />
                                              <div className="flex flex-col pl-4">
                                                {mission.company} - n°
                                                {mission.id_mission}
                                              </div>
                                            </label>
                                          </div>
                                          <Divider />
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </div>
                              {!openMissionVivier.includes(item._id) && (
                                <Divider />
                              )}
                            </>
                          ))}
                      </div>
                    </>
                  ) : (
                    <EmptyState type={'mission_search'} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'flex w-full justify-between items-center'}>
          <div className={'flex gap-2 items-center'}>
            {listAddVivierMission.length > 0 && (
              <>
                <div></div>
                <div>
                  {totalUsersAvailable.length > 1
                    ? `${totalUsersAvailable.length} profils disponibles`
                    : `${totalUsersAvailable.length} profil disponible`}
                </div>
              </>
            )}
          </div>
          {sendNotifLoading ? (
            <Button size={'LG'} color={'DISABLED'}>
              Confirmer ...
            </Button>
          ) : (
            <Button onClick={handleSubmit} size={'LG'}>
              Confirmer
            </Button>
          )}
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalNotificationVivier;

import React from 'react';
import {Add} from "assets/icons";
import Divider from "components/divider/divider";
import Chip from "components/chip/chip";
import usefetchdata from "hooks/usefetchdata";
import useModal from "components/modal/useModal";

import UseContext from "hooks/useContext";
import CardProfile from "components/card/cardProfile/cardProfile";

const Tools = () => {
    const {tempWorkerData}=usefetchdata()
    const {toggle}=useModal()
    const {setObjID}=UseContext()


    const handleDeletedTool = (e,item) =>{
        e.preventDefault()
        setObjID(item)
        toggle('sf_d_tool')
    }

    const handleMateriel = (e) => {
        e.preventDefault()
        toggle('sf_c_tool')
    }
    return (
        <>
            <CardProfile>
                <div className="grid grid-cols-4 flex items-center">
                    <div className="col-span-3 ft-b text-gray-900">Materiels
                        possédés
                    </div>
                    <div className='col-span-1 flex justify-end'>
                        <button
                            onClick={handleMateriel}
                            className='rounded-full w-10 h-10 bg-blue-50 flex items-center justify-center'>
                            <Add wh={18} color={'#3B82F6'}/>
                        </button>
                    </div>
                </div>
                <Divider w={95}/>
                <div className=' flex flex-wrap gap-2 items-center justify-start my-4'>
                    {tempWorkerData.tools?.map(item =>
                        <Chip key={item} onClick={e=>{handleDeletedTool(e,item)}} icon={true} select={true} type={'SELECTON'}
                        >{item}</Chip>
                    )}
                </div>
            </CardProfile>
        </>
    );
};

export default Tools;
import {
  ArrowLeft,
  BanIcon,
  BellIcon,
  ChevronDown,
  CrossIcon,
  InformationCircle,
  KanbanIcon,
  Loading2,
  Localisation,
  Plus,
  SearchLoop,
  SortIcon,
  SwitchIcon,
  TableIcon,
} from 'assets/icons/index.js';
import axios from 'axios';
import Button from 'components/button/index.js';
import useModal from 'components/modal/useModal.js';
import Pagination from 'components/pagination/pagination.js';
import UseContext from 'hooks/useContext.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from '../../../../../components/tooltips/Tooltips';
import useAbortController from '../../../../../hooks/useAbortController';
import KanbanFicheVivier from './components/kanban.fiche.vivier';
import TableFicheVivier from './components/table.fiche.vivier.js';

function BodyFicheVivier() {
  const { toggle } = useModal();
  const navigate = useNavigate();
  const {
    setInfoVivier,
    refreshVivierLog,
    usersVivier,
    setUsersVivier,
    setObjID,
  } = UseContext();
  const { id } = useParams();
  const { height, width } = useWindowDimensions();
  const [search, setSearch] = useState('');
  const [optionSearch, setOptionSearch] = useState('');
  const [selectMQ, setSelectMQ] = useState(false);
  const [selectGP, setSelectGP] = useState(false);
  const [selectProfil, setSelectProfil] = useState(false);
  const [searchCandidatWaiting, setSearchCandidatWaiting] = useState(true);

  const [selectApp, setSelectApp] = useState(false);
  const [sortName, setSortName] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [countPerPage, setCountPerPage] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [globalUsersVivier, setGlobal] = useState();
  const [data, setData] = useState();
  const [result, setResult] = useState();
  const [result2, setResult2] = useState();
  const [vivier, setVivier] = useState();
  const [listAddVivier, setAddVivier] = useState([]);
  const [lastLogVivier, setLastLogVivier] = useState('');
  const [isVivierExclude, setVivierExclude] = useState(false);
  const [isVivierLink, setIsVivierLink] = useState(true);
  const [isVivierKanban, setIsVivierKanban] = useState(
    id !== '64e8cfd10b4f73f442432c9f'
  );
  const [osChecked, setOsChecked] = useState({
    web: true,
    app: true,
  });
  const [departmentChecked, setDepartmentChecked] = useState({
    martinique: true,
    guadeloupe: true,
    other: true,
  });
  const [sortChecked, setSortChecked] = useState({
    a_z: false,
    recent: false,
  });
  //get params from url
  const params = new URLSearchParams(window.location.search);
  const searchListVivier = params.get('searchListVivier');
  const pageListVivier = params.get('pageListVivier');

  const [dropdownMultipleActionsOpen, setDropdownMultipleActionsOpen] =
    useState(false);
  const [dropdownSortOpen, setDropdownSortOpen] = useState(false);
  const [dropdownDepartmentsOpen, setDropdownDepartmentsOpen] = useState(false);
  const [dropdownOsOpen, setDropdownOsOpen] = useState(false);
  const dropDownMultipleActionsRef = useRef(null);
  const dropDownSortRef = useRef(null);
  const dropDownDepartmentsRef = useRef(null);
  const dropDownOsRef = useRef(null);

  const [userGroupedByVivierStatus, setUserGroupedByVivierStatus] = useState(
    {}
  );
  const [userGroupedByVivierStatus2, setUserGroupedByVivierStatus2] = useState(
    {}
  );
  const [selectedColumn, setSelectedColumn] = useState(null);

  const getAbortController = useAbortController();

  const token = localStorage.getItem('xsrfToken');

  const kanbanColumns = [
    {
      name: 'wish',
      title: 'Souhait',
      icon: '🧞',
      description:
        'Profils ayant indiqué vouloir participer à ce type de missions.',
    },
    {
      name: 'test',
      title: 'Test',
      icon: '⏳',
      description: "Profils qualifiés par l'équipe de recrutement.",
    },
    {
      name: 'valid',
      title: 'Validé',
      icon: '✅',
      description: 'Profils notés positivement par un client.',
    },
    {
      name: 'refused',
      title: 'Refusé',
      icon: '❌',
      description: "Profils refusés par l'équipe de recrutement.",
    },
    {
      name: 'archive',
      title: 'Archivé',
      icon: '📦',
      description:
        'Profils indisponibles indéfiniment et/ou ne sont plus actif depuis plus de 45 jours.',
    },
  ];

  useEffect(() => {
    if (id && token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}vivier/logVivier?token=${token}&idVivier=${id}`
        )
        .then((res) => {
          if (Object.keys(res.data).length > 0) {
            //get first log vivier
            const dateLogVivier = new Date(res.data[0].createdAt);
            const dateLogVivierFormat = `${('0' + dateLogVivier.getDate())
              .toString()
              .slice(-2)}/${('0' + (dateLogVivier.getMonth() + 1))
              .toString()
              .slice(-2)}/${dateLogVivier.getFullYear()} à ${(
              '0' + dateLogVivier.getHours()
            )
              .toString()
              .slice(-2)}:${('0' + dateLogVivier.getMinutes())
              .toString()
              .slice(-2)}`;
            setLastLogVivier(`Notifié le ${dateLogVivierFormat}`);
          } else {
            setLastLogVivier(null);
          }
        })
        .catch((err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            toast.error('Une erreur est survenue');
          }
        });
    }
  }, [id, refreshVivierLog]);

  const handleSelectProfil = (e) => {
    e.preventDefault();
    setSelectProfil((prevState) => !prevState);
  };
  const handleSelectApp = (e) => {
    e.preventDefault();
    setSelectApp((prevState) => !prevState);
  };
  const handleDelete = (e) => {
    e.preventDefault();
    toggle('vivier_add');
  };
  const handleNotification = (e) => {
    e.preventDefault();
    setInfoVivier({ listUsers: globalUsersVivier });
    toggle('vivier_notification');
    setObjID(id);
  };

  const manageCandidatSearch = async () => {
    setSearchCandidatWaiting(true);
    const controller = getAbortController();

    try {
      const filterOptions = {
        ...osChecked,
        ...departmentChecked,
        ...sortChecked,
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}vivier/vivierFiche`,
        {
          signal: controller.signal,
          params: {
            page: currentPage,
            limit: limitElement,
            search: search,
            optionSearch: optionSearch,
            profil: selectProfil,
            id: id,
            filterOptions: JSON.stringify(filterOptions),
          },
        }
      );

      if (selectProfil) setCurrentPage(1);
      setCountPerPage(limitElement);
      setCountTotal(data.count);
      setData(data.count);
      setResult(data.users);
      const userResult2 = getCurrentPageValues(
        data.users,
        currentPage,
        limitElement
      );
      setResult2(userResult2);
      setTotalPages(Math.ceil(userResult2.length / limitElement));
      setSearchCandidatWaiting(false);
      setVivier(data.vivier);
      setIsVivierLink(data.vivier.qualification?.id_qualification ?? false);
      setGlobal(data.vivier?.user_preferences);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error('An error occurred:', error);
        setSearchCandidatWaiting(false);
      }
    }
  };

  const getCurrentPageValues = (arrayElement, currentPage, limitElement) => {
    if (!Array.isArray(arrayElement) || currentPage < 1 || limitElement < 1) {
      return [];
    }

    const startIndex = (currentPage - 1) * limitElement;
    const currentPageValues = arrayElement.slice(
      startIndex,
      startIndex + limitElement
    );

    return currentPageValues;
  };

  let timerViverSearch = '';
  useEffect(() => {
    timerViverSearch = setTimeout(async () => {
      await manageCandidatSearch();
    }, 400);
    return () => clearTimeout(timerViverSearch);
  }, [
    search,
    optionSearch,
    selectApp,
    selectMQ,
    sortName,
    currentPage,
    selectProfil,
    id,
    osChecked,
    departmentChecked,
    sortChecked,
  ]);

  useEffect(() => {
    if (id) {
      if (id === '64e8cfd10b4f73f442432c9f') {
        setVivierExclude(true);
      } else {
        setVivierExclude(false);
      }
    }
  }, [id]);

  useEffect(() => {
    if (usersVivier.length > 0) {
      const updateResults = (sourceResult) => {
        return sourceResult.map((resultItem) => {
          const matchingUser = usersVivier.find(
            (user) => user.id_user.toString() === resultItem._id.toString()
          );

          if (matchingUser) {
            const isInVivier = isInVivierForUser(matchingUser);

            if (!isInVivier) {
              resultItem.isDeleted = true;
            } else {
              resultItem.viviers =
                matchingUser.viviers?.map((vivier) => vivier.name) || [];
            }

            if (matchingUser.preferences_department) {
              resultItem.preferences_department =
                matchingUser.preferences_department;
            }

            if (matchingUser.vivier_status) {
              resultItem.vivier_status = matchingUser.vivier_status;
            }
          }

          return resultItem;
        });
      };

      const updatedResult = updateResults(result);
      const updatedResult2 = updateResults(result2);

      setResult(updatedResult);
      setResult2(updatedResult2);

      setUsersVivier([]);
      setInfoVivier({ listUsers: [] });
      setAddVivier([]);
      setData(updatedResult.length);
    }
  }, [usersVivier]);

  function isInVivierForUser(user) {
    if (user.viviers) {
      return user.viviers.some(
        (vivierUser) => vivierUser.name === vivier?.name
      );
    }

    return false;
  }

  const handleMultipleActions = () => {
    setDropdownMultipleActionsOpen((prev) => !prev);
  };

  const handleSort = () => {
    setDropdownSortOpen((prev) => !prev);
  };

  const handleDepartments = () => {
    setDropdownDepartmentsOpen((prev) => !prev);
  };

  const handleOs = () => {
    setDropdownOsOpen((prev) => !prev);
  };

  const handleAddVivier = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('vivier_add');
  };

  const excludeAgency = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('exclude_agency');
  };

  const changeLocalisation = () => {
    setInfoVivier({ listUsers: listAddVivier });
    toggle('localisation_preferences');
  };

  useEffect(() => {
    const refs = {
      dropDownMultipleActions: dropDownMultipleActionsRef,
      dropDownSort: dropDownSortRef,
      dropDownDepartments: dropDownDepartmentsRef,
      dropDownOs: dropDownOsRef,
    };

    const states = {
      dropDownMultipleActions: setDropdownMultipleActionsOpen,
      dropDownSort: setDropdownSortOpen,
      dropDownDepartments: setDropdownDepartmentsOpen,
      dropDownOs: setDropdownOsOpen,
    };

    const handleClickOutside = (e) => {
      Object.entries(refs).forEach(([refName, ref]) => {
        if (!ref.current.contains(e.target)) {
          states[refName](false);
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    dropDownMultipleActionsRef,
    dropDownSortRef,
    dropDownDepartmentsRef,
    dropDownOsRef,
  ]);

  const deleteVivier = async () => {
    setInfoVivier({
      idVivier: id,
      name: vivier?.name,
    });
    toggle('vivier_delete');
  };

  const changeUserVivierStatus = () => {
    setInfoVivier({
      idVivier: id,
      listUsers: listAddVivier,
    });
    toggle('vivier_change_status');
  };

  const excludeVivier = () => {
    setInfoVivier({
      idVivier: id,
      listUsers: listAddVivier,
    });
    toggle('vivier_delete_candidate');
  };

  useEffect(() => {
    if (result && Array.isArray(result)) {
      const groupedUsers = result.reduce((acc, user) => {
        const vivierStatus = user.vivier_status;
        acc[vivierStatus] = acc[vivierStatus] || [];
        acc[vivierStatus].push(user);
        return acc;
      }, {});
      setUserGroupedByVivierStatus(groupedUsers);
    }
  }, [result]);

  useEffect(() => {
    if (result2 && Array.isArray(result2)) {
      const groupedUsers = result2.reduce((acc, user) => {
        const vivierStatus = user.vivier_status;
        acc[vivierStatus] = acc[vivierStatus] || [];
        acc[vivierStatus].push(user);
        return acc;
      }, {});
      setUserGroupedByVivierStatus2(groupedUsers);
    }
  }, [result2]);

  useEffect(() => {
    if (selectedColumn) {
      const allUsers = [...result];

      const filteredUsers = allUsers.filter(
        (user) => user.vivier_status === selectedColumn
      );
      setCurrentPage(1);
      setTotalPages(Math.ceil(filteredUsers.length / limitElement));

      setResult2(
        getCurrentPageValues(filteredUsers, currentPage, limitElement)
      );
    }
  }, [selectedColumn]);

  const navigateListVivier = () => {
    if ((searchListVivier || searchListVivier === '') && pageListVivier) {
      navigate(
        `../listAllVivier?searchListVivier=${searchListVivier}&pageListVivier=${pageListVivier}&selectedVivier=${id}`
      );
    } else {
      navigate('../vivier');
    }
  };

  return (
    <div className="flex flex-col space-y-8 h-full">
      <div className="flex flex-row items-center justify-between border-b border-gray-100 bg-white sticky top-0 z-20 py-3">
        <div className="flex items-center gap-4">
          <div onClick={() => navigateListVivier()}>
            <ArrowLeft w={24} h={12} color={'#374151'} />
          </div>
          <div>{vivier?.name}</div>
        </div>
        <div className=" flex items-center gap-4">
          {!isVivierExclude && (
            <div
              className={`text-gray-500 underline cursor-pointer`}
              onClick={() => toggle('vivier_history')}
            >
              {lastLogVivier ?? 'Pas encore notifié'}
            </div>
          )}
          {!isVivierExclude && (
            <div>
              <Button
                size={'SM'}
                color={globalUsersVivier?.length > 0 ? 'PRIMARY' : 'DISABLED'}
                disabled={globalUsersVivier?.length > 0 ? false : true}
                onClick={handleNotification}
              >
                <div className={'flex gap-2 items-center'}>
                  <div>
                    <BellIcon wh={20} color={'#FFFFFF'} />
                  </div>
                  <div>Notifier</div>
                </div>
              </Button>
            </div>
          )}
          {!isVivierLink && id !== '64e8cfd10b4f73f442432c9f' && (
            <div>
              <Button
                size={'SM'}
                color={'PRIMARY'}
                onClick={() => deleteVivier()}
              >
                Supprimer le vivier
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between border-b border-gray-100 bg-white sticky top-0 z-20 py-3">
        <div className="">{data ? data : 0} candidats</div>
        <div className="flex gap-3">
          <div className={'my-auto relative'} ref={dropDownOsRef}>
            <Button color={'SECONDARY'} size={'SMI'} onClick={handleOs}>
              <div className={'flex gap-2'}>
                <div className={'my-auto flex gap-1'}>
                  <div className={'my-auto'}>
                    {
                      Object.keys(osChecked).filter((key) => osChecked[key])
                        .length
                    }
                  </div>
                  <div className={'my-auto'}>OS</div>
                </div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#374151'} />
                </div>
              </div>
            </Button>
            {dropdownOsOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setOsChecked({ ...osChecked, web: !osChecked.web });
                        }}
                        checked={osChecked.web}
                      />
                    </div>
                    <div className={'my-auto'}>Web</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setOsChecked({ ...osChecked, app: !osChecked.app });
                        }}
                        checked={osChecked.app}
                      />
                    </div>
                    <div className={'my-auto'}>App</div>
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className={'my-auto relative'} ref={dropDownDepartmentsRef}>
            <Button
              color={'SECONDARY'}
              size={'SMI'}
              onClick={handleDepartments}
            >
              <div className={'flex gap-2'}>
                <div className={'my-auto flex gap-1'}>
                  <div className={'my-auto'}>
                    {
                      Object.keys(departmentChecked).filter(
                        (key) => departmentChecked[key]
                      ).length
                    }
                  </div>
                  <div className={'my-auto'}>
                    {Object.keys(departmentChecked).filter(
                      (key) => departmentChecked[key]
                    ).length > 1
                      ? 'DÉPARTEMENTS'
                      : 'DÉPARTEMENT'}
                  </div>
                </div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#374151'} />
                </div>
              </div>
            </Button>
            {dropdownDepartmentsOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            martinique: !departmentChecked.martinique,
                          });
                        }}
                        checked={departmentChecked.martinique}
                      />
                    </div>
                    <div className={'my-auto'}>Martinique</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            guadeloupe: !departmentChecked.guadeloupe,
                          });
                        }}
                        checked={departmentChecked.guadeloupe}
                      />
                    </div>
                    <div className={'my-auto'}>Guadeloupe</div>
                  </div>
                </label>
                <label className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer">
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <input
                        type="checkbox"
                        className={'w-4 h-4 accent-yellow-400 m-1'}
                        onClick={() => {
                          setDepartmentChecked({
                            ...departmentChecked,
                            other: !departmentChecked.other,
                          });
                        }}
                        checked={departmentChecked.other}
                      />
                    </div>
                    <div className={'my-auto'}>Aucun</div>
                  </div>
                </label>
              </div>
            )}
          </div>
          {id !== '64e8cfd10b4f73f442432c9f' && (
            <div className={'my-auto'}>
              <Button
                color={'SECONDARY'}
                size={'SMI'}
                className={'w-28'}
                onClick={() => setIsVivierKanban(!isVivierKanban)}
              >
                <div className={'flex gap-2'}>
                  <div className={'my-auto'}>
                    {isVivierKanban ? (
                      <KanbanIcon wh={20} color={'#374151'} />
                    ) : (
                      <TableIcon wh={20} color={'#374151'} />
                    )}
                  </div>
                  <div className={'my-auto'}>
                    {isVivierKanban ? 'Kanban' : 'Table'}
                  </div>
                </div>
              </Button>
            </div>
          )}
          <div className={'my-auto relative'} ref={dropDownSortRef}>
            <Button color={'SECONDARY'} size={'SMI'} onClick={handleSort}>
              <SortIcon wh={20} color={'#374151'} />
            </Button>
            {dropdownSortOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <div
                  className={`flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer ${
                    sortChecked.a_z && 'text-yellow-500'
                  }`}
                  onClick={() => {
                    setSortChecked({ a_z: true, recent: false });
                  }}
                >
                  A-Z
                </div>
                <div
                  className={`flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer ${
                    sortChecked.recent && 'text-yellow-500'
                  }`}
                  onClick={() => {
                    setSortChecked({ a_z: false, recent: true });
                  }}
                >
                  Récent
                </div>
              </div>
            )}
          </div>
          <div
            className={`${
              !listAddVivier.length > 0 && 'hidden '
            }my-auto relative cursor-pointer`}
            ref={dropDownMultipleActionsRef}
          >
            <Button onClick={handleMultipleActions} color={'PRIMARY'}>
              <div className={'flex gap-1'}>
                <div className={'my-auto'}>Actions multiples</div>
                <div className={'my-auto'}>
                  <ChevronDown wh={20} color={'#FFFFFF'} />
                </div>
              </div>
            </Button>
            {dropdownMultipleActionsOpen && (
              <div
                className={`${
                  dropdownMultipleActionsOpen ? '' : 'hidden'
                } absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-10 z-50 bg-gray-50`}
              >
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => handleAddVivier()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <Plus wh={20} color={'#374151'} />
                    </div>
                    <div className={'my-auto'}>Ajouter dans un vivier</div>
                  </div>
                </div>
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => changeLocalisation()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <Localisation wh={20} color={'#374151'} />
                    </div>
                    <div className={'my-auto'}>Modifier la localisation</div>
                  </div>
                </div>
                <div className="border-b border-gray-100"></div>
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => changeUserVivierStatus()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <SwitchIcon wh={20} color={'#374151'} />
                    </div>
                    <div className={'my-auto'}>Déplacer de colonne</div>
                  </div>
                </div>
                <div className="border-b border-gray-100"></div>
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                  onClick={() => excludeVivier()}
                >
                  <div className={'flex gap-2'}>
                    <div className={'my-auto'}>
                      <CrossIcon wh={20} color={'#374151'} />
                    </div>
                    <div className={'my-auto'}>Supprimer du vivier</div>
                  </div>
                </div>
                {id !== '64e8cfd10b4f73f442432c9f' && (
                  <>
                    <div
                      className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                      onClick={() => excludeAgency()}
                    >
                      <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                          <BanIcon wh={20} color={'#DC2626'} />
                        </div>
                        <div className={'my-auto'}>Exclure de l'agence</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className={'flex gap-3 my-auto'}>
            <div className="relative text-gray-500">
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="w-full h-10 pl-10 pr-8 ft-b border rounded-lg"
                type="text"
                placeholder="Rechercher ..."
                required
              />
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <SearchLoop wh={18} color={'#666666'} />
              </div>
            </div>
            <select
              className="h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
              onChange={(e) => setOptionSearch(e.target.value)}
            >
              <option value="">Tous</option>
              <option value="id_bestt">ID BeSTT</option>
              <option value="name">Nom</option>
              <option value="mail">Email</option>
              <option value="phone">Téléphone</option>
            </select>
          </div>
        </div>
      </div>
      {searchCandidatWaiting && (
        <div
          className={`flex flex-col ${
            width <= 460 ? 'gap-5' : 'gap-10'
          } justify-center items-center h-80`}
        >
          <div className={'animate-spin'}>
            <Loading2 wh={width <= 460 ? 40 : 60} color={'#FF9600'} />
          </div>
        </div>
      )}
      {!searchCandidatWaiting && (
        <>
          {isVivierKanban ? (
            <KanbanFicheVivier
              users={userGroupedByVivierStatus}
              setSortName={setSortName}
              sortName={sortName}
              setAddVivier={setAddVivier}
              list={listAddVivier}
              idVivier={vivier._id}
            />
          ) : (
            <>
              {id !== '64e8cfd10b4f73f442432c9f' && (
                <div className={'flex gap-4'}>
                  {kanbanColumns.map((kanbanColumn, index) => (
                    <div
                      key={index}
                      className={`rounded p-4 w-full h-full cursor-pointer border ${
                        selectedColumn === kanbanColumn.name
                          ? 'border-yellow-500 bg-yellow-50'
                          : 'border-gray-100 bg-gray-100'
                      }`}
                      onClick={() => {
                        if (selectedColumn === kanbanColumn.name) {
                          setSelectedColumn(null);
                          return;
                        }
                        setSelectedColumn(kanbanColumn.name);
                      }}
                    >
                      <div className={'flex justify-between gap-1'}>
                        <div className={'flex gap-2 my-auto'}>
                          <div className={'my-auto text-xl'}>
                            {kanbanColumn.icon}
                          </div>
                          <div className={'my-auto'}>{kanbanColumn.title}</div>
                        </div>
                        <div className={'my-auto flex gap-1 items-center'}>
                          <div className={''}>
                            <Tooltip
                              text={kanbanColumn.description}
                              placement="top"
                            >
                              <div>
                                <InformationCircle wh={18} color={'#6B7280'} />
                              </div>
                            </Tooltip>
                          </div>
                          <div
                            className={`p-1 rounded w-fit h-fit text-sm ${
                              selectedColumn === kanbanColumn.name
                                ? 'bg-yellow-200 text-yellow-800'
                                : 'bg-gray-200'
                            }`}
                          >
                            {userGroupedByVivierStatus[kanbanColumn.name]
                              ?.length || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <TableFicheVivier
                users={
                  selectedColumn
                    ? userGroupedByVivierStatus2[selectedColumn]
                    : [].concat(...Object.values(userGroupedByVivierStatus2))
                }
                setSortName={setSortName}
                sortName={sortName}
                setAddVivier={setAddVivier}
                list={listAddVivier}
                idVivier={vivier._id}
              />
            </>
          )}
        </>
      )}
      {!isVivierKanban &&
      !searchCandidatWaiting &&
      currentPage &&
      totalPages > 1 &&
      setCurrentPage ? (
        <div className={'px-8 py-4'}>
          <Pagination
            currentPage={currentPage}
            countPerPage={countPerPage}
            countTotal={countTotal}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default BodyFicheVivier;

import Button from "../button";
import {ChevronLeft, ChevronRight, Pencil, Plus, TrashIcon} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import useModal from "../modal/useModal";
import UseContext from "../../hooks/useContext";
import Badge from "../badge/badge";
import {dateToEpoch} from "../../helper/helper";

const ScheduleWeekList = ({ indexScheduleWeek, scheduleWeek, isPrevious, isNext, isHidden, setScheduleWeekDisplay }) => {
    const {toggle} = useModal()
    const {activeScheduleWeekDay, setActiveScheduleWeekDay, updSchedule, setUpdSchedule} = UseContext()
    const [isScheduleWithPeriods, setIsScheduleWithPeriods] = useState(false)

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    const openModalAddEditScheduleWeek = (indexScheduleWeek, indexScheduleWeekDay) => {
        setActiveScheduleWeekDay([indexScheduleWeek, indexScheduleWeekDay]);
        toggle('cM_addScheduleWeek')
    }

    const openModalDeleteScheduleWeekDay = (indexScheduleWeek, indexScheduleWeekDay) => {
        setActiveScheduleWeekDay([indexScheduleWeek, indexScheduleWeekDay]);
        toggle('cM_deleteScheduleWeekDay')
    }

    const openModalDeleteScheduleWeek = (indexScheduleWeek) => {
        setActiveScheduleWeekDay([indexScheduleWeek, 0]);
        toggle('cM_deleteScheduleWeek')
    }

    useEffect(() => {
        let scheduleWithPeriods = false;
        scheduleWeek?.days?.map((day, indexScheduleWeekDay) => {
            if(!!day?.periods){
                scheduleWithPeriods = true;
            }
        })

        if(scheduleWithPeriods){
            setIsScheduleWithPeriods(true)
        }
        else{
            setIsScheduleWithPeriods(false)
        }

        setUpdSchedule(false)
    }, [scheduleWeek, updSchedule]);

    return (
        <div key={indexScheduleWeek} className={`flex flex-col gap-3 ${isHidden ? 'hidden' : null}`}>
            <div className={'flex flex-col gap-1'}>
                <div className={'ft-lg text-gray-900 font-medium'}>{scheduleWeek.name}</div>
                <div className={'flex gap-5 justify-between'}>
                    <div className={''}>
                        <div className={'ft-sm text-gray-500'}>
                            Ajoutez des périodes travaillées pour les jours de mission.
                        </div>
                    </div>
                    <div className={'flex gap-2'}>
                        <div className={''}>
                            <Button size={'SMI'}
                                    color={isScheduleWithPeriods ? 'SECONDARY' : 'DISABLED'}
                                    disabled={isScheduleWithPeriods ? null : 'disabled'}
                                    onClick={() => openModalDeleteScheduleWeek(indexScheduleWeek)}
                            >
                                <TrashIcon wh={20} color={isScheduleWithPeriods ? '#111827' : '#6B7280'}/>
                            </Button>
                        </div>
                        <div className={''}>
                            {/* Show previous parent div if clicked */}
                            <Button size={'SMI'}
                                    color={isPrevious ? 'SECONDARY' : 'DISABLED'}
                                    disabled={isPrevious ? null : 'disabled'}
                                    onClick={() => setScheduleWeekDisplay(indexScheduleWeek - 1)}
                            >
                                <ChevronLeft wh={20} color={isPrevious ? '#111827' : '#6B7280'}/>
                            </Button>
                        </div>
                        <div className={''}>
                            <Button size={'SMI'}
                                    color={isNext ? 'SECONDARY' : 'DISABLED'}
                                    disabled={isNext ? null : 'disabled'}
                                    onClick={() => setScheduleWeekDisplay(indexScheduleWeek + 1)}
                            >
                                <ChevronRight wh={20} color={isNext ? '#111827' : '#6B7280'}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col gap-3'}>
                {
                    scheduleWeek?.days?.map((day, indexScheduleWeekDay) => {
                        const monthList = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
                        const year = dateToEpoch(day.date).getFullYear()
                        const dayNumber = dateToEpoch(day.date).getDate()
                        const month = monthList[dateToEpoch(day.date).getMonth()]
                        const weekday = dateToEpoch(day.date).toLocaleDateString("fr-FR", {weekday: "long"});

                        return (
                            <div key={indexScheduleWeekDay} className={'flex justify-between p-4 border rounded-md'}>
                                <div className={'my-auto'}>
                                    <div>
                                        {capitalizeFirstLetter(weekday)} {dayNumber} {month} {year}
                                    </div>
                                    {
                                        !!day?.periods ? (
                                            <div className={'flex gap-2'}>
                                                {
                                                    day?.periods.periode_one_start && day?.periods.periode_one_end ? (
                                                        <div className={'ft-sm'}>
                                                            <Badge type={day?.periods.periode_one_start === '00:00' && day?.periods.periode_one_end === '00:00' ? 'PRIMARY' : 'WARNING'}>
                                                                {day?.periods.periode_one_start === '00:00' && day?.periods.periode_one_end === '00:00' ? 'Non travaillé' : `${day?.periods.periode_one_start} > ${day?.periods.periode_one_end}`}
                                                            </Badge>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    day?.periods.periode_two_start && day?.periods.periode_two_end ? (
                                                        <div className={'ft-sm'}>
                                                            <Badge type={'WARNING'}>
                                                                {day?.periods.periode_two_start} > {day?.periods.periode_two_end}
                                                            </Badge>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    day?.periods.periode_three_start && day?.periods.periode_three_end ? (
                                                        <div className={'ft-sm'}>
                                                            <Badge type={'WARNING'}>
                                                                {day?.periods.periode_three_start} > {day?.periods.periode_three_end}
                                                            </Badge>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ) : null
                                    }
                                    <div></div>
                                </div>
                                <div className={'my-auto flex gap-3'}>
                                    {
                                        !!day?.periods ? (
                                            <>
                                                <div className={'my-auto'}>
                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={() => openModalDeleteScheduleWeekDay(indexScheduleWeek, indexScheduleWeekDay)}>
                                                        <TrashIcon wh={20} color={'#111827'}/>
                                                    </Button>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <Button size={'SMI'} color={'SECONDARY'} onClick={() => openModalAddEditScheduleWeek(indexScheduleWeek, indexScheduleWeekDay)}>
                                                        <Pencil wh={20} color={'#111827'}/>
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Button size={'SMI'} color={'SECONDARY'} onClick={() => openModalAddEditScheduleWeek(indexScheduleWeek, indexScheduleWeekDay)}>
                                                <Plus wh={'15'} color={'#111827'} className='fill-gray-400'/>
                                            </Button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ScheduleWeekList;
import Button from 'components/button';
import ModalBody from 'components/modal/components/body';
import ModalFooter from 'components/modal/components/footer';
import Modalheader from 'components/modal/components/header';
import useModal from 'components/modal/useModal';
import UseContext from 'hooks/useContext';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ApiDatabase from 'server';

const ModalDelBonusesAndAllowances = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const token = localStorage.getItem('xsrfToken');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (modalData.idCompany && modalData.idBonusesAndAllowances) {
      setIsLoading(true);

      ApiDatabase.postDelBonusesAndAllowances(
        {
          token,
          idCompany: modalData.idCompany,
          idBonusesAndAllowances: modalData.idBonusesAndAllowances,
        },
        (data) => {
          setIsLoading(false);
          toast.success('La prime a bien été supprimé');
          setModalDataReturn({
            type: 'bonuses_and_allowances_deleted',
            idBonusesAndAllowances: modalData.idBonusesAndAllowances,
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Supprimer une prime
      </Modalheader>
      <ModalBody>Êtes-vous sûr de vouloir supprimer cette prime ?</ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>
              Annuler
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Confirmation ...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Annuler
            </Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalDelBonusesAndAllowances;

import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Calendar,
  ChevronDown,
  ChevronRight,
  ClockIcon,
  CrossIcon,
  Pencil,
  Plus,
  Warning,
} from '../../../../../assets/icons';
import { dateWordShort } from '../../../../../helper/helper';
import UseContext from '../../../../../hooks/useContext';
import usefetchdata from '../../../../../hooks/usefetchdata';
import ApiDatabase from '../../../../../server';
import Badge from '../../../../badge/badge';
import BadgeSquare from '../../../../badge/badgeSquare';
import Button from '../../../../button';
import CopyToClipboardLarge from '../../../../copyToClipboard/copyToClipboardLarge';
import Divider from '../../../../divider/divider';
import Experiences from '../../../../experiences/experiences';
import IdentityFileItem from '../../../../identityFileItem/IdentityFileItem';
import Licences from '../../../../licences/licences';
import UseModal from '../../../../modal/useModal';
import Tooltip from '../../../../tooltips/Tooltips';
import Trainings from '../../../../trainings/trainings';

const PreviewTempWorker = React.forwardRef(({ tempWorkerInfos }, ref) => {
  const {
    setAdminLink,
    setAdminType,
    setAdminTempWorkerId,
    setInfoVivier,
    usersVivier,
    setUsersVivier,
    setUserVivierToArchive,
    userVivierToArchiveUpdated,
    setUserVivierToArchiveUpdated,
  } = UseContext();
  const { userData } = usefetchdata();
  const { toggle } = UseModal();
  const [isVivierOpen, setIsVivierOpen] = useState(false);
  const [isTrainingGuidesOpen, setIsTrainingGuidesOpen] = useState(false);
  const [isIdentityFilesOpen, setIsIdentityFilesOpen] = useState(false);
  const [selectedVivierStatus, setSelectedVivierStatus] = useState({});
  const [isToastVisible, setIsToastVisible] = useState(false);
  const vivierRef = useRef(null);
  const [isIdentityFileOpen, setIsIdentityFileOpen] = useState({
    identity: false,
    visa: false,
    card_vitality: false,
  });
  const token = localStorage.getItem('xsrfToken');

  const kanbanColumns = [
    { name: 'wish', title: 'Souhait', icon: '🧞' },
    { name: 'test', title: 'Test', icon: '⏳' },
    { name: 'valid', title: 'Validé', icon: '✅' },
    { name: 'refused', title: 'Refusé', icon: '❌' },
    { name: 'archive', title: 'Archivé', icon: '📦' },
  ];

  const openDocument = (type, type2, uri) => {
    setAdminLink(uri);
    setAdminType(type);

    if (type2 === 'view') {
      setAdminTempWorkerId('');
      toggle('ad_file_view');
    } else {
      setAdminTempWorkerId(tempWorkerInfos?.tempWorker?._id);
      if (type === 'identity') toggle('ad_file_accept_identity');
      else if (type === 'visa') toggle('ad_file_accept_visa');
      else if (type === 'card_vitality') toggle('ad_file_accept_card_vitality');
      else if (type === 'proof_of_address') toggle('ad_file_accept_simple');
      else if (type === 'accommodation_of_address')
        toggle('ad_file_accept_simple');
      else toggle('ad_file_view');
    }
  };

  useEffect(() => {
    if (usersVivier.length > 0) {
      for (let i = 0; i < usersVivier.length; i++) {
        if (
          usersVivier[i].id_user.toString() ===
          tempWorkerInfos?.user?._id.toString()
        ) {
          tempWorkerInfos.viviers = usersVivier[i].viviers;
          tempWorkerInfos.tempWorker.preferences_department =
            usersVivier[i].preferences_department;
        }
      }
      setUsersVivier([]);
      setInfoVivier({ listUsers: [] });
      if (tempWorkerInfos.viviers?.length === 0) setIsVivierOpen(false);
    }
  }, [usersVivier]);

  const addVivierSelected = (e, idUser) => {
    e.stopPropagation();
    setInfoVivier({ listUsers: [idUser] });
    toggle('vivier_add');
  };

  const handleChangeVivierStatus = (idVivier, vivierStatus) => {
    if (idVivier && vivierStatus) {
      if (vivierStatus === 'archive') {
        setUserVivierToArchive({
          idUsers: [tempWorkerInfos?.user?._id],
          idVivier,
          vivierStatus: vivierStatus,
        });

        toggle('vivier_user_archive');
      } else {
        ApiDatabase.updUserVivierPreferenceStatus(
          {
            token,
            idUsers: [tempWorkerInfos?.user?._id],
            idVivier,
            vivierStatus: vivierStatus,
          },
          (data) => {
            if (!isToastVisible) {
              setIsToastVisible(true);
              toast.success(`L'utilisateur a été déplacé.`);
            }

            setSelectedVivierStatus((prevData) => ({
              ...prevData,
              [idVivier]: vivierStatus,
            }));
          },
          (err) => {
            console.log('err', err);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (
      userVivierToArchiveUpdated.idUsers &&
      userVivierToArchiveUpdated.idVivier &&
      userVivierToArchiveUpdated.vivierStatus
    ) {
      for (let i = 0; i < userVivierToArchiveUpdated.idUsers.length; i++) {
        if (
          tempWorkerInfos?.user?._id === userVivierToArchiveUpdated.idUsers[i]
        ) {
          setSelectedVivierStatus((prevData) => ({
            ...prevData,
            [userVivierToArchiveUpdated.idVivier]:
              userVivierToArchiveUpdated.vivierStatus,
          }));
        }
      }
      setUserVivierToArchiveUpdated({});
    }
  }, [userVivierToArchiveUpdated]);

  useEffect(() => {
    if (tempWorkerInfos?.viviers?.length > 0) {
      for (let i = 0; i < tempWorkerInfos?.viviers.length; i++) {
        for (
          let j = 0;
          j < tempWorkerInfos?.viviers[i].user_preferences?.length;
          j++
        ) {
          if (
            tempWorkerInfos?.viviers[i].user_preferences[j].id_user ===
            tempWorkerInfos?.user?._id
          ) {
            setSelectedVivierStatus((prevData) => ({
              ...prevData,
              [tempWorkerInfos?.viviers[i]._id]:
                tempWorkerInfos?.viviers[i].user_preferences[j].status,
            }));
            break;
          }
        }
      }
    }
  }, [tempWorkerInfos?.viviers]);

  useEffect(() => {
    if (isToastVisible) {
      const timeoutId = setTimeout(() => {
        setIsToastVisible(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [isToastVisible]);

  const excludeVivier = (idVivier) => {
    setInfoVivier({
      idVivier: idVivier,
      nameUser:
        tempWorkerInfos?.user?.firstname +
        ' ' +
        tempWorkerInfos?.user?.lastname,
      listUsers: [tempWorkerInfos?.user?._id],
    });
    toggle('vivier_delete_candidate');
  };

  const changeLocalisation = (idUser) => {
    setInfoVivier({ listUsers: [idUser] });
    toggle('localisation_preferences');
  };

  const getAvailabilityText = () => {
    const availability = tempWorkerInfos?.tempWorker?.availability;

    if (!availability) {
      return 'Non renseigné';
    }

    const { is_available, date_start, date_end } = availability;

    if (is_available === false) {
      if (!date_start && !date_end) {
        return 'Non disponible';
      } else if (date_start && !date_end) {
        return "Non disponible jusqu'au " + dateWordShort(new Date(date_start));
      } else if (!date_start && date_end) {
        return (
          'Non disponible à partir du ' + dateWordShort(new Date(date_end))
        );
      } else {
        return 'Disponible du ' + dateWordShort(new Date(date_start)) + ' au ' + dateWordShort(new Date(date_end));
      }
    } else if (is_available === true) {
      if (!date_start && !date_end) {
        return 'Disponibilité complète';
      } else if (date_start && !date_end) {
        return 'Disponible à partir du ' + dateWordShort(new Date(date_start));
      } else if (!date_start && date_end) {
        return "Disponible jusqu'au " + dateWordShort(new Date(date_end));
      } else {
        return (
          'Disponible du ' +
          dateWordShort(new Date(date_start)) +
          ' au ' +
          dateWordShort(new Date(date_end))
        );
      }
    } else {
      return 'Non renseigné';
    }
  };

  const getAvailabilityStatus = (unavailableDays) => {
    const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    return days.map((day, index) => {
      return {
        day,
        status: unavailableDays.includes(index) ? false : true,
      };
    });
  };

  useImperativeHandle(ref, () => ({
    scrollToVivier: () => {
      setIsVivierOpen(true);
      if (vivierRef.current) {
        vivierRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
  }));

  // Pour l'Identity
  const identityData = tempWorkerInfos.tempWorker?.identity;
  const identityTitle =
    identityData?.type === 'Passeport' ? 'Passeport' : "Carte d'identité";

  let identityFilePathFinal = null;
  const identityFilePath1 =
    process.env.REACT_APP_FILE + identityData?.fileR?.filePath;
  const identityFilePath2 = identityData?.fileV?.filePath
    ? process.env.REACT_APP_FILE + identityData?.fileV?.filePath
    : null;

  if (identityFilePath2 === null)
    identityFilePathFinal = { filePath1: identityFilePath1 };
  else
    identityFilePathFinal = {
      filePath1: identityFilePath1,
      filePath2: identityFilePath2,
    };

  const identityValidatedType =
    identityData?.validated === '2' ? '5' : identityData?.validated;

  const identityDetails = [
    {
      label: "Numéro d'identité",
      value: identityData?.number_identity,
    },
    {
      label: 'Ville de naissance',
      value: identityData?.birthplace,
    },
    {
      label: 'Validité',
      value: `${
        identityData?.start_validity
          ? dateWordShort(identityData.start_validity)
          : 'NaN'
      } - ${
        identityData?.end_validity
          ? dateWordShort(identityData.end_validity)
          : 'NaN'
      }`,
    },
  ];

  // Pour le Visa
  const visaData = tempWorkerInfos.tempWorker?.visa;
  const visaTitle = visaData?.type === 'Visa' ? 'Visa' : 'Titre de séjour';

  let visaFilePathFinal = null;
  const visaFilePath1 = process.env.REACT_APP_FILE + visaData?.fileR?.filePath;
  const visaFilePath2 = visaData?.fileV?.filePath
    ? process.env.REACT_APP_FILE + visaData?.fileV?.filePath
    : null;

  if (visaFilePath2 === null) visaFilePathFinal = { filePath1: visaFilePath1 };
  else
    visaFilePathFinal = {
      filePath1: visaFilePath1,
      filePath2: visaFilePath2,
    };

  const visaValidatedType =
    visaData?.validated === '2' ? '5' : visaData?.validated;

  const visaDetails = [
    {
      label: 'Numéro',
      value: visaData?.number_visa,
    },
    {
      label: 'Validité',
      value: `${
        visaData?.start_validity
          ? dateWordShort(visaData.start_validity)
          : 'NaN'
      } - ${
        visaData?.end_validity ? dateWordShort(visaData.end_validity) : 'NaN'
      }`,
    },
  ];

  // Pour la Carte Vitale
  const cardVitalityData = tempWorkerInfos.tempWorker?.card_vitality;
  const cardVitalityTitle = 'Carte vitale';

  let cardVitalityFilePathFinal = null;
  const cardVitalityFilePath1 =
    process.env.REACT_APP_FILE + cardVitalityData?.file?.filePath;
  if (cardVitalityFilePath1)
    cardVitalityFilePathFinal = { filePath1: cardVitalityFilePath1 };

  const cardVitalityValidatedType =
    cardVitalityData?.validated === '2' ? '5' : cardVitalityData?.validated;

  const cardVitalityDetails = [
    {
      label: 'Numéro',
      value: cardVitalityData?.social_security,
    },
  ];

  // Pour le Justificatif d'adresse
  const proofOfAddressData = tempWorkerInfos.tempWorker?.proof_of_address;
  const proofOfAddressTitle = "Justificatif d'adresse";

  let proofOfAddressFilePathFinal = null;
  const proofOfAddressFilePath1 =
    process.env.REACT_APP_FILE + proofOfAddressData?.file?.filePath;
  if (proofOfAddressFilePath1)
    proofOfAddressFilePathFinal = { filePath1: proofOfAddressFilePath1 };

  const proofOfAddressValidatedType =
    proofOfAddressData?.validated === '2' ? '5' : proofOfAddressData?.validated;

  // Pour l'Attestation d'hébergement
  const accommodationData =
    tempWorkerInfos.tempWorker?.accommodation_of_address;
  const accommodationTitle = "Attestation d'hébergement";

  let accommodationFilePathFinal = null;
  const accommodationFilePath1 =
    process.env.REACT_APP_FILE + accommodationData?.file?.filePath;
  if (accommodationFilePath1)
    accommodationFilePathFinal = { filePath1: accommodationFilePath1 };

  const accommodationValidatedType =
    accommodationData?.validated === '2' ? '5' : accommodationData?.validated;

  return (
    <>
      <div className={'grid gap-2 grid-cols-1 md:grid-cols-2'}>
        <div className={'border rounded p-3 flex justify-between items-center'}>
          <CopyToClipboardLarge
            libelle={'Adresse e-mail'}
            text={tempWorkerInfos?.user.email}
          />
        </div>
        <div className={'border rounded p-3'}>
          <CopyToClipboardLarge
            libelle={'Numéro'}
            text={tempWorkerInfos?.user.phone}
          />
        </div>
        <div className={'border rounded p-3'}>
          <div>Date de naissance</div>
          <div className={'text-gray-500 text-sm'}>
            {tempWorkerInfos?.user.birthday}
          </div>
        </div>
        <div className={'border rounded p-3'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>
              <div>Localisation</div>
              <div className={'text-gray-500 text-sm'}>
                {tempWorkerInfos?.tempWorker?.preferences_department?.city &&
                tempWorkerInfos?.tempWorker?.preferences_department?.city
                  ? tempWorkerInfos?.tempWorker?.preferences_department?.city +
                    ', ' +
                    tempWorkerInfos?.tempWorker?.preferences_department
                      ?.department
                  : 'Non renseigné'}
              </div>
            </div>
            <div
              className={
                'border rounded w-8 h-8 flex justify-center items-center cursor-pointer'
              }
              onClick={() => changeLocalisation(tempWorkerInfos?.user._id)}
            >
              <Pencil wh={20} color={'#374151'} />
            </div>
          </div>
        </div>
        <div className={'border rounded p-3'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>
              <div>Nationalité</div>
              <div className={'text-gray-500 text-sm'}>
                {tempWorkerInfos?.tempWorker?.identity?.nationality
                  ? tempWorkerInfos?.tempWorker?.identity?.nationality
                      .NationaliteF
                  : 'Non renseigné'}
              </div>
            </div>
            <div className={'flex gap-1 items-center'}>
              {tempWorkerInfos?.tempWorker?.visa?.end_validity && (
                <>
                  {new Date(
                    tempWorkerInfos?.tempWorker?.visa?.end_validity
                  ).getTime() < new Date().getTime() && (
                    <Tooltip
                      text={`Date de fin de visa : ${dateWordShort(
                        new Date(
                          tempWorkerInfos?.tempWorker?.visa?.end_validity
                        )
                      )}`}
                      placement="top"
                    >
                      <div>
                        <ClockIcon wh={20} color={'#DC2626'} />
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
              {tempWorkerInfos?.tempWorker?.identity?.nationality
                ?.UnionEuropéenne === 0 && (
                <Tooltip text={`Pays hors UE`} placement="top">
                  <div>
                    <Warning wh={20} color={'#D77A1E'} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className={'border rounded p-3'}>
          {tempWorkerInfos?.tempWorker?.bank?.iban ? (
            <CopyToClipboardLarge
              libelle={'IBAN'}
              text={tempWorkerInfos?.tempWorker?.bank?.iban.replace(/\s/g, '')}
            />
          ) : (
            <>
              <div>IBAN</div>
              <div className={'text-gray-500 text-sm'}>Non renseigné</div>
            </>
          )}
        </div>
        <div className={'border rounded p-3 col-span-full'}>
          <div className={'flex flex-col gap-1'}>
            <div className={'flex gap-1 items-center'}>
              <div>
                <Calendar wh={20} color={'#374151'} />
              </div>
              <div>{getAvailabilityText()}</div>
            </div>
            <div className={'flex gap-1 items-center'}>
              {getAvailabilityStatus(
                tempWorkerInfos?.tempWorker?.unavailable_days ?? []
              ).map((day, index) => (
                <div
                  className={`my-auto rounded w-[24px] h-[24px] flex justify-center items-center cursor-pointer ${
                    day.status
                      ? 'text-green-500 bg-green-50'
                      : 'text-gray-500 bg-gray-100'
                  }`}
                >
                  <Tooltip
                    text={day.status ? 'Disponible' : 'Non disponible'}
                    placement="top"
                  >
                    <div>{day.day}</div>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={'border rounded p-3 flex flex-col gap-6'}>
        <div
          className={'flex justify-between select-none cursor-pointer'}
          onClick={() => setIsIdentityFilesOpen(!isIdentityFilesOpen)}
        >
          <div className={'my-auto'}>Fichiers d'identité</div>
          <div className={'my-auto'}>
            <div
              className={
                'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'
              }
            >
              {isIdentityFilesOpen ? (
                <ChevronDown wh={20} color={'#374151'} />
              ) : (
                <ChevronRight wh={24} color={'#374151'} />
              )}
            </div>
          </div>
        </div>
        {isIdentityFilesOpen && (
          <div className={'grid gap-2 grid-cols-1 md:grid-cols-2'}>
            {/* Identity */}
            <IdentityFileItem
              title={identityTitle}
              isOpen={isIdentityFileOpen.identity}
              onToggle={() => {
                setIsIdentityFileOpen((prevData) => ({
                  ...prevData,
                  identity: !prevData.identity,
                }));
              }}
              openDocument={openDocument}
              id="identity"
              filePathFinal={identityFilePathFinal}
              validatedType={identityValidatedType}
              details={identityDetails}
            />
            {/* Visa */}
            {tempWorkerInfos.tempWorker.visa?.type && (
              <IdentityFileItem
                title={visaTitle}
                isOpen={isIdentityFileOpen.visa}
                onToggle={() => {
                  setIsIdentityFileOpen((prevData) => ({
                    ...prevData,
                    visa: !prevData.visa,
                  }));
                }}
                openDocument={openDocument}
                id="visa"
                filePathFinal={visaFilePathFinal}
                validatedType={visaValidatedType}
                details={visaDetails}
              />
            )}
            {/* Carte Vitale */}
            <IdentityFileItem
              title={cardVitalityTitle}
              isOpen={isIdentityFileOpen.card_vitality}
              onToggle={() => {
                setIsIdentityFileOpen((prevData) => ({
                  ...prevData,
                  card_vitality: !prevData.card_vitality,
                }));
              }}
              openDocument={openDocument}
              id="card_vitality"
              filePathFinal={cardVitalityFilePathFinal}
              validatedType={cardVitalityValidatedType}
              details={cardVitalityDetails}
            />
            {/* Justificatif d'adresse */}
            <IdentityFileItem
              title={proofOfAddressTitle}
              openDocument={openDocument}
              id="proof_of_address"
              filePathFinal={proofOfAddressFilePathFinal}
              validatedType={proofOfAddressValidatedType}
              details={[]}
            />
            {/* Attestation d'hébergement */}
            {tempWorkerInfos.tempWorker?.accommodation_of_address?.file && (
              <IdentityFileItem
                title={accommodationTitle}
                openDocument={openDocument}
                id="accommodation_of_address"
                filePathFinal={accommodationFilePathFinal}
                validatedType={accommodationValidatedType}
                details={[]}
              />
            )}
          </div>
        )}
      </div>
      <div className={'border rounded p-3 flex flex-col gap-6'}>
        <div
          className={'flex justify-between select-none cursor-pointer'}
          onClick={() => setIsTrainingGuidesOpen(!isTrainingGuidesOpen)}
        >
          <div className={'my-auto'}>Guides & Formations</div>
          <div className={'my-auto'}>
            <div
              className={
                'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'
              }
            >
              {isTrainingGuidesOpen ? (
                <ChevronDown wh={20} color={'#374151'} />
              ) : (
                <ChevronRight wh={24} color={'#374151'} />
              )}
            </div>
          </div>
        </div>
        {isTrainingGuidesOpen && (
          <div className={'flex flex-col gap-6'}>
            <div className={'flex justify-between gap-2'}>
              <div className={'my-auto'}>Guide de bienvenue</div>
              <div className={'my-auto'}>
                <BadgeSquare
                  type={
                    tempWorkerInfos?.tempWorker?.welcome_guide?.is_read ? 6 : 7
                  }
                />
              </div>
            </div>
            <div className={'flex justify-between gap-2'}>
              <div className={'my-auto'}>Livret de sécurité</div>
              <div className={'my-auto'}>
                <BadgeSquare
                  type={
                    tempWorkerInfos?.tempWorker?.safety_booklet?.is_read ? 6 : 7
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div ref={vivierRef} className={'border rounded p-3 flex flex-col gap-6'}>
        <div
          className={'flex justify-between select-none cursor-pointer'}
          {...(tempWorkerInfos.viviers.length > 0
            ? { onClick: () => setIsVivierOpen(!isVivierOpen) }
            : {})}
        >
          <div className={'my-auto'}>Vivier</div>
          <div className={'my-auto'}>
            {tempWorkerInfos.viviers.length === 0 ? (
              <Button
                color={'SECONDARY'}
                size={'SM'}
                onClick={(e) =>
                  addVivierSelected(e, tempWorkerInfos?.user?._id)
                }
              >
                <div className={'flex gap-1 items-center'}>
                  <div className={''}>
                    <Plus wh={20} color={'#374151'} />
                  </div>
                  <div className={''}>Ajouter</div>
                </div>
              </Button>
            ) : (
              <>
                <div className={'flex gap-2 items-center'}>
                  <div className={'text-gray-500 text-sm'}>
                    {tempWorkerInfos.viviers.length === 1
                      ? '1 vivier'
                      : tempWorkerInfos.viviers.length + ' viviers'}
                  </div>
                  <div
                    className={
                      'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'
                    }
                    onClick={(e) =>
                      addVivierSelected(e, tempWorkerInfos?.user?._id)
                    }
                  >
                    <Plus wh={20} color={'#374151'} />
                  </div>
                  <div
                    className={
                      'flex cursor-pointer border rounded w-8 h-8 items-center justify-center'
                    }
                  >
                    {isVivierOpen ? (
                      <ChevronDown wh={20} color={'#374151'} />
                    ) : (
                      <ChevronRight wh={20} color={'#374151'} />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isVivierOpen && (
          <div className={'flex flex-col gap-6'}>
            {tempWorkerInfos.viviers?.map((vivier, index) => (
              <div
                key={index}
                className={'flex justify-between gap-2 items-center'}
              >
                <div className={''}>{vivier.name}</div>
                <div className={'flex gap-2 items-center'}>
                  <div className={''}>
                    {selectedVivierStatus[vivier._id] === 'archive' ? (
                      <div
                        className={
                          'w-full px-2 py-1 ft-b border rounded-lg text-gray-900 bg-gray-100 flex items-center'
                        }
                      >
                        {kanbanColumns.map((kanban, index) => {
                          if (
                            selectedVivierStatus[vivier._id] === kanban.name
                          ) {
                            return (
                              <div
                                key={index}
                                className={'flex gap-1 items-center'}
                              >
                                <div className={'my-auto'}>{kanban.icon}</div>
                                <div className={'my-auto'}>{kanban.title}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <>
                        {vivier._id.toString() !==
                          '64e8cfd10b4f73f442432c9f' && (
                          <select
                            className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900"
                            value={selectedVivierStatus[vivier._id]}
                            onChange={(e) =>
                              handleChangeVivierStatus(
                                vivier._id,
                                e.target.value
                              )
                            }
                          >
                            {kanbanColumns.map((kanban, index) => (
                              <option key={index} value={kanban.name}>
                                {kanban.icon} - {kanban.title}
                              </option>
                            ))}
                          </select>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={'cursor-pointer'}
                    onClick={() => excludeVivier(vivier._id)}
                  >
                    <CrossIcon wh={20} color={'#374151'} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={'border rounded p-3 flex flex-col gap-6'}>
        <div className={'flex flex-col gap-4'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>Langues</div>
            <div
              className={
                'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'
              }
            >
              {tempWorkerInfos?.tempWorker?.language?.length}
            </div>
          </div>
          {tempWorkerInfos?.tempWorker?.language?.length > 0 && (
            <div className={'flex gap-2 flex-wrap'}>
              {tempWorkerInfos?.tempWorker?.language?.map((language, index) => (
                <Badge key={index} type={'WARNING'}>
                  {language}
                </Badge>
              ))}
            </div>
          )}
        </div>
        <Divider />
        <div className={'flex flex-col gap-4'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>Formations</div>
            <div
              className={
                'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'
              }
            >
              {tempWorkerInfos?.tempWorker?.formations?.length}
            </div>
          </div>
          {tempWorkerInfos?.tempWorker?.formations?.length > 0 && (
            <div className={'flex flex-col gap-4 text-sm'}>
              {tempWorkerInfos?.tempWorker?.formations && (
                <Trainings formations={tempWorkerInfos.tempWorker.formations} />
              )}
            </div>
          )}
        </div>
        <Divider />
        <div className={'flex flex-col gap-4'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>Permis</div>
            <div
              className={
                'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'
              }
            >
              {tempWorkerInfos?.tempWorker?.licences?.length}
            </div>
          </div>
          {tempWorkerInfos?.tempWorker?.licences?.length > 0 && (
            <div className={'flex flex-col gap-4 text-sm'}>
              {tempWorkerInfos?.tempWorker?.licences && (
                <Licences licences={tempWorkerInfos.tempWorker.licences} />
              )}
            </div>
          )}
        </div>
        <Divider />
        <div className={'flex flex-col gap-4'}>
          <div className={'flex justify-between gap-2 items-center'}>
            <div>Expériences</div>
            <div
              className={
                'border rounded bg-gray-100 w-5 h-5 flex justify-center items-center text-sm'
              }
            >
              {tempWorkerInfos?.tempWorker?.experiences?.length}
            </div>
          </div>
          {tempWorkerInfos?.tempWorker?.experiences?.length > 0 && (
            <div className={'flex flex-col gap-4 text-sm'}>
              {tempWorkerInfos?.tempWorker?.experiences && (
                <Experiences
                  experiences={tempWorkerInfos.tempWorker.experiences}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default PreviewTempWorker;

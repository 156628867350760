import React from 'react';
import Banner from "components/banner/banner";
import Divider from "components/divider/divider";
import CardIdentityValidationLine
    from "components/card/CardIndentityValidation/CardIndentityValidationLine";
import usefetchdata from "hooks/usefetchdata";
import useModal from "components/modal/useModal";

const Identity = () => {
    const {tempWorkerData}=usefetchdata()
const {toggle}=useModal()
    return (
        <div className='flex flex-col mb-6'>
            <div className=''>
                <h1 className='ft-xl text-gray-700 py-2'>Justificatifs
                    d’identité</h1>
                <span className='ft-b text-gray-500'>Ces documents seront utilisés pour valider votre identité, créer votre contrat et vous payer. Validation en 48h maximum.</span>
            </div>
            <div className='py-4'>
                <Banner type='info'>
                    Nous ne gardons pas ces données, elles sont uniquement
                    traitées
                    afin de valider votre inscription dans notre agence.
                </Banner>
            </div>
            <Divider/>
            <div className='py-2'>
                <CardIdentityValidationLine type={'identity'}
                                            onClick={e=>{toggle('j_c_identity')}}
                                            status={tempWorkerData?.identity?.validated}/>
            </div>
            <div className='py-2'>
                <CardIdentityValidationLine type={'vital_card'}
                                            onClick={e=>{toggle('j_c_vitale')}}
                                            status={tempWorkerData?.card_vitality?.validated}/>
            </div>
            <div className='py-2'>
                <CardIdentityValidationLine type={'proof_address'}
                                            onClick={e=>{toggle('j_c_proof')}}
                                            status={tempWorkerData?.proof_of_address?.validated}/>
            </div>
            <div className='py-2'>
                <CardIdentityValidationLine type={'bank_id'}
                                            onClick={e=>{tempWorkerData?.bank?.validated === '0' ? toggle('j_c_iban'):toggle('j_u_iban')}}
                                            status={tempWorkerData?.bank?.validated}/>
            </div>
            {/*<div className='py-2'>*/}
            {/*    <Meetingcard pC={'meeting_disabled'}*/}
            {/*        // status={tempWorkerData?.validation === 1 ? 'meeting_disabled':'meeting_soon'}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className='py-2'>*/}
            {/*    <CardIdentityValidationLine type={'meeting'}*/}
            {/*                                status={'meeting_waiting'}/>*/}
            {/*</div>*/}
            {/*<div className='py-2'>*/}
            {/*    <CardIdentityValidationLine type={'meeting'}*/}
            {/*                                status={'meeting_refuse'}*/}
            {/*                                motif={'Je suis un motif'}/>*/}
            {/*</div>*/}
            {/*<div className='py-2'>*/}
            {/*    <CardIdentityValidationLine type={'meeting'}*/}
            {/*                                status={'meeting_soon'}*/}
            {/*                                href={'https://www.estcequonmetenprodaujourdhui.info/'}/>*/}
            {/*</div>*/}
            {/*<div className='py-2'>*/}
            {/*    <CardIdentityValidationLine type={'meeting'}*/}
            {/*                                status={'success'}/>*/}
            {/*</div>*/}
        </div>
    );
};

export default Identity;
import React, {useContext, useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import {Import, Warning} from "assets/icons";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import axios from "axios";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import Divider from "components/divider/divider";
import {tReplace} from "helper/helper";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const Modallicence = () => {
    const {refreshLoadWorker} = UseContext()
    const {toggle}=useModal()
    const [singleFile, setSingleFile] = useState('')
    const [titleFile, setTitleFile] = useState('')
    const [typeLicence, setTypeLicence] = useState('')
    const [licences, setLicences] = useState([])

    useEffect(() => {
        ApiDatabase.getAllLicence(
            (data) => {
                setLicences(data)
            },
            (err) => {
                console.log(err)
            }
        )
    }, [setLicences])

    const handlerSingleFile = (e) => {
        setSingleFile(e.target.files[0]);
        setTitleFile(e.target.files[0].name)
    }


    const singleUpload = async (data) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}upload/singleFile/licence`, data)
                .then((res) => {
                    if (res.data) {
                        toast.success('Le permis a été ajoutée')
                        refreshLoadWorker()
                        setTimeout(() => {
                            setTitleFile('')
                        }, 4000)
                    }
                    if (res.errors) {
                        toast.error('Une erreur est survenu')
                        setTimeout(() => {
                            setTitleFile('')
                        }, 3000)
                    }
                })
                .catch((e) => {
                    toast.error('Une erreur est survenu')
                    setTimeout(() => {
                        setTitleFile('')
                    }, 3000)
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        const token = localStorage.getItem('xsrfToken')
        const formData = new FormData();
        formData.append('file', singleFile);
        formData.append('token', token)
        formData.append('licence', typeLicence)
        formData.append('licenceName', typeLicence)
        await singleUpload(formData)
    }
    return (
        <>
                <Modalheader hide={toggle} hideShow={true}>
                    Ajouter un permis
                </Modalheader>
                <ModalBody>
                    <p className='ft-sm text-gray-500 my-3'>Critères de validation du
                        document :</p>
                    <span className='flex  gap-2 items-center'><Warning wh={17}
                                                                        color={"#EF4444"}/> Le document doit être de bonne qualité</span>
                    <Divider w={100}/>
                    <span className='flex  gap-2 items-center'><Warning wh={17}
                                                                        color={"#EF4444"}/>Les informations doivent être lisibles</span>
                    <Divider w={100}/>
                    <div className="flex flex-col w-full gap-2">
                        <select name="" id=""
                                onChange={(e) => setTypeLicence(e.target.value)}
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                        >
                            <option value=""></option>
                            {licences.map(item=>
                                <option value={tReplace(item.name)}>{item.name}</option>
                            )}
                        </select>
                        <form id='uploadForm'>
                            <label
                                className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                                <div
                                    className='ft-sm text-gray-700 w-1/4 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                                    <Import color={'#374151'} wh={14}/>
                                    Importer
                                </div>
                                <div
                                    className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                                    {titleFile ? titleFile : 'Aucun fichier'}
                                </div>

                                <input type="file" id='file'
                                       accept='image/jpeg,image/jpg,application/pdf'
                                       className='hidden'
                                       onChange={(e) => handlerSingleFile(e)}/>
                            </label>

                            <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button size={'LG'} color={'SECONDARY'}
                            onClick={toggle}>Annuler</Button>
                    <Button size={'LG'} onClick={handleUpload}>Confirmer</Button>
                </ModalFooter>
        </>

    );
};

export default Modallicence;
import {useEffect, useState} from "react";
import ApiDatabase from "server";
import Table from "../../../../../components/table/table";
import EmptyState from "../../../../../components/EmptyState/EmptyState";
import Pagination from "../../../../../components/pagination/pagination";
import UseContext from "../../../../../hooks/useContext";

const BodyVivierRatingTempWorker = ({treated}) => {
    const {reviewUntreatedDelete} = UseContext()
    const [ratings, setRatings] = useState([])
    const token = localStorage.getItem('xsrfToken')
    const [isWaiting, setIsWaiting] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [limitElement, setLimitElement] = useState(25)
    const [totalPages, setTotalPages] = useState(0)
    const [countPerPage, setCountPerPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)
    const [search, setSearch] = useState('')
    let reviewSearch = null;

    const getRatings = () => {
        setIsWaiting(true)
        setRatings([])
        if(treated) {
            ApiDatabase.getRatingTreatedTempWorkers({token, search: search, limit: limitElement, page: currentPage}, (data)=>{
                setRatings(data.reviews)
                setTotalPages(data.countPages)
                setCountPerPage(data.countPerPage)
                setCountTotal(data.count)

                setIsWaiting(false)
            },(err)=>{
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
        } else {
            ApiDatabase.getRatingUntreatedTempWorkers({token, search: search, limit: limitElement, page: currentPage}, (data)=>{
                setRatings(data.reviews)
                setTotalPages(data.countPages)
                setCountPerPage(data.countPerPage)
                setCountTotal(data.count)

                setIsWaiting(false)
            },(err)=> {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            })
        }
    }

    useEffect(() => {
        getRatings()
    }, [treated, currentPage]);

    useEffect(() => {
        if (search) {
            reviewSearch = setTimeout(async () => {
                await getRatings();
            }, 400);
        } else {
            getRatings()
        }
        return () => clearTimeout(reviewSearch);
    }, [search])

    useEffect(() => {
        if(reviewUntreatedDelete) {
            setRatings(ratings.filter((review) => review._id !== reviewUntreatedDelete))
        }
    }, [reviewUntreatedDelete]);

    const titles = ['Intérimaire', 'Mission', 'Motif', 'Responsable'];

    return (
        <>
            <div className={'flex flex-col gap-6'}>
                <div className={'flex justify-between gap-3'}>
                    <div className={'my-auto'}>
                        <h1 className={'text-lg'}>
                            {isWaiting ? (
                                <div className={'animate-pulse'}>
                                    <div className="h-2 w-20 bg-gray-200 rounded"></div>
                                </div>
                            ) :
                                `${ratings.length} avis`
                            }
                        </h1>
                    </div>
                    <div className={'my-auto'}>
                        <div>
                            <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                Recherche
                            </label>
                            <input type="text"
                                   value={search}
                                   name='lastname'
                                   className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                   onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {
                    isWaiting ? (
                        <>
                            <table className="animate-pulse w-full animate-fadeIn">
                                <tbody>
                                {
                                    [1, 2, 3, 4, 5].map((i, index)=>(
                                        <tr key={index}>
                                            {
                                                titles?.map((title, index) => (
                                                    <td key={index} className={'px-2 py-7'}>
                                                        <div className="h-2 bg-gray-200 rounded"></div>
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <>
                            {ratings?.length > 0 ?
                                <Table champsBody={ratings} champsHeader={titles} currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                                :
                                <EmptyState type={'time_sheet_check'}/>
                            }
                        </>
                    )
                }
            </div>
        </>
    )
}

export default BodyVivierRatingTempWorker
import React, {useEffect, useState} from 'react';
import AvatarCompagny from "components/avatar/avatarcompany";
import {Calendar, Euro, Localisation} from "assets/icons";
import Chip from "components/chip/chip";
import {NavLink} from "react-router-dom";
import {dateDiff} from "helper/helper";
import MediaQuery from "react-responsive";
import ApiDataBase from 'server'


const Cardsearchmission = ({data, validation}) => {
    const [nCompany, setNCompany] = useState('')
    const [lCompany,setLCompany]=useState('')
    // useEffect(() => {
    //     // ApiDataBase.getCompanyProfile({token: data.company},
    //     //     (data) => {
    //     //         console.log(data)
    //     //         setNCompany(data.name_commercial)
    //     //     },
    //     //     (err) => {
    //     //         console.log(err)
    //     //     })
    //     ApiDataBase.getAddress({id:data.location},
    //         (data)=>{
    //             console.log(data)
    //         },
    //         (err)=>{
    //             console.log(err)})
    // }, [])
    const Date = dateDiff(data.createdAt)

    return (
        <div
            className='grid grid-cols-3 max-w-3xl gap-3 tab:mx-2 shadow-md rounded-lg'>
            <div className="col-span-3 p-2 ">
                <div className="grid grid-cols-5 mt-2 gap-2">
                    <div
                        className='col-span-3 tab:col-span-1 flex justify-start items-start tab:items-center tab:justify-center -z-10'>
                        <AvatarCompagny image={''}/>
                    </div>
                    <div
                        className='col-span-5 order-last tab:order-none tab:col-span-3'>
                        <div className="grid grid-cols-2">
                            <div
                                className='col-span-2  ft-xl font-semibold'>{data.title}
                            </div>
                            <div
                                className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm'>
                                <Localisation wh={14}
                                              color={'#374151'}/>
                                {data.location}
                            </div>
                            <div
                                className='col-span-2 tab:col-span-1 flex items-center gap-2 ft-sm'>
                                <Localisation wh={14}
                                              color={'#374151'}/>
                                {nCompany}
                            </div>
                        </div>
                    </div>
                    <div
                        className='col-span-2 tab:col-span-1 flex items-start justify-end tab:items-start md:justify-center md:col-span-1'>
                        <Chip color={'INFO'} type={'INDICATOR'}
                              disabled={'disabled'}>{data.activity}</Chip>
                    </div>
                </div>
            </div>
            <div
                className="col-span-3 gap-3 flex flex-col tab:flex-row tab:items-center p-2">
                <div className='inline-flex items-center gap-3'>
                    <Calendar color={'#374151'} wh={15}/>
                    <span
                        className='ft-sm text-gray-500'>{data.duration.datestart} > {data.duration.dateend}</span>
                </div>
                <MediaQuery minWidth={768}>
                    <hr className='h-6 w-0 border border-gray-300'/>
                </MediaQuery>
                <div className='inline-flex items-center gap-3'>
                    <Euro color={'#374151'} wh={10}/>
                    <span
                        className='ft-sm text-gray-500'>{data.salary.wage_per_hour} €/h</span>
                </div>
            </div>
            <div className="col-span-3 flex items-end">
                <div
                    className='h-9 w-full inline-flex items-center justify-between px-2 bg-yellow-500 rounded-b-lg'>
                    <div className='text-white ft-sm font-semibold '>
                        il y a {Date.day > 0 ?
                        `${Date.day} jours`
                        : Date.hour > 0 ?
                            `${Date.hour} heures`
                            : Date.min > 0 ?
                                `${Date.min} minutes`
                                : Date.sec > 0 ?
                                    `${Date.sec} secondes` : ''
                    }
                    </div>
                    <NavLink to={`/ficheMission/${data._id}`}

                             className={`${validation === 1 ? 'pointer-events-none' : ''} text-white ft-sm font-semibold `}>Voir
                        plus
                        ></NavLink>
                </div>
            </div>
        </div>
    );
};

export default Cardsearchmission;
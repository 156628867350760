import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CrossIcon } from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Button from '../../../../button';
import StyledInput from '../../../../styledInput/StyledInput';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ModalOvertimesCompany = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [thresholds, setThresholds] = useState([
    {
      start: '',
      increase: '',
    },
  ]);
  const [errors, setErrors] = useState([
    {
      start: null,
      increase: null,
    },
  ]);
  const token = localStorage.getItem('xsrfToken');

  const addThreshold = () => {
    setThresholds((prevThresholds) => [
      ...prevThresholds,
      { start: '', increase: '' },
    ]);
    setErrors((prevErrors) => [...prevErrors, { start: null, increase: null }]);
  };

  const removeThreshold = (index) => {
    setThresholds((prevThresholds) =>
      prevThresholds.length > 1
        ? prevThresholds.filter((_, i) => i !== index)
        : prevThresholds
    );
    setErrors((prevErrors) =>
      prevErrors.length > 1
        ? prevErrors.filter((_, i) => i !== index)
        : prevErrors
    );
  };

  const validateThresholds = () => {
    const newErrors = thresholds.map((threshold) => {
      const errors = {
        start: !threshold.start ? 'Veuillez entrer une valeur' : null,
        increase:
          !threshold.increase || threshold.increase > 100
            ? 'La valeur doit être entre 0 et 100'
            : null,
      };

      return errors;
    });

    setErrors(newErrors);

    return newErrors.every((error) => !error.start && !error.increase);
  };

  const handleSubmit = () => {
    if (validateThresholds() && modalData?.idCompany) {
      setIsLoading(true);
      ApiDatabase.postOvertimesCompany(
        { token, idCompany: modalData?.idCompany, overtimes: thresholds },
        (data) => {
          setIsLoading(false);
          toast.success('Les heures supplémentaires ont étés mises à jour');
          setModalDataReturn({
            type: 'overtimes_updated',
            overtimes: thresholds,
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const handleChange = (index, field, value) => {
    setThresholds((prevThresholds) =>
      prevThresholds.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, i) => {
        if (i !== index) return error;

        const updatedError = { ...error };
        if (field === 'start') {
          updatedError[field] = !value ? 'Veuillez entrer une valeur' : null;
        }
        if (field === 'increase') {
          updatedError.increase =
            value > 100 ? 'La valeur doit être entre 0 et 100' : null;
        }
        return updatedError;
      })
    );
  };

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaiting(true);

    ApiDatabase.getOvertimesCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        const provThreshold = data.overtimes?.length
          ? data.overtimes.map(({ start, increase }) => ({
              start,
              increase,
            }))
          : [
              {
                start: '',
                increase: '',
              },
            ];
        setThresholds(provThreshold);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaiting(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Heures supplementaires
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-2.5 text-sm">
          {isWaiting ? (
            <div className={'animate-pulse'}>
              {[1, 2, 3].map((i, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div className="w-56 h-4 bg-gray-200 rounded"></div>
                      <div className="w-8 h-8 bg-gray-200 rounded"></div>
                    </div>
                    <div className="flex gap-3 w-full">
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                    <div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {thresholds.map((threshold, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div>SEUIL {index + 1}</div>
                      {thresholds.length > 1 && (
                        <div>
                          <Button
                            size={'ICON'}
                            color={'ICON'}
                            onClick={() => removeThreshold(index)}
                          >
                            <CrossIcon wh={20} color={'#374151'} />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div>
                      <StyledInput
                        type="number"
                        label="À partir de"
                        value={threshold.start}
                        unit={'heures travaillées'}
                        onChange={(e) =>
                          handleChange(index, 'start', e.target.value)
                        }
                        error={errors[index]?.start}
                      />
                    </div>
                    <div>
                      <StyledInput
                        type="number"
                        label="Majoration"
                        value={threshold.increase}
                        unit={'%'}
                        onChange={(e) =>
                          handleChange(index, 'increase', e.target.value)
                        }
                        error={errors[index]?.increase}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'w-full flex gap-1 justify-between items-center'}>
          <div>
            <Button size={'LG'} color={'SECONDARY'} onClick={addThreshold}>
              Ajouter un seuil
            </Button>
          </div>
          <div className={'flex gap-3'}>
            {isLoading ? (
              <>
                <Button size={'LG'} color={'SECONDARY'}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'DISABLED'}>
                  Confirmation ...
                </Button>
              </>
            ) : (
              <>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
                  Confirmer
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalOvertimesCompany;

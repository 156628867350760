import React, {useState, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function TabsVRTW({children}) {
    const pathname = useLocation().pathname;
    const pathnameTab = pathname.split('/')[2] ? pathname.split('/')[2] : pathname.split('/')[1];

    const [activeTab, setActiveTab] = useState(pathnameTab);
    const handleActiveTab = useCallback(label => setActiveTab(label), [])
    const navigate = useNavigate()

    const redirect = (label) => {
        let path = pathname.split('/')
        path.pop()
        path = path.join('/')
        navigate(`${path}/${label}`,{replace:true})
        handleActiveTab(label)
    }

    const tabs = children.map(child => (

        <div onClick={e => {redirect(child.props.label)}}
             className={`flex items-center justify-center  py-3 px-4 cursor-pointer text-gray-500 h-9 ${child.props.label === activeTab ? ' bg-gray-100 text-gray-700 rounded-md ' : 'hover:bg-gray-100 hover:rounded-md hover:text-gray-700'}`}
             key={child.props.label}>
            {child.props.tabName}
        </div>
    ))

    const tabContent = children.filter(child => child.props.label === activeTab)
    return (
        <div>
            <div className='flex justify-end items-center pr-4 gap-4  bg-white border-b-0 border-gray-100'>{tabs}</div>
            <div className=''>{tabContent}</div>
        </div>
    )
}

function TabVRTW(props) {
    return <>{props.children}</>
}

export {TabsVRTW, TabVRTW}
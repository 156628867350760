import { Pencil, TrashIcon } from 'assets/icons';
import Button from 'components/button';
import useModal from 'components/modal/useModal';
import UseContext from 'hooks/useContext';

const TbodyCompanyBonusAndCompensation = ({ champsBody }) => {
  const { toggle } = useModal();
  const { setModalData, modalDataReturn, setModalDataReturn } = UseContext();

  const editBonusesAndAllowances = (idCompany, idBonusesAndAllowances) => {
    setModalData({ idCompany, idBonusesAndAllowances });
    toggle('edit_bonuses_and_allowances');
  };
  const deleteBonusesAndAllowances = (idCompany, idBonusesAndAllowances) => {
    setModalData({ idCompany, idBonusesAndAllowances });
    toggle('delete_bonuses_and_allowances');
  };

  return (
    <>
      {champsBody?.bonuses?.map((bonusAndCompensation, key) => (
        <tr key={key} className={'border-b-2 border-gray-100 '}>
          <td className={'px-2 py-5 text-gray-900'}>
            {bonusAndCompensation.name}
          </td>
          <td className={'px-2 py-5'}>{bonusAndCompensation.unit}</td>
          <td className={'px-2 py-5'}>{bonusAndCompensation.ref}</td>
          <td className={'px-2 py-5'}>{bonusAndCompensation.payed}</td>
          <td className={'px-2 py-5'}>{bonusAndCompensation.coef}</td>
          <td className={'px-2 py-5'}>
            {bonusAndCompensation.payed &&
              bonusAndCompensation.coef &&
              parseFloat(
                parseFloat(bonusAndCompensation.payed) *
                  parseFloat(bonusAndCompensation.coef)
              ).toFixed(2)}
          </td>
          <td className={'px-2 py-5'}>
            <div className="flex gap-1">
              <Button
                size={'SMI'}
                color={'ICON'}
                onClick={() =>
                  editBonusesAndAllowances(
                    champsBody?.companyInfo?._id,
                    bonusAndCompensation?._id
                  )
                }
              >
                <Pencil wh={20} color={'#111827'} />
              </Button>
              <Button
                size={'SMI'}
                color={'SECONDARY'}
                onClick={() =>
                  deleteBonusesAndAllowances(
                    champsBody?.companyInfo?._id,
                    bonusAndCompensation?._id
                  )
                }
              >
                <TrashIcon wh={20} color={'#111827'} />
              </Button>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TbodyCompanyBonusAndCompensation;

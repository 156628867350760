import { LicenceIcon } from "../../../assets/icons";
import UseContext from "../../../hooks/useContext";
import UseModal from "../../modal/useModal";

const LicenceItem = ({ licence, index }) => {
    const {setAdminLink, setAdminType, setAdminTempWorkerId }=UseContext()
    const {toggle} = UseModal();
    const filePath1 = process.env.REACT_APP_FILE + licence.file?.filePath;
    const filePathFinal = filePath1 ? { filePath1 } : null;

    const openDocument = (type, uri) => {
        setAdminLink(uri);
        setAdminType(type);
        setAdminTempWorkerId('');
        toggle('ad_file_view');
    }

    return (
        <div key={index} className={'flex gap-2 items-center'}>
            <div className={'w-10 h-10 min-w-10 min-h-10 flex justify-center items-center bg-gray-100 rounded'}>
                <LicenceIcon wh={20} color={'#374151'} />
            </div>
            <div className={'flex flex-col'}>
                <div className={''}>{licence.licence}</div>
                {filePathFinal && (
                    <div className={'text-blue-500 cursor-pointer underline'} onClick={() => openDocument('licence', filePathFinal)}>
                        {licence.file?.fileName}
                    </div>
                )}
            </div>
        </div>
    );
}

export default LicenceItem;

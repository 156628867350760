import React, {useContext} from 'react';
import {Add, DocumentIcon, Pencil} from "assets/icons";
import BadgeSquare from "components/badge/badgeSquare";
import usefetchdata from "hooks/usefetchdata";


const Threeform = ({setFormIndex,setCheck}) => {
    const {tempWorkerData, userData} = usefetchdata()

    const handleModifyProof = (e) => {
        e.preventDefault()
        setFormIndex(4)
    }

    const handleModifyAttest = (e) => {
        e.preventDefault()
        setFormIndex(5)
    }
    return (
        <div className={'flex flex-col gap-4'}>

            <div className="flex items-center justify-between">
                <div className="flex gap-2">
                    <div
                        className='relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                        <DocumentIcon wh={30} color={'#111827'}/>
                    </div>
                    <div
                        className="col-span-3 ft-b text-gray-900 my-auto">
                        <div className={`text-gray-900`}>
                            Justificatif de domicile
                        </div>
                    </div>
                </div>
                <div className='col-span-1 flex'>
                    <div className='my-auto mr-2'>
                        <BadgeSquare type={tempWorkerData?.proof_of_address?.validated}/>
                    </div>
                    {(tempWorkerData?.proof_of_address?.validated === '1' || '3') ?
                    <div className='my-auto'>
                        <button onClick={handleModifyProof}
                                className='rounded-full w-10 h-10 flex items-center justify-center'>
                            <Pencil wh={22} color={'#374151'}/>
                        </button>
                    </div>
                        :''}
                </div>
            </div>
            {tempWorkerData?.accommodation_of_address ?
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div
                            className='relative my-auto bg-gray-100 w-[40px] h-[40px] flex justify-center items-center rounded-full'>
                            <DocumentIcon wh={30} color={'#111827'}/>
                        </div>
                        <div
                            className="col-span-3 ft-b text-gray-900 my-auto">
                            <div className={`text-gray-900`}>
                                Attestation d'hébergement
                            </div>
                        </div>
                    </div>
                    <div className='col-span-1 flex'>
                        <div className='my-auto mr-2'>
                            <BadgeSquare type={tempWorkerData?.accommodation_of_address?.validated}/>
                        </div>
                        <div className='my-auto'>
                            <button onClick={handleModifyAttest}
                                    className='rounded-full w-10 h-10 flex items-center justify-center'>
                                <Pencil wh={22} color={'#374151'}/>
                            </button>
                        </div>
                    </div>
                </div>
                : ''}
        </div>
    );
};

export default Threeform;
import React, {useEffect, useState} from 'react';
import PageForm from "components/pageForm/PageForm";
import Filtersearchmission from "components/filter/filtersearchmission";
import Chip from "components/chip/chip";
import Cardsearchmission from "components/card/cardsearchmission/cardsearchmission";
import axios from "axios";
import MediaQuery from "react-responsive";
import Button from "components/button";
import {Filter} from "assets/icons";
import usefetchdata from "hooks/usefetchdata";
import Bannertitle from "components/banner/bannertitle";
import EmptyState from "components/EmptyState/EmptyState";

const SearchMission = () => {
    const {userData} = usefetchdata()
    const [consulte, setConsulte] = useState(false)
    const [postule, setPostule] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    //Partie filtre
    const [job, setJob] = useState('')
    const [location, setLocation] = useState('')
    const [secteur, setSecteur] = useState('')
    const [salaryMin, setSalaryMin] = useState(0)
    const [salaryMax, setSalaryMax] = useState(100)
    const [listMission, setListMission] = useState([])


    const handleFilter = (e) => {
        setFilterOpen(!filterOpen)
    }

    const handleConsulte = (e) => {
        setConsulte(!consulte)
    }
    const handlePostule = (e) => {
        setPostule(!postule)
    }
    //Filter Mission
    const applyFilter = async () => {
        let updatedList = await axios.get(`${process.env.REACT_APP_API_URL}mission`)
        let result = updatedList.data
        //   Salary
        const minS = salaryMin
        const maxS = salaryMax

        //Filtre metier
        if (job !== '') {
            result = result.filter((item) => item.title.toLowerCase().search(job.toLowerCase().trim()) !== -1);
        }

        //Filtre Localisation
        if (location !== '')
            result = result.filter((item) => item.location.toLowerCase().search(location.toLowerCase().trim()) !== -1);


        //Filter Secteur
        if (secteur !== '')
            result = result.filter(
                (item) => item.activity === secteur
            )

        //Filter salary
        result = result.filter(
            (item) => item.salary.wage_per_hour >= minS && item.salary.wage_per_hour <= maxS
        )


        setListMission(result)
        !result.length ? setResultsFound(false) : setResultsFound(true)
    }

    useEffect(() => {
        if (userData.validation !== '1') {
            applyFilter()
        }
    }, [salaryMax, salaryMin, secteur, job, location])

    const missionBlur = userData.validation === 1 ? 'blur-sm pointer-events-none' : ''
    return (
        <PageForm>
            <MediaQuery minWidth={768}>
                <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-4 tab:col-span-5 md:col-span-4'>
                        <Filtersearchmission
                            job={setJob}
                            jobV={job}
                            location={setLocation}
                            locationV={location}
                            secteur={setSecteur}
                            secteurV={secteur}
                            salaryMin={setSalaryMin}
                            salaryMinV={salaryMin}
                            salaryMax={setSalaryMax}
                            salaryMaxV={salaryMax}
                        />
                    </div>
                    <div className='col-span-8 tab:col-span-7 md:col-span-8 my-26'>
                        {userData?.validation === 1 ?

                            <div className="max-w-3xl mt-10">
                                <Bannertitle type={'alert'} title={'Profil non validé'}>Validez votre identité pour
                                    postuler à une de ces offres.</Bannertitle>
                            </div>
                            : ''}
                        <div className=' flex  items-center gap-7 mt-4'>
                            <Chip icon={true} select={consulte} type={consulte ? 'SELECTON' : 'SELECTOFF'}
                                  onClick={handleConsulte}>Consultée</Chip>
                            <Chip icon={true} select={postule} type={postule ? 'SELECTON' : 'SELECTOFF'}
                                  onClick={handlePostule}>Postulée</Chip>

                        </div>
                        <div className={missionBlur}>
                            <div className='flex flex-col mb-60 mt-12 gap-8'>
                                {resultsFound ?
                                    listMission?.map(mission =>
                                        <Cardsearchmission key={mission.company} data={mission}
                                                           validation={userData.validation}/>
                                    )
                                    : <EmptyState type={'mission_search'}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="col-span-full h-14 flex items-center justify-center gap-2 ft-b font-bold text-gray-700 border-b b-gray-500">
                    <h1>Recherche de missions</h1>
                    <Button size={'SMI'} color={'DEFAULT'} onClick={handleFilter}><Filter wh={18} color={'#374151'}/>
                    </Button>

                </div>
                <div className={`${
                    filterOpen ? "" : "hidden"
                } col-span-full  h-[400px]  bg-white  border-b b-gray-500  p-2`}>
                    <Filtersearchmission
                        job={setJob}
                        jobV={job}
                        location={setLocation}
                        locationV={location}
                        secteur={setSecteur}
                        secteurV={secteur}
                        salaryMin={setSalaryMin}
                        salaryMinV={salaryMin}
                        salaryMax={setSalaryMax}
                        salaryMaxV={salaryMax}
                    />
                </div>
                <div className="col-span-full  flex  items-center gap-7 ml-2">
                    <Chip icon={true} select={consulte} type={consulte ? 'SELECTON' : 'SELECTOFF'}
                          onClick={handleConsulte}>Consultée</Chip>
                    <Chip icon={true} select={postule} type={postule ? 'SELECTON' : 'SELECTOFF'}
                          onClick={handlePostule}>Postulée</Chip>
                </div>
                <div className={`col - span - full flex flex-col mb-60 mt-12 gap-8 mx-2 ${missionBlur}`}>
                    {resultsFound ?
                        listMission?.map(mission =>
                            <Cardsearchmission key={mission.company} data={mission}/>
                        )
                        : ''}
                </div>
            </MediaQuery>

        </PageForm>
    );
};

export default SearchMission;
import React from 'react';
import EmptyState from "components/EmptyState/EmptyState";
import usefetchdata from "hooks/usefetchdata";

const Comingsoon = () => {
    const {tempWorkerData}=usefetchdata()
    return (
        <>
            {tempWorkerData?.jobs?.length ?'test':
                <EmptyState type={'mission_soon'}/>
            }
        </>
    );
};

export default Comingsoon;
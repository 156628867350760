import {useEffect, useRef, useState} from "react";

const TextSelectNationality = ({ dataList, onSelect, setData }) => {
    const [textSearch, setTextSearch] = useState('')
    const [dataChoice, setDataChoice] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const dropDownRef = useRef(null)

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            } else {
                setDropdownOpen(true)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    useEffect(() => {
        //fuzzy search on dataList with textSearch
        const dataChoices = []
        if (dataList.length > 0 && textSearch) {
            for (let i = 0; i < dataList.length; i++) {
                //compare textSearch with dataList[i].NationaliteF, escape special characters and accents and case insensitive and spaces and uppercase
                //replace œ by oe, replace éèêë by e, replace àâä by a, replace ùûü by u, replace îï by i, replace ôö by o, replace ç by c, replace characters other than letters by nothing
                const regex = new RegExp(textSearch.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''), 'i')
                if (regex.test(dataList[i].NationaliteF.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''))) {
                    dataChoices.push(dataList[i])
                }
            }
        }
        setDataChoice(dataChoices)
        if (textSearch !== selectedData?.NationaliteF) {
            setSelectedData(null)
        }
    }, [textSearch, dropdownOpen])

    const selectData = (data) => {
        setTextSearch(data.NationaliteF)
        setDataChoice([])
        setSelectedData(data)
        onSelect(data)
    }

    useEffect(() => {
        if (setData) {
            setTextSearch(setData.NationaliteF)
            setSelectedData(setData)
        }
    }, [setData]);

    return (
        <label className='ft-sm text-gray-500' ref={dropDownRef}>
            <div>
                Nationalité
            </div>
            <div>
                <div className={'relative flex'}>
                    <input type="text"
                           value={textSearch}
                           onChange={(e) => setTextSearch(e.target.value)}
                           className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg`}
                    />
                    {(dropdownOpen && !selectedData) &&
                        <>
                            {dataChoice.length > 0 ? (
                                <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                    <div className={'bg-white border rounded'}>
                                        {dataChoice.map((dataC, index) => (
                                            <>
                                                <div key={index} className={`flex flex-col gap-2 p-2 ${dataChoice.length -1 !== index && 'border-b'}`}>
                                                    <button className={'flex flex-row gap-2 items-center'} onClick={(e) => {
                                                        e.preventDefault()
                                                        selectData(dataC)
                                                    }}>
                                                        <span className={'ft-sm'}>{dataC.NationaliteF ?? null}</span>
                                                    </button>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                    <div className={'bg-white border rounded'}>
                                        <div className={`flex flex-col gap-2 p-2`}>
                                            <p className={'ft-sm'}>Aucun résultat</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </div>
            </div>
        </label>
    )
}

export default TextSelectNationality
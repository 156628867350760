import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CrossIcon } from '../../../../../assets/icons';
import UseContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Button from '../../../../button';
import StyledInput from '../../../../styledInput/StyledInput';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ModalEditBonusesAndAllowancesOther = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [thresholds, setThresholds] = useState([
    {
      section: '',
      description: '',
    },
  ]);
  const [thresholdSectionLists, setThresholdSectionLists] = useState([]);
  const [errors, setErrors] = useState([
    {
      section: null,
      description: null,
    },
  ]);
  const token = localStorage.getItem('xsrfToken');

  const addThreshold = () => {
    setThresholds((prevThresholds) => [
      ...prevThresholds,
      { section: '', description: '' },
    ]);
    setErrors((prevErrors) => [
      ...prevErrors,
      { section: null, description: null },
    ]);
    setThresholdSectionLists((prevSectionLists) => [
      ...prevSectionLists,
      sectionList.map((item) => ({ ...item, checked: false })),
    ]);
  };

  const removeThreshold = (index) => {
    if (thresholds.length > 1) {
      setThresholds((prevThresholds) =>
        prevThresholds.filter((_, i) => i !== index)
      );
      setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
      setThresholdSectionLists((prevSectionLists) =>
        prevSectionLists.filter((_, i) => i !== index)
      );
    }
  };

  const validateThresholds = () => {
    const newErrors = thresholds.map((threshold) => {
      const errors = {
        section: !threshold.section ? 'Veuillez entrer une valeur' : null,
        description: !threshold.description
          ? 'Veuillez entrer une valeur'
          : null,
      };
      return errors;
    });

    setErrors(newErrors);

    return newErrors.every((error) => !error.section && !error.description);
  };

  const handleSubmit = () => {
    if (validateThresholds() && modalData?.idCompany) {
      setIsLoading(true);
      ApiDatabase.postBonusesAndAllowancesOtherCompany(
        { token, idCompany: modalData?.idCompany, others: thresholds },
        (data) => {
          setIsLoading(false);
          toast.success('Les rubriques ont étés mises à jour');
          setModalDataReturn({
            type: 'bonuses_and_allowances_other_updated',
            others: thresholds.map((threshold) => ({
              section: sectionList.find(
                (section) => section.value === threshold.section
              )?.label,
              description: threshold.description,
            })),
          });
          toggle();
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const handleChangeSectionList = (index, field, updatedSectionList) => {
    const updatedThresholdSectionLists = [...thresholdSectionLists];
    updatedThresholdSectionLists[index] = updatedSectionList;
    setThresholdSectionLists(updatedThresholdSectionLists);

    const valueChecked = updatedSectionList.find((item) => item.checked)?.value;

    setThresholds((prevThresholds) =>
      prevThresholds.map((item, i) =>
        i === index ? { ...item, [field]: valueChecked } : item
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, i) => {
        if (i !== index) return error;
        const updatedError = { ...error };
        updatedError[field] = !valueChecked
          ? 'Veuillez entrer une valeur'
          : null;
        return updatedError;
      })
    );
  };

  const handleChange = (index, field, value) => {
    setThresholds((prevThresholds) =>
      prevThresholds.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, i) => {
        if (i !== index) return error;
        const updatedError = { ...error };
        updatedError[field] = !value ? 'Veuillez entrer une valeur' : null;
        return updatedError;
      })
    );
  };

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaiting(true);

    ApiDatabase.getBonusesAndAllowancesOtherCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        const provThreshold = data.bonusesAndAllowancesOther?.length
          ? data.bonusesAndAllowancesOther.map(({ name, desc }) => ({
              section: name,
              description: desc,
            }))
          : [
              {
                section: '',
                description: '',
              },
            ];
        setThresholds(provThreshold);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaiting(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  useEffect(() => {
    if (!modalData?.idCompany) return;

    setIsWaiting(true);

    ApiDatabase.getBonusListCompany(
      { token, idCompany: modalData.idCompany },
      (data) => {
        const fetchedSectionList = [
          { value: '', label: '-' },
          ...data.bonusList?.map((item) => ({
            ...item,
            value: item._id,
            label: item.name,
            checked: false,
          })),
        ];
        setSectionList(fetchedSectionList);
        setIsWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setIsWaiting(false);
        }
      }
    );
  }, [modalData?.idCompany]);

  useEffect(() => {
    if (thresholds.length > 0 && sectionList.length > 0) {
      const initialThresholdSectionLists = thresholds.map((threshold) => {
        return sectionList.map((item) => ({
          ...item,
          checked: item.value === threshold.section,
        }));
      });
      setThresholdSectionLists(initialThresholdSectionLists);
    }
  }, [thresholds, sectionList]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Autres
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-2.5 text-sm">
          {isWaiting ? (
            <div className={'animate-pulse'}>
              {[1, 2, 3].map((i, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div className="w-56 h-4 bg-gray-200 rounded"></div>
                      <div className="w-8 h-8 bg-gray-200 rounded"></div>
                    </div>
                    <div className="flex gap-3 w-full">
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                    <div>
                      <div className="w-full h-14 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {thresholds.map((threshold, index) => (
                <div key={index} className="rounded-lg bg-gray-50 p-4">
                  <div className="flex flex-col gap-3">
                    <div className={'flex gap-1 justify-between'}>
                      <div>SEUIL {index + 1}</div>
                      {thresholds.length > 1 && (
                        <div>
                          <Button
                            size={'ICON'}
                            color={'ICON'}
                            onClick={() => removeThreshold(index)}
                          >
                            <CrossIcon wh={20} color={'#374151'} />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div>
                      <StyledInput
                        type={'selectMultipleRadio'}
                        label="Rubrique"
                        value={
                          thresholdSectionLists[index]?.find(
                            (section) => section.checked
                          )?.label || '-'
                        }
                        list={thresholdSectionLists[index]}
                        onChange={(updatedSectionList) =>
                          handleChangeSectionList(
                            index,
                            'section',
                            updatedSectionList
                          )
                        }
                        isSearchable
                        error={errors[index]?.section}
                      />
                    </div>
                    <div>
                      <StyledInput
                        type="text"
                        label="Description"
                        value={threshold.description}
                        onChange={(e) =>
                          handleChange(index, 'description', e.target.value)
                        }
                        error={errors[index]?.description}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={'w-full flex gap-1 justify-between items-center'}>
          <div>
            <Button size={'LG'} color={'SECONDARY'} onClick={addThreshold}>
              Ajouter un seuil
            </Button>
          </div>
          <div className={'flex gap-3'}>
            {isLoading ? (
              <>
                <Button size={'LG'} color={'SECONDARY'}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'DISABLED'}>
                  Confirmation ...
                </Button>
              </>
            ) : (
              <>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                  Annuler
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
                  Confirmer
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalEditBonusesAndAllowancesOther;

import { ChevronDown, ChevronTop, Pencil, Plus } from 'assets/icons';
import Button from 'components/button';
import UseModal from 'components/modal/useModal';
import Table from 'components/table/table';
import UseContext from 'hooks/useContext';
import { useEffect, useState } from 'react';

const SlideOverCompanyBonusAndCompensation = ({
  companyInfo,
  setCompanyInfo,
}) => {
  const { toggle } = UseModal();
  const { setModalData, modalDataReturn, setModalDataReturn } = UseContext();
  const [isShowBonusesAndAllowances, setIsShowBonusesAndAllowances] =
    useState(false);
  const [isShowBonusesAndAllowancesOther, setIsShowBonusesAndAllowancesOther] =
    useState(false);
  const token = localStorage.getItem('xsrfToken');

  const editBonusesAndAllowances = (idCompany) => {
    setModalData({ idCompany });
    toggle('edit_bonuses_and_allowances');
  };

  const editBonusesAndAllowancesOther = (idCompany) => {
    setModalData({ idCompany });
    toggle('edit_bonuses_and_allowances_other');
  };

  const editBonusesAndAllowancesRestaurantTicket = (idCompany) => {
    setModalData({ idCompany });
    toggle('edit_bonuses_and_allowances_restaurant_ticket');
  };

  useEffect(() => {
    if (
      modalDataReturn?.type === 'restaurant_ticket_updated' &&
      modalDataReturn?.restaurantTicket
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        bonusesAndAllowances: {
          ...prevData.bonusesAndAllowances,
          restaurant_ticket: modalDataReturn.restaurantTicket,
        },
      }));
    }
    if (
      modalDataReturn?.type === 'bonuses_and_allowances_added' &&
      modalDataReturn?.bonusesAndAllowances
    ) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        bonusesAndAllowances: {
          ...prevData.bonusesAndAllowances,
          bonuses: [
            ...(prevData.bonusesAndAllowances?.bonuses || []),
            modalDataReturn.bonusesAndAllowances,
          ],
        },
      }));
      setIsShowBonusesAndAllowances(true);
    }
    if (
      modalDataReturn?.type === 'bonuses_and_allowances_updated' &&
      modalDataReturn?.bonusesAndAllowances
    ) {
      setCompanyInfo((prevData) => {
        const updatedBonuses = prevData.bonusesAndAllowances?.bonuses?.map(
          (bonus) =>
            bonus._id.toString() ===
            modalDataReturn.bonusesAndAllowances._id.toString()
              ? modalDataReturn.bonusesAndAllowances
              : bonus
        );

        return {
          ...prevData,
          bonusesAndAllowances: {
            ...prevData.bonusesAndAllowances,
            bonuses: updatedBonuses,
          },
        };
      });
    }
    if (
      modalDataReturn?.type === 'bonuses_and_allowances_deleted' &&
      modalDataReturn?.idBonusesAndAllowances
    ) {
      setCompanyInfo((prevData) => {
        const updatedBonuses = prevData.bonusesAndAllowances?.bonuses?.filter(
          (bonus) =>
            bonus._id.toString() !==
            modalDataReturn.idBonusesAndAllowances.toString()
        );

        if (updatedBonuses.length === 0) setIsShowBonusesAndAllowances(false);
        else setIsShowBonusesAndAllowances(true);

        return {
          ...prevData,
          bonusesAndAllowances: {
            ...prevData.bonusesAndAllowances,
            bonuses: updatedBonuses,
          },
        };
      });
    }
    if (
      modalDataReturn?.type === 'bonuses_and_allowances_other_updated' &&
      Array.isArray(modalDataReturn?.others)
    ) {
      setCompanyInfo((prevData) => {
        const updatedBonusesOther = modalDataReturn.others.map((other) => ({
          name: other.section,
          desc: other.description,
        }));

        if (updatedBonusesOther.length === 0)
          setIsShowBonusesAndAllowancesOther(false);
        else setIsShowBonusesAndAllowancesOther(true);

        return {
          ...prevData,
          bonusesAndAllowances: {
            ...prevData.bonusesAndAllowances,
            others: updatedBonusesOther,
          },
        };
      });
    }

    setModalDataReturn(null);
  }, [modalDataReturn]);

  const titles = [
    'AUTRES PRIMES ET RUBRIQUES',
    'UNITÉS',
    'REF.',
    '=> PAYÉ',
    'x COEF',
    '= FACTURÉ',
  ];

  useEffect(() => {
    if (companyInfo?.bonusesAndAllowances?.bonuses?.length > 0)
      setIsShowBonusesAndAllowances(true);
  }, [companyInfo?.bonusesAndAllowances?.bonuses?.length]);

  useEffect(() => {
    if (companyInfo?.bonusesAndAllowances?.others?.length > 0)
      setIsShowBonusesAndAllowancesOther(true);
  }, [companyInfo?.bonusesAndAllowances?.others?.length]);

  return (
    <>
      <div className={`border rounded p-3 flex flex-col gap-2.5`}>
        <div className="flex justify-between items-center gap-2">
          <div className={''}>
            <div className={''}>Primes & indemnité</div>
          </div>
          <div className={'flex gap-1 items-center'}>
            <Button
              size={'SMI'}
              color={'ICON'}
              onClick={() => editBonusesAndAllowances(companyInfo?._id)}
            >
              <Plus wh={20} color={'#111827'} />
            </Button>
            {companyInfo?.bonusesAndAllowances?.bonuses?.length > 0 && (
              <Button
                size={'SMI'}
                color={'SECONDARY'}
                onClick={() =>
                  setIsShowBonusesAndAllowances(!isShowBonusesAndAllowances)
                }
              >
                {isShowBonusesAndAllowances ? (
                  <ChevronDown wh={20} color={'#111827'} />
                ) : (
                  <ChevronTop wh={20} color={'#111827'} />
                )}
              </Button>
            )}
          </div>
        </div>
        {isShowBonusesAndAllowances && (
          <div>
            <Table
              champsBody={{
                bonuses: companyInfo?.bonusesAndAllowances.bonuses,
                companyInfo,
              }}
              champsHeader={titles}
              type={'companyBonusAndCompensation'}
            />
          </div>
        )}
      </div>
      <div className={`border rounded p-3 flex flex-col gap-2.5`}>
        <div className="flex justify-between items-center gap-2">
          <div className={''}>
            <div className={''}>Autres</div>
          </div>
          <div className={'flex gap-1 items-center'}>
            <Button
              size={'SMI'}
              color={'ICON'}
              onClick={() => editBonusesAndAllowancesOther(companyInfo?._id)}
            >
              <div className="flex gap-1 items-center">
                <Pencil wh={20} color={'#111827'} />
                <div>Modifier</div>
              </div>
            </Button>
            {companyInfo?.bonusesAndAllowances?.others?.length > 0 && (
              <Button
                size={'SMI'}
                color={'SECONDARY'}
                onClick={() =>
                  setIsShowBonusesAndAllowancesOther(
                    !isShowBonusesAndAllowancesOther
                  )
                }
              >
                {isShowBonusesAndAllowancesOther ? (
                  <ChevronDown wh={20} color={'#111827'} />
                ) : (
                  <ChevronTop wh={20} color={'#111827'} />
                )}
              </Button>
            )}
          </div>
        </div>
        {isShowBonusesAndAllowancesOther && (
          <div>
            {companyInfo?.bonusesAndAllowances?.others?.map((item, index) => (
              <div key={index} className="flex justify-between gap-1 py-4">
                <div className="w-1/2">{item.name}</div>
                <div className="w-1/2 text-gray-500">{item.desc}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={`border rounded p-3 flex flex-col gap-2.5`}>
        <div className="flex justify-between items-center gap-2">
          <div className={''}>
            <div className={''}>Ticket restaurant</div>
            {companyInfo?.bonusesAndAllowances?.restaurant_ticket
              ?.employer_share &&
              companyInfo.bonusesAndAllowances?.restaurant_ticket.modality && (
                <div className={'text-gray-500'}>
                  <div>
                    La part patronale est de{' '}
                    {
                      companyInfo.bonusesAndAllowances?.restaurant_ticket
                        .employer_share
                    }
                    %
                  </div>
                  <div>
                    {
                      companyInfo.bonusesAndAllowances?.restaurant_ticket
                        .modality
                    }
                  </div>
                </div>
              )}
          </div>
          <div className={'flex gap-1 items-center'}>
            <Button
              size={'SMI'}
              color={'SECONDARY'}
              onClick={() =>
                editBonusesAndAllowancesRestaurantTicket(companyInfo?._id)
              }
            >
              {companyInfo?.bonusesAndAllowances?.restaurant_ticket
                ?.employer_share &&
              companyInfo.bonusesAndAllowances?.restaurant_ticket.modality ? (
                <Pencil wh={20} color={'#111827'} />
              ) : (
                <Plus wh={20} color={'#111827'} />
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideOverCompanyBonusAndCompensation;

import React, {useState} from 'react';
import useModal from "components/modal/useModal";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import {Import, Warning} from "assets/icons";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import axios from "axios";
import {toast} from "react-toastify";
import Divider from "components/divider/divider";
import Banner from "components/banner/banner";
import usefetchdata from "hooks/usefetchdata";
import UseContext from "hooks/useContext";


const Cardvitale = () => {
    const {toggle}=useModal()
    const {tempWorkerData}=usefetchdata()
    const {refreshLoadWorker,language}=UseContext()
    const [singleFile, setSingleFile] = useState('')
    const [titleFile, setTitleFile] = useState('')

    const handlerSingleFile = (e) => {
        setSingleFile(e.target.files[0]);
        setTitleFile(e.target.files[0].name)
    }

    const singleUpload = async (data) =>{
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}upload/singleFile/Vitale`, data)
                .then((res) => {
                    if (res.data) {
                        toast.success('La carte vitale a été ajoutée')
                        refreshLoadWorker()
                        setTimeout(() => {
                            setTitleFile('')
                        }, 4000)
                    }
                    if (res.errors) {
                        toast.error('Une erreur est survenu')
                        setTimeout(() => {
                            setTitleFile('')
                        }, 3000)
                    }
                })
                .catch((e) => {
                    toast.error('Une erreur est survenu')
                    setTimeout(() => {
                        setTitleFile('')
                    }, 3000)
                    console.log(e)
                })
        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        const token =localStorage.getItem('xsrfToken')
        const formData= new FormData()
        formData.append('file',singleFile)
        formData.append('token',token)
        await singleUpload(formData)
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {tempWorkerData?.card_vitality?.validated === '1' ?
                    language.getValue('justify.m_vitale_title_u')
                    :
                    language.getValue('justify.m_vitale_title_c')
                }
            </Modalheader>
            <ModalBody>
                {tempWorkerData?.card_vitality?.validated === '3' ?
                <Banner type={'alert'}>Motif a venir</Banner>
                    :''}
                {tempWorkerData?.card_vitality?.validated === '1' ?
                    <Banner type={'warning'}>Un document est déjà en cours d’observation, la modification reste possible.</Banner>
                    :''}
                <p className='ft-sm text-gray-500 my-3'>Critères de validation du
                    document :</p>
                <span className='flex  gap-2 items-center'><Warning wh={17}
                                                                    color={"#EF4444"}/> Le document doit être de bonne qualité</span>
                <Divider w={100}/>
                <span className='flex  gap-2 items-center'><Warning wh={17}
                                                                    color={"#EF4444"}/>Les informations doivent être lisibles</span>
                <Divider w={100}/>
                <div className="flex flex-col w-full gap-2">
                    <form id='uploadForm'>
                        <label
                            className="w-full flex flex-row  border border-gray-300 rounded-lg h-11 shadow-sm">
                            <div
                                className='ft-sm text-gray-700 w-1/4 p-4 flex items-center justify-center gap-2 border-r border-gray-300'>
                                <Import color={'#374151'} wh={14}/>
                                Importer
                            </div>
                            <div
                                className='ft-sm truncate overflow-hidden  text-gray-400 flex items-center ml-3'>
                                {titleFile ? titleFile : 'Aucun fichier'}
                            </div>

                            <input type="file" id='file'
                                   accept='image/jpeg,image/jpg,application/pdf'
                                   className='hidden'
                                   onChange={(e) => handlerSingleFile(e)}/>
                        </label>

                        <span className='ft-xs text-gray-400'>Format acceptées : PDF, JPG (5Mo max)</span>
                    </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit} >Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Cardvitale;
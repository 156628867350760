import useSlideOver from '../../../useSlideOver';
import UseContext from '../../../../../hooks/useContext';
import SlideOverHeader from '../../../component/header';
import SlideOverBody from '../../../component/body';
import SlideOverFooter from '../../../component/footer';
import ApiDatabase from 'server';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from '../../../../../assets/icons';
import Table from '../../../../table/table';
import Banner from '../../../../banner/banner';
import Button from '../../../../button';
import { toast } from 'react-toastify';
import CopyToClipboard from '../../../../copyToClipboard';
import SlideOverCompanyOverview from './slideOverCompanyOverview';
import SlideOverCompanyMembers from './slideOverCompanyMembers';
import SlideOverCompanyRemunerationConditions from './slideOverCompanyRemunerationConditions';
import SlideOverCompanyBonusAndCompensation from './slideOverCompanyBonusAndCompensation';

const SlideOverCompany = () => {
  const { removeAllParameters } = UseContext();
  const { objID, coeffs } = UseContext();
  const { toggleSlideOver } = useSlideOver();
  const [companyInfo, setCompanyInfo] = useState({});
  const token = localStorage.getItem('xsrfToken');
  const [isShowCoeff, setIsShowCoeff] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [pageType, setPageType] = useState('overview');
  const [isCoeffSend, setIsCoeffSend] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const titlesCoeff = [
    'TRANCHES DE SALAIRE BRUT MENSUEL',
    'COEFF. DÉLÉGATION',
    'COEFF. GESTION',
  ];

  useEffect(() => {
    if (objID) {
      setIsWaiting(true);
      ApiDatabase.getCompanyInfo(
        { token: token, idCompany: objID },
        (data) => {
          setCompanyInfo(data.company);
          setIsWaiting(false);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
          }
        }
      );
    }
  }, [objID]);

  const closeSlideOver = () => {
    toggleSlideOver();
    removeAllParameters();
  };

  const checkScrollable = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setIsScrollable(scrollLeft > 0 || scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => window.removeEventListener('resize', checkScrollable);
  }, []);

  const handleScroll = () => checkScrollable();

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const submitCoeff = () => {
    if (coeffs?.length > 0) {
      let isCoeffFormAdd = false;
      for (let i = 0; i < coeffs.length; i++) {
        if (coeffs[i]?.management !== null || coeffs[i]?.delegation !== null) {
          isCoeffFormAdd = true;
        }
      }

      if (isCoeffFormAdd) {
        ApiDatabase.postCoefficient(
          { token: token, idCompany: objID, coefficients: coeffs },
          (data) => {
            setIsCoeffSend(true);
            toast.success('Les coefficients ont bien été ajoutés');
          },
          (err) => {
            if (err.name !== 'CanceledError') {
              console.error(err);
            }
          }
        );
      } else {
        toast.error("Aucun champ n'est renseigné");
      }
    } else {
      toast.error("Aucun champ n'est renseigné");
    }
  };

  return (
    <>
      {isWaiting ? (
        <>
          <SlideOverHeader hide={closeSlideOver} hideShow={true}>
            <div className={'animate-pulse'}>
              <div className="w-56 h-5 bg-gray-200 rounded"></div>
            </div>
          </SlideOverHeader>
          <SlideOverBody>
            <div className={'flex flex-col gap-2 animate-pulse'}>
              <button
                type={'button'}
                className={'text-left border rounded p-3 mb-5'}
              >
                <div className={'flex justify-between'}>
                  <div className={'my-auto'}>
                    <div className="w-56 h-3 bg-gray-200 rounded"></div>
                  </div>
                  <div className={'my-auto'}>
                    <ChevronRight wh={24} color={'#111827'} />
                  </div>
                </div>
              </button>
              <div className={''}>
                <div
                  className={'border rounded p-3 flex justify-between gap-2'}
                >
                  <div className={'my-auto w-full'}>
                    <div className="w-56 h-3 bg-gray-200 rounded"></div>
                  </div>
                  <div className={'my-auto w-full'}>
                    <div className="w-56 h-6 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </div>
              <div className={''}>
                <div
                  className={'border rounded p-3 flex justify-between gap-2'}
                >
                  <div className={'my-auto'}>
                    <div className="w-72 h-3 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </div>
              <div className={'border rounded p-3 flex flex-col gap-1'}>
                <div className="w-56 h-3 bg-gray-200 rounded"></div>
                <div className="w-56 h-3 bg-gray-200 rounded"></div>
                <div className="w-36 h-3 bg-gray-200 rounded"></div>
              </div>
            </div>
          </SlideOverBody>
        </>
      ) : (
        <>
          {!isShowCoeff ? (
            <>
              <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                <div className={'flex flex-col'}>
                  {companyInfo?.name_commercial}
                  <div className={'text-sm text-gray-500'}>
                    <CopyToClipboard text={companyInfo?.id_bestt}>
                      {companyInfo?.id_bestt}
                    </CopyToClipboard>
                  </div>
                </div>
              </SlideOverHeader>
              <SlideOverBody>
                <div
                  className={
                    'flex flex-col gap-5 text-sm h-full overflow-y-auto'
                  }
                >
                  <div className={'flex items-center gap-2 w-full'}>
                    {isScrollable && (
                      <Button size="ICON" color="ICON" onClick={scrollLeft}>
                        <ChevronLeft wh={24} color="#111827" />
                      </Button>
                    )}
                    <div
                      ref={scrollContainerRef}
                      className={'flex gap-2 overflow-x-auto no-scrollbar'}
                    >
                      <div
                        className={`cursor-pointer p-2 rounded-md whitespace-nowrap hover:bg-gray-100 hover:text-gray-900 ${
                          pageType === 'overview'
                            ? 'bg-gray-100'
                            : 'text-gray-500'
                        }`}
                        onClick={() => setPageType('overview')}
                      >
                        Vue d'ensemble
                      </div>
                      <div
                        className={`cursor-pointer p-2 rounded-md whitespace-nowrap hover:bg-gray-100 hover:text-gray-900 ${
                          pageType === 'members'
                            ? 'bg-gray-100'
                            : 'text-gray-500'
                        }`}
                        onClick={() => setPageType('members')}
                      >
                        Membres
                      </div>
                      <div
                        className={`cursor-pointer p-2 rounded-md whitespace-nowrap hover:bg-gray-100 hover:text-gray-900 ${
                          pageType === 'remuneration_conditions'
                            ? 'bg-gray-100'
                            : 'text-gray-500'
                        }`}
                        onClick={() => setPageType('remuneration_conditions')}
                      >
                        Conditions de rémunération
                      </div>
                      <div
                        className={`cursor-pointer p-2 rounded-md whitespace-nowrap hover:bg-gray-100 hover:text-gray-900 ${
                          pageType === 'bonus_and_compensation'
                            ? 'bg-gray-100'
                            : 'text-gray-500'
                        }`}
                        onClick={() => setPageType('bonus_and_compensation')}
                      >
                        Prime & indemnité
                      </div>
                    </div>
                    {isScrollable && (
                      <Button size="ICON" color="ICON" onClick={scrollRight}>
                        <ChevronRight wh={24} color="#111827" />
                      </Button>
                    )}
                  </div>
                  <div className={'flex flex-col gap-4 h-full overflow-y-auto'}>
                    {pageType === 'overview' && (
                      <SlideOverCompanyOverview
                        companyInfo={companyInfo}
                        setCompanyInfo={setCompanyInfo}
                      />
                    )}
                    {pageType === 'members' && (
                      <SlideOverCompanyMembers
                        companyInfo={companyInfo}
                        setCompanyInfo={setCompanyInfo}
                      />
                    )}
                    {pageType === 'remuneration_conditions' && (
                      <SlideOverCompanyRemunerationConditions
                        companyInfo={companyInfo}
                        setCompanyInfo={setCompanyInfo}
                        setIsShowCoeff={setIsShowCoeff}
                        setIsCoeffSend={setIsCoeffSend}
                      />
                    )}
                    {pageType === 'bonus_and_compensation' && (
                      <SlideOverCompanyBonusAndCompensation
                        companyInfo={companyInfo}
                        setCompanyInfo={setCompanyInfo}
                      />
                    )}
                  </div>
                </div>
              </SlideOverBody>
            </>
          ) : (
            <>
              <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                <div className={'text-sm mb-3'}>
                  <button
                    type={'button'}
                    className={'border rounded p-2'}
                    onClick={() => setIsShowCoeff(false)}
                  >
                    <div className={'flex justify-between gap-3'}>
                      <div className={'my-auto'}>
                        <ChevronLeft wh={20} color={'#111827'} />
                      </div>
                      <div className={'my-auto'}>Profil de l'entreprise</div>
                    </div>
                  </button>
                </div>
                <div>Paramètres de paiement</div>
                <div className={'text-gray-500 text-sm'}>
                  Consulter et gérer le coefficient de facuration et les
                  conditions de paiement de l’entreprise utilisatrice.
                </div>
              </SlideOverHeader>
              <SlideOverBody>
                <div className={'flex flex-col gap-4'}>
                  {isCoeffSend ? (
                    <div className="">
                      <Banner type="warning">
                        Les données sont actuellement visible par l’entreprise.
                      </Banner>
                    </div>
                  ) : (
                    <div className="">
                      <Banner type="info">
                        Les informations ne sont pas encore visible par le
                        client, ajoutez des coefficients et confirmez afin de
                        les rendre visible.
                      </Banner>
                    </div>
                  )}
                  {companyInfo?.coefficients.length > 0 && (
                    <Table
                      champsBody={companyInfo?.coefficients}
                      champsHeader={titlesCoeff}
                      type={'coeff'}
                    />
                  )}
                </div>
              </SlideOverBody>
              <SlideOverFooter>
                <Button
                  size={'LG'}
                  color={'SECONDARY'}
                  onClick={closeSlideOver}
                >
                  Annuler
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={submitCoeff}>
                  Confirmer
                </Button>
              </SlideOverFooter>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SlideOverCompany;

import {useEffect, useRef, useState} from "react";

const TextSelect = ({ onTextSearch, dataListFiltered, onSelect, selectID }) => {
    const [textSearch, setTextSearch] = useState('')
    const [selectedTextSearch, setSelectedTextSearch] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedData, setSelectedData] = useState(null)
    const dropDownRef = useRef(null)

    useEffect(() => {
        let handler = (e) => {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            } else {
                setDropdownOpen(true)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    useEffect(() => {
        if (selectID) {
            setSelectedData(selectID)
        }
    }, [selectID])

    useEffect(() => {
        if (selectedData) {
            onSelect(selectedData)
            setTextSearch(dataListFiltered.find((data) => data.id === selectedData)?.names[0] ?? '')
            setSelectedTextSearch(dataListFiltered.find((data) => data.id === selectedData)?.names[0] ?? '')
        }
    }, [selectedData]);

    useEffect(() => {
        onTextSearch(textSearch)
        if (textSearch !== selectedTextSearch) {
            setSelectedData(null)
        }
    }, [textSearch]);

    return (
        <div className={'relative'} ref={dropDownRef}>
            <input type="text"
                   value={textSearch}
                   onChange={(e) => setTextSearch(e.target.value)}
                   className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg`}
            />
            {(dropdownOpen && !selectedData) &&
                <>
                    {dataListFiltered.length > 0 ? (
                        <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                            <div className={'bg-white border rounded'}>
                                {dataListFiltered.map((dataC, index) => (
                                    <>
                                        <div key={index} className={`flex flex-col gap-2 p-2 ${dataListFiltered.length -1 !== index && 'border-b'}`}>
                                            <button className={'flex flex-row gap-2 items-center'} onClick={(e) => {
                                                e.preventDefault()
                                                setSelectedData(dataC.id)
                                            }}>
                                                <div className={'flex flex-col'}>
                                                    {dataC.names.map((name, index) => (
                                                        <div key={index} className={`ft-sm text-left ${index > 0 ? 'text-gray-500 text-sm' : 'text-base text-black'}`}>{name}</div>
                                                    ))}
                                                </div>
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                            <div className={'bg-white border rounded'}>
                                <div className={`flex flex-col gap-2 p-2`}>
                                    <p className={'ft-sm'}>Aucun résultat</p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            }
        </div>
    )
}

export default TextSelect
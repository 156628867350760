import React, {useContext} from 'react';
import Cv from "pages/content/savoirfaire/cv";
import MediaQuery from "react-responsive";
import Experience from "pages/content/savoirfaire/experience";
import Langues from "pages/content/savoirfaire/langues";
import Tools from "pages/content/savoirfaire/tools";
import {ChevronRight} from "assets/icons";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UseContext from "hooks/useContext";
import Divider from "components/divider/divider";

const Savoirfaire = () => {
    const {language} = UseContext()
    const route = useLocation()
    const {pathname} = route
    const navigate = useNavigate()
    const linkMobile = [
        {
            path: 'cv',
            pTrad: 'savoir.cv'
        }, {
            path: 'experiences',
            pTrad: 'savoir.exp'
        },
        {
            path: 'skills',
            pTrad: 'savoir.skills'
        },
        {
            path: 'language',
            pTrad: 'savoir.lang'
        },
        {
            path: 'tools',
            pTrad: 'savoir.tools'
        }
    ]


    return (
        <>
            <MediaQuery minWidth={769}>
                <div className='col-span-12 flex justify-center items-center'>
                    <div className=' w-[800px]'>
                        <div className="flex flex-col gap-8">
                            <Cv/>
                            <Divider w={95}/>
                            <Experience/>
                            {/*<Competence/>*/}
                            <Langues/>
                            <Tools/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                {pathname === '/profil/savoir_faire' ?
                    <div className="col-span-12 mt-8 mb-28">
                        {linkMobile.map((item) =>
                            (<div key={item.path} onClick={e =>
                                navigate(`./${item.path}`)
                            }>
                                <div
                                    className='flex items-center justify-between h-14 px-2 my-2'>
                                    <p className='ft-sm font-bold text-gray-900'>
                                        {language.getValue(item.pTrad)}
                                    </p>
                                    <ChevronRight color={'#111827'} wh={15}/>
                                </div>
                            </div>)
                        )}
                    </div>
                    :
                    <div className="col-span-12 flex justify-center items-center mb-32">
                        <Outlet/>
                    </div>
                }
            </MediaQuery>
        </>
    )
        ;
};

export default Savoirfaire;
import React from 'react';

const Button = ({color, size, className, children, disabled, onClick}) => {

    const BUTTON_COLOR = {
        PRIMARY: 'hover:bg-gray-50 hover:text-gray-700 hover:shadow-md active:bg-gray-100 bg-yellow-500 text-white rounded-md sh-sm',
        PRIMARYICO: 'active:bg-gray-100 bg-yellow-500 text-white rounded-md sh-sm',
        SECONDARY: 'hover:bg-gray-50 hover:text-gray-700 hover:shadow-md active:bg-gray-100 bg-white text-gray-700 border b-white rounded-md border-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100',
        DEFAULT: 'hover:bg-gray-50 hover:text-gray-700 hover:shadow-md active:bg-gray-100 bg-transparent text-gray-700 rounded',
        DISABLED: 'bg-gray-50 text-gray-400 border-0 b-gray-300 rounded-md sh-sm',
        ICON: "bg-transparent flex items-center justify-center rounded-md",
        REFUSED: 'hover:bg-red-100 hover:text-red-700 hover:shadow-md active:bg-red-100 bg-red-50 text-white rounded-md sh-sm',
    }

    const BUTTON_SIZE = {
        SM: 'ft-sm py-2 px-4',
        SMI: 'ft-sm p-2',
        LG: 'h-12 ft-lg py-3 px-6',
        LGI: 'ft-sm p-3',
        FULL: 'w-full ft-lg py-3 px-6',
        ICON: "h-9 w-9",
    }
    const colorClassName = BUTTON_COLOR[color || 'PRIMARY']
    const sizeClassName = BUTTON_SIZE[size || 'SM']

    return (
        <button type={'button'}
                className={'flex flex-row justify-center items-center gap-2 ' + sizeClassName + ' ' + colorClassName + ' ' + className}
                disabled={disabled} onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
import CopyToClipboard from "../../copyToClipboard";
import UseContext from "../../../hooks/useContext";
import useSlideOver from "../../SlideOver/useSlideOver";
import {CheckIcon, ExternalLink, NegativeThumb, PositiveThumb} from "../../../assets/icons";
import {useLocation} from "react-router-dom";
import useModal from "../../modal/useModal";
import React, {useEffect, useState} from "react";

const TbodyVivierRatingTempWorker = ({ champsBody }) => {
    const { setObjID, reviewUntreatedDelete } = UseContext()
    const [reviews, setReviews] = useState(champsBody)
    const {toggle} = useModal()
    const { toggleSlideOver } = useSlideOver();
    const pathname = useLocation().pathname

    const openCompanyInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_company')
    }

    const openModalRatingValidation = (id_rating) => {
        setObjID(id_rating)
        toggle('rating_temp_worker_validation')
    }

    useEffect(() => {
        if(reviewUntreatedDelete) {
            setReviews(reviews.filter((review) => review._id !== reviewUntreatedDelete))
        }
    }, [reviewUntreatedDelete]);

    const openTempWorkerInfo = (id) => {
        setObjID(id)
        toggleSlideOver('get_temp_worker')
    }

    return (
        <>
            {reviews?.map((rating, key) => (
                <tr key={key} className={'border-b-2 border-gray-100'}>
                    <td className={'py-5'}>
                        <div className={'flex gap-1'}>
                            <CopyToClipboard text={rating?.user?.firstname + ' ' + rating?.user?.lastname}>
                                <div className={'text-gray-900'}>
                                    {rating?.user?.firstname} {rating?.user?.lastname}
                                </div>
                            </CopyToClipboard>
                            <div className={'my-auto cursor-pointer'} onClick={() => openTempWorkerInfo(rating?.user?._id)}>
                                <ExternalLink wh={18} color={'#235FE6'}/>
                            </div>
                        </div>
                        <div className={'text-gray-500'}>
                            <CopyToClipboard text={rating?.user?.email}>
                                <a className={'underline'} href={`mailto:${rating?.user?.email}`}>
                                    {rating?.user?.email}
                                </a>
                            </CopyToClipboard>
                        </div>
                    </td>
                    <td className={'py-5'}>
                        <div className={'text-gray-900'}>
                            {rating?.mission?.title} n°{rating?.mission?.id_mission}
                        </div>
                        <div className={'text-gray-500'}>
                            <div className={'flex gap-2'}>
                                <div className={"my-auto"}>
                                    {rating?.name_commercial}
                                </div>
                                <div className={"my-auto cursor-pointer"} onClick={() => openCompanyInfo(rating?.id_company)}>
                                    <ExternalLink wh={18} color={'#235fe6'}/>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className={'py-5'}>
                        <div className={'flex gap-2 max-w-[250px] max-h-[100px] text-ellipsis overflow-hidden'}>
                            {rating.type === 'positive' ? (
                                <div className={'my-auto w-fit rounded-full p-2 bg-green-50'}>
                                    <PositiveThumb wh={24} color={'#10B981'}/>
                                </div>
                            ) : (
                                <>
                                    <div className={'my-auto w-fit rounded-full p-2 bg-red-50'}>
                                        <NegativeThumb wh={24} color={'#EF4444'}/>
                                    </div>
                                </>
                            )}
                            <div className={'my-auto'}>
                                {rating.type_negative === 'performance' && (
                                    <div className={'text-gray-900'}>
                                        Performance
                                    </div>
                                )}
                                {rating.type_negative === 'professionalism' && (
                                    <div className={'text-gray-900'}>
                                        Professionnalisme
                                    </div>
                                )}
                                <div className={'text-gray-500'}>
                                    {rating?.comment}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className={'py-5'}>
                        <div className={'text-gray-500 max-w-[250px] max-h-[100px] text-ellipsis overflow-hidden'}>
                            {rating?.employee?.firstname} {rating?.employee?.lastname}
                        </div>
                    </td>
                    {pathname.includes('rating-untreated') && (
                        <td className={'py-5'}>
                            <div className={'cursor-pointer w-9 h-9 flex rounded hover:bg-gray-100'} onClick={() => openModalRatingValidation(rating?._id)}>
                                <div className={'m-auto'}>
                                    <CheckIcon w={24} h={12} color={'#374151'}/>
                                </div>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
        </>
    )
}

export default TbodyVivierRatingTempWorker